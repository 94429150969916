/**
 *
 * PrivacyLink
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { FormattedHTMLMessage } from 'react-intl';
import messages from './messages';

function PrivacyLink() {
  return (
    <a href="#privacy">
      <FormattedHTMLMessage {...messages.privacy} />
    </a>
  );
}

export default PrivacyLink;
