const conditions = {
  nl: `

## Algemene voorwaarden

1.  Algemeen
    1.  Deze algemene voorwaarden zijn van toepassing op alle overeenkomsten tussen HarryOnline (inclusief de door HarryOnline geëxploiteerde websites waaronder schaatshorloge.nl, runnermaps.nl en smartersudoku.nl en de bijbehorende applicaties) hierna gezamenlijk te noemen HarryOnline, en haar wederpartijen bij enige overeenkomst hierna te noemen Gebruiker.
    2.  Voor zover hierna wordt gesproken over geleverde zaken of softwarediensten (zoals een account via een website of mobiele applicatie), worden daaronder ook begrepen abonnementen.
    3.  Deze algemene voorwaarden zijn niet van toepassing op overeenkomsten met betrekking tot producten van of via HarryOnline die door Gebruiker rechtstreeks worden gesloten met derden, waaronder begrepen via de app-stores van Apple, Google, Garmin en Pebble.
2.  Identificatie
    *   HarryOnline is gevestigd te Buenos Aireshof 46, 2548 HE Den Haag  
        Telefoon 070-3099139  
        E-mailadres: mail@harryonline.net  
        KvK-nummer: 27251733  
        BTW-identificatienummer: NL 0019.44.671.B35
3.  Aanbiedingen en prijzen
    1.  Een aanbieding van HarryOnline die een kennelijke verschrijving en/of vergissing bevat is nietig en leidt niet tot enig rechtsgevolg voor HarryOnline. HarryOnline kan niet aan haar aanbiedingen worden gehouden indien Gebruiker redelijkerwijs kan begrijpen dat de aanbiedingen, dan wel een onderdeel daarvan, een kennelijke vergissing of verschrijving bevat.
    2.  Alle prijzen zijn inclusief BTW, tenzij uitdrukkelijk anders vermeld.
4.  Softwarediensten
    1.  Indien Gebruiker van HarryOnline softwarediensten afneemt, verstrekt HarryOnline een niet-exclusief, niet-overdraagbaar en strikt persoonlijk gebruiksrecht voor deze softwarediensten aan Gebruiker door middel van toegang tot die diensten via de persoonlijke account van Gebruiker tot wederopzegging daarvan door middel van staking van het gebruik, tenzij uitdrukkelijk anders is bepaald.
    2.  HarryOnline draagt zorg voor bereikbaarheid van de softwarediensten en spant zich in om Gebruiker continue toegang tot de softwarediensten te verschaffen. HarryOnline garandeert niet dat de softwarediensten te allen tijde ononderbroken of volledig beschikbaar zullen zijn.
    3.  HarryOnline draagt zorg voor onderhoud aan de softwarediensten waar door haar noodzakelijk geacht. Indien de softwarediensten door onderhoud niet bereikbaar zijn, zal HarryOnline trachten dit tenminste 48 uur van te voren aan Gebruiker aankondigen door (naar keuze van HarryOnline) daarvan mededeling te doen op de softwarediensten en/of per e-mail.
5.  Betaling, levering en ontbinding
    1.  Betaling voor softwarediensten door Gebruiker dient altijd vooraf plaats te vinden.
    2.  Levering van softwarediensten geschiedt direct na ontvangst van betaling door het ter beschikking stellen van toegang daartoe via de persoonlijke account aan Gebruiker. Indien de betaling van Gebruiker na ontvangst daarvan door HarryOnline op enige wijze wordt gestorneerd, gerestitueerd of anderszins ongedaan wordt gemaakt door de Gebruiker of door een derde, ontbindt HarryOnline de overeenkomst met de gebruiker en beëindigt HarryOnline de toegang van de Gebruiker tot de softwarediensten met onmiddellijke ingang.
6.  Bedenktijd
    1.  Voor het gebruik van de softwarediensten geldt een bedenktijd (proefperiode) van 14 dagen vanaf de dag nadat daartoe toegang is verstrekt door HarryOnline via de persoonlijke account van Gebruiker waarna Gebruiker deze softwaredienst kan beëindigen door middel van opzegging via de website of de support desk van HarryOnline, te bereiken via de website of het daartoe bestemde formulier op de contact-pagina van de betreffende website van HarryOnline. De softwaredienst zal door HarryOnline vervolgens niet meer aan Gebruiker ter beschikking worden gesteld en HarryOnline zal de door Gebruiker betaalde aankoopprijs binnen dertig dagen na opzegging door Gebruiker restitueren.
7.  Abonnementen
    1.  Softwarediensten die worden afgenomen via een abonnement worden afgesloten voor een bepaalde duur en kunnen door Gebruiker niet tussentijds worden beëindigd.
    2.  Na verloop van een overeengekomen abonnementsperiode, worden abonnementen niet automatisch verlengd, tenzij uitdrukkelijk anders is overeengekomen.
8.  Garanties en aansprakelijkheid
    1.  De door HarryOnline te leveren softwarediensten voldoen aan de gebruikelijke eisen en normen die daaraan op het moment van levering c.q. beschikbaarstelling door middel van toegang tot die dienst redelijkerwijs gesteld kunnen worden en waarvoor zij bij normaal gebruik zijn bestemd. HarryOnline garandeert derhalve niet dat de te leveren producten en softwarediensten geschikt zijn voor het door Gebruiker bestemde gebruik.
    2.  HarryOnline maakt regelmatig (wekelijks en dagelijks) een back-up van alle door haar op haar server opgeslagen tijden en andere gegevens. HarryOnline adviseert Gebruiker te allen tijde zelf zijn gegevens lokaal op te slaan. HarryOnline is derhalve niet aansprakelijk voor het verloren gaan van gegevens of dientengevolge geleden schade. HarryOnline is niet aansprakelijk voor schade ontstaan doordat haar server (met inbegrip van de door haar te leveren softwarediensten) niet bereikbaar is.
    3.  HarryOnline is niet aansprakelijk voor schade die ontstaat doordat een softwaredienst niet beschikbaar is voor Gebruiker, tenzij sprake is van opzet of bewuste roekeloosheid aan de zijde van HarryOnline.
    4.  HarryOnline is niet aansprakelijk voor schade, van welke aard ook, ontstaan doordat HarryOnline is uitgegaan van door of namens Gebruiker verstrekte onjuiste en/of onvolledige gegevens.
    5.  Aansprakelijkheid van HarryOnline voor welke schade dan ook is uitgesloten tenzij er sprake is van opzet of grove schuld. In het geval van consumentenkoop strekt deze beperking niet verder dan die, die is toegestaan ingevolge artikel 7:24 lid 2 BW.
    6.  Indien HarryOnline aansprakelijk is voor enige schade, dan is de aansprakelijkheid van HarryOnline beperkt tot maximaal de waarde van de bestelling, althans tot dat gedeelte van de bestelling waarop de aansprakelijkheid betrekking heeft. De aansprakelijkheid van HarryOnline is verder steeds beperkt tot het bedrag dat haar verzekeraar in voorkomend geval uitkeert.
9.  Intellectuele eigendom
    1.  Alle rechten van intellectuele eigendom, waaronder begrepen maar niet beperkt auteursrecht en databankrechten op HarryOnline, haar producten of softwarediensten berusten uitsluitend bij HarryOnline of haar licentiegevers. Door het aangaan en uitvoeren van een overeenkomst tussen HarryOnline en Gebruiker worden derhalve geen auteursrechten, merkrechten of andere rechten van intellectuele eigendom op de geleverde producten, softwarediensten, de broncode daarvan of de ontwerpen daarvan of afbeeldingen daarop overgedragen aan Gebruiker. Voor het gebruik van de softwarediensten wordt door HarryOnline aan Gebruiker slechts een gebruiksrecht als omschreven in artikel 3 verstrekt, welk gebruiksrecht op elk moment door HarryOnline kan worden opgezegd door niet langer toegang te verschaffen via de persoonlijke account.
10.  Privacy policy
    1.  HarryOnline verwerkt de persoonsgegevens van Gebruiker die nodig zijn voor het verlenen van diensten aan Gebruiker. Op deze verwerking van persoonsgegevens is de betreffende Privacy Policy van HarryOnline van toepassing. Gebruiker geeft door het aanmaken van een account toestemming voor de verwerking van zijn persoonsgegevens zoals beschreven in de Privacy Policy.
11.  Toepasselijk recht en geschillen
    1.  Op alle rechtsbetrekkingen waarbij HarryOnline partij is, is uitsluitend het Nederlands recht van toepassing, ook indien aan een verbintenis geheel of gedeeltelijk in het buitenland uitvoering wordt gegeven of indien de bij de rechtsbetrekking betrokken partij aldaar woonplaats heeft. De toepasselijkheid van het Weens Koopverdrag wordt uitgesloten.
    2.  Partijen zullen pas een beroep op de rechter doen nadat zij zich tot het uiterste hebben ingespannen een geschil in onderling overleg te beslechten.

Bijgewerkt: 9 nov. 2020
`,
};

export default conditions;
