import firebase from 'firebase/app';
import 'firebase/auth';

const config = {
  apiKey: 'AIzaSyB8E4xvs1-_Nkc4LWKq_WgjCUZf3sA9VVA',
  authDomain: 'runnermaps-authentication.firebaseapp.com',
  databaseURL: 'https://runnermaps-authentication.firebaseio.com',
  projectId: 'runnermaps-authentication',
  storageBucket: 'runnermaps-authentication.appspot.com',
  messagingSenderId: '5824890436',
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export default firebase;
