import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormField from './index';

function renderField(messages) {
  const handleChange = ev => {
    const { id, value, val } = ev.target;
    this.setState({ [id]: val === undefined ? value : val });
  };
  return options => {
    const { id } = options;
    return (
      <FormField
        key={id}
        value={this.state[id]}
        {...options}
        onChange={handleChange}
      >
        <FormattedMessage {...messages[id]} />
      </FormField>
    );
  };
}

export default renderField;
