/**
 *
 * Link
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import withRoute from 'containers/withRoute';

function Link(props) {
  const { href, onLinkClick, className, children } = props;
  return (
    <a className={className} href={href} onClick={onLinkClick}>
      {children}
    </a>
  );
}

Link.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  onLinkClick: PropTypes.func.isRequired,
};

export default withRoute(Link);
