function aroundInvalid(data, range = 1) {
  const includeSet = new Set();
  data.forEach((p, idx) => {
    if (p.valid !== false) {
      return;
    }
    for (let i = idx - range; i <= idx + range; i++) {
      includeSet.add(i);
    }
  });
  return data.filter((p, idx) => includeSet.has(idx));
}

export default aroundInvalid;
