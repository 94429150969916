import memoize from 'fast-memoize';
import { STRAVA_CONNECTED } from 'containers/User/constants';

function joined(strava, entries) {
  if (!strava || !strava.status === STRAVA_CONNECTED || !entries) {
    return false;
  }
  const { athlete_id } = strava;
  return entries.some(e => e.athlete_id === athlete_id);
}

export default memoize(joined);
