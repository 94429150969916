import { css, createGlobalStyle } from 'styled-components';
import { black, white, green500, grey700, red500 } from './colors';

const editIconCss = css`
  border-radius: 50%;
  border: 2px solid ${black};
  background-color: ${white};
`;

const EditIconsStyles = createGlobalStyle`
.edit-icon-default {
  ${editIconCss};
  border-color: ${black};
  background-color: ${white};
}

.edit-icon-start {
  ${editIconCss};
  border-color ${black};
  background-color: ${green500};
}

.edit-icon-end {
  ${editIconCss};
  border-color: ${black};
  background-color: ${red500};
}

.edit-icon-hover {
  ${editIconCss};
  border: 2px solid ${grey700};
  background-color: ${white};
}
`;

export default EditIconsStyles;
