/*
 * PrList Messages
 *
 * This contains all the text for the PrList component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.Kmvreters.PrList.title',
    defaultMessage: 'PBs of members of {club}',
  },
  name: {
    id: 'app.components.Kmvreters.PrList.name',
    defaultMessage: 'Name',
  },
  distance: {
    id: 'app.components.Kmvreters.PrList.distance',
    defaultMessage: 'Distance',
  },
  time: {
    id: 'app.components.Kmvreters.PrList.time',
    defaultMessage: 'Time',
  },
  date: {
    id: 'app.components.Kmvreters.PrList.date',
    defaultMessage: 'Date',
  },
  event: {
    id: 'app.components.Kmvreters.PrList.event',
    defaultMessage: 'Wedstrijd',
  },
});
