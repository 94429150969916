import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import FormField from '.';
import controlValue from './controlValue';

function useForm(initialState, messages) {
  const [state, setState] = useState(initialState);
  const setValue = key => value =>
    setState({ ...state, [key]: controlValue(value) });
  function renderField(attribs) {
    const { id } = attribs;
    const message = messages && messages[id];
    return (
      <FormField value={state[id]} onChange={setValue(id)} {...attribs}>
        {message && <FormattedMessage {...message} />}
      </FormField>
    );
  }
  return [state, setState, renderField];
}

export default useForm;
