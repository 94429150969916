/**
 *
 * Wrapper
 *
 */

import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 10px;
  @media (orientation: portrait) and (min-width: 768px) {
    width: 748px;
    margin: 0 auto;
  }
  @media (orientation: landscape) and (min-width: 878px) {
    width: 748px;
    margin: 0 auto;
  }
  th {
    text-align: center;
  }
`;

export default Wrapper;
