// import { take, call, put, select } from 'redux-saga/effects';

import { put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { unserialize, serialize } from 'utils/urlUtils';
import { makeSelectLocation } from './selectors';
import { HASH_ADD, HASH_CLEAR, HASH_DELETE } from './constants';

function getHash(action, hash) {
  const { type, key, value } = action;
  switch (type) {
    case HASH_ADD:
      return typeof key === 'object'
        ? { ...unserialize(hash), ...key }
        : { ...unserialize(hash), [key]: value };
    case HASH_CLEAR:
      return {};
    case HASH_DELETE:
      return { ...unserialize(hash), [key]: undefined };
    default:
      throw new Error('Invalid action type');
  }
}

// Individual exports for testing
export function* setHash(action) {
  const location = yield select(makeSelectLocation());
  const base = `${location.pathname}${location.search}`;
  const hashString = serialize(getHash(action, location.hash));
  const path = hashString ? `${base}#${hashString}` : base;
  yield put(push(path));
}

export default function* defaultSaga() {
  yield takeLatest(HASH_ADD, setHash);
  yield takeLatest(HASH_CLEAR, setHash);
  yield takeLatest(HASH_DELETE, setHash);
}
