import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Spinner from '../Spinner';
import DataTable from './DataTable';

function UserTable(props) {
  const { loading, users } = props;
  const { pathname } = useLocation();
  const getLink = (content, item) => `${pathname}/${item.id}`;

  if (loading) {
    return <Spinner />;
  }
  if (users.length === 0) {
    return <p>No users</p>;
  }
  const fields = [
    { key: 'email', getLink },
    { key: 'created', className: 'text-center' },
    { key: 'lastLogin', className: 'text-center' },
    {
      key: 'strava_id',
      getLink: content => `/virtual?strava=${content}`,
    },
    { key: 'enabled', className: 'text-center' },
  ];

  return <DataTable data={users} fields={fields} />;
}

UserTable.propTypes = {
  loading: PropTypes.bool,
  users: PropTypes.array,
};
export default UserTable;
