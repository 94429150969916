/* eslint-disable react/no-array-index-key */
/**
 * View file from URL as Tab;e
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Alert, Button, Modal } from 'react-bootstrap';
import useCurrentUser from 'containers/User/useCurrentUser';
import Spinner from 'components/Spinner';
import { useRequest } from 'utils/request';
import messages from '../../messages';
import { lineStyles } from './constants';
import CorrectionsMap from './CorrectionsMap';
import DataTable from './DataTable';
import Graph from './Graph';

const Wrapper = styled(Modal)`
  .modal-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 20px);
    @media (min-width: 768px) {
      height: calc(100vh - 60px);
    }
    .leaflet-container {
      flex-grow: 1;
      height: 100%;
    }
  }
`;

const Legend = styled.div`
  border-left: 3px solid ${p => p.color};
  padding-left: 5px;
  margin-right: 15px;
`;

function MapModal(props) {
  const { activityId, athleteId, onHide } = props;
  const user = useCurrentUser();
  const [tab, setTab] = useState('Map');
  const [data] = useRequest({
    url: `/strava/${athleteId}/${activityId}?format=json`,
    user,
  });
  const dataReady = !data.loading && !data.error;
  const legendItems = [
    { style: lineStyles.accept, label: messages.corrAccept },
    { style: lineStyles.reject, label: messages.corrReject },
    { style: lineStyles.replace, label: messages.corrReplace },
  ];
  function getComponent() {
    switch (tab) {
      case 'Map':
        return CorrectionsMap;
      case 'Table':
        return DataTable;
      case 'Graph':
        return Graph;
      default:
        return null;
    }
  }
  const Component = getComponent();
  return (
    <Wrapper show onHide={onHide} bsSize="large">
      <Modal.Header closeButton>
        <div className="d-flex mr-4">
          <Modal.Title className="flex-grow-1">
            <FormattedMessage {...messages.routeCorrections} />
          </Modal.Title>
          {user.su ? (
            <nav className="mr-5">
              {['Map', 'Table', 'Graph'].map(key => (
                <Button
                  bsStyle="link"
                  className="py-0"
                  key={key}
                  disabled={tab === key}
                  onClick={() => setTab(key)}
                >
                  {key}
                </Button>
              ))}
            </nav>
          ) : (
            legendItems.map(item => (
              <Legend key={item.label.id} color={item.style.color}>
                <FormattedMessage {...item.label} />
              </Legend>
            ))
          )}
        </div>
      </Modal.Header>
      {data.loading && <Spinner className="mt-5" />}
      {data.error && (
        <Alert bsStyle="danger" className="m-4">
          {data.error.message}
        </Alert>
      )}
      {dataReady && <Component data={data} />}
    </Wrapper>
  );
}

MapModal.propTypes = {
  activityId: PropTypes.number.isRequired,
  athleteId: PropTypes.number.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default MapModal;
