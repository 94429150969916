/*
 * Subscription Messages
 *
 * This contains all the text for the SubscriptionModal component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.SubscriptionModal.header',
    defaultMessage: 'Become a RunnerMaps Supporter',
  },
  extend: {
    id: 'app.components.SubscriptionModal.extend',
    defaultMessage: 'Extend Subscription',
  },
  intro: {
    id: 'app.components.SubscriptionModal.intro',
    defaultMessage:
      'A subscription is valid for one year. Before the end of the year, you will receive a notification and you may extend the subscription.',
  },
  recurringLabel: {
    id: 'app.components.SubscriptionModal.recurringLabel',
    defaultMessage:
      'Automatically renew the subscription after each year (optional)',
  },
  recurringHelp: {
    id: 'app.components.SubscriptionModal.recurringHelp',
    defaultMessage:
      'About a week before the renewal date, you will receive a notification. You can cancel the renewal any time.',
  },
  acceptConditions: {
    id: 'app.components.SubscriptionModal.acceptConditions',
    defaultMessage:
      'I have read and agree with the General Conditions (required)',
  },
  viewConditions: {
    id: 'app.components.SubscriptionModal.viewConditions',
    defaultMessage: 'View the General Conditions',
  },
  premiumUntilDate: {
    id: 'app.components.AccountModal.premiumUntilDate',
    defaultMessage: 'Your subscription is valid until {date}',
  },
});

// Ik word RunnerMaps Supporter voor een jaar. Voor het einde van het jaar krijg je een bericht, je kunt dan het abonnement verlengen.
// Ik word doorlopend RunnerMaps Supporter. Na het jaar wordt het abonnementsgeld opnieuw afgeschreven. Je kunt het abonnement altijd stopzetten en je ontvangt tijdig een bericht voordat het verlengd wordt.

// Ik heb de algemene voorwaarden gelezen en ga hiermee akkoord.
