import formatTime from 'utils/formatTime';

const ELLIPSIS = '\u2026';
const NBSP = '\u00A0';

const formatDate = v => v.slice(0, 10);

const basic = [
  { key: 'email' },
  { key: 'language' },
  { key: 'created' },
  { key: 'lastLogin' },
  { key: 'premium_date' },
  { key: 'premium_reminder' },
  { key: 'premium_renew' },
  { key: 'strava_id', getLink: content => `/virtual?strava=${content}` },
  { key: 'clubname', getLink: (content, item) => `/club/${item.club}` },
  { key: 'yob' },
  { key: 'super' },
  { key: 'enabled' },
  {
    key: 'kmvreters',
    getLink: (content, item) => `/kmvreters/list/${item.id}`,
  },
];

const routes = [
  { key: 'title', getLink: (content, item) => `/route/${item.id}` },
  { key: 'city' },
  { key: 'distance', format: v => v.toFixed(1) },
  { key: 'created', format: formatDate, className: 'text-center' },
  { key: 'deleted', className: 'text-center' },
  { key: 'public', className: 'text-center' },
];

const testruns = [
  {
    key: 'title',
    getLink: (content, item) =>
      `/virtual/${item.race_hash}?strava=${item.athlete_id}`,
  },
  { key: 'race_distance' },
  { key: 'race_time', format: formatTime },
  { key: 'distance', format: v => Math.round(v) },
  { key: 'start_date', format: formatDate, className: 'text-center' },
];

const payments = [
  {
    key: 'id',
    getLink: content =>
      `https://my.mollie.com/dashboard/org_2768081/payments/${content}`,
  },
  { key: 'invoice_id' },
  { key: 'amount' },
  {
    key: 'description',
    format: v =>
      `${ELLIPSIS}${NBSP}${v
        .split(/\s+/)
        .slice(-4)
        .join(' ')}`,
  },
  { key: 'created', format: formatDate },
  { key: 'cb_id' },
  { key: 'cb_invoice_id' },
];

const fieldDefinitions = { basic, routes, testruns, payments };

export default fieldDefinitions;
