import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

function getPath(entry) {
  const { activity_id, athlete_id } = entry;
  if (activity_id) {
    return `activities/${activity_id}`;
  }
  if (athlete_id) {
    return `athletes/${athlete_id}`;
  }
  return '';
}

function StravaLink(props) {
  const { entry, target, ...rest } = props;
  const path = getPath(entry);
  if (!path) {
    return null;
  }
  return (
    <a href={`https://www.strava.com/${path}`} target={target} {...rest}>
      <FormattedMessage {...messages.viewOnStrava} />
    </a>
  );
}

StravaLink.propTypes = {
  entry: PropTypes.object,
  target: PropTypes.string,
};

StravaLink.defaultProps = {
  target: '_blank',
};

export default StravaLink;
