/**
 *
 * JoinSwitch
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useCurrentUser } from 'containers/User';
import Switch from 'components/Switch';
import { isPast, isFuture } from 'utils/compareDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import joined from '../joined';
import messages from '../messages';

function JoinSwitch(props) {
  const { race, updateEntry, working } = props;
  const endDate = race.end_date || race.date;
  const { strava } = useCurrentUser();
  const hasJoined = joined(strava, race.entries) ? !working : working; // Optimistic switch
  const disabled = !race.isTestRun && isPast(endDate);
  const canSync =
    hasJoined &&
    !isFuture(race.date) &&
    (race.isTestRun || !isPast(endDate, 1));
  return (
    <span className="d-flex align-items-center">
      <Switch
        id="join-race"
        value={hasJoined}
        disabled={disabled || working}
        onChange={updateEntry}
        inline
        working={working}
      >
        <FormattedMessage {...messages.join} />
      </Switch>
      {canSync && (
        <Button bsStyle="link" onClick={() => updateEntry(true)}>
          <FontAwesomeIcon icon="redo" />
        </Button>
      )}
    </span>
  );
}

JoinSwitch.propTypes = {
  athlete: PropTypes.object,
  race: PropTypes.object,
  working: PropTypes.bool,
  setAthlete: PropTypes.func,
  updateEntry: PropTypes.func,
};

export default JoinSwitch;
