/**
 *
 * Reservations
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Container from 'components/KmVreters/Container';
import Markdown from 'components/Markdown';
import svg from 'images/noun/noun_schedule_green_check.svg';
import messages from './messages';
import UserGroups from './UserGroups';
import text from './introText';

export const Img = styled.img`
  width: 100px;
  margin-left: 10px;
  margin-bottom: 10px;
  float: right;
  @media (min-width: 468px) {
    width: 150px;
  }
`;

function Reservations(props) {
  const { user } = props;
  return (
    <Container width={720}>
      <Container.Header>
        <FormattedMessage {...messages.header} />
      </Container.Header>
      <Img src={svg} />
      <UserGroups user={user} />
      <Markdown source={text} />
    </Container>
  );
}

Reservations.propTypes = {
  user: PropTypes.object,
};

export { default as GroupEdit } from './GroupEdit';
export { default as GroupMembers } from './GroupMembers';
export { default as SessionsView } from './SessionsView';
export default Reservations;
