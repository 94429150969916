const text = {
  nl: `
  Hiermee schrijf je je in voor de trainingen, als niet iedereen tegelijk kan trainen.
  
  Met het schuifje naast de trainingstijd geef je aan of wel of niet komt.
  
  Iedereen kan in principe even vaak meedoen, want als je langer niet bent geweest, kom je hoger op de lijst.

  De volgorde wordt 24 uur voor de training vastgelegd, zodat je tijdig weet of je mee kunt trainen. Je kunt daarna nog wel inschrijven, maar dan gaat het op volgorde van inschrijving.
  
  ## Vaste dag
  
  Je kunt een vaste dag instellen. Je wordt dan automatisch ingeschreven voor de trainingen op die dag. Je moet je dan wel afmelden als je niet kunt, zodat iemand anders in jouw plaats kan komen.`,
  en: `
  Here you can register for training sessions, in case not everyone can attend at the same time.
  
  Use the switch next to the session time to indicate whether you join or not.
  
  In principle, everyone can join equally often, as the longer you did not attend, the higher you will be on the list.
  
  The order is fixed 24 hours before the session starts, so you know well in advance whether you can join. You can still register after that, but the order will then be the time of registration.
  
  ## Fixed Day
  
  You can set a fixed day. You will automatically be registered for the training session on that day. In that case, you have to cancel if you can't make it, so another person can take your spot. `,
};

export default text;
