import React from 'react';
import { Button, ControlLabel, Radio } from 'react-bootstrap';
import useSelectContext from './useSelectContext';
import useAuthRequest from '../useAuthRequest';

function SelectForm() {
  const { items } = useSelectContext();
  const [emailId, setEmailId] = React.useState('');
  const authRequest = useAuthRequest();

  if (items.length === 0) {
    return null;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const userIds = items.map(item => item.id).sort();
    const { email } = items.find(item => item.id === emailId);
    const response = await authRequest('/manage/merge', {
      method: 'POST',
      body: { userIds, email },
    });
    console.log('Merge response', response);
  }

  return (
    <form className="border p-3 d-flex justify-content-between align-items-end">
      <div>
        <ControlLabel>Select the email to keep:</ControlLabel>
        {items.map(item => (
          <Radio
            key={item.id}
            name="radioGroup"
            onChange={() => setEmailId(item.id)}
            className="mb-0 mt-3"
          >
            {item.email}
          </Radio>
        ))}
      </div>
      <div>
        <Button
          bsStyle="primary"
          type="submit"
          disabled={items.length < 2 || !emailId}
          onClick={handleSubmit}
        >
          Merge users
        </Button>
      </div>
    </form>
  );
}

export default SelectForm;
