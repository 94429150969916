import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

function EmailButton(props) {
  const { onClick } = props;
  return (
    <li id="email-button" className="firebaseui-list-item">
      <button
        className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-password firebaseui-id-idp-button"
        style={{ backgroundColor: '#db4437' }}
        onClick={onClick}
      >
        <span className="firebaseui-idp-icon-wrapper">
          <img
            className="firebaseui-idp-icon"
            alt=""
            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/mail.svg"
          />
        </span>
        <span className="firebaseui-idp-text firebaseui-idp-text-long">
          <FormattedMessage {...messages.emailSignIn} />
        </span>
        <span className="firebaseui-idp-text firebaseui-idp-text-short">
          Email
        </span>
      </button>
    </li>
  );
}

EmailButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default EmailButton;
