/**
 *
 * Import
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { compose } from 'redux';
import { omit } from 'lodash';
import {
  Alert,
  Button,
  Form,
  FormControl,
  FormGroup,
  ControlLabel,
  Table,
} from 'react-bootstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withKvApi from 'containers/KmVretersPage/withKvApi';
import withUser from 'containers/User/withUser';
import withRoute from 'containers/withRoute';
import withSettings from 'containers/SettingsProvider/withSettings';
import Spinner from 'components/Spinner';
import PrimaryAction from 'components/PrimaryAction';
import MetaTags from 'components/MetaTags';
import Container from '../Container';
import messages from './messages';

/* eslint-disable react/prefer-stateless-function */
class Import extends React.PureComponent {
  static getDerivedStateFromProps(props, state) {
    const { user, fetchList, data } = props;
    if (data.loading) {
      return null;
    }
    if (state.name === null && user.api) {
      const name = user.auth.displayName;
      fetchList(`kmvreters/import?n=${name}&p=${1}`);
      return { name, page: 1 };
    }
    const { selected } = state;
    if (data.value && data.value.list.length > selected.length) {
      const newRaces = data.value.list
        .slice(selected.length)
        .map(race => !data.value.dates.includes(race.date));
      return { selected: [...selected, ...newRaces] };
    }
    return null;
  }

  state = { name: null, page: 1, selected: [] };

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (prevProps.data.working && !data.working && !data.error) {
      this.toList();
    }
  }

  handleChange = ev => {
    this.setState({ name: ev.target.value });
  };

  handleSearch = ev => {
    ev.preventDefault();
    const { fetchList } = this.props;
    const { name } = this.state;
    this.setState({ selected: [], page: 1 });
    const url = `kmvreters/import?n=${name}&p=1`;
    fetchList(url);
  };

  loadMore = () => {
    const { fetchList } = this.props;
    this.setState(prev => {
      const { name, page } = prev;
      const url = `kmvreters/import?n=${name}&p=${page + 1}`;
      fetchList(url);
      return { page: page + 1 };
    });
  };

  handleSubmit = ev => {
    ev.preventDefault();
    const formData = new FormData(ev.target);
    const { data, apiChange } = this.props;
    const { selected } = this.state;
    const list = data.value.list
      .map(
        (race, idx) =>
          race.distance
            ? race
            : { ...race, distance: Number(formData.get(`dist_${idx}`)) },
      )
      .filter((race, idx) => selected[idx])
      .map(race => ({
        ...omit(race, ['name']),
        distance: race.distance.toFixed(1),
      }));
    apiChange({
      body: { list },
    });
  };

  handleToggle = ev => {
    const id = ev.target.id.slice(4);
    const value = ev.target.checked;
    this.setState(prev => ({
      selected:
        id === 'all'
          ? prev.selected.map(() => value)
          : prev.selected.map((v, idx) => (idx == id ? value : v)), // eslint-disable-line eqeqeq
    }));
  };

  toList = () => {
    const { goTo } = this.props;
    goTo('/kmvreters/list');
  };

  render() {
    const tags = { title: messages.title };
    const { data, intl } = this.props;
    const valid =
      data.url && data.url.startsWith('kmvreters/import') && data.value;
    const { name, selected, page } = this.state;
    const moreCount = { count: valid && Number(data.value.nPages) - page };
    return (
      <Container width={720}>
        <MetaTags tags={tags} />
        <Container.Header>
          <FormattedMessage {...messages.title} />
        </Container.Header>
        <Form inline onSubmit={this.handleSearch}>
          <FormGroup controlId="name">
            <ControlLabel>Name</ControlLabel>{' '}
            <FormControl
              type="text"
              value={name || ''}
              onChange={this.handleChange}
            />
          </FormGroup>
          <Button type="submit">
            <FormattedMessage {...messages.search} />
          </Button>
        </Form>
        {valid && (
          <React.Fragment>
            <Alert>
              <ul>
                <li>
                  <FormattedMessage {...messages.helpCheck} />
                </li>
                <li>
                  <FormattedMessage {...messages.helpComplete} />
                </li>
                <li>
                  <FormattedMessage {...messages.helpSubmit} />
                </li>
              </ul>
            </Alert>
            <form onSubmit={this.handleSubmit}>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>
                      <input
                        id="chk-all"
                        checked={selected.some(Boolean)}
                        type="checkbox"
                        onChange={this.handleToggle}
                        aria-label={intl.formatMessage(messages.checkAll)}
                      />
                    </th>
                    <th>
                      <FormattedMessage {...messages.date} />
                    </th>
                    <th>
                      <FormattedMessage {...messages.race} />
                    </th>
                    <th className="text-right">
                      <FormattedMessage {...messages.distance} />
                    </th>
                    <th className="text-right">
                      <FormattedMessage {...messages.time} />
                    </th>
                    <th className="text-center">
                      <FormattedMessage {...messages.link} />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.value.list.map((race, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={idx}>
                      <td>
                        <input
                          id={`chk-${idx}`}
                          type="checkbox"
                          checked={Boolean(selected[idx])}
                          onChange={this.handleToggle}
                          aria-label={intl.formatMessage(messages.check)}
                        />
                      </td>
                      <td className="td-date">{race.date}</td>
                      <td>
                        {race.event}, {race.city}
                      </td>
                      <td className="text-right td-distance">
                        {race.distance ||
                          (selected[idx] && (
                            <input
                              type="number"
                              name={`dist_${idx}`}
                              min="0"
                              required
                            />
                          ))}{' '}
                        km
                      </td>
                      <td className="text-right">{race.time}</td>
                      <td className="text-center">
                        <a href={race.link} target="_blank">
                          <span className="sr-only">
                            <FormattedMessage {...messages.link} />
                          </span>
                          <FontAwesomeIcon icon="external-link-alt" />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {data.loading ? (
                <Spinner />
              ) : (
                moreCount.count > 0 && (
                  <React.Fragment>
                    <FormGroup>
                      <Button onClick={this.loadMore}>
                        <FormattedMessage {...messages.more} />
                      </Button>{' '}
                      <FormattedMessage
                        {...messages.morePages}
                        values={moreCount}
                      />
                    </FormGroup>
                    <PrimaryAction
                      onCancel={this.toList}
                      submitText={messages.import}
                      valid={selected.some(Boolean)}
                    />
                  </React.Fragment>
                )
              )}
            </form>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

Import.propTypes = {
  user: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  units: PropTypes.string.isRequired,
  apiChange: PropTypes.func.isRequired,
  fetchList: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  intl: intlShape,
};

export default compose(
  injectIntl,
  withUser,
  withKvApi,
  withRoute,
  withSettings(['units']),
)(Import);
