/*
 * ExplanationModal Messages
 *
 * This contains all the text for the ExplanationModal component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.ExplanationModal.header',
    defaultMessage: 'How It Works',
  },
  explanation: {
    id: 'app.components.ExplanationModal.explanation',
    defaultMessage: 'Explanation',
  },
  understood: {
    id: 'app.components.ExplanationModal.understood',
    defaultMessage: 'OK, clear!',
  },
});
