import { useDispatch } from 'react-redux';
import { DIALOG_ALERT, DIALOG_CONFIRM, DIALOG_PROMPT } from './constants';
import { showDialog } from './actions';

function dialog(dispatch, modalType, modalProps) {
  return new Promise((resolve, reject) => {
    dispatch(
      showDialog(modalType, modalProps, {
        resolve,
        reject,
        rejectOnFalse: modalType !== DIALOG_ALERT,
      }),
    );
  });
}

function useDialog() {
  const dispatch = useDispatch();
  return {
    dlgAlert: options => dialog(dispatch, DIALOG_ALERT, options),
    dlgConfirm: options => dialog(dispatch, DIALOG_CONFIRM, options),
    dlgPrompt: options => dialog(dispatch, DIALOG_PROMPT, options),
  };
}

export default useDialog;
