const credentials = {
  thunderforest: 'apikey=f2a97b56c5c640a28156b9edecb0c021',
  here2: 'apiKey=0wIGLJvD-2Yptoh34mCqLFUgL6hg5Y4BstvO5De8CEk',
  mapbox:
    'access_token=pk.eyJ1IjoiaGFycnlvbmxpbmUiLCJhIjoiY2o3MTI3bmFtMDBrdTMzcWptOXJ3azM4MCJ9.OrhIlMOrb4eZe32Re-a8dQ',
  mapy: '-66TJR1gj3p2ZpFLj1pUexIZbGgt1gM5o7KPK37rKAI',
  openroute: 'api_key=5b3ce3597851110001cf624857dbb6b73f994b708c8ad8955a2ad813',
  opencage: 'e1695abd265e471283ce945b044eb625',
  graphhopper: 'c6a2d0a4-3999-46de-a47a-4180ca67352e',
  esri:
    'token=AAPK75f3968f7c6f42d8a4da007ff18e91e8J4UF05A8SdKlMpuMl9XKrDX6Wknm6uJIk9gzUjkXJuRggHvNO8KjD5u6JHI3wExK',
};

export default credentials;
