/**
 *
 * Races
 *
 */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Table, Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withSettings from 'containers/SettingsProvider/withSettings';
import withDialog from 'containers/Dialog/withDialog';
import withKvApi from 'containers/KmVretersPage/withKvApi';
import Link from 'components/Link';
import usePagination from 'components/Pagination/usePagination';
import SearchInput from 'components/SearchInput';
import { green500 } from 'styles/colors';
import formatDate from 'utils/formatDate';
import formatTime from 'utils/formatTime';
import { useHash } from 'utils/urlUtils';
import distanceString from '../distanceString';
import filterItem from './filterItem';
import messages from '../messages';
import showLink from './showLink';

const FilterWrapper = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  input {
    margin-left: 0.5em;
    max-width: 8em;
    @media (min-width: 480px) {
      max-width: 12em;
    }
  }
`;

const Wrapper = styled(Table)`
  @media (max-width: 479px) {
    font-size: smaller;
  }
  th:not(.th-event) {
    text-align: center;
  }
  .td-index {
    text-align: right;
    width: 5%;
    min-width: 2.3em;
  }
  .td-event {
    width: 40%;
  }
  .td-date {
    text-align: right;
    white-space: nowrap;
    width: 10%;
  }
  .td-distance {
    text-align: right;
    width: 5%;
    white-space: nowrap;
  }
  .td-time {
    text-align: right;
    width: 5%;
    min-width: 4.6em;
  }
  .td-link {
    text-align: center;
    width: 5%;
    white-space: nowrap;
  }
  .td-button {
    width: 5%;
    padding: 8px 0;
    text-align: center;
    white-space: nowrap;
  }
  .btn.btn-link {
    padding: 0 8px;
    font-size: inherit;
  }
  svg.fa-pen {
    color: ${green500};
  }
`;

const Wrapped = styled.span`
  white-space: pre-wrap;
`;

function Races(props) {
  const { data, units, dlgConfirm, apiChange } = props;
  const { list, isOwn } = data.value;
  const hash = useHash(); // Page refresh if using search
  const filterText = hash.q === undefined ? '' : hash.q.toString();
  const filteredList = useMemo(
    () => list && list.filter(filterItem(filterText, units)),
    [list, filterText, units],
  );
  const { slice, offset, Paginator, setPage } = usePagination(
    filteredList.length,
  );

  function handleDelete(item) {
    dlgConfirm({
      title: { ...messages.confirmDelete, values: { name: item.event } },
    })
      .then(() => {
        const options = {
          method: 'DELETE',
          id: item.id,
        };
        apiChange(options);
      })
      .catch(() => {});
  }

  function handleChange(q) {
    hash.push('q', q);
    setPage(0);
  }

  const notesPopover = item => (
    <Popover id={item.id}>
      <Wrapped>{item.notes}</Wrapped>
    </Popover>
  );

  const slicedList = slice(filteredList);
  const message = filterText
    ? messages.countFilteredRaces
    : messages.countRaces;
  const values = {
    total: list.length,
    count: filteredList && filteredList.length,
  };
  return (
    <React.Fragment>
      <FilterWrapper>
        <FormattedHTMLMessage {...message} values={values} />
        <SearchInput
          placeholder={messages.search}
          value={filterText}
          onChange={handleChange}
        />
      </FilterWrapper>
      <Wrapper responsive hover>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th className="th-event">
              <FormattedMessage {...messages.race} />
            </th>
            <th>
              <FormattedMessage {...messages.date} />
            </th>
            <th>
              <FormattedMessage {...messages.distance} />
            </th>
            <th>
              <FormattedMessage {...messages.time} />
            </th>
            <th>
              <FormattedMessage {...messages.link} />
            </th>
            {isOwn && <th colSpan="2">&nbsp;</th>}
          </tr>
        </thead>
        <tbody>
          {slicedList.map((item, idx) => (
            <tr key={item.id}>
              <td className="td-index">{idx + offset + 1}</td>
              <td className="td-event">
                {item.event}, {item.city}
                {item.notes && (
                  <OverlayTrigger
                    trigger="click"
                    placement="right"
                    overlay={notesPopover(item)}
                  >
                    <Button bsStyle="link">
                      <span className="sr-only">
                        <FormattedMessage {...messages.notes} />
                      </span>
                      <FontAwesomeIcon icon={['far', 'sticky-note']} />
                    </Button>
                  </OverlayTrigger>
                )}
              </td>
              <td className="td-date">{formatDate(item.date, 'PP')}</td>
              <td className="td-distance">{`${distanceString(
                item.distance,
                units,
              )} ${units}`}</td>
              <td className="td-time">{formatTime(item.time)}</td>
              <td className="td-link">{showLink(item.link)}</td>
              {isOwn && (
                <React.Fragment>
                  <td className="td-button">
                    <Link href={`/kmvreters/edit/${item.id}`}>
                      <span className="sr-only">
                        <FormattedMessage {...messages.edit} />
                      </span>
                      <FontAwesomeIcon icon="pen" />
                    </Link>
                  </td>
                  <td className="td-button">
                    <Button bsStyle="link" onClick={() => handleDelete(item)}>
                      <span className="sr-only">
                        <FormattedMessage {...messages.delete} />
                      </span>
                      <FontAwesomeIcon icon="trash-alt" />
                    </Button>
                  </td>
                </React.Fragment>
              )}
            </tr>
          ))}
        </tbody>
      </Wrapper>
      <Paginator />
    </React.Fragment>
  );
}

Races.propTypes = {
  data: PropTypes.object.isRequired,
  units: PropTypes.string.isRequired,
  dlgConfirm: PropTypes.func.isRequired,
  apiChange: PropTypes.func.isRequired,
};

export default compose(
  withDialog,
  withKvApi,
  withSettings(['units']),
)(Races);
