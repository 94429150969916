/**
 *
 * UserControl
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, DropdownButton, MenuItem } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { red500 } from 'styles/colors';
import withUser from 'containers/User/withUser';
import withRoute from 'containers/withRoute';
import Avatar from 'components/Avatar';
import Spinner from 'components/Spinner';
import Flex from 'components/Flex';
import messages from './messages';

const LoggedUser = styled(Flex)`
  div {
    padding-left: 5px;
    max-width: 7em;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledSpinner = styled(Spinner)`
  min-width: 160px;
`;

const ThumbsUpIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0;
  right: 0;
  color: ${red500};
`;

function UserControl(props) {
  const { user, signOut, intl } = props;
  if (user.loading && !user.email) {
    return <StyledSpinner />;
  }
  if (!user.email) {
    const title = intl.formatMessage(messages.signIn);
    return (
      <Button bsStyle="link" href="#login" title={title}>
        <FontAwesomeIcon icon="sign-in-alt" size="lg" />
        <span className="hidden-xs">
          &nbsp;<FormattedMessage {...messages.signIn} />
        </span>
      </Button>
    );
  }
  const icon = user.photoURL ? (
    <Avatar src={user.photoURL} alt="" />
  ) : (
    <FontAwesomeIcon icon="user" size="lg" title={user.email} />
  );
  const title = (
    <LoggedUser center title={user.email}>
      <div className="position-relative">
        {icon}
        {user.pm && (
          <ThumbsUpIcon icon="thumbs-up" size="xs" title="Supporter" />
        )}
      </div>
      <div className="hidden-xs">{user.email}</div>
    </LoggedUser>
  );
  return (
    <DropdownButton
      noCaret
      pullRight
      bsStyle="link"
      title={title}
      id="dropdown-user"
    >
      <MenuItem eventKey="1" href="#account">
        <FormattedMessage {...messages.account} />
      </MenuItem>
      <MenuItem eventKey="9" onSelect={signOut}>
        <FormattedMessage {...messages.signOut} />
      </MenuItem>
    </DropdownButton>
  );
}

UserControl.propTypes = {
  user: PropTypes.object,
  signOut: PropTypes.func,
  intl: intlShape,
};

export default compose(
  injectIntl,
  withUser,
  withRoute,
)(UserControl);
