/**
 *
 * Spinner
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Wrapper = styled.div`
  visibility: ${p => (p.show ? 'visible' : 'hidden')};
  text-align: center;
  opacity: ${p => p.opacity};
`;

const Container = styled.div`
  position: relative;
  .fa-spinner {
    position: absolute;
    top: 0;
    left: 50%;
    display: ${p => (p.show ? 'block' : 'none')};
    opacity: ${p => p.opacity};
  }
`;

function Spinner(props) {
  const { children } = props;
  if (!children) {
    return (
      <Wrapper {...props}>
        <FontAwesomeIcon icon="spinner" spin size="2x" />
      </Wrapper>
    );
  }
  return (
    <Container {...props}>
      {children}
      <FontAwesomeIcon icon="spinner" spin size="2x" />
    </Container>
  );
}

Spinner.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
  opacity: PropTypes.number,
};

Spinner.defaultProps = {
  opacity: 0.5,
  show: true,
};

export default Spinner;
