import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Button, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import messages from './messages';

const Wrapper = styled.div`
  margin: 30px auto 0;
  form {
    max-width: 220px;
    margin: 15px auto;
  }
`;

class EmailForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { email: '' };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(ev) {
    this.setState({ email: ev.target.value });
  }

  handleSubmit(ev) {
    ev.preventDefault();
    const { onSubmit } = this.props;
    const { email } = this.state;
    onSubmit(email);
  }

  render() {
    const { email } = this.state;
    return (
      <Wrapper>
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="email">
            <ControlLabel>
              <FormattedMessage {...messages.email} />
            </ControlLabel>
            <FormControl
              type="email"
              value={email}
              onChange={this.handleChange}
              required
              autoFocus
            />
          </FormGroup>
          <Button type="submit" bsStyle="success">
            OK
          </Button>
        </form>
      </Wrapper>
    );
  }
}

EmailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default EmailForm;
