/**
 *
 * FirebaseAuth
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import fbStart from 'containers/User/fbStart';
import Spinner from 'components/Spinner';
import EmailButton from './EmailButton';

const NoDisplay = styled.div`
  display: none;
`;

class FirebaseAuth extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: true };
    this.addedButton = false;
  }

  componentDidMount() {
    fbStart('#firebaseui-auth-container').then(() =>
      this.setState({ loading: false }),
    );
  }

  componentDidUpdate() {
    const { loading } = this.state;
    if (loading || this.addedButton) {
      return;
    }
    const listElem = document.getElementsByClassName('firebaseui-idp-list')[0];
    const button = document.getElementById('email-button');
    listElem.appendChild(button);
    this.addedButton = true;
  }

  render() {
    const { onEmail } = this.props;
    const { loading } = this.state;
    return (
      <div>
        <div id="firebaseui-auth-container" />
        <NoDisplay>
          <EmailButton onClick={onEmail} />
        </NoDisplay>
        {loading && <Spinner />}
      </div>
    );
  }
}

FirebaseAuth.propTypes = {
  onEmail: PropTypes.func.isRequired,
};

export default FirebaseAuth;
