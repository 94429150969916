import { useRef } from 'react';
import { useMeasure } from 'react-use';

function useVisible(remember) {
  const [ref, { width, height }] = useMeasure();
  const ever = useRef(false);
  const visible = width > 0 && height > 0;
  if (visible) {
    ever.current = true;
  }
  return [ref, remember ? ever.current : visible];
}

export default useVisible;
