function getAverage(data, x) {
  return data.reduce((acc, d) => acc + d[x], 0) / data.length;
}

function getStandardDeviation(data, x) {
  const avg = getAverage(data, x);
  return Math.sqrt(
    data.reduce((acc, d) => acc + (d[x] - avg) ** 2, 0) / data.length,
  );
}

function linearRegression(data, x, y, exclude) {
  const avg = exclude ? getAverage(data, y) : null;
  const stdDev = exclude ? getStandardDeviation(data, y) : null;
  let n = 0;
  let sumX = 0;
  let sumY = 0;
  let sumXY = 0;
  let sumXX = 0;
  for (let i = 0; i < data.length; i++) {
    if (
      data[i][y] === undefined ||
      data[i][y] === Infinity ||
      data[i][y] === -Infinity
    ) {
      continue; // eslint-disable-line no-continue
    }
    if (exclude === 0 && data[i][y] === 0) {
      continue; // eslint-disable-line no-continue
    }
    if (exclude && Math.abs(data[i][y] - avg) > exclude * stdDev) {
      continue; // eslint-disable-line no-continue
    }
    n++;
    sumX += data[i][x];
    sumY += data[i][y];
    sumXY += data[i][x] * data[i][y];
    sumXX += data[i][x] * data[i][x];
  }
  const m = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
  const b = (sumY - m * sumX) / n;
  return { m, b };
}

export default linearRegression;
