import { createSelector } from 'reselect';

/**
 * Direct selector to the dialog state domain
 */
const selectDialogDomain = state => state.get('dialog');

/**
 * Other specific selectors
 */

/**
 * Default selector used by Dialog
 */

const makeSelectDialog = () =>
  createSelector(selectDialogDomain, substate => substate.toJS());

export default makeSelectDialog;
export { selectDialogDomain };
