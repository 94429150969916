/**
 *
 * Header
 *
 */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { red500, grey100, grey300, darkBlack } from 'styles/colors';
import { FormattedMessage } from 'react-intl';
import { Dropdown, MenuItem } from 'react-bootstrap';
import withRoute from 'containers/withRoute';
import withUser from 'containers/User/withUser';
import HomeLink from 'components/HomeLink';
import UserControl from 'components/UserControl';
import storage from 'utils/storage';
import { useMinWidth } from 'utils/useMinSize';
import messages from './messages';
import Link from '../Link';

const Wrapper = styled.header`
  padding: 10px 15px;
  padding-right: 5px;
  background-color: ${grey100};
  border-bottom: 2px solid ${red500};
  color: ${darkBlack};
  height: 48px;
  min-height: 48px;
  display: flex;
  align-items: center;
  @media (max-width: 359px) {
    font-size: smaller;
    line-height: 2;
    padding-left: 10px;
    h1 {
      font-size: 16px;
    }
  }
  & > :first-child {
    flex-grow: 1;
  }
  & > :not(:first-child) {
    @media print {
      display: none;
    }
  }
  .dropdown-menu {
    z-index: 1010;
  }
  .btn.btn-default {
    border: none;
    background-color: transparent;
    &:hover {
      background-color: ${grey300};
    }
  }
  .btn-group.open .dropdown-toggle {
    box-shadow: none;
  }
`;

function Header(props) {
  const { onMenuSelect, user } = props;
  const largeScreen = useMinWidth();
  const toggleLabel = largeScreen ? messages.kmvreters : messages.menu;
  const hasReservations = storage.getItem('showReservations');

  return (
    <Wrapper flat>
      <HomeLink />
      {largeScreen && (
        <React.Fragment>
          <Link className="btn btn-default" href="/">
            <FormattedMessage {...messages.routes} />
          </Link>
          <Link className="btn btn-default" href="/virtual">
            <FormattedMessage {...messages.virtual} />
          </Link>
          {hasReservations && (
            <Link className="btn btn-default" href="/inschrijven">
              <FormattedMessage {...messages.reservations} />
            </Link>
          )}
          {user.su && (
            <Link className="btn btn-default" href="/manage">
              Admin
            </Link>
          )}
        </React.Fragment>
      )}
      <Dropdown id="kmvreters-menu" pullRight>
        <Dropdown.Toggle>
          <FormattedMessage {...toggleLabel} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {!largeScreen && (
            <React.Fragment>
              <MenuItem eventKey="1" href="/">
                <FormattedMessage
                  {...messages.routes}
                  onSelect={onMenuSelect}
                />
              </MenuItem>
              <MenuItem eventKey="2" href="/virtual">
                <FormattedMessage
                  {...messages.virtual}
                  onSelect={onMenuSelect}
                />
              </MenuItem>
              {hasReservations && (
                <MenuItem eventKey="3" href="/inschrijven">
                  <FormattedMessage
                    {...messages.reservations}
                    onSelect={onMenuSelect}
                  />
                </MenuItem>
              )}
              <MenuItem divider />
              <MenuItem header>
                <FormattedMessage {...messages.kmvreters} />
              </MenuItem>
            </React.Fragment>
          )}
          <MenuItem
            eventKey="6"
            href="/kmvreters/list"
            disabled={!user || !user.api}
            onSelect={onMenuSelect}
          >
            <FormattedMessage {...messages.list} />
          </MenuItem>
          <MenuItem
            eventKey="7"
            href="/kmvreters/profile"
            disabled={!user || !user.api}
            onSelect={onMenuSelect}
          >
            <FormattedMessage {...messages.profile} />
          </MenuItem>
          <MenuItem
            eventKey="8"
            href="/kmvreters/ranking"
            onSelect={onMenuSelect}
          >
            <FormattedMessage {...messages.ranking} />
          </MenuItem>
          <MenuItem
            eventKey="9"
            href="https://runnermaps.freshdesk.com/support/solutions/articles/43000546015-kilometervreters"
          >
            Uitleg
          </MenuItem>
        </Dropdown.Menu>
      </Dropdown>
      <UserControl />
    </Wrapper>
  );
}

Header.propTypes = {
  onMenuSelect: PropTypes.func,
  user: PropTypes.object,
};

export default compose(
  withUser,
  withRoute,
)(Header);
