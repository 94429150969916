function controlValue(val) {
  const { target } = val;
  if (!target) {
    return val;
  }
  const { value, checked, type } = target;
  return ['radio', 'checkbox'].includes(type) && checked !== undefined
    ? checked
    : value;
}

export default controlValue;
