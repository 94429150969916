/**
 *
 * Switch
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Switch from 'react-switch';
import Spinner from 'components/Spinner';
import { green500 } from 'styles/colors';

const Wrapper = styled.label`
  position: relative;
  display: flex;
  margin-bottom: 0;
  align-items: center;
  justify-content: space-between;
  &.switch-inline {
    display: inline-flex;
    flex-direction: row-reverse;
    span {
      font-weight: normal;
      margin-left: 0.5em;
    }
  }
  .eEmqtl {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
  }
  .react-switch-bg,
  .react-switch-handle {
    cursor: ${p => (p.disabled ? 'not-allowed' : 'drag')}!important;
  }
`;

function MySwitch(props) {
  const { children, value, size, inline, working, ...rest } = props;
  const height = ['small', 'sm'].includes(size) ? 20 : 28;
  const width = 2 * height;
  const className = inline || !children ? 'switch-inline' : '';
  return (
    <Wrapper htmlFor={rest.id} className={className} disabled={props.disabled}>
      {children}
      <Switch
        checked={value}
        {...rest}
        height={height}
        width={width}
        onColor={green500}
      />
      {working && <Spinner />}
    </Wrapper>
  );
}

MySwitch.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
  value: PropTypes.bool,
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
  working: PropTypes.bool,
  size: PropTypes.string,
};

export default MySwitch;
