/**
 *
 * AutoRegister
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import {
  Button,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { green500, red500 } from 'styles/colors';
import { request2 } from 'utils/request';
import messages from '../messages';
import getDOW from '../getDOW';

const allowMultiple = false;

const ColorIcon = styled(FontAwesomeIcon)`
  &.fa-pen {
    color: ${green500};
    opacity: 0.65;
  }
  &.fa-times {
    color: ${red500};
    opacity: 0.5;
  }
`;

const InlineButton = styled(Button)`
  &&.btn {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const Wrapper = styled(FormGroup)`
  .flex {
    display: flex;
    align-items: flex-start;
  }
  select {
    width: 14em;
    margin-left: 1rem;
  }
}
`;

function scheduleTime(schedule) {
  const { weekday, startTime, location } = schedule;
  return `${getDOW(weekday)} ${startTime.slice(0, -3)} ${location}`;
}

function AutoRegister(props) {
  const { member, schedule, hash, user } = props;
  if (!member) {
    return null;
  }
  const [edit, setEdit] = useState(false);
  const initialIds = member.schedule
    ? member.schedule.split(',').map(Number)
    : [0];
  const [scheduleIds, setScheduleIds] = useState(initialIds);
  const selected =
    scheduleIds && schedule.filter(s => scheduleIds.includes(s.id));
  const day = selected && selected.map(scheduleTime).join(', ');
  if (!edit) {
    return (
      <p>
        {selected.length > 0 ? (
          <FormattedHTMLMessage {...messages.autoRegSet} values={{ day }} />
        ) : (
          <FormattedMessage {...messages.autoRegNot} />
        )}
        <InlineButton bsStyle="link" onClick={() => setEdit(true)}>
          <ColorIcon icon="pen" />
        </InlineButton>
      </p>
    );
  }

  function getSelected(select) {
    if (Number(select.value) === 0) {
      return [0];
    }
    return Array.from(select.options)
      .filter(o => o.selected && Number(o.value) !== 0)
      .map(o => o.value);
  }

  function handleSelect(ev) {
    const newIds = getSelected(ev.target).map(Number);
    setScheduleIds(newIds);
    if (!allowMultiple) {
      submitSelection(newIds);
    }
  }

  function submitSelection(newId) {
    const options = {
      method: 'POST',
      url: `/res/groups/${hash}/autoregister`,
      body: { schedule_id: (newId || scheduleIds).join(',') },
      user,
      withPromise: true,
    };
    setEdit(false);
    request2(options);
  }

  const value = allowMultiple ? scheduleIds : scheduleIds[0];
  return (
    <Wrapper controlId="auto-register">
      <span className="d-flex align-items-baseline">
        <ControlLabel>
          <FormattedMessage {...messages.autoRegister} />
        </ControlLabel>
        <FormControl
          componentClass="select"
          value={value}
          onChange={handleSelect}
          multiple={allowMultiple}
        >
          <FormattedMessage {...messages.none}>
            {text => <option value="0">{text}</option>}
          </FormattedMessage>
          {schedule.map(s => (
            <option key={s.id} value={s.id}>
              {scheduleTime(s)}
            </option>
          ))}
        </FormControl>
        <Button
          bsStyle="link"
          onClick={allowMultiple ? submitSelection : () => setEdit(false)}
        >
          <ColorIcon icon="check" />
        </Button>
      </span>
      <HelpBlock>
        <FormattedMessage {...messages.autoRegisterHelp} />
      </HelpBlock>
    </Wrapper>
  );
}

AutoRegister.propTypes = {
  user: PropTypes.object,
  hash: PropTypes.string,
  schedule: PropTypes.array,
  member: PropTypes.object,
};

export default AutoRegister;
