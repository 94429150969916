/**
 *
 * MemberControl
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as colors from 'styles/colors';
import messages from '../messages';

const Wrapper = styled.form`
  display: flex;
  align-items: center;
  label {
    margin-right: 1rem;
    margin-bottom: 0;
    white-space: nowrap;
  }
  input {
    margin-right: 1rem;
  }
  .fa-pen {
    color: ${colors.green500};
    opacity: 0.5;
  }
  .fa-times {
    color: ${colors.minBlack};
  }
  .fa-sign-out-alt {
    color: ${colors.red500};
    opacity: 0.5;
  }
  .buttons {
    white-space: nowrap;
    flex-grow: 1;
    text-align: right;
    .btn-link:last-child {
      margin-right: -6px;
    }
  }
`;

function findName(arr) {
  const obj = arr.find(item => item && (item.name || item.displayName));
  return obj && (obj.name || obj.displayName);
}

function MemberControl(props) {
  const { user, onJoin, member } = props;
  const isMember = Boolean(member);
  const initialName =
    findName([member, user.api.userData, user.auth]) ||
    user.email.split('@')[0];
  const [name, setName] = useState(initialName);
  const [edit, setEdit] = useState(!isMember);
  const submitMessage = isMember ? messages.save : messages.joinGroup;
  function handleSubmit(ev) {
    ev.preventDefault();
    onJoin(true, name).then(() => setEdit(false));
  }
  const label = isMember ? messages.groupMember : messages.noMember;
  return (
    <Wrapper onSubmit={handleSubmit}>
      <label htmlFor="memberName">
        <FormattedMessage {...label} />
      </label>
      {edit || !isMember ? (
        <React.Fragment>
          <input
            type="text"
            id="memberName"
            className="form-control"
            value={name}
            onChange={ev => setName(ev.target.value)}
          />
          <span className="buttons">
            {isMember && (
              <Button
                bsStyle="link"
                type="button"
                onClick={() => setEdit(false)}
              >
                <FontAwesomeIcon icon="times" size="sm" />
              </Button>
            )}
            <Button bsStyle="success" type="submit">
              <FormattedMessage {...submitMessage} />
            </Button>
          </span>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <span id="memberName">{name}</span>
          <Button bsStyle="link" onClick={() => setEdit(true)}>
            <FontAwesomeIcon icon="pen" size="sm" />
          </Button>
          <span className="buttons">
            <Button bsStyle="link" onClick={() => onJoin(false)}>
              <FontAwesomeIcon icon="sign-out-alt" size="lg" />
            </Button>
          </span>
        </React.Fragment>
      )}
    </Wrapper>
  );
}

MemberControl.propTypes = {
  member: PropTypes.object,
  user: PropTypes.object,
  onJoin: PropTypes.func,
};

export default MemberControl;
