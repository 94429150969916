/**
 * Implement storage object, based on availability,
 * in order of preference: localStorage, sessionStorage or WindowStorage
 * see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
 */

import storageAvailable from './storageAvailable.lib';

export function getStorage() {
  if (storageAvailable('localStorage')) {
    return localStorage;
  } else if (storageAvailable('sessionStorage')) {
    return sessionStorage;
  }
  return {};
}
