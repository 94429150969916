/*
 *
 * Rest actions
 *
 */

import L from 'leaflet';
import {
  GET_ENTITY,
  LIST_ENTITY,
  CHANGE_ENTITY,
  RESET_ENTITY,
} from './constants';

export function getEntity(entity, id, options = {}) {
  const url = `${entity}/${id}`;
  return {
    type: GET_ENTITY,
    options: { ...options, method: 'GET', entity, id },
    url,
  };
}

export function listEntity(entity, params, options = {}) {
  const url = `${entity}${L.Util.getParamString(params)}`;
  return {
    type: LIST_ENTITY,
    options: { ...options, method: 'GET', entity, params },
    url,
  };
}

export function fetchEntity(entity, url, options) {
  return {
    type: LIST_ENTITY,
    options: { ...options, method: 'GET', entity },
    url,
  };
}

export function changeEntity(entity, options = {}) {
  const url = options.id ? `${entity}/${options.id}` : entity;
  return {
    type: CHANGE_ENTITY,
    options: { method: 'POST', ...options, entity },
    url,
  };
}

export function resetEntity(entity, body) {
  const url = entity;
  return {
    type: RESET_ENTITY,
    options: { method: 'POST', entity, body },
    url,
  };
}
