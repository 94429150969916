import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { useCurrentUser } from 'containers/User';
import useDialog from 'containers/Dialog/useDialog';
import { defaultButtons } from 'containers/Dialog';
import Avatar from 'components/Avatar';
import { request2 } from 'utils/request';
import messages from '../messages';

const Profile = styled.span`
  img {
    margin: 0 0.5rem;
  }
  text-align: right;
`;

function StravaStatus() {
  const user = useCurrentUser();
  const { strava } = user;
  const { dlgConfirm } = useDialog();
  const { firstname, lastname, profile_medium } = strava;
  const name = `${firstname} ${lastname}`;
  const disconnect = () => {
    dlgConfirm({
      title: messages.disconnect,
      message: messages.disconnectAlert,
      buttons: [
        defaultButtons[0],
        { label: messages.disconnectButton, value: true, style: 'danger' },
      ],
    })
      .then(() => {
        request2({ url: '/strava/disconnect', user }).then(() =>
          window.location.reload(),
        );
      })
      .catch(() => {});
  };
  return (
    <Dropdown id="strava-account-dropdown" pullRight>
      <Dropdown.Toggle noCaret bsStyle="link">
        <Profile>
          <Avatar src={profile_medium} />
          <b>{name}</b>
        </Profile>
      </Dropdown.Toggle>
      <Dropdown.Menu className="super-colors">
        <MenuItem eventKey="1" onClick={disconnect}>
          <FormattedMessage {...messages.disconnect} />
        </MenuItem>
      </Dropdown.Menu>
    </Dropdown>
  );
}

StravaStatus.propTypes = {};
export default StravaStatus;
