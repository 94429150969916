import { useWindowSize } from 'react-use';

export function useMinWidth(w = 768) {
  const { width } = useWindowSize();
  return width >= w;
}

export function useMinHeight(h = 800) {
  const { height } = useWindowSize();
  return height >= h;
}
