/**
 *
 * ActivityModal
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { parseISO } from 'date-fns';
import useCurrentUser from 'containers/User/useCurrentUser';
import ConfirmButton from 'components/ConfirmButton';
import { request2 } from 'utils/request';
import formatTime from 'utils/formatTime';
import { formatNoDot, multiDay } from 'utils/datetime';
import { ENDASH } from 'utils/characters';
import messages from '../messages';
import StravaLink from './StravaLink';
import makeName from './makeName';
import Supporter from './Supporter';
import MapModal from './MapModal';

const DL = styled.dl`
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  & > * {
    flex: 0 0 50%;
    margin-bottom: 1rem;
  }
  dt {
    text-align: right;
  }
  dd {
    padding-left: 1rem;
  }
`;

function ActivityModal(props) {
  const { activity, onHide } = props;
  const user = useCurrentUser();
  const [show, setShow] = useState(false);
  // const { su, stravaId } = user.api || {};
  const { su } = user.api || {};
  const values = { name: makeName(activity) };
  const {
    distance,
    pos,
    start_date,
    moving_time,
    elapsed_time,
    race_distance,
    race_time,
    source,
    d_start,
    t_start,
    race_hash,
    activity_id,
    athlete_id,
    filtered,
  } = activity;
  const dist = race_distance / 1000;
  function formatDist(d) {
    return (d / 1000).toFixed(2);
  }
  function handleRemove() {
    request2({
      url: `/virtual/races/${race_hash}/join?id=${athlete_id}`,
      method: 'DELETE',
      user,
    });
  }
  const formatTpl = multiDay(activity) ? 'd MMM H:mm' : 'H:mm';
  const canView = race_time && su;
  // const showFix = Boolean(filtered && (su || stravaId === athlete_id));
  const showFix = Boolean(filtered && su);
  if (show) {
    return (
      <MapModal
        activityId={activity_id}
        athleteId={athlete_id}
        onHide={() => setShow(false)}
      />
    );
  }
  const showInfo = (
    <Button
      bsStyle="link"
      className="text-info py-0 px-2"
      onClick={() => setShow(true)}
    >
      <FontAwesomeIcon icon="info-circle" className="text-info" />
    </Button>
  );
  return (
    <Modal show onHide={onHide} bsSize="small">
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage {...messages.resultFor} values={values} />
          {Boolean(activity.premium) && <Supporter />}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {activity.race_time ? (
          <React.Fragment>
            <DL>
              <dt>
                <FormattedMessage {...messages.ranking} />
              </dt>
              <dd>{pos}</dd>
              <dt>
                <FormattedMessage {...messages.startDate} />
              </dt>
              <dd>{formatNoDot(parseISO(start_date), formatTpl)}</dd>
              <dt>
                <FormattedMessage {...messages.distance} />
              </dt>
              <dd>{`${formatDist(distance)} km`}</dd>
              {showFix && (
                <React.Fragment>
                  <dt className="text-danger">
                    <FormattedMessage {...messages.corrected} />
                  </dt>
                  <dd className="text-danger">
                    {`${formatDist(filtered)} km`}
                    {showInfo}
                  </dd>
                </React.Fragment>
              )}
              <dt>
                <FormattedMessage {...messages.elapsedTime} />
              </dt>
              <dd>{formatTime(elapsed_time)}</dd>
              <dt>
                <FormattedMessage {...messages.movingTime} />
              </dt>
              <dd>{formatTime(moving_time)}</dd>
              <dt>
                <FormattedMessage {...messages.raceTime} />
              </dt>
              <dd>{formatTime(race_time, 1)}</dd>
              <dt>
                <FormattedMessage {...messages.pace} />
              </dt>
              <dd>{`${formatTime(race_time / dist)} min/km`}</dd>
              <dt>
                <FormattedMessage {...messages.avgSpeed} />
              </dt>
              <dd>
                <FormattedMessage
                  {...messages.kph}
                  values={{
                    speed: (3.6 * (race_distance / race_time)).toFixed(2),
                  }}
                />
              </dd>
              {source === 'streams' && (
                <React.Fragment>
                  <dt>
                    <FormattedMessage {...messages.fastestPart} />
                  </dt>
                  <dd>
                    {`${formatDist(d_start)} ${ENDASH} ${formatDist(
                      d_start + race_distance,
                    )} km`}
                    <br />
                    {`${formatTime(t_start)} ${ENDASH} ${formatTime(
                      t_start + race_time,
                    )}`}
                  </dd>
                </React.Fragment>
              )}
            </DL>
          </React.Fragment>
        ) : (
          <p>
            <FormattedMessage {...messages.didNotFinish} />
          </p>
        )}
        <div className="d-flex justify-content-between align-items-baseline">
          <span>
            <StravaLink target="_blank" entry={activity} className="pr-3" />
            {canView && showInfo}
          </span>
          {su && (
            <ConfirmButton
              bsStyle="link"
              confirmationText={messages.removeActivity}
              onClick={handleRemove}
            >
              <FontAwesomeIcon icon="trash-alt" />
            </ConfirmButton>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

ActivityModal.propTypes = {
  activity: PropTypes.object,
  onHide: PropTypes.func,
};

export default ActivityModal;
