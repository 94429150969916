/*
 *
 * GeoSearch constants
 *
 */

export const GEO_SEARCH = 'app/GeoSearch/GEO_SEARCH';
export const GEO_SEARCH_FOUND = 'app/GeoSearch/GEO_SEARCH_FOUND';
export const GEO_SEARCH_CLEAR = 'app/GeoSearch/GEO_SEARCH_CLEAR';
export const GEO_SELECT = 'app/GeoSearch/GEO_SELECT';
