// import { take, call, put, select } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import storage from 'utils/storage';
import { updateLocale } from 'utils/useDateLocale';
import { CHANGE_SETTING } from './constants';

export function* changeSetting(action) {
  const { setting, value } = action;
  storage.setItem(setting, value);
  if (setting === 'locale') {
    updateLocale(value);
  }
}

// Individual exports for testing
export default function* defaultSaga() {
  yield takeLatest(CHANGE_SETTING, changeSetting);
}
