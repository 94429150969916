/**
 * useCurrentUser
 */

import { useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import useDateLocale from 'utils/useDateLocale';
import useCreateSelector from 'utils/useCreateSelector';

import { selectUserDomain } from './selectors';

function useCurrentUser() {
  const user = useCreateSelector(selectUserDomain, substate => substate.toJS());
  const locale = useDateLocale();
  const userWithDate = useMemo(
    () => {
      if (!user.pm) {
        return user;
      }
      const premiumDate = format(parseISO(user.api.pm), 'PPP', { locale });
      return { ...user, premiumDate };
    },
    [user],
  );
  return userWithDate;
}

export default useCurrentUser;
