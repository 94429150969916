/**
 * useStravaActivity.js
 */

import React from 'react';
import { useRequest } from 'utils/request';
import { useCurrentUser } from 'containers/User';
import ActivityMap from './ActivityMap';
import ActivityChart from './ActivityChart';

function useStravaActivity(result) {
  const { athlete_id, activity_id, t_start, race_time, race_distance } =
    result || {};
  const user = useCurrentUser();
  const times = {
    start: t_start,
    end: t_start + race_time,
    duration: race_time,
  };
  const [data] = useRequest({
    url:
      result && `/strava/${athlete_id}/${activity_id}?format=json&withHR=true`,
    user,
  });
  const dataReady = data && !data.loading && !data.error;
  return {
    map: dataReady && (
      <ActivityMap data={data} times={times} distance={race_distance} />
    ),
    chart: dataReady && (
      <ActivityChart data={data} times={times} distance={race_distance} />
    ),
  };
}

export default useStravaActivity;
