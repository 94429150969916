/**
 *
 * Profile
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'redux';
import { Alert } from 'react-bootstrap';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import withKvApi from 'containers/KmVretersPage/withKvApi';
import withUser from 'containers/User/withUser';
import withRoute from 'containers/withRoute';
import withSettings from 'containers/SettingsProvider/withSettings';
import Spinner from 'components/Spinner';
import MetaTags from 'components/MetaTags';
import renderField from 'components/FormField/renderField';
import PrimaryAction from 'components/PrimaryAction';
import Container from '../Container';
import SelectClub from './SelectClub';
import messages from './messages';

const EDIT_STATUS_LOADING = 1;
const EDIT_STATUS_FORBIDDEN = 2;

const Wrapper = styled(Container)`
  p span.pull-right .btn {
    width: 25rem;
    text-align: left;
  }
`;

/* eslint-disable react/prefer-stateless-function */
class Profile extends React.PureComponent {
  constructor(props) {
    super(props);
    const { user, apiList } = props;
    this.state = user.api.userData;
    this.renderField = renderField.bind(this)(messages);
    apiList();
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (prevProps.data.working && !data.working && !data.error) {
      this.toRanking();
    }
  }

  handleSubmit = ev => {
    const { apiChange, updateProfile } = this.props;
    const { status, ...user } = this.state;
    ev.preventDefault();
    apiChange({
      body: { user },
      onSuccess: () => {
        updateProfile(user);
        this.toRanking();
      },
    });
  };

  toRanking = () => {
    const { goTo } = this.props;
    goTo('/kmvreters/ranking');
  };

  renderForm() {
    const { status, name, sex, yob, club } = this.state;
    switch (status) {
      case EDIT_STATUS_LOADING:
        return <Spinner />;
      case EDIT_STATUS_FORBIDDEN:
        return (
          <Alert bsStyle="danger">
            <FormattedMessage {...messages.notAllowed} />
          </Alert>
        );
      default:
    }
    const sexOptions = [
      { value: 'M', label: <FormattedMessage {...messages.male} /> },
      { value: 'V', label: <FormattedMessage {...messages.female} /> },
    ];

    return (
      <form onSubmit={this.handleSubmit}>
        {this.renderField({
          id: 'name',
          value: name || '',
          type: 'text',
          required: true,
        })}
        {this.renderField({
          id: 'sex',
          componentClass: 'radio',
          options: sexOptions,
          value: sex || '',
          required: true,
        })}
        {this.renderField({
          id: 'yob',
          type: 'number',
          min: 1900,
          max: new Date().getFullYear(),
          step: 1,
          className: 'input-short',
          value: yob || '',
          required: true,
        })}
        {this.renderField({
          id: 'club',
          componentClass: SelectClub,
          value: club,
        })}
        <PrimaryAction onCancel={this.toRanking} />
      </form>
    );
  }

  render() {
    const tags = { title: messages.title };
    // const { match, data } = this.props;
    // const { status } = this.state;
    return (
      <Wrapper width={720}>
        <MetaTags tags={tags} />
        <Container.Header>
          <FormattedMessage {...messages.title} />
        </Container.Header>
        <p className="help-block">
          <FormattedHTMLMessage {...messages.intro} />
        </p>
        {this.renderForm()}
      </Wrapper>
    );
  }
}

Profile.propTypes = {
  user: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  units: PropTypes.string.isRequired,
  updateProfile: PropTypes.func,
  apiList: PropTypes.func.isRequired,
  apiChange: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
};

export default compose(
  withUser,
  withKvApi,
  withRoute,
  withSettings(['units']),
)(Profile);
