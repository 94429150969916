/**
 * useCurrentUser
 */

import { useDispatch } from 'react-redux';
import { USER_UPDATE } from './constants';
import useCurrentUser from './useCurrentUser';

function useCurrentUser2() {
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const update = (path, value) =>
    dispatch({ type: USER_UPDATE, data: { path, value } });
  return [user, update];
}

export default useCurrentUser2;
