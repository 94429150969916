/**
 *
 * SelectClub
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { compose } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import Select from 'react-select';
import memoize from 'fast-memoize';
import withClubs from 'containers/Clubs/withEntity';
import messages from './messages';

function SelectClub(props) {
  const { apiList, clubs, intl, value, onChange } = props;
  React.useEffect(() => {
    if (clubs.loading === undefined) {
      apiList();
    }
  });
  const clubOptions = (clubs.list || []).map(id => ({
    value: id,
    label: `${clubs[id].name}, ${clubs[id].city}`,
  }));
  clubOptions.unshift({
    value: 0,
    label: intl.formatMessage(messages.noClub),
  });
  const findClub = memoize(id => clubOptions.find(item => item.value === id));
  const handleChange = option =>
    onChange({ target: { id: 'club', value: option && option.value } });
  return (
    <Select
      name="club"
      value={findClub(value)}
      options={clubOptions}
      onChange={handleChange}
      isClearable
      isSearchable
    />
  );
}

SelectClub.propTypes = {
  value: PropTypes.number,
  clubs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  apiList: PropTypes.func.isRequired,
  intl: intlShape,
};

export default compose(
  injectIntl,
  withClubs,
)(SelectClub);
