const text = {
  nl: `
- Loop minimaal de aangegeven afstand in de gegeven periode.
- Je loopt alleen, je bepaalt zelf waar je loopt (zoek of maak een mooie route op [RunnerMaps Routes](/)).
- De gegevens worden automatisch van Strava gehaald (zorg dat de activiteit zichtbaar is voor volgers of voor iedereen).
- Je testtijd wordt berekend uit het snelste gedeelte van je loop; als je bijvoorbeeld 6 km loopt bij een 5 km wedstrijd, geldt de tijd voor de snelste 5 km in je loop.

## Details

- Als je de koppeling met Strava maakt ontvangt RunnerMaps een melding als je een activiteit voltooit of aanpast.
- Als deze activiteit past bij een ingeschreven testloop wordt deze automatisch toegevoegd. Als je aan meerdere testlopen meedoet wordt die met de grootste afstand gekozen.
- Als je je in de testperiode inschrijft voor een loop worden je activiteiten voor die dag opgehaald en toegevoegd. Bij meerdere activiteiten wordt alleen het beste resultaat gekozen.
- Het maakt dus niet uit of je vooraf of achteraf inschrijft, maar na de testperiode is niet meer mogelijk.
- Soms duurt het even voor Strava een activiteit meldt of komt een melding niet door. Je kunt op het ↻ tekentje naast het schuifje klikken om de activiteiten voor die dag op te halen. Dit kan ook nog na de testperiode, als je je tenminste op tijd ingeschreven hebt.
- Als je weet dat je de exacte afstand gelopen hebt, bijv. op de atletiekbaan, kan het zijn dat de GPS een beetje afwijkt. Je kunt dan toch de exacte afstand gebruiken door *#baan* in de naam van de activiteit op te nemen, als de afwijking met de GPS tenminste minder dan 10% is. Als je activitieit al was toegevoegd voordat je de naam aangepast hebt, klik je op het ↻ tekentje om de uitslag te verversen.

## Strava

RunnerMaps vraagt toestemming voor het volgende:

- _Bekijk gegevens van je openbare profiel (verplicht)_: worden verder niet gebruikt
- _Bekijk gegevens van je activiteiten_: nodig voor de tijd en afstand in de testresultaten
- _Upload je activiteiten van RunnerMaps TestLopen naar Strava_: je resultaat wordt automatisch toegevoegd aan de beschrijving van je activiteit in Strava
`,
  en: `
- Run at least the given distance in the given period.
- You run on your own, you choose where you run (search or create a nice route on [RunnerMaps Routes](/)).
- The activity data will automatically be collected from Strava (ensure it is visible by followers or by everyone).
- Your test time will be calculated from the fastest part of your run; for example, if you run 6K in a 5K race, the time of the fastest 5K will count.

## Details

- If you connect with Strava, RunnerMaps receives a notification if you complete or update an activity.
- If the activity matches a run that you joined, it will automatically be added. If you joined multiple runs, it will be added to the longest matching run.
- If you register for a run in  the test period, your activities for that period will be fetched and added. If there are multiple activities, the best result will be selected.
- It does not matter whether you register in advance or after the activity, but after the test period it is not possible anymore.
- If you have multiple qualifying runs in the test period, only the best result is kept.
- Sometimes it takes a while before Strava sends a notification for an activity, or the notication is not received. You can click on the ↻ icon next to the switch to fetch the activities for that day. You can still do this after the test period, as long as you have registered in time.
- If you know you ran the exact distance, e.g. when you run on a track, GPS may give a slighly different result. You can use the exact distance by adding *#track* to the name of your activity, provided the difference with GPS distance is less than 10%. You may have to refresh the results by clicking the ↻ icon if your activity was fetched already before changing the name.

## Strava

RunnerMaps asks permission for the following:

- _View data about your public profile (required)_: not used anymore.
- _View data about your activities_: necessary for the time and distance in the results list.
- _Upload your activities from RunnerMaps Test Runs to Strava_: the result will automatically be appended to the description of your activity.
`,
};

export default text;
