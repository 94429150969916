/*
 *
 * AlertService actions
 *
 */

import uniqueId from 'lodash/uniqueId';
import { ALERT_ADD, ALERT_DISMISS } from './constants';

export function addAlert(options) {
  return {
    type: ALERT_ADD,
    options: { ...options, id: uniqueId('alert_') },
  };
}

export function addError(options) {
  return {
    type: ALERT_ADD,
    options: { style: 'danger', ...options, id: uniqueId('alert_') },
  };
}

export function dismissAlert(id) {
  return {
    type: ALERT_DISMISS,
    id,
  };
}
