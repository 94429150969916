/* eslint-disable react/no-array-index-key */
/**
 * CorrectionsMaps
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { Panel } from 'react-bootstrap';
import L from 'leaflet';
import { Map, Polyline, ScaleControl } from 'react-leaflet';
import Control from 'react-leaflet-control';
import LayersControl from 'components/LayersControl';
import formatTime from 'utils/formatTime';
import splitData from './splitData';
import getPositions from './getPositions';
import TimeMarkers from './TimeMarkers';
import aroundInvalid from './aroundInvalid';
import useShowPosition from './useShowPosition';
import IndexMarker from './IndexMarker';

function CorrectionsMap(props) {
  const { data } = props;
  const [zoom, setZoom] = useState(15);
  const positions = getPositions(data);
  const { time, index } = useShowPosition(data);
  const lines = splitData(data) || [{ positions }];
  const points = aroundInvalid(data, 2);
  const bounds = L.latLngBounds(positions);
  return (
    <Map bounds={bounds} onZoomend={ev => setZoom(ev.target.getZoom())}>
      <ScaleControl position="bottomright" />
      <LayersControl position="topright" />
      {lines.map((line, i) => <Polyline key={i} {...line} />)}
      <TimeMarkers
        points={points}
        start={positions[0]}
        zoom={zoom}
        finish={positions[positions.length - 1]}
      />
      {Boolean(data[index]) && <IndexMarker center={data[index].latlng} />}
      {time !== null && (
        <Control position="bottomleft">
          <Panel className="leaflet-control-layers p-2">
            {formatTime(time)}
          </Panel>
        </Control>
      )}
    </Map>
  );
}

CorrectionsMap.propTypes = {
  data: PropTypes.array.isRequired,
};

export default CorrectionsMap;
