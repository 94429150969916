/*
 * Settings Messages
 *
 * This contains all the text for the Settings component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.Settings.header',
    defaultMessage: 'Settings',
  },
  locale: {
    id: 'app.components.Settings.locale',
    defaultMessage: 'Language',
  },
  nl: {
    id: 'app.components.Settings.nl',
    defaultMessage: 'Nederlands',
  },
  en: {
    id: 'app.components.Settings.en',
    defaultMessage: 'English',
  },
  units: {
    id: 'app.components.Settings.units',
    defaultMessage: 'Units',
  },
  km: {
    id: 'app.components.Settings.km',
    defaultMessage: 'kilometers',
  },
  mi: {
    id: 'app.components.Settings.mi',
    defaultMessage: 'miles',
  },
  autocenter: {
    id: 'app.components.Settings.autocenter',
    defaultMessage: 'Automatic Center',
  },
  autocenterHelp: {
    id: 'app.components.Settings.autocenterHelp',
    defaultMessage: 'Center map on last point while editing',
  },
});
