/**
 *
 * Ranking
 *
 */

// import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

// http://unicode.org/emoji/charts-13.0/full-emoji-list.html
const ranking = {
  1: String.fromCodePoint(0x1f947),
  2: String.fromCodePoint(0x1f948),
  3: String.fromCodePoint(0x1f949),
};

export function Ranking({ pos }) {
  if (!pos) {
    return '';
  }
  if (pos > 3) {
    return pos.toLocaleString();
  }
  return `${ranking[pos]} ${pos.toLocaleString()}`;
}

Ranking.propTypes = {
  pos: PropTypes.number,
};

export default Ranking;
