/*
 * Edit Messages
 *
 * This contains all the text for the Edit component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.KmVreters.Edit.title',
    defaultMessage: 'Add/Edit a Race',
  },
  addRace: {
    id: 'app.components.KmVreters.Edit.addRace',
    defaultMessage: 'Add a Race',
  },
  editRace: {
    id: 'app.components.KmVreters.Edit.editRace',
    defaultMessage: "Edit Race '{name}'",
  },
  importLabel: {
    id: 'app.components.KmVreters.Edit.importLabel',
    defaultMessage: 'Import from <code>uitslagen.nl</code>',
  },
  importHelp: {
    id: 'app.components.KmVreters.Edit.importHelp',
    defaultMessage:
      'You can import results from <code>uitslagen.nl</code> directly:',
  },
  importCommand: {
    id: 'app.components.KmVreters.Edit.importCommand',
    defaultMessage: 'Import now',
  },
  intro: {
    id: 'app.components.KmVreters.Edit.intro',
    defaultMessage:
      'Enter the date first. If someone has entered the race before, you can copy the information.',
  },
  date: {
    id: 'app.components.KmVreters.Edit.date',
    defaultMessage: 'Race Date',
  },
  event: {
    id: 'app.components.KmVreters.Edit.event',
    defaultMessage: 'Name of the Race',
  },
  city: {
    id: 'app.components.KmVreters.Edit.city',
    defaultMessage: 'City',
  },
  distance: {
    id: 'app.components.KmVreters.List.distance',
    defaultMessage: 'Distance',
  },
  time: {
    id: 'app.components.KmVreters.List.time',
    defaultMessage: 'Time',
  },
  link: {
    id: 'app.components.KmVreters.Edit.link',
    defaultMessage: 'Link to the Results',
  },
  linkHelp: {
    id: 'app.components.KmVreters.Edit.linkHelp',
    defaultMessage:
      'If you do not enter a link, the race does not count in the ranking.',
  },
  notes: {
    id: 'app.components.KmVreters.Edit.notes',
    defaultMessage: 'Notes',
  },
  notesHelp: {
    id: 'app.components.KmVreters.Edit.notesHelp',
    defaultMessage: 'Notes for yourself, not visible to others',
  },
  save: {
    id: 'app.components.KmVreters.Edit.save',
    defaultMessage: 'Save',
  },
  notAllowed: {
    id: 'app.components.KmVreters.Edit.notAllowed',
    defaultMessage: 'You cannot edit another users race.',
  },
  suggestions: {
    id: 'app.components.KmVreters.Edit.suggestions',
    defaultMessage:
      'Races have been entered for this date already. Click on a link to copy the data.',
  },
});
