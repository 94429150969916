/*
 * Dialog Messages
 *
 * This contains all the text for the Dialog component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  ok: {
    id: 'app.containers.Dialog.ok',
    defaultMessage: 'OK',
  },
  cancel: {
    id: 'app.containers.Dialog.cancel',
    defaultMessage: 'Cancel',
  },
  yes: {
    id: 'app.containers.Dialog.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'app.containers.Dialog.no',
    defaultMessage: 'No',
  },
});
