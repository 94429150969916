/**
 * Pagination Hook
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { PAGESIZE } from 'utils/constants';
import { grey600 } from 'styles/colors';
import messages from './messages';
import Pagination from './index';

const PaginationText = styled.span`
  color: ${grey600};
`;

function usePagination(totalCount, limit = PAGESIZE, onChange) {
  const [page, setPage] = useState(0);
  const pageCount = Math.ceil(totalCount / limit);
  const offset = page * limit;
  function slice(items) {
    return items.slice(offset, offset + limit);
  }
  function handleChange(p) {
    setPage(p);
    if (onChange) {
      onChange(p);
    }
  }
  function Paginator() {
    if (pageCount === 1) {
      return null;
    }
    return (
      <Pagination value={page} length={pageCount} onChange={handleChange} v2 />
    );
  }
  const values = {
    start: offset + 1,
    end: Math.min(offset + limit, totalCount),
    total: totalCount,
  };
  const paginationText =
    pageCount === 1 ? (
      ''
    ) : (
      <PaginationText className="pagination-text">
        <FormattedMessage {...messages.paginationText} values={values} />
      </PaginationText>
    );
  return {
    slice,
    offset,
    Paginator,
    setPage,
    page,
    paginationText,
  };
}

export default usePagination;
