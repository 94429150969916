/**
 *
 * urlUtils
 *
 */

import { useLocation, useHistory } from 'react-router-dom';
import linkify from 'linkify-it';

const PREFIXES = { hash: '#', search: '?' };

export function old_serialize(obj) {
  return Object.keys(obj)
    .filter(key => obj[key] !== undefined)
    .reduce(
      (acc, key) => [
        ...acc,
        obj[key] === true
          ? encodeURIComponent(key)
          : `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`,
      ],
      [],
    )
    .join('&');
}

export function unserialize(str) {
  const parts = str
    .replace(/^[#?]/, '')
    .split('&')
    .filter(Boolean);
  return parts.reduce((acc, part) => {
    const [key, value] = part.split('=').map(decodeURIComponent);
    return {
      ...acc,
      [key]: value === undefined ? true : value,
    };
  }, {});
}

export function serialize(obj) {
  if (obj === undefined || obj === null) {
    return '';
  }
  return Object.keys(obj)
    .map(k => {
      switch (obj[k]) {
        case undefined:
        case null:
        case false:
          return false;
        case true:
          return k;
        default:
      }
      const v = encodeURIComponent(obj[k]);
      return `${k}=${v}`;
    })
    .filter(Boolean)
    .join('&');
}

export function parseUrl(url) {
  const result = { path: url, search: '', searchObject: {}, hash: '' };
  const match = url.match(/([^?#]*)(\?[^#]*)?(#.*)?/);
  if (!match) {
    return result;
  }
  const [path, search, hash] = match.slice(1);
  result.path = path;
  Object.assign(result, parsePath(match[1]));
  if (search) {
    result.search = decodeURI(search.slice(1));
    result.searchObject = parseString(search);
  }
  if (hash) {
    result.hash = decodeURI(hash.slice(1));
  }
  return result;
}

export function parsePath(path) {
  const result = { params: {} };
  const parts = path.split('/');
  let key;
  while (parts.length > 0) {
    const term = parts.shift();
    if (key && /^[a-zA-Z]/.test(key) && /^\d+$/.test(term)) {
      result.params[key] = Number(term);
    }
    key = term;
  }
  if (/^[a-zA-Z]/.test(key)) {
    result.verb = key;
  }
  return result;
}

function parseValue(val) {
  const test = typeof val === 'string' ? val.toLowerCase() : val;
  switch (test) {
    case undefined:
    case 'true':
      return true;
    case 'null':
    case 'false':
      return false;
    default:
  }
  const numVal = val && Number(val);
  // eslint-disable-next-line no-restricted-globals
  return isNaN(numVal) ? decodeURIComponent(val) : numVal;
}

export function parseString(str) {
  const result = {};
  str
    .replace(/^[#?]/, '')
    .trim()
    .split('&')
    .forEach(item => {
      const [key, value] = item.split('=');
      if (key) {
        result[key] = parseValue(value);
      }
    });
  return result;
}

export function strToLink(str) {
  const match = str && linkify().match(str);
  if (!match || match.length === 0) {
    return undefined;
  }
  return match[0].url;
}

export function getParam(url, param) {
  const re = new RegExp(`[?&]${param}=([^&]+)`);
  const match = url.match(re);
  return match ? match[1] : undefined;
}

const useLocationPart = part => {
  const prefix = PREFIXES[part];
  if (!prefix) {
    throw new Error(`Unknown location part ${part}`);
  }
  return () => {
    const location = useLocation();
    const history = useHistory();
    const asObject = parseString(location[part]);
    function setValue(o, v) {
      if (typeof o === 'string') {
        // Update property o
        return setValue({ ...asObject, [o]: v });
      }
      const str = serialize(o);
      const newLocation = { ...location, [part]: str ? `${prefix}${str}` : '' };
      return `${location.pathname}${newLocation.search}${newLocation.hash}`;
    }
    return {
      ...asObject,
      push: (o, v) => history.push(setValue(o, v)),
      replace: (o, v) => history.replace(setValue(o, v)),
    };
  };
};

export const useHash = useLocationPart('hash');
export const useSearch = useLocationPart('search');
