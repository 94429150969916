/**
 *
 * Settings
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { ListGroup, ListGroupItem, Modal } from 'react-bootstrap';
import withSettings from 'containers/SettingsProvider/withSettings';
import { CHOICES } from 'containers/SettingsProvider/constants';
import Choice from 'components/Choice';
import Switch from 'components/Switch';
import messages from './messages';

/* eslint-disable react/prefer-stateless-function */
class Settings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  static getValue(ev) {
    return ev.target ? ev.target.value : ev;
  }

  handleChange(setting) {
    const { changeSetting } = this.props;
    return ev => changeSetting(setting, Settings.getValue(ev));
  }

  render() {
    const { autocenter, onHide } = this.props;
    const choiceItems = ['locale', 'units'];
    return (
      <React.Fragment>
        <Modal show bsSize="small" onHide={onHide}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage {...messages.header} />
            </Modal.Title>
          </Modal.Header>
          <ListGroup>
            {choiceItems.map(key => (
              <ListGroupItem key={key}>
                <Choice
                  id={key}
                  label={messages[key]}
                  value={this.props[key]}
                  items={CHOICES[key].map(id => ({
                    value: id,
                    label: messages[id],
                  }))}
                  onChange={this.handleChange(key)}
                />
              </ListGroupItem>
            ))}
            <ListGroupItem>
              <Switch
                value={autocenter}
                onChange={this.handleChange('autocenter')}
                id="autocenter"
              >
                <FormattedMessage {...messages.autocenter} />
              </Switch>
              <div className="help-block">
                <FormattedMessage {...messages.autocenterHelp} />
              </div>
            </ListGroupItem>
          </ListGroup>
        </Modal>
      </React.Fragment>
    );
  }
}

Settings.propTypes = {
  locale: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired,
  autocenter: PropTypes.bool.isRequired,
  changeSetting: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default withSettings(['locale', 'units', 'autocenter'])(Settings);
