/*
 * Premium banner
 *
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

function Premium() {
  return (
    <div className="premium-banner text-center p-2 pb-3">
      <div className="mb-2">
        <FormattedMessage {...messages.useSpace} />
      </div>
      <a className="btn btn-primary btn-sm text-wrap" href="#subscription">
        <FormattedMessage {...messages.getPremium} />
      </a>
    </div>
  );
}

export default Premium;
