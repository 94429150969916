/**
 *
 * MetaTags
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { injectIntl, intlShape } from 'react-intl';
import { Helmet } from 'react-helmet';

class MetaTags extends React.PureComponent {
  render() {
    const { intl, tags, vars } = this.props;
    return (
      <Helmet>
        {Object.keys(tags).map(name => {
          const message = tags[name];
          if (!message) {
            return null;
          }
          const content = message.id
            ? intl.formatMessage(message, vars)
            : message;
          return name === 'title' ? (
            <title key={name}>{content}</title>
          ) : (
            <meta key={name} name={name} content={content} />
          );
        })}
      </Helmet>
    );
  }
}

MetaTags.propTypes = {
  tags: PropTypes.object.isRequired,
  vars: PropTypes.object,
  intl: intlShape.isRequired,
};

export default injectIntl(MetaTags);
