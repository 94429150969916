/*
 *
 * WithRoute actions
 *
 */

import { HASH_ADD, HASH_CLEAR, HASH_DELETE } from './constants';

export function hashAdd(key, value) {
  return {
    type: HASH_ADD,
    key,
    value,
  };
}

export function hashClear() {
  return {
    type: HASH_CLEAR,
  };
}

export function hashDelete(key) {
  return {
    type: HASH_DELETE,
    key,
  };
}
