/**
 *
 * Jubileum
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { pick } from 'lodash';
import Container from 'components/KmVreters/Container';
import { useRequest } from 'utils/request';
import Markdown from 'components/Markdown';
import MetaTags from 'components/MetaTags';

import virtualRunner from 'images/rm-jubileum-2.png';
import messages from './messages';
import { text1, text2 } from './jubileum.txt';

const Img = styled.img`
  width: 128px;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
  @media (min-width: 512px) {
    width: 192px;
  }
`;

const SepSpan = styled.span`
  &:not(:first-child) ::before {
    content: '-';
  }
`;

function Jubileum() {
  const tags = pick(messages, ['title', 'description']);
  const [races] = useRequest({
    url: `/virtual/list?owner=7&&title=jubileum%&date=2020-08-23`,
  });

  return (
    <Container width={720}>
      <MetaTags tags={tags} />
      <Container.Header>
        <FormattedMessage {...messages.title} />
      </Container.Header>
      <div>
        <Img src={virtualRunner} className="pull-right" alt="Jubileum Runner" />
        <Markdown source={text1} linkTarget="_blank" />
        <div className="lh-lg list-unstyled">
          {races &&
            !races.loading &&
            races.map(race => (
              <SepSpan key={race.hash}>
                <Link
                  key={race.hash}
                  to={`/virtual/${race.hash}`}
                  className="btn btn-link"
                >
                  {race.title
                    .split(' ')
                    .slice(1, -1)
                    .join(' ')}
                </Link>
              </SepSpan>
            ))}
        </div>
        <Markdown source={text2} linkTarget="_blank" />
      </div>
    </Container>
  );
}

Jubileum.propTypes = {};

export default Jubileum;
