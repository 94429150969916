/**
 *
 * RouteLocation
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-leaflet';
import { injectIntl, intlShape } from 'react-intl';

import startIconImage from 'images/icons/start.png';
import finishIconImage from 'images/icons/finish.png';
import leafletIcon from 'utils/leafletIcon';

import messages from './messages';

function PathMarker(props) {
  const { position, type, children, intl } = props;
  const icon = leafletIcon({
    iconUrl: type === 'start' ? startIconImage : finishIconImage,
    iconSize: [20, 32],
  });
  const title = intl.formatMessage(messages[type]);
  const attribs = { title, position, icon, zIndexOffset: 100 };
  return <Marker {...attribs}>{children}</Marker>;
}

PathMarker.propTypes = {
  position: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.node,
  intl: intlShape,
};

export default injectIntl(PathMarker);
