import { format, parseISO } from 'date-fns';

const DATE_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

function getContent(value, formatValue) {
  if (formatValue) {
    return formatValue(value);
  }
  if (value instanceof Date) {
    return format(value, 'yyyy-MM-dd HH:mm');
  }
  if (typeof value === 'string' && DATE_REGEX.test(value)) {
    return format(parseISO(value), 'yyyy-MM-dd HH:mm');
  }
  if (typeof value === 'object') {
    return JSON.stringify(value);
  }
  return value;
}

export default getContent;
