import Cryptr from 'cryptr';

const cryptr = new Cryptr(
  '2945506da3da19554097c47350178fe37f7291ba2f98b799d3908449aa90cc8a',
);

export function encrypt(str) {
  return cryptr.encrypt(str);
}

export function decrypt(str) {
  return cryptr.decrypt(str);
}
