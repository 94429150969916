import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import DataCell from './DataCell';

function DataList(props) {
  const { fields, data } = props;
  const className = 'text-left pr-2 py-1';
  return (
    <Table className="w-auto m-4">
      <tbody>
        {fields.map(field => (
          <tr key={field.key}>
            <th className={className}>{field.key}:</th>
            <DataCell
              key={field.key}
              field={field}
              item={data}
              className={className}
            />
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

DataList.propTypes = {
  fields: PropTypes.array,
  data: PropTypes.object,
};
export default DataList;
