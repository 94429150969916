import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, FormControl } from 'react-bootstrap';
import withSettings from 'containers/SettingsProvider/withSettings';
import { MILES_KM } from 'utils/constants';

class Input extends React.PureComponent {
  handleChange(ev) {
    const { onChange, units } = this.props;
    const { target } = ev;
    const value = units === 'km' ? target.value : target.value / MILES_KM;
    onChange({ target: { ...target, value } });
  }

  render() {
    const { id, value, onChange, units, readOnly } = this.props;
    return (
      <InputGroup className="input-short">
        <FormControl
          type="number"
          id={id}
          step="0.1"
          min="0"
          value={value}
          onChange={onChange}
          readOnly={readOnly}
        />
        <InputGroup.Addon>{units}</InputGroup.Addon>
      </InputGroup>
    );
  }
}

Input.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  units: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

export default withSettings(['units'])(Input);
