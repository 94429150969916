export function parseParamString(str) {
  return str
    .replace(/^[#?]/, '')
    .split('&')
    .filter(p => p !== '')
    .reduce((acc, item) => {
      const [key, value] = item.split('=');
      return {
        ...acc,
        [key]: value === undefined ? true : decodeURIComponent(value),
      };
    }, {});
}

function parseSearch() {
  return parseParamString(window.location.search);
}

export default parseSearch;
