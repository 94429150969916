import { subDays } from 'date-fns';
import { dateFormat } from 'utils/datetime';

function compareDate(date, d = 0) {
  const today = dateFormat(subDays(new Date(), d));
  if (today === date) {
    return 0;
  }
  return date > today ? 1 : -1;
}

export const isPast = (date, d) => compareDate(date, d) < 0;
export const isFuture = (date, d) => compareDate(date, d) > 0;

export default compareDate;
