import firebase from './firebase';

export function* sendSignUp(email) {
  const actionCodeSettings = {
    url: window.location.href.replace(/#.*/, ''),
    // This must be true.
    handleCodeInApp: true,
  };
  yield firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
}

export function* confirmSignUp(email) {
  yield firebase.auth().signInWithEmailLink(email, window.location.href);
}
