/* eslint-disable react/no-array-index-key */
/**
 * ActivityMap
 */

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Panel } from 'react-bootstrap';
import { red500 } from 'styles/colors';
import L from 'leaflet';

import { Map, Polyline, ScaleControl } from 'react-leaflet';
import Control from 'react-leaflet-control';
import LayersControl from 'components/LayersControl';
import formatTime from 'utils/formatTime';
import useVisible from 'utils/useVisible';
import TimeMarkers from './MapModal/TimeMarkers';
import useShowPosition from './MapModal/useShowPosition';
import IndexMarker from './MapModal/IndexMarker';

const Wrapper = styled.div`
  height: 300px;
  @media screen and (min-height: 1200px) {
    height: 500px;
  }
  .leaflet-container {
    flex-grow: 1;
    height: 100%;
  }
`;

function ActivityMap(props) {
  const { data, times, distance } = props;
  const { start, end } = times;
  const [zoom, setZoom] = useState(15);
  const latlng = o => o.latlng;
  const lines = useMemo(
    () => [
      {
        positions: data.filter(o => o.time < start).map(latlng),
        color: red500,
        opacity: 0.5,
        id: 'before',
      },
      {
        positions: data
          .filter(o => o.time >= start && o.time < end)
          .map(latlng),
        color: red500,
        weight: 3,
        id: 'during',
      },
      {
        positions: data.filter(o => o.time >= end).map(latlng),
        color: red500,
        opacity: 0.5,
        id: 'after',
      },
    ],
    [data, start, end],
  );
  const [ref, visible] = useVisible(true);
  const { time, index } = useShowPosition(data);
  const bounds = L.latLngBounds(data.map(latlng));
  return (
    <Wrapper ref={ref}>
      {visible && (
        <Map bounds={bounds} onZoomend={ev => setZoom(ev.target.getZoom())}>
          <ScaleControl position="bottomright" />
          <LayersControl position="topright" />
          {lines.map(line => <Polyline key={line.id} {...line} />)}
          <TimeMarkers
            points={[]}
            start={data[0].latlng}
            zoom={zoom}
            kmData={data.filter(o => o.time >= start)}
            distance={distance}
            finish={data[data.length - 1].latlng}
          />
          {Boolean(data[index]) && <IndexMarker center={data[index].latlng} />}
          {time !== null && (
            <Control position="bottomleft">
              <Panel className="leaflet-control-layers p-2">
                {formatTime(time)}
              </Panel>
            </Control>
          )}
        </Map>
      )}
    </Wrapper>
  );
}

ActivityMap.propTypes = {
  data: PropTypes.array.isRequired,
  times: PropTypes.object.isRequired,
  distance: PropTypes.number.isRequired,
};

export default ActivityMap;
