/**
 *
 * KmVretersPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { replace } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { FormattedHTMLMessage } from 'react-intl';
import { Alert } from 'react-bootstrap';
import withRest from 'containers/Rest/withRest';
import withUser from 'containers/User/withUser';
import { fetchEntity, getEntity } from 'containers/Rest/actions';
import withRoute from 'containers/withRoute';
import {
  Edit,
  Import,
  List,
  Profile,
  Ranking,
  PrList,
  Overview,
} from 'components/KmVreters';
import Footer from 'components/Footer';
import Spinner from 'components/Spinner';
import injectReducer from 'utils/injectReducer';
import makeSelectKmVretersPage from './selectors';
import reducer from './reducer';
import messages from './messages';

const USER_PATHS = [
  '/kmvreters/list',
  '/kmvreters/edit',
  '/kmvreters/import',
  '/kmvreters/profile',
];

export const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  & > div {
    flex-grow: 1;
  }
  & > .eEmqtl:first-child {
    margin-top: 20px;
  }
`;

export const PlainWrapper = styled.div`
  max-width: 640px;
  min-width: 200px;
  margin: 20px auto;
  min-height: 200px;
  a {
    font-weight: bold;
  }
`;

const componentForPage = {
  edit: Edit,
  import: Import,
  list: List,
  profile: Profile,
  ranking: Ranking,
  clubs: Ranking,
  prlist: PrList,
  overview: Overview,
};

/* eslint-disable react/prefer-stateless-function */
export class KmVretersPage extends React.PureComponent {
  componentDidMount() {
    this.checkChange();
  }

  componentDidUpdate(prevProps) {
    this.checkHash();
    this.checkChange(prevProps);
  }

  checkHash() {
    const { location, pathReplace, match } = this.props;
    if (location.hash && componentForPage[location.hash.slice(1)]) {
      pathReplace(location.hash.replace('#', '/kmvreters/'));
      return;
    }
    if (location.pathname !== match.url) {
      pathReplace(location.pathname);
    }
  }

  getUrl() {
    const { location, match } = this.props;
    return `${match.url.slice(1)}${location.search}`;
  }

  matchUrl() {
    const { match, data } = this.props;
    switch (match.params.page) {
      case 'edit':
        return !match.params.id || data.url === `kmvreters/${match.params.id}`;
      case 'import':
      case 'profile':
        return true;
      default:
        return data.url === this.getUrl();
    }
  }

  isUserPath() {
    const { match } = this.props;
    return USER_PATHS.includes(match.url);
  }

  checkChange(prevProps) {
    const { location, match, user, fetchList, getItem } = this.props;
    const userPath = this.isUserPath();
    if (
      !prevProps ||
      prevProps.match.url !== match.url ||
      prevProps.location.search !== location.search ||
      (userPath && prevProps.user !== user)
    ) {
      switch (match.params.page) {
        case 'edit':
          if (!match.params.id) {
            return;
          }
          getItem(Number(match.params.id));
          return;
        case 'import':
        case 'profile':
          return;
        default:
          if (!userPath || user.api) {
            fetchList(this.getUrl());
          }
      }
    }
  }

  renderContent() {
    const { match, user } = this.props;
    const { page } = match.params;
    const Component = (page && componentForPage[page]) || Ranking;
    if (!this.isUserPath() || user.api) {
      return this.matchUrl() && <Component {...this.props} />;
    }
    const output = user.loading ? (
      <Spinner />
    ) : (
      <Alert>
        <FormattedHTMLMessage {...messages.login} />
      </Alert>
    );
    return <PlainWrapper>{output}</PlainWrapper>;
  }

  render() {
    return (
      <Wrapper>
        <Helmet>
          <title>RunnerMaps Kilometervreters</title>
          <meta
            name="description"
            content="RunnerMaps Kilometervreters competitie"
          />
        </Helmet>
        {this.renderContent()}
        <Footer />
      </Wrapper>
    );
  }
}

KmVretersPage.propTypes = {
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  getItem: PropTypes.func.isRequired,
  fetchList: PropTypes.func.isRequired,
  pathReplace: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  data: makeSelectKmVretersPage(),
});

function mapDispatchToProps(dispatch) {
  return {
    getItem: id => dispatch(getEntity('kmvreters', id)),
    fetchList: url => dispatch(fetchEntity('kmvreters', url)),
    pathReplace: path => dispatch(replace(path)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'kmVretersPage', reducer });

export default compose(
  withRest,
  withUser,
  withReducer,
  withRoute,
  withConnect,
)(KmVretersPage);
