import { addDays, format } from 'date-fns';
import { staticLocale } from 'utils/useDateLocale';

const refMonday = new Date('1980-01-07');

/**
 * Get name for day of week
 * @param {integer} d  0 = monday
 */
function getDOW(d) {
  return format(addDays(refMonday, d), 'iiiiii', staticLocale);
}

export default getDOW;
