import React, { useRef } from 'react';
import { PropTypes } from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

function SearchUser(props) {
  const { email = '', loading } = props;
  const { pathname } = useLocation();
  const { push } = useHistory();
  const count = useRef(0);

  async function handleSearch(e) {
    e.preventDefault();
    const { value } = e.target.email;
    if (!value) {
      push(pathname);
    }
    count.current++;
    const search = new URLSearchParams({
      email: value,
      count: count.current,
    }).toString();
    push(`${pathname}?${search}`);
  }

  return (
    <form onSubmit={handleSearch} className="d-flex mb-4">
      <FormGroup controlId="email" className="mr-3 mb-0">
        <ControlLabel className="sr-only">Email</ControlLabel>
        <FormControl
          type="text"
          placeholder="Email"
          defaultValue={email}
          // onChange={handleChange}
          autoFocus
        />
      </FormGroup>
      <Button type="submit" disabled={loading} bsStyle="primary">
        Search
      </Button>
    </form>
  );
}

SearchUser.propTypes = {
  loading: PropTypes.bool,
  email: PropTypes.string,
};

export default SearchUser;
