/*
 *
 * User reducer
 *
 */

import { fromJS } from 'immutable';
import { addDays, endOfDay } from 'date-fns';
import userFromAuth from 'utils/userFromAuth';
import { decrypt } from 'utils/cryptr';
import storage from 'utils/storage';
import {
  USER_CHANGE,
  USER_LOADING,
  USER_API,
  USER_STRAVA,
  USER_PROFILE,
  USER_ERROR,
  USER_SEND_SIGNUP_ERROR,
  USER_SEND_SIGNUP_SUCCESS,
  USER_UPDATE,
} from './constants';

const initialUser = {};
try {
  const userInfoCode = storage.getItem('userInfo');
  if (userInfoCode) {
    const userInfo = JSON.parse(decrypt(userInfoCode));
    Object.assign(initialUser, userInfo, { loading: true });
    storage.removeItem('userInfo');
  }
} catch (err) {
  // Ignore, just don't set initial user info
}
export const initialState = fromJS(initialUser);

function isPm(data) {
  if (!data || !data.pm) {
    return false;
  }
  const { pm, ar } = data;
  let endDate = new Date(pm);
  if (ar) {
    endDate = addDays(endDate, 7);
  }
  return new Date() < endOfDay(endDate);
}

function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_CHANGE:
      return action.user
        ? state.merge(userFromAuth(action.user.toJSON()))
        : state.clear();
    case USER_LOADING:
      return state.set('loading', action.value);
    case USER_API:
      return state.merge({
        api: action.data,
        su: action.data && action.data.su,
        pm: isPm(action.data),
        loading: false,
      });
    case USER_STRAVA:
      return state.merge({
        strava: action.data,
      });
    case USER_UPDATE: {
      const { path, value } = action.data;
      return state.setIn(path, value);
    }
    case USER_PROFILE:
      return state.mergeIn(['api', 'userData'], action.data);
    case USER_ERROR:
    case USER_SEND_SIGNUP_ERROR:
      return state.merge({ error: action.error });
    case USER_SEND_SIGNUP_SUCCESS:
      return state.merge({ signup: action.payload });
    default:
      return state;
  }
}

export default userReducer;
