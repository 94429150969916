import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import useInfo from './useInfo';
import Spinner from '../Spinner';
import fieldDefinitions from './fieldDefinitions';
import DataTable from './DataTable';
import DataList from './DataList';

function UserInfo(props) {
  const { userId } = props;
  const userInfo = useInfo(userId);
  const { hash } = useLocation();
  const { replace } = useHistory();
  const [activeKey, setActiveKey] = useState(
    hash.slice(1) || Object.keys(fieldDefinitions)[0],
  );

  if (!userInfo) {
    return <Spinner />;
  }

  function handleSelect(key) {
    replace(`#${key}`);
    setActiveKey(key);
  }

  const tabs = Object.entries(fieldDefinitions)
    .map(([key, fields]) => ({
      key,
      eventKey: key,
      title: key,
      data: userInfo[key],
      fields,
    }))
    .map(({ data, ...rest }) => ({
      ...rest,
      data,
      disabled: !data || data.length === 0,
      Component: Array.isArray(data) ? DataTable : DataList,
    }));

  return (
    <div>
      <h1>{`UserInfo ${userId}`}</h1>
      <Tabs activeKey={activeKey} id="user-info" onSelect={handleSelect}>
        {tabs.map(({ Component, data, fields, ...rest }) => (
          <Tab {...rest}>
            {data && <Component data={data} fields={fields} />}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}

UserInfo.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default UserInfo;
