import * as Sentry from '@sentry/browser';
import { version } from '../../package.json';

const serverName = window.location.hostname;

Sentry.init({
  dsn: 'https://f67e7cf01a6248b2bd51e2957ded85e9@sentry.io/1296212',
  release: version,
  beforeSend,
});

function getMessage(data) {
  const { message, exception } = data;
  if (message) {
    return message;
  }
  if (exception && exception.values && exception.values[0]) {
    return exception && exception.values && exception.values[0].value;
  }
  return undefined;
}

const messageCount = {};

function beforeSend(event) {
  const message = getMessage(event);
  messageCount[message] = (messageCount[message] || 0) + 1;
  if (messageCount[message] > 3) {
    return null;
  }
  if (serverName === 'localhost') {
    console.log('Sentry', event); // eslint-disable-line no-console
    return null;
  }
  const reduceState = state =>
    state.delete ? state.delete('user').delete('editservice') : state;
  const reduceLastAction = lastAction => {
    const { payload } = lastAction;
    return Array.isArray(payload)
      ? { ...lastAction, payload: payload.slice(0, 20) }
      : lastAction;
  };
  const extra = {
    ...event.extra,
    state: reduceState(event.extra.state),
    lastAction: reduceLastAction(event.extra.lastAction),
  };
  const tags = {
    ...event.tags,
    dbg_msg: message,
  };
  return { ...event, extra, tags };
}

export function reportError(err, context) {
  Object.keys(context).forEach(key => {
    Sentry.setExtra(key, context[key]);
  });
  Sentry.captureException(err);
}
