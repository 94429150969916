import useAuthRequest from './useAuthRequest';

function useFetch() {
  const authRequest = useAuthRequest();
  return (url, params) => {
    const query = new URLSearchParams(params).toString();
    const fullUrl = `${url}?${query}`;
    return authRequest(fullUrl);
  };
}

export default useFetch;
