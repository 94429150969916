/**
 *
 * Virtual
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Container from 'components/KmVreters/Container';
import TitleLink from '../TitleLink';
import AllRaces from './AllRaces';
import messages from '../messages';

function Virtual(props) {
  const { user } = props;
  const { api } = user;
  return (
    <Container width={720}>
      <Container.Header>
        <TitleLink to="/virtual" />
        <FormattedMessage {...messages.header} />
      </Container.Header>
      <div>{api && api.su && <AllRaces user={user} />}</div>
    </Container>
  );
}

Virtual.propTypes = {
  user: PropTypes.object,
};

export default Virtual;
