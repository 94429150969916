import * as colors from 'styles/colors';

export const GC_GREEN_TEXT = colors.green700;
export const GC_GREEN_FILL = colors.green500;
export const GC_GREEN_LINE = colors.green500;
export const GC_RED_TEXT = colors.red500;
export const GC_RED_FILL = colors.red300;
export const GC_RED_LINE = colors.red500;

export const GC_GREEN_AXIS = {
  textStyle: {
    color: GC_GREEN_TEXT,
  },
  titleTextStyle: {
    color: GC_GREEN_TEXT,
  },
};

export const GC_RED_AXIS = {
  textStyle: {
    color: GC_RED_TEXT,
  },
  titleTextStyle: {
    color: GC_RED_TEXT,
  },
};
