/**
 *
 * CreateGroup
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import GroupForm from './GroupForm';
import createSession from './createSession';
import getTempId from './getTempId';

function CreateGroup(props) {
  const { onSubmit } = props;
  const handleSubmit = data => {
    const body = {
      name: data.name,
      schedule: Object.values(data.schedule),
    };
    onSubmit({
      method: 'POST',
      url: '/res/groups',
      body,
    });
  };
  const group = {
    name: '',
    schedule: { [getTempId()]: createSession() },
  };
  return <GroupForm group={group} onSubmit={handleSubmit} />;
}

CreateGroup.propTypes = {
  onSubmit: PropTypes.func,
};

export default CreateGroup;
