/*
 * Required Messages
 *
 * This contains all the text for the Required component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  required: {
    id: 'app.components.Required.required',
    defaultMessage: 'Required',
  },
});
