/**
 *
 * useStorage
 *
 */

import { useState } from 'react';
import PropTypes from 'prop-types';

import storage from 'utils/storage';

function useStorage(key, initialValue) {
  const current = storage.getItem(key);
  const [value, setValue] = useState(
    current === undefined ? initialValue : current,
  );
  const setStorage = val => {
    if (val === null) {
      storage.removeItem(key);
    } else {
      storage.setItem(key, val);
    }
    setValue(val);
  };
  return [value, setStorage];
}

useStorage.propTypes = {
  key: PropTypes.string,
};

export default useStorage;
