/**
 *
 * ProgressAlert
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import InfoPopover from './InfoPopover';

function formatProgress(progress) {
  if (progress >= 60) {
    const minutes = Math.floor(progress / 60);
    const seconds = Math.round(progress % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }
  return progress.toPrecision(2);
}

function ProgressAlert(props) {
  const { progress } = props;
  const progressMessage = progress < 0 ? messages.progress : messages.decay;
  return (
    <Alert bsStyle={progress < 0 ? 'success' : 'warning'}>
      <FormattedMessage {...progressMessage} />
      {`: ${formatProgress(Math.abs(progress))} `}
      <FormattedMessage {...messages.secWeek} />
      <InfoPopover trigger="click" rootClose placement="top" className="py-0">
        <FormattedMessage {...messages.ignoreDeviations} />
      </InfoPopover>
    </Alert>
  );
}

ProgressAlert.propTypes = {
  progress: PropTypes.number,
};

export default ProgressAlert;
