/**
 *
 * List
 *
 */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Alert, Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withSettings from 'containers/SettingsProvider/withSettings';
import Link from 'components/Link';
import MetaTags from 'components/MetaTags';
import Spinner from 'components/Spinner';
import { useHash } from 'utils/urlUtils';
import { red500 } from 'styles/colors';
import Container from '../Container';
import messages from './messages';
import Races from './Races';
import Distances from './Distances';
import Period from './Period';
import distanceString from './distanceString';
import { selectTab, tabAttribs } from './tabs';

const Wrapper = styled(Container)`
  .pull-right svg + span {
    margin-left: 0.5em;
    @media (max-width: 479px) {
      display: none;
    }
  }
  .nav-tabs {
    & > li {
      max-width: 25%;
      white-space: nowrap;
      & > a {
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 479px) {
          padding: 10px 5px;
        }
      }
    }
    svg.fa-chart-bar {
      margin-left: 0.5em;
      color: ${red500};
    }
  }
`;

function getSummary(list, units) {
  if (!list || list.length === 0) {
    return null;
  }
  const year = list[0].dateStr.slice(0, 4);
  const summary = list.reduce(
    (acc, item) => {
      const distance = Number(item.distance);
      const result = {
        ...acc,
        total: acc.total + distance,
      };
      if (item.dateStr.startsWith(year)) {
        result.current += distance;
      }
      return result;
    },
    {
      total: 0,
      current: 0,
      units,
      year,
    },
  );
  summary.total = distanceString(summary.total, units);
  summary.current = distanceString(summary.current, units);
  return summary;
}

/* eslint-disable react/prefer-stateless-function */
function List(props) {
  const { data, units } = props;
  const { list, isOwn } = data.value || {};
  const summary = useMemo(() => getSummary(list, units), [list, units]);
  const hash = useHash();

  function handleSelect(key) {
    hash.push({ tab: key.toLowerCase() });
  }

  function renderIntro() {
    if (data.value.isOwn) {
      return <FormattedMessage {...messages.introSelf} />;
    }
    const values = { name: data.value.user };
    return <FormattedMessage {...messages.intro} values={values} />;
  }

  function renderTabs() {
    const activeKey = selectTab(hash.tab);
    return (
      <React.Fragment>
        <p>
          {renderIntro()}
          <br />
          <FormattedHTMLMessage {...messages.summary} values={summary} />
        </p>
        <Tabs activeKey={activeKey} onSelect={handleSelect} id="list-tabs">
          <Tab {...tabAttribs.allRaces}>
            <Races {...props} />
          </Tab>
          <Tab {...tabAttribs.byDistance}>
            <Distances {...props} active={activeKey === 'byDistance'} />
          </Tab>
          <Tab {...tabAttribs.byMonth}>
            <Period
              {...props}
              active={activeKey === 'byMonth'}
              period="month"
            />
          </Tab>
          <Tab {...tabAttribs.byYear}>
            <Period {...props} active={activeKey === 'byYear'} period="year" />
          </Tab>
        </Tabs>
      </React.Fragment>
    );
  }

  function renderStart() {
    return (
      <React.Fragment>
        <p>
          <FormattedMessage {...messages.summaryNone} />
        </p>
        <Alert>
          <ul>
            <li>
              <FormattedHTMLMessage {...messages.startUitslagen} />
            </li>
            <li>
              <FormattedHTMLMessage {...messages.startButton} />
            </li>
          </ul>
        </Alert>
      </React.Fragment>
    );
  }

  function renderMain() {
    if (data.error) {
      return <Alert bsStyle="danger">{data.error.toString()}</Alert>;
    }
    if (data.loading) {
      return <Spinner />;
    }
    const showTabs = list && list.length > 0;
    return (
      <main>
        {isOwn && (
          <Link className="btn btn-default pull-right" href="/kmvreters/edit">
            <FontAwesomeIcon icon="plus" />
            <FormattedMessage {...messages.addRace} />
          </Link>
        )}
        {showTabs ? renderTabs() : renderStart()}
      </main>
    );
  }

  const tags = { title: messages.title };
  return (
    <Wrapper width={720}>
      <MetaTags tags={tags} />
      <Container.Header>
        <FormattedMessage {...messages.title} />
      </Container.Header>
      {renderMain()}
    </Wrapper>
  );
}

List.propTypes = {
  data: PropTypes.object.isRequired,
  units: PropTypes.string.isRequired,
};

export default withSettings(['units'])(List);
