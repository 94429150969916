/*
 * ShareLink Messages
 *
 * This contains all the text for the ShareLink component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.ShareLink.title',
    defaultMessage: 'Copy the link to this page to the clipboard',
  },
  copied: {
    id: 'app.components.ShareLink.copied',
    defaultMessage: 'The link has been copied to the clipboard',
  },
  share: {
    id: 'app.components.ShareLink.share',
    defaultMessage: 'Share',
  },
  copyLink: {
    id: 'app.components.ShareLink.copyLink',
    defaultMessage: 'Copy Link',
  },
});
