/**
 *
 * useAlerts
 *
 */

import { useDispatch } from 'react-redux';
import { addAlert } from './actions';

function useAlerts() {
  const dispatch = useDispatch();
  return options => dispatch(addAlert(options));
}

export default useAlerts;
