import { useEffect, useRef, useState } from 'react';
// From: https://gist.github.com/AlpacaGoesCrazy/25e3a15fcd4e57fb8ccd408d488554d7
/*
	If you attempt to set some state after asynchronous request it may happen that component you wish to set state on has been unmounted.
	This will trigger "Warning: Can’t call setState (or forceUpdate) on an unmounted component." warning.
	This hooks is `useState` hook which prevents setting state on an unmounted component
	Usage:
		const [myState, mySafeSetState] = useSafeState(initialValue)
*/
const useSafeState = initialValue => {
  const isMounted = useRef(); // useRef to memorize if the component is mounted between renders
  const [state, setState] = useState(initialValue);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });
  const safeSetState = (...args) => {
    if (isMounted.current) {
      // do not call setState if the component already unmounted
      setState(...args);
    }
  };
  return [state, safeSetState];
};

export default useSafeState;
