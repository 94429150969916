/*
 *
 * EditService actions
 *
 */

import {
  EDIT_ADD,
  EDIT_CLEAR,
  EDIT_CREATE,
  EDIT_DELETE,
  EDIT_DRAG,
  EDIT_EDIT,
  EDIT_GEOCODE,
  EDIT_IMPORT,
  EDIT_INSERT,
  EDIT_MOVE,
  EDIT_REPLACE,
  EDIT_ROUTING,
  EDIT_SAVE,
  EDIT_UNDO,
} from './constants';

export function editAdd(point, travelMode) {
  return {
    type: EDIT_ADD,
    point,
    travelMode,
  };
}
export function editClear(forceEmpty) {
  return {
    type: EDIT_CLEAR,
    forceEmpty,
  };
}
export function editCreate() {
  return {
    type: EDIT_CREATE,
  };
}
export function editDelete(id, travelMode) {
  return {
    type: EDIT_DELETE,
    id,
    travelMode,
  };
}
export function editDrag(id, point) {
  return {
    type: EDIT_DRAG,
    id,
    point,
  };
}
export function editEdit(route) {
  return {
    type: EDIT_EDIT,
    route,
  };
}
export function editGeocode(address) {
  return {
    type: EDIT_GEOCODE,
    address,
  };
}
export function editImport() {
  return {
    type: EDIT_IMPORT,
  };
}
export function editInsert(id, point, travelMode) {
  return {
    type: EDIT_INSERT,
    id,
    point,
    travelMode,
  };
}
export function editMove(id, point, travelMode) {
  return {
    type: EDIT_MOVE,
    id,
    point,
    travelMode,
  };
}
export function editReplace(id, travelMode) {
  return {
    type: EDIT_REPLACE,
    id,
    travelMode,
  };
}
export function editRouting(legs, routes) {
  return {
    type: EDIT_ROUTING,
    legs,
    routes,
  };
}
export function editSave() {
  return {
    type: EDIT_SAVE,
  };
}
export function editUndo() {
  return {
    type: EDIT_UNDO,
  };
}
