/**
 *
 * subcomponents
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Alert } from 'react-bootstrap';
import * as colors from 'styles/colors';

export const Header = styled.h1`
  border-bottom: 2px solid ${colors.green500};
  padding-bottom: 10px;
  margin-bottom: 15px;
`;

const Container = styled.div`
  width: 100%;
  max-width: calc(100vw);
  margin-bottom: 20px;
  & > :not(.table-responsive):not(ul) {
    margin-left: 10px;
    margin-right: 10px;
  }
  @media (min-width: ${p => p.width + 20}px) {
    & > *:not(ul) {
      padding-left: 0;
      padding-right: 0;
    }
    max-width: ${p => p.width}px;
    margin: 0 auto;
  }
  .btn-default:not(:first-child) {
    & .pull-right {
      margin-bottom: 20px;
    }
  }
  .btn-default:not(:first-child) {
    margin-left: 10px;
  }
  .alert {
    padding: 10px;
    margin-top: 20px;
  }
  .alert-dismissable {
    padding-right: 30px;
  }
  .alert-info {
    clear: right;
    background-color: transparent;
    color: ${colors.grey700};
    border-color: ${colors.green100};
  }
  .td-date,
  .td-distance {
    white-space: nowrap;
    &.text-danger {
      color: ${colors.red500};
      font-weight: bold;
    }
    input {
      margin: -2px 0;
      text-align: right;
      width: 4em;
      border: 2px solid ${colors.green300};
    }
  }
`;

function ContainerAlert(props) {
  const { bsStyle, children, ...rest } = props;
  return (
    <Container {...rest}>
      <Alert bsStyle={bsStyle}>{children}</Alert>
    </Container>
  );
}

ContainerAlert.propTypes = {
  className: PropTypes.string,
  bsStyle: PropTypes.string,
  children: PropTypes.node,
};

Container.Alert = ContainerAlert;
Container.Header = Header;
export default Container;
