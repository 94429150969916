/**
 *
 * Legend
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconColors from 'styles/iconColors';
import messages from '../messages';

const Wrapper = styled.section`
  ${iconColors};
  margin: 2rem 0;
  div {
    display: flex;
    svg {
      margin-top: 3px;
    }
    margin-bottom: 1rem;
  }
`;

function Legend() {
  const icons = ['check', 'bars', 'times'];
  return (
    <Wrapper id="legend" className="alert alert-info">
      {icons.map(icon => (
        <div key={icon}>
          <FontAwesomeIcon icon={icon} fixedWidth />
          <FormattedMessage {...messages[icon]} />
        </div>
      ))}
    </Wrapper>
  );
}

export default Legend;
