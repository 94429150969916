/*
 *
 * KmVretersPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  GET_ENTITY_REQUEST,
  GET_ENTITY_SUCCESS,
  GET_ENTITY_ERROR,
  LIST_ENTITY_REQUEST,
  LIST_ENTITY_SUCCESS,
  LIST_ENTITY_ERROR,
  CHANGE_ENTITY_REQUEST,
  CHANGE_ENTITY_SUCCESS,
  CHANGE_ENTITY_ERROR,
} from 'containers/Rest/constants';

export const initialState = fromJS({});

function sameUrl(u1, u2) {
  const page = u => u && u.split(/\W+/, 2)[1];
  return page(u1) === page(u2);
}

function kmVretersPageReducer(state = initialState, action) {
  const { type, options, payload, error, url } = action;
  if (!options || options.entity !== 'kmvreters') {
    return state;
  }
  const mergeOnRequest = { url, loading: true, error: null };
  switch (type) {
    case GET_ENTITY_REQUEST:
      return state.merge(mergeOnRequest);
    case LIST_ENTITY_REQUEST:
      return sameUrl(url, state.get('url'))
        ? state.merge(mergeOnRequest)
        : state.merge(mergeOnRequest).delete('value');
    case GET_ENTITY_SUCCESS:
      return state.merge({ value: payload, loading: false });
    case LIST_ENTITY_SUCCESS:
      if (payload.pageNr && Number(payload.pageNr) > 1) {
        const value = {
          ...payload,
          list: [...state.getIn(['value', 'list']).toJS(), ...payload.list],
        };
        return state.merge({ value, loading: false });
      }
      return state.merge({ value: payload, loading: false });
    case GET_ENTITY_ERROR:
    case LIST_ENTITY_ERROR:
      return state.merge({ error, loading: false });
    case CHANGE_ENTITY_REQUEST:
      return state.merge({ working: true, error: null });
    case CHANGE_ENTITY_SUCCESS:
      if (options.method === 'DELETE') {
        return state
          .update('value', removeFromList(options.id))
          .merge({ working: false });
      }
      // No need to store payload, will be reloaded anyway
      return state.merge({ working: false });
    case CHANGE_ENTITY_ERROR:
      return state.merge({ working: false, error });
    default:
      return state;
  }
}

function removeFromList(id) {
  return value =>
    value.update('list', list => list.filter(item => item.get('id') !== id));
}

export default kmVretersPageReducer;
