/*
 * LayersControl Messages
 *
 * This contains all the text for the LayersControl component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  stadia: {
    id: 'app.components.LayersControl.stadia',
    defaultMessage: 'OpenStreetMap',
  },
  carto: {
    id: 'app.components.LayersControl.carto',
    defaultMessage: 'Carto OSM',
  },
  mapy: {
    id: 'app.components.LayersControl.mapy',
    defaultMessage: 'Mapy OSM',
  },
  openstreetmap: {
    id: 'app.components.LayersControl.openstreetmap',
    defaultMessage: 'openstreetmap.org',
  },
  openCycle: {
    id: 'app.components.LayersControl.openCycle',
    defaultMessage: 'OpenCycleMap',
  },
  outdoors: {
    id: 'app.components.LayersControl.outdoors',
    defaultMessage: 'Outdoors',
  },
  landscape: {
    id: 'app.components.LayersControl.landscape',
    defaultMessage: 'Landscape',
  },
  transport: {
    id: 'app.components.LayersControl.transport',
    defaultMessage: 'Public Transport',
  },
  openbusmap: {
    id: 'app.components.LayersControl.transport',
    defaultMessage: 'Public Transport',
  },
  railway: {
    id: 'app.components.LayersControl.railway',
    defaultMessage: 'Railways',
  },
  stamenTerrain: {
    id: 'app.components.LayersControl.stamenTerrain',
    defaultMessage: 'Terrain',
  },
  worldTopo: {
    id: 'app.components.LayersControl.worldTopo',
    defaultMessage: 'Topographic',
  },
  worldImagery: {
    id: 'app.components.LayersControl.worldImagery',
    defaultMessage: 'Satellite',
  },
  hereHybrid: {
    id: 'app.components.LayersControl.hybrid',
    defaultMessage: 'Hybrid',
  },
  stadiaHybrid: {
    id: 'app.components.LayersControl.hybrid',
    defaultMessage: 'Hybrid',
  },
  hikingRoutes: {
    id: 'app.components.LayersControl.hikingRoutes',
    defaultMessage: 'Hiking Routes',
  },
  cyclingRoutes: {
    id: 'app.components.LayersControl.cyclingRoutes',
    defaultMessage: 'Cycling Routes',
  },
  mtbRoutes: {
    id: 'app.components.LayersControl.mtbRoutes',
    defaultMessage: 'MTB Routes',
  },
  strava: {
    id: 'app.components.LayersControl.strava',
    defaultMessage: 'Strava Heatmap',
  },
});
