/*
 *
 * User constants
 *
 */

export const USER_SIGNOUT = 'app/User/USER_SIGNOUT';
export const USER_ERROR = 'app/User/USER_ERROR';
export const USER_SIMULATE = 'app/User/USER_SIMULATE';
export const USER_CHANGE = 'app/User/USER_CHANGE';
export const USER_LOADING = 'app/User/USER_LOADING';
export const USER_API = 'app/User/USER_API';
export const USER_UPDATE = 'app/User/USER_UPDATE';
export const USER_STRAVA = 'app/User/USER_STRAVA';
export const USER_PROFILE = 'app/User/USER_PROFILE';
export const USER_SEND_SIGNUP = 'app/User/USER_SEND_SIGNUP';
export const USER_SEND_SIGNUP_SUCCESS = 'app/User/USER_SEND_SIGNUP_SUCCESS';
export const USER_SEND_SIGNUP_ERROR = 'app/User/USER_SEND_SIGNUP_ERROR';
export const USER_CONFIRM_SIGNUP = 'app/User/USER_CONFIRM_SIGNUP';
export const USER_CONFIRM_SIGNUP_SUCCESS =
  'app/User/USER_CONFIRM_SIGNUP_SUCCESS';
export const USER_CONFIRM_SIGNUP_ERROR = 'app/User/USER_CONFIRM_SIGNUP_ERROR';

export const STRAVA_NONE = Symbol('No Strava connection');
export const STRAVA_LOADING = Symbol('Strava loading');
export const STRAVA_CONNECTED = Symbol('Connected with Strava');
