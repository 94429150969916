/* eslint-disable react/no-array-index-key */
/**
 */

import React from 'react';
import PropTypes from 'prop-types';
import PathMarker from 'components/RoutePath/PathMarker';
import KmMarker from 'components/KmMarker';
import getStepDistance from '../getStepDistance';

function interpolate(p1, p2, d1, d2, d) {
  const f = (d - d1) / (d2 - d1);
  const lat = p1[0] + (p2[0] - p1[0]) * f;
  const lng = p1[1] + (p2[1] - p1[1]) * f;
  return [lat, lng];
}

function TimeMarkers(props) {
  const { kmData, start, zoom, finish, distance } = props;
  const startStop = (
    <React.Fragment key="start-stop">
      <PathMarker position={start} type="start" />
      <PathMarker position={finish} type="finish" />
    </React.Fragment>
  );
  const stepDistance = getStepDistance(zoom);
  const digits = stepDistance < 1000 ? 1 : 0;
  const kmMarkers = [];
  const dStart = kmData[0].distance;
  const formatDistance = d => ((d - dStart) / 1000).toFixed(digits);
  if (dStart > 0) {
    kmMarkers.push(
      <KmMarker key="0" pos={kmData[0].latlng} km={formatDistance(dStart)} />,
    );
  }
  const dEnd = dStart + distance;
  let nextDistance = dStart + stepDistance;
  let lastData = kmData[0];
  kmData
    .slice(1)
    .filter(d => d.distance <= dEnd + stepDistance / 2)
    .forEach(d => {
      while (d.distance >= nextDistance) {
        const latlng = interpolate(
          lastData.latlng,
          d.latlng,
          lastData.distance,
          d.distance,
          nextDistance,
        );
        kmMarkers.push(
          <KmMarker
            key={nextDistance}
            pos={latlng}
            km={formatDistance(nextDistance)}
          />,
        );
        nextDistance += stepDistance;
      }
      lastData = d;
    });
  return [startStop, ...kmMarkers];
}

TimeMarkers.propTypes = {
  points: PropTypes.array.isRequired,
  start: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  finish: PropTypes.array.isRequired,
};

export default TimeMarkers;
