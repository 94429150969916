/**
 *
 * Pagination
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
// import styled from 'styled-components';
import { Pager } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PAGESIZE } from 'utils/constants';

import { FormattedMessage } from 'react-intl';
import messages from './messages';

const PagedTableCss = createGlobalStyle`
  .table + .pager,
  .table-responsive + .pager {
    margin-top: 0;
  }
`;

function Pagination(props) {
  const { value, onChange, length, v2 } = props;
  if (v2 ? length === 1 : length <= PAGESIZE) {
    return null;
  }
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  });
  const hasPrevious = value > 0;
  const hasNext = v2 ? value < length - 1 : value + PAGESIZE < length;
  const step = v2 ? 1 : PAGESIZE;
  const handleKeyDown = ev => {
    switch (ev.key) {
      case 'ArrowLeft':
        if (hasPrevious) {
          onChange(value - step);
        }
        break;
      case 'ArrowUp':
        if (hasPrevious) {
          onChange(0);
        }
        break;
      case 'ArrowRight':
        if (hasNext) {
          onChange(value + step);
        }
        break;
      default:
      // Do nothing
    }
  };
  return (
    <Pager>
      <PagedTableCss />
      <Pager.Item
        previous
        onClick={() => onChange(value - step)}
        disabled={!hasPrevious}
      >
        <FontAwesomeIcon icon="chevron-left" />&nbsp;
        <FormattedMessage {...messages.previous} />
      </Pager.Item>
      <Pager.Item
        next
        onClick={() => onChange(value + step)}
        disabled={!hasNext}
      >
        <FormattedMessage {...messages.next} />
        &nbsp;<FontAwesomeIcon icon="chevron-right" />
      </Pager.Item>
    </Pager>
  );
}

Pagination.propTypes = {
  value: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  v2: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default Pagination;
