const text = {
  nl: `
  Vanwege de huidige beperkingen (maximale groepsgrootte, 1,5 m afstand) is het niet altijd mogelijk om met de hele groep tegelijk te trainen. Daarom is er nu de slimme inschrijflijst.
   
  Het principe is eenvoudig: als trainer geef je eenmalig aan wat de wekelijkse trainingstijden zijn en hoeveel er iedere keer mee kunnen doen. Er wordt dan automatisch een inschrijflijst gemaakt voor iedere trainingssessie, elke week opnieuw. 
    
  De trainer deelt de weblink met de groep en de deelnemers kunnen zich zelf inschrijven. Eventueel kun je als trainer richtlijnen geven wie op welke dag verwacht wordt.
  
  Het voordeel is dat je niet iedere week opnieuw een inschrijflijst hoeft te maken. Maar er is meer:
  
  - Als je als deelnemer iedere week op een vaste dag komt, kun je dat vastleggen. In plaats van je iedere keer opnieuw in te schrijven, meld je je dan af als je een keer niet kunt.
  - De volgorde op de lijst wordt bepaald door hoe lang een deelnemer niet geweest is. Dus als er teveel deelnemers zijn, komen diegene die vorige keer niet geweest zijn hoger op de lijst te staan. Zo komt iedereen even vaak aan de beurt, wel zo eerlijk.

## Voor trainers

[Maak een groep](#/inschrijven/create) om van start te gaan.
  
## Voor atleten

Deel deze inschrijflijst met je trainer. Hij/zij zal de link naar je groep dan kunnen delen.
`,
  en: `
  In the current circumstances (limited group size, 1.5m distance) it is not always possible to practice with the whole group together. Therefore we have the Smart Reservations List.
   
  The principle is simple: as a coach, you enter the weekly schedule once and indicate how many people can join each session. A new reservations list will be created automatically for each session each week. 
    
  The coach shares the link with the group and each participant can make a reservation. You may give an indication which participant is expected on which day, if appropriate.
  
  The advantage is that you don't have to create the registration lists each week. But there is more:
    
  - If you, as a participant, join every week at the same day, you can set this fixed day. Instead of making a reservation each week, you now have to cancel if you can't join.
  - The priority on the list is determined by how long each member has not joined. So if there too many, those who could not attend the previous time will be higher on the list. This way, everyone can have its turn, which makes it more fair.

## For Coaches

[Create a Group](#/inschrijven/create) to get started.
  
## For Athletes

Tell your coach to use this reservations list. He/She will share the link for your group.
`,
};

export default text;
