/**
 *
 * HomeLink
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { red700, lightBlack } from 'styles/colors';

import { FormattedMessage } from 'react-intl';
import Logo from 'images/icon-32x32.png';
import messages from './messages';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Img = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 4px;
`;

const H1 = styled.h1`
  font-style: italic;
  margin: 0;
  color: ${red700};
`;

const Subtitle = styled.span`
  @media (max-width: 991px) {
    display: none;
  }
  margin-left: 0.33em;
  font-size: 15px;
  color: ${lightBlack};
  font-style: normal;
`;

function HomeLink() {
  return (
    <Wrapper>
      <Link to="/">
        <Img src={Logo} alt="RunnerMaps logo" />
      </Link>
      <H1>
        <FormattedMessage {...messages.title} />
        <Subtitle>
          - <FormattedMessage {...messages.subtitle} />
        </Subtitle>
      </H1>
    </Wrapper>
  );
}

HomeLink.propTypes = {};

export default HomeLink;
