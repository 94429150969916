/*
 * Virtual Messages
 *
 * This contains all the text for the Virtual component.
 */

import React from 'react';
import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.Virtual.header',
    defaultMessage: 'Test Runs',
  },
  headerOne: {
    id: 'app.containers.Virtual.headerOne',
    defaultMessage: 'Virtual Race',
  },
  intro: {
    id: 'app.containers.Virtual.intro',
    defaultMessage: `<p>Test Runs replaces Virtual Races.</p><p>Virtual Races were set up four years ago when there were no races because of Corona and thus gave you the chance to keep challenging yourself. Now that there have been regular road races again for quite some time, we are seeing a sharp decline in participation in Virtual Races. However, we do see that a small group of runners continues to participate frequently.</p><p>Given the small number of participants, the competition element is less important, but by participating regularly you can track your progress. Thus, in practice, the Virtual Races have already become more like test runs.</p><p>This has now also been realised in the design. The ranking is no longer as relevant and has thus been dropped for runs after 1 Feb 2024. Instead, you now see an overview of your results.</p><p>This change has been accelerated because Strava no longer allows virtual races.</p>`,
  },
  testRunsIntro: {
    id: 'app.containers.Virtual.testRunsIntro',
    defaultMessage:
      '<p>Every week you can join a test run.</p><p>By regularly participating, you can track your progress. This is visualized in the graph, that also shows a trend line indicating your progress.</p><p>From the graph or the list of previous runs, you can quickly access those run, see an analysis of that run and compare it with your other runs.</p>',
  },
  upcomingRaces: {
    id: 'app.containers.Virtual.upcomingRaces',
    defaultMessage: 'Upcoming Test Runs',
  },
  moreRaces: {
    id: 'app.containers.Virtual.moreRaces',
    defaultMessage:
      'There are more races, you can join if the organiser had shared the link with you.',
  },
  pastRaces: {
    id: 'app.containers.Virtual.pastRaces',
    defaultMessage: 'Past Test Runs',
  },
  joinAction: {
    id: 'app.containers.Virtual.joinAction',
    defaultMessage: 'Join a Run',
  },
  results: {
    id: 'app.containers.Virtual.results',
    defaultMessage: 'Your Results',
  },
  more: {
    id: 'app.containers.Virtual.more',
    defaultMessage: 'More',
  },
  less: {
    id: 'app.containers.Virtual.less',
    defaultMessage: 'Less',
  },
  promote: {
    id: 'app.containers.Virtual.promote',
    defaultMessage:
      'You have created this race. Good!<br />Now let people know that they can join, you can do so by using the <b>Share</b> button above<br />Share the link via your web site, WhatsApp, email, Facebook, and whatever channel you can think of.',
  },
  ownRoute: {
    id: 'app.containers.Virtual.ownRoute',
    defaultMessage: 'Own Route',
  },
  ownPrivate: {
    id: 'app.containers.Virtual.ownPrivate',
    defaultMessage:
      'Only those who registered see the race here.{br}Use the link on the race page to promote the race.',
    values: {
      br: <br />,
    },
  },
  organiseHeader: {
    id: 'app.containers.Virtual.organiseHeader',
    defaultMessage: 'Organise',
  },
  organise: {
    id: 'app.containers.Virtual.organise',
    defaultMessage: `<ul>
    <li>Select a day and a distance for the race;</li>
    <li>Invite participants by sharing the link you get.</li>
    </ul>
    `,
  },
  explanation: {
    id: 'app.components.ExplanationModal.explanation',
    defaultMessage: 'Explanation',
  },
  joinRace: {
    id: 'app.containers.Virtual.joinRace',
    defaultMessage: 'Join a Test Run',
  },
  create: {
    id: 'app.containers.Virtual.create',
    defaultMessage: 'Create a Test RUn',
  },
  racesCreated: {
    id: 'app.containers.Virtual.racesCreated',
    defaultMessage: 'Test Runs You Created',
  },
  login: {
    id: 'app.containers.KmVretersPage.login',
    defaultMessage: 'You must <a href="#login">sign in</a> first',
  },
  title: {
    id: 'app.containers.Virtual.title',
    defaultMessage: 'Name for the Race',
  },
  period: {
    id: 'app.containers.Virtual.date',
    defaultMessage: 'Date',
  },
  date: {
    id: 'app.containers.Virtual.date',
    defaultMessage: 'Date',
  },
  endDate: {
    id: 'app.containers.Virtual.endDate',
    defaultMessage: 'End Date',
  },
  until: {
    id: 'app.containers.Virtual.until',
    defaultMessage: 'to',
  },
  multiDay: {
    id: 'app.containers.Virtual.multiDay',
    defaultMessage: 'Multiple Days',
  },
  description: {
    id: 'app.containers.Virtual.description',
    defaultMessage: 'Description',
  },
  descriptionHelp: {
    id: 'app.containers.Virtual.descriptionHelp',
    defaultMessage: 'Optional explanation or description for this race',
  },
  image: {
    id: 'app.containers.Virtual.image',
    defaultMessage: 'Image Link',
  },
  imageHelp: {
    id: 'app.containers.Virtual.imageHelp',
    defaultMessage:
      'Will be shown next to the description, e.g., the logo of your club',
  },
  duplicate: {
    id: 'app.containers.Virtual.duplicate',
    defaultMessage: 'Duplicate',
  },
  cancelRace: {
    id: 'app.containers.Virtual.cancelRace',
    defaultMessage: 'Cancel',
  },
  confirmDeleteRace: {
    id: 'app.containers.Virtual.confirmDeleteRace',
    defaultMessage: 'Cancel this Race?',
  },
  hasResultsDelete: {
    id: 'app.containers.Virtual.hasResultsDelete',
    defaultMessage: 'There are results already, the race cannot be cancelled.',
  },
  hasEntriesDate: {
    id: 'app.containers.Virtual.hasEntriesDate',
    defaultMessage: 'There are entries already, dates cannot be removed.',
  },
  hasEntriesDistance: {
    id: 'app.containers.Virtual.hasEntriesDistance',
    defaultMessage:
      'There are entries already, the distance cannot be changed.',
  },
  distance: {
    id: 'app.containers.Virtual.distance',
    defaultMessage: 'Distance',
  },
  fixed: {
    id: 'app.containers.Virtual.fixed',
    defaultMessage: 'Fixed Course',
  },
  fixedHelp: {
    id: 'app.containers.Virtual.fixedHelp',
    defaultMessage:
      'Usually, the race time will be calculated from the activity time and the distance measured by the runners device. If you select this option, the participants should run a given course (e.g., a route from RunnerMaps). The race time will be the same as the elapsed activity time, since all participants will run the same distance.',
  },
  fixedTime: {
    id: 'app.containers.Virtual.fixedTime',
    defaultMessage:
      '<b>Fixed Course</b>: the race time will not be adapted if your GPS shows a different distance.',
  },
  is_public: {
    id: 'app.containers.Virtual.isPublic',
    defaultMessage: 'Public',
  },
  connect: {
    id: 'app.containers.Virtual.connect',
    defaultMessage: 'Connect with Strava',
  },
  connectToJoin: {
    id: 'app.containers.Virtual.connectToJoin',
    defaultMessage: 'To join, you first have to connect with Strava.',
  },
  disconnect: {
    id: 'app.containers.Virtual.disconnect',
    defaultMessage: 'Revoke Access',
  },
  disconnectAlert: {
    id: 'app.containers.Virtual.disconnectAlert',
    defaultMessage:
      'This will revoke access for RunnerMaps to access your activities on Strava. Continue?',
  },
  disconnectButton: {
    id: 'app.containers.Virtual.disconnectButton',
    defaultMessage: 'Yes, Revoke',
  },
  join: {
    id: 'app.containers.Virtual.join',
    defaultMessage: 'Join',
  },
  noEntries: {
    id: 'app.containers.Virtual.noEntries',
    defaultMessage: 'No entries yet',
  },
  ranking: {
    id: 'app.containers.Virtual.ranking',
    defaultMessage: 'Pos.',
  },
  name: {
    id: 'app.containers.Virtual.name',
    defaultMessage: 'Name',
  },
  sex: {
    id: 'app.containers.Virtual.sex',
    defaultMessage: 'M/F',
  },
  M: {
    id: 'app.containers.Virtual.M',
    defaultMessage: 'M',
  },
  F: {
    id: 'app.containers.Virtual.F',
    defaultMessage: 'F',
  },
  HM: {
    id: 'app.containers.Virtual.HM',
    defaultMessage: 'Men',
  },
  HF: {
    id: 'app.containers.Virtual.HF',
    defaultMessage: 'Women',
  },
  topResults: {
    id: 'app.containers.Virtual.topResults',
    defaultMessage:
      'Best Results for the RunnerMaps Virtual {distance, select, 3 { 3K } 5 { 5K } 10 { 10K } 21 { Half Marathon }}',
  },
  raceTime: {
    id: 'app.containers.Virtual.raceTime',
    defaultMessage: 'Race Time',
  },
  testDistanceTime: {
    id: 'app.containers.Virtual.testDistanceTime',
    defaultMessage: 'Test Distance Time',
  },
  time: {
    id: 'app.components.KmVreters.List.time',
    defaultMessage: 'Time',
  },
  startDate: {
    id: 'app.containers.Virtual.startDate',
    defaultMessage: 'Start',
  },
  activityDistance: {
    id: 'app.containers.Virtual.activityDistance',
    defaultMessage: 'Activity Distance',
  },
  activityTime: {
    id: 'app.containers.Virtual.activityTime',
    defaultMessage: 'Activity Time',
  },
  dnf: {
    id: 'app.containers.Virtual.dnf',
    defaultMessage: 'DNF',
  },
  didNotFinish: {
    id: 'app.containers.Virtual.didNotFinish',
    defaultMessage: 'Did Not Finish Yet',
  },
  organiseSelf: {
    id: 'app.containers.Virtual.organiseSelf',
    defaultMessage:
      'Organise a virtual race yourself, e.g. for your running group or club',
  },
  showResultsLater: {
    id: 'app.containers.Virtual.showResultsLater',
    defaultMessage:
      'Here you will find the race results on/after the race day. <b>Join now</b> to make sure you are listed.',
  },
  showResultsLaterJoined: {
    id: 'app.containers.Virtual.showResultsLaterJoined',
    defaultMessage:
      '<b>Nice that you joined</b>! Here you will your time together with the other runners on/after the race day.',
  },
  reconnectStrava: {
    id: 'app.containers.Virtual.reconnectStrava',
    defaultMessage:
      'The race time can automatically be added to your Strava activity. You have to reconnect with Strava to enable this.',
  },
  challengeFriends: {
    id: 'app.containers.Virtual.challengeFriends',
    defaultMessage: 'Challenge your friends and ',
  },
  shareRace: {
    id: 'app.containers.Virtual.shareRace',
    defaultMessage: 'share this race',
  },
  details: {
    id: 'app.containers.Virtual.details',
    defaultMessage: 'Details',
  },
  resultFor: {
    id: 'app.containers.virtual.resultFor',
    defaultMessage: 'Result for {name}',
  },
  movingTime: {
    id: 'app.containers.Virtual.movingTime',
    defaultMessage: 'Moving Time',
  },
  elapsedTime: {
    id: 'app.containers.Virtual.elapsedTime',
    defaultMessage: 'Elapsed Time',
  },
  avgSpeed: {
    id: 'app.containers.Virtual.avgSpeed',
    defaultMessage: 'Avg Speed',
  },
  kph: {
    id: 'app.containers.Virtual.kph',
    defaultMessage: '{speed} km/h',
  },
  pace: {
    id: 'app.containers.Virtual.pace',
    defaultMessage: 'Pace',
  },
  timeInfo: {
    id: 'app.containers.Virtual.timeInfo',
    defaultMessage:
      'Gross Time is determined by the Elapsed Time, Net Time by the Moving Time.',
  },
  viewOnStrava: {
    id: 'app.containers.Virtual.viewOnStrava',
    defaultMessage: 'View on Strava',
  },
  net: {
    id: 'app.containers.Virtual.net',
    defaultMessage: 'Net',
  },
  gross: {
    id: 'app.containers.Virtual.gross',
    defaultMessage: 'Gross',
  },
  netTime: {
    id: 'app.containers.Virtual.netTime',
    defaultMessage: 'Net Time',
  },
  grossTime: {
    id: 'app.containers.Virtual.grossTime',
    defaultMessage: 'Gross Time',
  },
  fastestPartNotice: {
    id: 'app.containers.Virtual.fastestPartNotice',
    defaultMessage:
      'From now on, your race time will be determined by the <b>fastest part in your run</b>, instead of the average pace.',
  },
  fastestPart: {
    id: 'app.containers.Virtual.fastestPart',
    defaultMessage: 'Fastest Part',
  },
  jubileum: {
    id: 'app.containers.Virtual.jubileum',
    defaultMessage: 'RunnerMaps 12.5 Years!',
  },
  removeActivity: {
    id: 'app.containers.Virtual.removeActivity',
    defaultMessage: 'Remove this athlete/activity from race?',
  },
  corrected: {
    id: 'app.containers.Virtual.corrected',
    defaultMessage: 'Corrected',
  },
  correctedHelp: {
    id: 'app.containers.Virtual.correctedHelp',
    defaultMessage: 'GPS errors are fixed and the distance has been adapted.',
  },
  routeCorrections: {
    id: 'app.containers.Virtual.routeCorrections',
    defaultMessage: 'Route Corrections',
  },
  corrAccept: {
    id: 'app.containers.Virtual.corrAccepted',
    defaultMessage: 'Accepted',
  },
  corrReject: {
    id: 'app.containers.Virtual.corrRejected',
    defaultMessage: 'Rejected',
  },
  corrReplace: {
    id: 'app.containers.Virtual.corrReplacement',
    defaultMessage: 'Replacement',
  },
  downloadCSV: {
    id: 'app.containers.Virtual.downloadCSV',
    defaultMessage: 'Download CSV',
  },
  period_3: {
    id: 'app.containers.Virtual.period_3',
    defaultMessage: '3 months',
  },
  period_12: {
    id: 'app.containers.Virtual.period_12',
    defaultMessage: '1 year',
  },
  period_36: {
    id: 'app.containers.Virtual.period_36',
    defaultMessage: '3 years',
  },
  period_all: {
    id: 'app.containers.Virtual.period_all',
    defaultMessage: 'all time',
  },
  showPeriod: {
    id: 'app.containers.Virtual.showPeriod',
    defaultMessage: 'Period',
  },
  refDist: {
    id: 'app.containers.Virtual.refDist',
    defaultMessage: 'Times converted to',
  },
  timeScale: {
    id: 'app.containers.Virtual.timeScale',
    defaultMessage: 'Time Scale',
  },
  progress: {
    id: 'app.containers.Virtual.progress',
    defaultMessage: 'Progress',
  },
  decay: {
    id: 'app.containers.Virtual.decay',
    defaultMessage: 'Decay',
  },
  secWeek: {
    id: 'app.containers.Virtual.secWeek',
    defaultMessage: 'sec/week',
  },
  ignoreDeviations: {
    id: 'app.containers.Virtual.ignoreDeviations',
    defaultMessage:
      'Results that deviate too much from the average are ignored.',
  },
  analysis: {
    id: 'app.containers.Virtual.analysis',
    defaultMessage: 'Analysis',
  },
  map: {
    id: 'app.containers.Virtual.map',
    defaultMessage: 'Map',
  },
  chart: {
    id: 'app.containers.Virtual.chart',
    defaultMessage: 'Chart',
  },
  raceResults: {
    id: 'app.containers.Virtual.raceResults',
    defaultMessage: 'Results',
  },
  speed: {
    id: 'app.containers.Virtual.speed',
    defaultMessage: 'Speed',
  },
  heartrate: {
    id: 'app.containers.Virtual.heartrate',
    defaultMessage: 'Heart Rate',
  },
  metric: {
    id: 'app.containers.Virtual.metric',
    defaultMessage: 'Metric',
  },
  average: {
    id: 'app.containers.Virtual.average',
    defaultMessage: 'Average',
  },
  changeMinute: {
    id: 'app.containers.Virtual.changeMinute',
    defaultMessage: 'Change per 5 minutes',
  },
  split: {
    id: 'app.containers.Virtual.split',
    defaultMessage: 'Split',
  },
  efficiencyFactor: {
    id: 'app.containers.Virtual.efficiencyFactor',
    defaultMessage: 'Efficiency Factor',
  },
  efIntro: {
    id: 'app.containers.Virtual.efIntro',
    defaultMessage:
      'The efficiency factor is the nominal speed (meters/minute) divided by the average heart rate (bpm). See',
  },
  noActivity: {
    id: 'app.containers.Virtual.noActivity',
    defaultMessage: 'No activity yet',
  },
});
