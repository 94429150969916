/*
 *
 * UiControl reducer
 *
 */

import { fromJS } from 'immutable';
import { UI_TOGGLE, UI_SET } from './constants';

export const initialState = fromJS({
  onlyOwnRoutes: false,
  showRoutes: false,
  distanceFilter: false,
  routesMin: 0,
  routesMax: 100,
  showLocations: false,
});

function uiControlReducer(state = initialState, action) {
  switch (action.type) {
    case UI_TOGGLE:
      return state.set(action.prop, !state.toJS()[action.prop]);
    case UI_SET:
      return state.set(action.prop, action.val);
    default:
      return state;
  }
}

export default uiControlReducer;
