/**
 *
 * Edit
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'redux';
import { Alert, Button } from 'react-bootstrap';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import withKvApi from 'containers/KmVretersPage/withKvApi';
import withUser from 'containers/User/withUser';
import withRoute from 'containers/withRoute';
import withSettings from 'containers/SettingsProvider/withSettings';
import { DistanceInput } from 'components/Distance';
import Link from 'components/Link';
import Spinner from 'components/Spinner';
import MetaTags from 'components/MetaTags';
import renderField from 'components/FormField/renderField';
import PrimaryAction from 'components/PrimaryAction';
import { MILES_KM } from 'utils/constants';
import formatTime from 'utils/formatTime';
import DatePickerWithSuggestions from './DatePickerWithSuggestions';
import Container from '../Container';
import messages from './messages';

const EDIT_STATUS_LOADING = 1;
const EDIT_STATUS_FORBIDDEN = 2;
const EDIT_STATUS_EDIT = 3;
const EDIT_STATUS_CREATE = 4;
const EDIT_STATUS_SUBMITTED = 4;

const Wrapper = styled(Container)`
  .pull-right svg + span {
    margin-left: 0.5em;
    @media (max-width: 479px) {
      display: none;
    }
  }
  input[type='date'] {
    width: 12em;
  }
  .km-suggestions .btn {
    white-space: normal;
    text-align: left;
  }
`;

const today = format(new Date(), 'yyyy-MM-dd');

/* eslint-disable react/prefer-stateless-function */
class Edit extends React.PureComponent {
  static loading(props) {
    const { data, user } = props;
    return data.loading || user.loading;
  }

  constructor(props) {
    super(props);
    const { match } = props;
    /* eslint-disable react/no-unused-state */
    this.state = {
      date: today,
      suggestions: [],
      status:
        match.params.id === undefined
          ? EDIT_STATUS_CREATE
          : EDIT_STATUS_LOADING,
      event: '',
      city: '',
      distance: '',
      time: '',
      link: '',
    };
    this.renderField = renderField.bind(this)(messages);
  }

  componentDidMount() {
    const { data } = this.props;
    const { status } = this.state;
    if (status === EDIT_STATUS_LOADING && data.loading === false) {
      this.dataToState();
    }
  }

  componentDidUpdate(prevProps) {
    if (Edit.loading(prevProps) && !Edit.loading(this.props)) {
      this.dataToState();
    }
    const { data } = this.props;
    if (prevProps.data.working && !data.working && !data.error) {
      this.toList();
    }
  }

  dataToState() {
    const { data, units, user } = this.props;
    if (!data.value) {
      return;
    }
    const { distance, time, user_id } = data.value;
    const status =
      user.api.userId === user_id ? EDIT_STATUS_EDIT : EDIT_STATUS_FORBIDDEN;
    this.setState({
      ...data.value,
      time: formatTime(time),
      distance: units === 'km' ? distance : distance * MILES_KM,
      status,
    });
  }

  handleSubmit = ev => {
    const { apiChange } = this.props;
    const { status, ...run } = this.state;
    ev.preventDefault();
    apiChange({
      body: { run },
    });
    this.setState({ status: EDIT_STATUS_SUBMITTED });
  };

  toList = () => {
    const { goTo } = this.props;
    goTo('/kmvreters/list');
  };

  setSuggestions = suggestions => {
    this.setState({ suggestions });
  };

  copySuggestion = sugg => () => {
    this.setState(sugg);
  };

  renderForm() {
    const { match } = this.props;
    const { status, suggestions } = this.state;
    switch (status) {
      case EDIT_STATUS_LOADING:
        return <Spinner />;
      case EDIT_STATUS_FORBIDDEN:
        return (
          <Alert bsStyle="danger">
            <FormattedMessage {...messages.notAllowed} />
          </Alert>
        );
      default:
    }
    const createNew = !match.params.id;
    const asString = sugg =>
      `${sugg.event} (${sugg.city}) - ${sugg.distance} km`;
    const makeKey = sugg => Object.values(sugg).join('|');
    return (
      <form onSubmit={this.handleSubmit}>
        {this.renderField({
          id: 'date',
          componentClass: DatePickerWithSuggestions,
          attributes: {
            max: today,
            autoComplete: 'off',
            onSuggestions: createNew ? this.setSuggestions : undefined,
          },
          required: true,
        })}
        {suggestions.length > 0 && (
          <Alert onDismiss={() => this.setSuggestions([])}>
            <p>
              <FormattedMessage {...messages.suggestions} />
            </p>
            <ul className="list-unstyled km-suggestions">
              {suggestions.map(sugg => (
                <li key={makeKey(sugg)}>
                  <Button bsStyle="link" onClick={this.copySuggestion(sugg)}>
                    {asString(sugg)}
                  </Button>
                </li>
              ))}
            </ul>
          </Alert>
        )}
        {this.renderField({ id: 'event', type: 'text', required: true })}
        {this.renderField({ id: 'city', type: 'text', required: true })}
        {this.renderField({
          id: 'distance',
          componentClass: DistanceInput,
          required: true,
        })}
        {this.renderField({
          id: 'time',
          type: 'text',
          required: true,
          autoComplete: 'off',
          placeholder: 'h:mm:ss',
          className: 'input-short',
        })}
        {this.renderField({
          id: 'link',
          type: 'url',
          placeholder: 'http://',
          helpText: <FormattedMessage {...messages.linkHelp} />,
        })}
        {this.renderField({
          id: 'notes',
          componentClass: 'textarea',
          rows: 3,
          helpText: <FormattedMessage {...messages.notesHelp} />,
        })}
        <PrimaryAction onCancel="/kmvreters/list" />
      </form>
    );
  }

  render() {
    const tags = { title: messages.title };
    const { match, data } = this.props;
    const { status } = this.state;
    const name = data.value ? data.value.event : '…';
    const title = match.params.id
      ? { ...messages.editRace, values: { name } }
      : messages.addRace;
    return (
      <Wrapper width={720}>
        <MetaTags tags={tags} />
        <Container.Header>
          <FormattedMessage {...title} />
        </Container.Header>
        {status === EDIT_STATUS_CREATE && (
          <p>
            <Link
              className="pull-right btn btn-default"
              href="/kmvreters/import"
            >
              <FontAwesomeIcon icon="file-import" />
              <FormattedHTMLMessage {...messages.importLabel} />
            </Link>
            <FormattedMessage {...messages.intro} />
          </p>
        )}
        {this.renderForm()}
      </Wrapper>
    );
  }
}

Edit.propTypes = {
  user: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  units: PropTypes.string.isRequired,
  apiChange: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
};

export default compose(
  withUser,
  withKvApi,
  withRoute,
  withSettings(['units']),
)(Edit);
