/**
 *
 * SocialMediaLinks
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Wrapper = styled.div`
  white-space: nowrap;
`;

function SocialMediaLinks() {
  return (
    <Wrapper>
      <a
        href="https://www.facebook.com/runnermaps.nl/"
        className="btn btn-link"
        aria-label="Facebook"
      >
        <FontAwesomeIcon icon={['fab', 'facebook']} size="lg" fixedWidth />
      </a>
      <a
        href="https://www.twitter.com/runnermaps"
        className="btn btn-link"
        aria-label="Twitter"
      >
        <FontAwesomeIcon icon={['fab', 'twitter']} size="lg" fixedWidth />
      </a>
    </Wrapper>
  );
}

export default SocialMediaLinks;
