/**
 * Toggle property in state
 * Usage in constructor this.someToggle = toggleState('property').bind(this)
 * @param prop
 */

function toggleState(prop) {
  return function doToggle() {
    this.setState(prev => ({ [prop]: !prev[prop] }));
  };
}

export function updateState(prop) {
  return function doUpdateState(ev) {
    const value = ev.target ? ev.target.value : ev;
    this.setState({ [prop]: value });
  };
}
export default toggleState;
