/**
 *
 * ApiStatus
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner';

function ApiStatus(props) {
  const { user } = props;
  if (!user || !user.auth || user.error) {
    return null;
  }
  if (!user.api) {
    return <Spinner />;
  }
  return null;
}

ApiStatus.propTypes = {
  user: PropTypes.object.isRequired,
};

export default ApiStatus;
