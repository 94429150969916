import {
  addMonths,
  differenceInMonths,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from 'date-fns';

function getTicks(start, step) {
  const ticks = [];
  let current = addMonths(start, step);
  const now = new Date();
  while (current < now) {
    ticks.push(current.getTime());
    current = addMonths(current, step);
  }
  return ticks;
}
function getParams(start) {
  const end = new Date();
  const domain = [start.getTime(), end.getTime()];
  const months = differenceInMonths(end, start);
  if (months < 1) {
    return { domain };
  }
  if (months < 6) {
    return { domain, ticks: getTicks(startOfMonth(start), 1) };
  }
  if (months < 24) {
    return { domain, ticks: getTicks(startOfQuarter(start), 3) };
  }
  return { domain, ticks: getTicks(startOfYear(start), 12) };
}

export default getParams;
