import styled from 'styled-components';
import { green100, lightBlack } from 'styles/colors';

export const H2 = styled.h2`
  &:not(.banner) {
    font-weight: bold;
    color: ${lightBlack};
  }
  &.banner {
    padding: 10px;
    background-color: ${green100};
    border-radius: 4px;
  }
`;
