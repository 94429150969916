import Track from 'utils/analytics/Track';

let previous = {};

function trackLocation(current) {
  if (
    current.pathname === previous.pathname &&
    current.hash &&
    current.hash !== previous.hash
  ) {
    const title = current.hash.slice(1);
    Track.send({
      hitType: 'pageview',
      page: `/${title}`,
      title,
      location: `${window.location.origin}/${title}`,
    });
  }
  previous = current;
}

export default trackLocation;
