/*
 * Header Messages
 *
 * This contains all the text for the Header component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  signIn: {
    id: 'app.components.UserControl.signIn',
    defaultMessage: 'Sign In',
  },
  menu: {
    id: 'app.components.UserControl.menu',
    defaultMessage: 'Menu',
  },
  support: {
    id: 'app.components.UserControl.support',
    defaultMessage: 'Support',
  },
  routes: {
    id: 'app.components.Header.routes',
    defaultMessage: 'Routes',
  },
  virtual: {
    id: 'app.components.Header.virtual',
    defaultMessage: 'Test Runs',
  },
  virtualRaces: {
    id: 'app.components.Header.virtualRaces',
    defaultMessage: '/Virtual Races',
  },
  reservations: {
    id: 'app.components.Header.reservations',
    defaultMessage: 'Reservations',
  },
  kmvreters: {
    id: 'app.components.KmVreters.kmvreters',
    defaultMessage: 'Mile Eaters',
  },
  list: {
    id: 'app.components.KmVreters.list',
    defaultMessage: 'My Races',
  },
  profile: {
    id: 'app.components.KmVreters.profile',
    defaultMessage: 'Profile',
  },
  ranking: {
    id: 'app.components.KmVreters.ranking',
    defaultMessage: 'Ranking',
  },
});
