/**
 *
 * IndexMarker
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { CircleMarker } from 'react-leaflet';
// import { FormattedMessage } from 'react-intl';
// import messages from './messages';
import { red700, white } from 'styles/colors';

function IndexMarker(props) {
  const { center } = props;
  const style = {
    color: red700,
    radius: 3,
    fillColor: white,
  };
  return center && <CircleMarker {...style} {...props} />;
}

IndexMarker.propTypes = {
  center: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default IndexMarker;
