/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/**
 * View file from URL as Tab;e
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
// import formatTime from 'utils/formatTime';
import GChart from 'components/GChart';
import { GC_RED_LINE } from 'components/GChart/constants';

function interpolate(time, r1, r2) {
  return (
    r1.distance +
    ((time - r1.time) / (r2.time - r1.time)) * (r2.distance - r1.distance)
  );
}

function Graph(props) {
  const { data } = props;
  const [step, setStep] = useState(10);
  let { time, distance } = data[0];
  time += step;
  let lastRow;
  const graphData = [['time', 'speed']];
  data.forEach(row => {
    while (time <= row.time) {
      const newDistance = interpolate(time, lastRow, row);
      graphData.push([time / 60, (3.6 * (newDistance - distance)) / step]);
      distance = newDistance;
      time += step;
    }
    lastRow = row;
  });

  const max = data[data.length - 1].time - data[0].time;

  function handleSubmit(ev) {
    ev.preventDefault();
    setStep(Number(ev.target.elements.time.value));
  }

  const chartProps = {
    id: `chart-speed`,
    data: graphData,
    options: {
      title: 'Speed vs time',
      hAxis: {
        title: 'Minutes',
        legend: { position: 'top' },
        maxValue: time / 60,
        minValue: 0,
      },
      colors: [GC_RED_LINE],
      vAxes: [
        {
          minValue: 0,
          maxValue: 20,
          title: 'km/h',
        },
      ],
      interpolateNulls: true,
      focusTarget: 'category',
      height: 240,
    },
    chartType: 'SteppedAreaChart',
    width: '100%',
    active: true,
  };

  return (
    <div>
      <div className="d-flex p-3 align-items-baseline">
        <form
          className="form-inline align-items-baseline"
          onSubmit={handleSubmit}
        >
          <label htmlFor="time">Step (sec)</label>
          <input
            type="number"
            className="form-control mb-2 ml-2 form-control-sm"
            defaultValue={step}
            id="time"
            min={1}
            max={max}
            step={1}
          />
        </form>
      </div>
      <GChart {...chartProps} />
    </div>
  );
}

Graph.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Graph;
