import nl from 'date-fns/locale/nl';
import en from 'date-fns/locale/en-GB';
import useLocale from 'containers/SettingsProvider/useLocale';
import storage from 'utils/storage';

export const staticLocale = { locale: nl };

export function updateLocale(language) {
  const locale = language.startsWith('nl') ? nl : en;
  staticLocale.locale = locale;
  return locale;
}

function init() {
  const language = storage.getItem('locale');
  updateLocale(language || 'nl');
}

init();

function useDateLocale() {
  const language = useLocale();
  const locale = updateLocale(language);
  return locale;
}

export default useDateLocale;
