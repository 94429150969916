import React from 'react';
import useList from './useList';
import SearchUser from './SearchUser';
import UserTable from './UserTable';
import SelectProvider from './SelectContext';
import SelectForm from './SelectContext/SelectForm';

function UserList() {
  const { email, loading, list } = useList();
  return (
    <SelectProvider>
      <h1>Search users</h1>
      <SearchUser loading={loading} email={email} />
      <SelectForm />
      <UserTable users={list} loading={loading} />
    </SelectProvider>
  );
}

export default UserList;
