/* eslint-disable react/no-danger */
/**
 *
 * Ranking
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Alert, Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withRoute from 'containers/withRoute';
import MetaTags from 'components/MetaTags';
import Spinner from 'components/Spinner';
import { unserialize } from 'utils/urlUtils';
import Container from '../Container';
import messages from './messages';

const Wrapper = styled(Container)`
  .row {
    display: flex;
  }
  .col-sm-4 {
    flex-basis: 33%;
  }
  .col-sm-6 {
    flex-basis: 50%;
  }
  .col-sm-8 {
    flex-basis: 67%;
  }
  p.yearHtml {
    margin-bottom: 2em;
  }
  tr.current-user {
    font-weight: bold;
  }
  .btn.btn-filter {
    border-radius: 9px;
  }
  .btn .fa-times {
    margin-left: 0.5em;
  }
  .btn + .btn {
    margin-left: 10px;
  }
`;

const Select = styled.select`
  &.form-control {
    display: inline;
    height: 30px;
    line-height: 30px;
    padding: 6px;
    width: inherit;
  }
`;

/* eslint-disable react/prefer-stateless-function */
class Ranking extends React.PureComponent {
  getFilters() {
    const { location } = this.props;
    return unserialize(location.hash);
  }

  findClub(id) {
    const { data } = this.props;
    if (data.loading !== false) {
      return id;
    }
    const member = data.value.list.find(
      user => Number(user.club_id) === Number(id),
    );
    return member ? member.club : id;
  }

  showFilter(key) {
    const { hashDelete } = this.props;
    const filters = this.getFilters();
    if (!filters[key]) {
      return null;
    }
    const label = key === 'club' ? this.findClub(filters[key]) : filters[key];
    const link =
      key === 'club' ? (
        <Link to={`/kmvreters/prlist/${filters[key]}`} className="pull-right">
          <FormattedMessage {...messages.pbList} values={{ club: label }} />
        </Link>
      ) : null;
    return (
      <React.Fragment>
        {link}
        <Button
          bsSize="xsmall"
          className="btn-filter px-2 rounded-pill"
          bsStyle="default"
          onClick={() => hashDelete(key)}
        >
          {label} <FontAwesomeIcon size="lg" icon="times" />
        </Button>
      </React.Fragment>
    );
  }

  filteredList() {
    const { data } = this.props;
    if (data.loading !== false) {
      return [];
    }
    const setHash = this.getFilters();
    let { list } = data.value;
    if (setHash.club) {
      list = list.filter(item => Number(item.club_id) === Number(setHash.club));
    }
    if (setHash.cat) {
      list = list.filter(item => item.cat === setHash.cat);
    }
    return list;
  }

  handleClick = ev => {
    if (ev.shiftKey || ev.ctrlKey) {
      return;
    }
    const { hashAdd } = this.props;
    ev.preventDefault();
    hashAdd(unserialize(ev.currentTarget.attributes.href.value));
  };

  hasProfile() {
    const { user } = this.props;
    const data = user && user.api && user.api.userData;
    return Boolean(data && data.name && data.sex && data.yob);
  }

  infoAlerts() {
    const { data, user } = this.props;
    const api = user && user.api;
    if (!api) {
      return null;
    }
    if (!this.hasProfile()) {
      return (
        <Alert>
          <FormattedHTMLMessage {...messages.noProfile} />
        </Alert>
      );
    }
    if (data.value.list.every(item => Number(item.user_id) !== api.userId)) {
      return (
        <Alert>
          <FormattedHTMLMessage {...messages.noRace} />
        </Alert>
      );
    }
    return null;
  }

  goToYear = ev => {
    const { goTo } = this.props;
    goTo(`/kmvreters/ranking/${ev.target.value}`);
  };

  render() {
    const { data, user, match } = this.props;
    const { id: year } = match.params;
    const userId = user && user.api && user.api.userId;
    const getClassName = id => (Number(id) === userId ? 'current-user' : '');
    const tags = { title: messages.title };
    const currentYear = new Date().getFullYear();
    const prevYears = Array(currentYear - 2014)
      .fill(undefined)
      .map((v, idx) => currentYear - 1 - idx);
    return (
      <Wrapper width={720}>
        <MetaTags tags={tags} />
        <Container.Header>
          <FormattedMessage {...messages.title} />
          {year && ` ${year}`}
        </Container.Header>
        {data.loading !== false ? (
          <Spinner />
        ) : (
          <React.Fragment>
            {data.value.html && (
              <p
                className="yearHtml"
                dangerouslySetInnerHTML={{ __html: data.value.html }}
              />
            )}
            <p>
              {year ? (
                <FormattedHTMLMessage {...messages.forYear} values={{ year }} />
              ) : (
                <React.Fragment>
                  <FormattedMessage {...messages.prevYears} />
                  <Select
                    className="form-control inline"
                    onChange={this.goToYear}
                  >
                    <FormattedMessage {...messages.select}>
                      {text => <option>{text}</option>}
                    </FormattedMessage>
                    {prevYears.map(yr => (
                      <option key={yr} value={yr}>
                        {yr}
                      </option>
                    ))}
                  </Select>
                </React.Fragment>
              )}
            </p>
            {this.infoAlerts()}
            {this.showFilter('club')}
            {this.showFilter('cat')}
            <Table responsive hover>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>
                    <FormattedMessage {...messages.name} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.club} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.category} />
                  </th>
                  <th className="text-right">
                    <FormattedMessage {...messages.number} />
                  </th>
                  <th className="text-right">
                    <FormattedMessage {...messages.distance} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.filteredList().map((item, idx) => (
                  <tr key={item.user_id} className={getClassName(item.user_id)}>
                    <td className="text-right">{idx + 1}</td>
                    <td>
                      <Link to={`/kmvreters/list/${item.user_id}`}>
                        {item.name}
                      </Link>
                    </td>
                    <td>
                      {item.club && (
                        <a
                          href={`#club=${item.club_id}`}
                          onClick={this.handleClick}
                        >
                          {item.club}
                        </a>
                      )}
                    </td>
                    <td>
                      <a href={`#cat=${item.cat}`} onClick={this.handleClick}>
                        {item.cat}
                      </a>
                    </td>
                    <td className="text-right">{item.number}</td>
                    <td className="text-right">{item.total}</td>
                  </tr>
                ))}
                <tr />
              </tbody>
            </Table>
          </React.Fragment>
        )}
      </Wrapper>
    );
  }
}

Ranking.propTypes = {
  data: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object,
  goTo: PropTypes.func,
  hashAdd: PropTypes.func,
  hashDelete: PropTypes.func,
};

export default withRoute(Ranking);
