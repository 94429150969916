import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const SelectContext = createContext();

function SelectProvider({ children }) {
  const [items, setItems] = useState([]);
  const value = useMemo(
    () => ({ items, itemSet: new Set(items.map(item => item.id)), setItems }),
    [items, setItems],
  );
  return (
    <SelectContext.Provider value={value}>{children}</SelectContext.Provider>
  );
}

SelectProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SelectProvider;
