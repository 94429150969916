/**
 *
 * InfoPopover
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function InfoPopover(props) {
  const { children, className, ...rest } = props;
  const popover = <Popover id="info-popover">{children}</Popover>;
  return (
    <OverlayTrigger {...rest} overlay={popover}>
      <Button bsStyle="link" icon="info-circle" className={className}>
        <FontAwesomeIcon icon="info-circle" />
      </Button>
    </OverlayTrigger>
  );
}

InfoPopover.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default InfoPopover;
