/*
 * Overview Messages
 *
 * This contains all the text for the Overview component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.Kmvreters.Overview.title',
    defaultMessage: 'Races of {club} members',
  },
});
