/**
 *
 * Footer
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import SupportLink from './SupportLink';
import SettingsLink from './SettingsLink';
import PrivacyLink from './PrivacyLink';
import SocialMediaLinks from './SocialMediaLinks';

const Wrapper = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  @media (max-width: 479px) {
    .btn-link > span {
      display: none;
    }
  }
`;

function Footer() {
  return (
    <Wrapper>
      <div>
        <SupportLink />
        <SettingsLink />
      </div>
      <PrivacyLink />
      <SocialMediaLinks />
    </Wrapper>
  );
}

Footer.propTypes = {};

export default Footer;

export { default as PrivacyLink } from './PrivacyLink';
export { default as SettingsLink } from './SettingsLink';
export { default as PremiumLink } from './PremiumLink';
export { default as SupportLink } from './SupportLink';
export { default as SocialMediaLinks } from './SocialMediaLinks';
