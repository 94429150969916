/*
 * Auth Messages
 *
 * This contains all the text for the Auth component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  login: {
    id: 'app.components.Auth.login',
    defaultMessage: 'Sign in on RunnerMaps',
  },
  intro: {
    id: 'app.components.Auth.intro',
    defaultMessage:
      'You can sign in using Google, Facebook, or with your email address. If you sign in with your email address, you will receive a message to confirm the address.',
  },
  submitted: {
    id: 'app.components.Auth.submitted',
    defaultMessage:
      "An email message has been sent to '{email}'. Click on the link in the message to continue.",
  },
  emailSignIn: {
    id: 'app.components.Auth.emailSignIn',
    defaultMessage: 'Sign in with email',
  },
  noEmail: {
    id: 'app.components.Auth.noEmail',
    defaultMessage: 'No email provided, try another signin method',
  },
  email: {
    id: 'app.components.AskEmail.email',
    defaultMessage: 'Email address',
  },
  error: {
    id: 'app.components.Auth.error',
    defaultMessage:
      'Error in authentication, try again or try another signin method',
  },
});
