/**
 *
 * Subscribe
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FormGroup, Checkbox, HelpBlock, Button } from 'react-bootstrap';
import { useCurrentUser } from 'containers/User';
import useLocale from 'containers/SettingsProvider/useLocale';
import withRoute from 'containers/withRoute';
import { request2 } from 'utils/request';
import messages from './messages';
import LoginAlert from '../LoginAlert';

const Help = styled(HelpBlock)`
  &.help-block {
    font-size: small;
    margin-left: 2rem;
    margin-top: -1rem;
    .btn {
      font-size: small;
    }
  }
`;

function Subscribe() {
  const user = useCurrentUser();
  const language = useLocale();
  const { pathname, search, hash } = useLocation();
  const history = useHistory();
  if (!user.api) {
    return <LoginAlert />;
  }
  function showConditions() {
    const state = `${pathname}${search}${hash}`;
    history.push('#conditions', state);
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    const recurring = ev.target.elements.recurring.checked;
    const body = {
      language,
      recurring,
      href: window.location.href,
    };
    request2({
      method: 'POST',
      url: '/user/subscribe',
      body,
      user,
    }).then(response => {
      // eslint-disable-next-line no-underscore-dangle
      window.location = response._links.checkout.href;
    });
  }
  const submitText = user.pm ? messages.extend : messages.header;
  const subscriptionMessage = {
    ...messages.premiumUntilDate,
    values: { date: user.premiumDate },
  };
  return (
    <form onSubmit={handleSubmit}>
      {user.pm && (
        <p>
          <FormattedMessage {...subscriptionMessage} />
        </p>
      )}
      <FormGroup>
        <Checkbox name="recurring" value="true">
          <FormattedMessage {...messages.recurringLabel} />
        </Checkbox>
        <Help>
          <FormattedMessage {...messages.recurringHelp} />
        </Help>
      </FormGroup>
      <FormGroup>
        <Checkbox name="acceptConditions" required>
          <FormattedMessage {...messages.acceptConditions} />
        </Checkbox>
        <Help>
          <Button bsStyle="link" className="p-0" onClick={showConditions}>
            <FormattedMessage {...messages.viewConditions} />
          </Button>
        </Help>
      </FormGroup>
      <div className="text-center">
        <Button bsStyle="success" type="submit">
          <FormattedMessage {...submitText} />
        </Button>
      </div>
    </form>
  );
}

export default withRoute(Subscribe);
