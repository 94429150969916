/**
 * EffInfo
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from '../messages';
import InfoPopover from '../ChartResults/InfoPopover';

function EffInfo() {
  return (
    <InfoPopover trigger="click" rootClose placement="top" className="py-0">
      <FormattedMessage {...messages.efIntro} />
      <a
        className="ml-1"
        href="https://www.trainingpeaks.com/blog/the-efficiency-factor-in-running/"
        target="_blank"
      >
        The Efficiency Factor in Running
      </a>
    </InfoPopover>
  );
}

export default EffInfo;
