import linearRegression from 'utils/linearRegression';

function getEfficiencyFactor(speedSum, speedCount, hrSum, hrCount) {
  const nominalIntensity = (speedSum / speedCount) ** 0.25;
  const averageHeartrate = hrSum / (hrCount || 1);
  return averageHeartrate ? nominalIntensity / averageHeartrate : null;
}

function getDecay(v1, v2) {
  return (1 - v2 / v1) * 100;
}

function processData(data, start, end) {
  const fullData = [];
  let lastValue;
  const isInside = d => d.time >= start && d.time <= end;
  const halfwayTime = (start + end) / 2;

  let halfwayData = null;

  // Interpolate data
  data.forEach((newValue, idx) => {
    const { time, distance, heartrate } = newValue;
    if (idx === 0) {
      fullData.push({ time, distance, heartrate });
      lastValue = newValue;
      return;
    }
    const dt = time - lastValue.time;
    for (let i = 1; i <= dt; i++) {
      fullData.push({
        time: lastValue.time + i,
        distance:
          lastValue.distance + (distance - lastValue.distance) * (i / dt),
        heartrate:
          lastValue.heartrate + (heartrate - lastValue.heartrate) * (i / dt),
      });
    }
    lastValue = newValue;
  });

  // Add pace, speed, efficiencyFactor
  let speedSum = 0;
  let speedCount = 0;
  let hrSum = 0;
  let hrCount = 0;
  fullData.forEach((value, idx) => {
    if (idx === 0) {
      return;
    }
    const { distance, heartrate } = value;
    const dt = Math.min(idx, 30);
    const dd = distance - fullData[idx - dt].distance;
    const pace = dt * (1000 / dd);
    const speed = (3.6 * dd) / dt;
    const intensity = dd * (60 / dt);
    const efficiencyFactor = heartrate && intensity / heartrate;
    fullData[idx] = { ...value, pace, speed, efficiencyFactor };
    if (isInside(value)) {
      speedSum += intensity ** 4;
      speedCount++;
      if (heartrate) {
        hrSum += heartrate;
        hrCount++;
      }
      if (!halfwayData && value.time >= halfwayTime) {
        halfwayData = { speedCount, speedSum, hrCount, hrSum, distance };
      }
    }
  });
  const lastIndex = fullData.length - 1;

  const efficiencyFactor = getEfficiencyFactor(
    speedSum,
    speedCount,
    hrSum,
    hrCount,
  );

  const ef1 =
    halfwayData &&
    getEfficiencyFactor(
      halfwayData.speedSum,
      halfwayData.speedCount,
      halfwayData.hrSum,
      halfwayData.hrCount,
    );
  const ef2 =
    halfwayData &&
    getEfficiencyFactor(
      speedSum - halfwayData.speedSum,
      speedCount - halfwayData.speedCount,
      hrSum - halfwayData.hrSum,
      hrCount - halfwayData.hrCount,
    );

  const efDecay = ef1 && ef2 ? getDecay(ef1, ef2) : null;

  const trends = {};
  const testData = fullData.filter(isInside);
  ['pace', 'speed', 'heartrate'].forEach(key => {
    const { m, b } = linearRegression(testData, 'time', key);
    trends[key] = { m, b };
  });

  const addTrends = d => ({
    ...d,
    trendHeartrate: trends.heartrate.m * d.time + trends.heartrate.b,
    trendSpeed: trends.speed.m * d.time + trends.speed.b,
  });

  const speedDecay = getDecay(
    halfwayData.distance - testData[0].distance,
    testData[testData.length - 1].distance - halfwayData.distance,
  );

  const paceDecay = -getDecay(
    testData[testData.length - 1].distance - halfwayData.distance,
    halfwayData.distance - testData[0].distance,
  );

  const hrDecay = getDecay(
    halfwayData.hrSum / halfwayData.hrCount,
    (hrSum - halfwayData.hrSum) / (hrCount - halfwayData.hrCount),
  );
  return {
    data: fullData
      .filter((_, idx) => idx % 30 === 0 || idx === lastIndex)
      .map(d => (isInside(d) ? addTrends(d) : d)),
    trends,
    efficiencyFactor,
    efDecay,
    hrDecay,
    speedDecay,
    paceDecay,
    averageHeartrate: hrSum / hrCount,
  };
}

export default processData;
