/**
 * Top List
 * List best result by distance
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Panel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import DateString from 'components/DateString';
import { useRequest } from 'utils/request';
import formatTime from 'utils/formatTime';
import messages from '../messages';
import { ResultTable } from './Participants';
import Ranking from './Ranking';

import { H2 } from '../styleComponents';
import makeName from './makeName';
import ResultColumns from './ResultColumns';
import Supporter from './Supporter';

const distances = {
  3000: 3,
  5000: 5,
  10000: 10,
  21100: 21,
};

function TopResults(props) {
  const { is_public, distance, athleteId } = props;
  const distanceKey = distances[distance];
  if (!is_public || !distanceKey) {
    return null;
  }
  const [topResults] = useRequest({
    url: `/virtual/top?distance=${distance}`,
  });
  if (!topResults || topResults.loading) {
    return null;
  }
  const isSelf = entry => entry.athlete_id === athleteId;
  return (
    <section>
      <H2 className="banner">
        <FormattedMessage
          {...messages.topResults}
          values={{ distance: distanceKey }}
        />
      </H2>
      <ResultColumns>
        {['F', 'M'].map(sex => (
          <Panel key={sex}>
            <Panel.Heading>
              <FormattedMessage {...messages[`H${sex}`]} />
            </Panel.Heading>
            <ResultTable condensed hover responsive>
              <thead>
                <tr>
                  <th className="td-pos">
                    <FormattedMessage {...messages.ranking} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.name} />
                  </th>
                  <th className="td-num">
                    <FormattedMessage {...messages.time} />
                  </th>
                  <th className="td-num">
                    <FormattedMessage {...messages.date} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {topResults[sex].map(e => (
                  <tr
                    key={e.athlete_id}
                    className={isSelf(e) ? 'current-athlete' : ''}
                  >
                    <td className="td-pos">
                      <Ranking pos={e.pos} />
                    </td>
                    <td>
                      {makeName(e)}
                      {Boolean(e.premium) && <Supporter />}
                    </td>
                    <td className="td-num">{formatTime(e.race_time)}</td>
                    <td className="td-num">
                      <Link to={`/virtual/${e.race_hash}`}>
                        <DateString date={e.start_date_local} format="PP" />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </ResultTable>
          </Panel>
        ))}
      </ResultColumns>
    </section>
  );
}

TopResults.propTypes = {
  is_public: PropTypes.number,
  distance: PropTypes.number,
  athleteId: PropTypes.number,
};

export default TopResults;
