/*
 * AdSpace Messages
 *
 * This contains all the text for the AdSpace component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  useSpace: {
    id: 'app.components.AdSpace.useSpace',
    defaultMessage: 'Use this space too',
  },
  getPremium: {
    id: 'app.components.AdSpace.getPremium',
    defaultMessage: 'Get RunnerMaps Premium',
  },
});
