/**
 *
 * Rest mapDispatchToProps
 *
 */

// import React from 'react';
// import PropTypes from 'prop-types';

import { getEntity, listEntity, changeEntity, resetEntity } from './actions';

function mapDispatchToProps(entity) {
  return dispatch =>
    // const dispatchWait = action =>
    //   new Promise((resolve, reject) =>
    //     dispatch({ ...action, promise: { resolve, reject } }),
    //   );
    ({
      apiGet: (id, options) => dispatch(getEntity(entity, id, options)),
      apiList: (params, options) =>
        dispatch(listEntity(entity, params, options)),
      apiChange: options => dispatch(changeEntity(entity, options)),
      apiReset: params => dispatch(resetEntity(entity, params)),
    });
}

export default mapDispatchToProps;
