const log2 = Math.log10(2);
const log5 = Math.log10(5);

const os = 25;

function getStepDistance(zoom, units = 'km') {
  const div = units === 'km' ? 1 : 1.60934;
  const lg = (os - zoom) * log2 - Math.log10(div);
  const floor = Math.floor(lg);
  const frac = lg - floor;
  const step = 10 ** floor;
  if (step < 100) {
    return 100;
  }
  if (frac > log5) {
    return step * 5;
  }
  if (frac > log2) {
    return step * 2;
  }
  return step;
}

export default getStepDistance;
