/**
 *
 * withClubs
 * HOC to add Locations tuple
 *
 */

// import React from 'react';
import injectSaga from 'utils/injectSaga';
import { DAEMON } from 'utils/constants';
// import { compose } from 'redux';
import saga from './saga';

const withSaga = injectSaga({ key: 'rest-api', saga, mode: DAEMON });

export default withSaga;
