/**
 *
 * SearchInput
 *
 */

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
// import styled from 'styled-components';
import { FormControl } from 'react-bootstrap';
import { debounce } from 'lodash';

function SearchInput(props) {
  const { placeholder, value, onChange, intl } = props;
  const [text, setText] = useState(value);
  const debouncedChange = useCallback(debounce(onChange, 500), []);

  function handleChange(ev) {
    const newText = ev.target.value;
    setText(newText);
    debouncedChange(newText);
  }

  const label = placeholder.id ? intl.formatMessage(placeholder) : placeholder;
  return (
    <FormControl
      type="search"
      placeholder={label}
      aria-label={label}
      value={text}
      onChange={handleChange}
    />
  );
}

SearchInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  intl: intlShape,
};

SearchInput.defaultProps = {
  placeholder: '',
  value: '',
};

export default injectIntl(SearchInput);
