/**
 *
 * SettingsLink
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withRoute from 'containers/withRoute';
import { green500 } from 'styles/colors';

import messages from './messages';

const Wrapper = styled(Button)`
  &.btn {
    text-align: left;
  }
  svg {
    color: ${green500};
  }
`;

function SettingsLink(props) {
  const { className, goTo } = props;
  return (
    <Wrapper
      bsStyle="link"
      className={className}
      onClick={() => goTo('#settings')}
    >
      <FontAwesomeIcon icon="cog" size="lg" fixedWidth />
      <FormattedMessage {...messages.settings} />
    </Wrapper>
  );
}

SettingsLink.propTypes = {
  className: PropTypes.string,
  goTo: PropTypes.func.isRequired,
};

export default withRoute(SettingsLink);
