const text = {
  nl: `
  Runnermaps draait al weer een flink aantal jaren (sinds 2008) en het gebruik neemt steeds verder toe. **Bedankt voor het gebruik van RunnerMaps door de jaren heen!**

  Met het toenemende gebruik zijn ook de kosten gestegen: servers, webservices (bijv. achtergrondkaarten, routering), ondersteuning en het ontwikkelen van nieuwe functies en onderhouden van bestaande.
  
  Daarom is er nu de mogelijkheid om een premium abonnement te nemen.
  
  Goed om te weten: **bestaande functies blijven gewoon gratis** en ook in de toekomst zullen nieuwe gratis functies toegevoegd worden.
  
  **Je kunt RunnerMaps steunen met een abonnement van 10 euro per jaar.**
  
  Met een abonnement:
  * kun je RunnerMaps gebruiken zonder advertenties (dus meer ruimte op je scherm)
  * krijg je meer en betere ([Thunderforest](https://www.thunderforest.com/maps/), [Mapy](http://mapy.cz/)) achtergrondkaarten
  * kun je nieuwe functies eerder gebruiken
  * en je **helpt de verdere ontwikkeling van RunnerMaps**, waarvoor onze dank!
  `,
  en: `
  RunnerMaps has been running for quite a number of years (since 2008) and usage continues to increase. **Big thanks for using RunnerMaps throughout these years!**

  With the increasing usage, also the cost have increased: servers, web services (e.g., background maps, routing), support, and developing new and maintaining existing features.
  
  Therefore we have now introduced a premium subscription.
  
  Good to know: **existing features remain free to use** and in future, new free features will be added.
  
  **You can support RunnerMaps with a subscription of 10 euro per year.**
  
  With a subscription you:
  * can use RunnerMaps without the advertising (so more space on your screen)
  * get more and better ([Thunderforest](https://www.thunderforest.com/maps/), [Mapy](http://mapy.cz/)) background maps
  * get earlier access to new features
  * and you **support the further development of RunnerMaps**, for which we thank you!
`,
};

export default text;
