/**
 *
 * Virtual
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Container from 'components/KmVreters/Container';
import virtualRunner from 'images/rm-virtual-bg.png';
import UpcomingRaces from './UpcomingRaces';
import CreateRaces from './CreateRaces';
import RaceResults from './RaceResults';
import messages from './messages';
import { H2 } from './styleComponents';

export const Img = styled.img`
  width: 64px;
  margin-left: 10px;
  margin-bottom: 10px;
`;

function Virtual(props) {
  const { user } = props;
  const isAdmin = user.api && user.api.su;

  return (
    <Container width={720}>
      <Container.Header>
        {isAdmin && (
          <Link to="/virtual/admin" className="pull-right small">
            admin
          </Link>
        )}
        <FormattedMessage {...messages.header} />
      </Container.Header>
      <div>
        <Img src={virtualRunner} className="pull-right" alt="Virtual Runner" />
        <FormattedHTMLMessage {...messages.testRunsIntro} />
        <H2 banner>
          <FormattedHTMLMessage {...messages.joinAction} />
        </H2>
        <UpcomingRaces user={user} />
        <RaceResults user={user} />
        {user.api && <CreateRaces user={user} />}
      </div>
    </Container>
  );
}

Virtual.propTypes = {
  user: PropTypes.object,
};

export default Virtual;
