/**
 *
 * ConfirmButton
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import useDialog from 'containers/Dialog/useDialog';
import { Button } from 'react-bootstrap';

import messages from './messages';

function ConfirmButton(props) {
  const { onClick, confirmationText, ...rest } = props;
  const { dlgConfirm } = useDialog();

  function handleClick(ev) {
    dlgConfirm({ title: confirmationText })
      .then(() => onClick(ev))
      .catch(() => {});
  }
  return <Button {...rest} onClick={handleClick} />;
}

ConfirmButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  confirmationText: PropTypes.object,
};

ConfirmButton.defaultProps = {
  confirmationText: messages.confirmationText,
};

export default ConfirmButton;
