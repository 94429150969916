/**
 *
 * ShareLink
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import useAlerts from 'containers/AlertService/useAlerts';
import { reportError } from 'utils/Sentry';
import messages from './messages';

function ShareLink(props) {
  const { href, children, ...rest } = props;
  const { pathname } = useLocation();
  const addAlert = useAlerts();
  const url = href || `https://runnermaps.nl${pathname}`;

  async function handleClick() {
    if (navigator.canShare) {
      try {
        navigator.share({ url });
        return;
      } catch (err) {
        reportError(err, { url });
      }
    }
    copy(url);
    addAlert({
      title: messages.copied,
      style: 'success',
      autoDismiss: true,
    });
  }

  const toggle = children || (
    <React.Fragment>
      <span className="hidden-xs">
        <FormattedMessage {...messages.share} />
      </span>
      <FontAwesomeIcon icon="share-alt" size="lg" fixedWidth />
    </React.Fragment>
  );
  return (
    <Button onClick={handleClick} {...rest}>
      {toggle}
    </Button>
  );
}

ShareLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  bsStyle: PropTypes.string,
};

ShareLink.defaultProps = {
  bsStyle: 'link',
};

export default ShareLink;
