/**
 *
 * RaceInfo
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Linkify from 'react-linkify';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCurrentUser from 'containers/User/useCurrentUser';
import DateString from 'components/DateString';
import Spinner from 'components/Spinner';
import Flex from 'components/Flex';
import Markdown from 'components/Markdown';
import ShareLink from 'components/ShareLink';
import messages from '../messages';

const Info = styled.div`
  flex-grow: 1;
  display: block;
  @media (min-width: 468px) {
    display: flex;
    align-items: flex-start;
  }
  dl {
    display: inline-block;
    @media (min-width: 480px) {
      dt {
        float: left;
        width: 80px;
        clear: left;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      dd {
        margin-left: 100px;
      }
    }
  }
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 1rem;
  .btn + * {
    margin-top: 1rem;
  }
  padding-bottom: 10px;
`;

const Img = styled.img`
  max-width: 64px;
  @media (min-width: 468px) {
    max-width: 128px;
  }
  float: left;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
`;

const UserText = styled.div`
  white-space: pre-line;
`;

function RaceInfo(props) {
  const { race } = props;
  const user = useCurrentUser();
  if (race.error) {
    return <Alert bsStyle="danger">{race.error.message}</Alert>;
  }
  if (race.loading) {
    return <Spinner />;
  }
  const canEdit = user.api && race && user.api.userId === race.user_id;
  const description =
    race.description &&
    (race.is_public ? (
      <Markdown source={race.description} />
    ) : (
      <p>
        <Linkify>{race.description}</Linkify>
      </p>
    ));
  return (
    <Flex spaced align="flex-start">
      <Info>
        {race.image && <Img src={race.image} alt="" />}
        <div>
          {description && <UserText>{description}</UserText>}
          <dl className="dl-horizontal">
            <dt>
              <FormattedMessage {...messages.distance} />
            </dt>
            <dd>{`${race.distance / 1000} km`}</dd>
            <dt>
              <FormattedMessage {...messages.date} />
            </dt>
            <dd>
              <DateString
                date={race.date}
                endDate={race.end_date}
                format="d MMMM yyyy"
              />
            </dd>
          </dl>
          {Boolean(race.fixed) && (
            <Alert bsStyle="info">
              <FormattedHTMLMessage {...messages.fixedTime} />
            </Alert>
          )}
        </div>
      </Info>
      <Controls>
        {canEdit && (
          <Link
            to={`/virtual/edit/${race.hash}`}
            className="mb-3 btn btn-default"
          >
            <FontAwesomeIcon icon="pen" size="lg" fixedWidth />
          </Link>
        )}
        <ShareLink bsStyle={canEdit ? 'success' : 'link'} />
      </Controls>
    </Flex>
  );
}

RaceInfo.propTypes = {
  race: PropTypes.object,
};

export default RaceInfo;
