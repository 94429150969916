/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const PATH_LOCATION = '/club/:clubId/loc/:locationId';
export const PATH_CLUB = '/club/:clubId';
export const PATH_ROUTE = '/route/:routeId';
export const PATH_SIGNIN = '/finishSignUp';
export const PATH_DEBUG = '/debug';
export const PATH_MANAGE = '/manage/:userId?';
export const PATH_KMVRETERS = '/kmvreters/:page/:id?';
export const PATH_VIRTUAL = '/virtual/:page(edit|admin|create)?/:hash?';
export const PATH_RESERVATIONS =
  '/inschrijven/:page(edit|create|members)?/:hash?';
export const PATH_HOME = '/home';

export const IFRAME = window.self !== window.top;
export const EMBEDDED =
  /view=embed/.test(window.location.search) ||
  (IFRAME && /^\/(club|route)/.test(window.location.pathname));
