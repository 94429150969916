/**
 *
 * GChart
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import addScript from 'utils/addScript';

const loadScript = addScript({
  src: '//www.gstatic.com/charts/loader.js',
  wait: true,
});

const Wrapper = styled.div`
  margin: 20px 0;
`;

/* global google */

/* eslint-disable react/prefer-stateless-function */
class GChart extends React.PureComponent {
  componentDidMount() {
    loadScript.then(() => {
      google.charts.load('current', { packages: ['corechart'] });
      google.charts.setOnLoadCallback(this.initChart);
    });
  }

  initChart = () => {
    const { chartType, id } = this.props;
    const container = document.getElementById(id);
    if (container) {
      this.chart = new google.visualization[chartType](
        document.getElementById(id),
      );
      this.drawChart();
    }
  };

  drawChart = () => {
    const { data, options, firstRowIsData, format } = this.props;
    if (this.chart) {
      this.gData = google.visualization.arrayToDataTable(data, firstRowIsData);
      if (format) {
        const dateFormatter = new google.visualization.DateFormat({
          pattern: format,
        });
        dateFormatter.format(this.gData, 0);
      }
      this.chart.draw(this.gData, options);
    }
  };

  componentDidUpdate(prevProps) {
    const { active } = this.props;
    if (!active) {
      return;
    }
    if (prevProps.active) {
      this.drawChart();
    } else {
      window.setTimeout(this.drawChart, 500);
    }
  }

  render() {
    const { id, width, height } = this.props;
    return <Wrapper id={id} width={width} height={height} />;
  }
}

GChart.propTypes = {
  firstRowIsData: PropTypes.bool,
  id: PropTypes.string,
  format: PropTypes.string,
  active: PropTypes.bool,
  data: PropTypes.array,
  chartType: PropTypes.string,
  options: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
};

GChart.defaultProps = {
  firstRowIsData: false,
};

export default GChart;
