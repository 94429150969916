/**
 *
 * Avatar
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import svgUser from 'images/icons/user.svg';

const StyledImg = styled.img`
  width: ${p => p.size};
  height: ${p => p.size};
  border-radius: 50%;
`;

function Avatar(props) {
  const { src, size } = props;
  const [imgSrc, setImgSrc] = useState(src);
  return (
    <StyledImg size={size} src={imgSrc} onError={() => setImgSrc(svgUser)} />
  );
}

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
  size: PropTypes.string,
};

Avatar.defaultProps = {
  size: '36px',
};

export default Avatar;
