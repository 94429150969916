import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import mapDispatchToProps from 'containers/Rest/mapDispatchToProps';
import makeSelectKmVretersPage from './selectors';

const mapStateToProps = createStructuredSelector({
  kvData: makeSelectKmVretersPage(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps('kmvreters'),
);
