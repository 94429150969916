import firebase from './firebase';

const uiConfig = {
  // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
  signInFlow: 'popup',
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      scopes: ['https://www.googleapis.com/auth/userinfo.email'],
    },
    {
      provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      scopes: ['public_profile', 'email'],
    },
  ],
  // Terms of service url.
  // tosUrl: 'https://www.harryonline.nl/',
  // Privacy policy url.
  // privacyPolicyUrl: 'https://www.harryonline.nl/',
};

export default uiConfig;
