/**
 *
 * useLocale
 *
 */

import { useSelector } from 'react-redux';
import { selectLocale } from './selectors';

function useLocale() {
  return useSelector(selectLocale);
}

export default useLocale;
