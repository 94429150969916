/**
 *
 * FormField
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import {
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
} from 'react-bootstrap';
import Checkbox from 'components/Checkbox';
import RadioButtons from 'components/RadioButtons';
import Required from './Required';

function FormField(props) {
  const {
    id,
    children,
    helpText,
    options,
    componentClass,
    attributes,
    ...controlProps
  } = props;
  switch (componentClass) {
    case 'checkbox':
      return (
        <FormGroup>
          <Checkbox {...props} />
          {helpText && <HelpBlock>{helpText}</HelpBlock>}
        </FormGroup>
      );
    case 'radio':
      return (
        <FormGroup>
          <RadioButtons {...props} />
          {helpText && <HelpBlock>{helpText}</HelpBlock>}
        </FormGroup>
      );
    default:
      return (
        <FormGroup controlId={id}>
          <ControlLabel>
            {children}
            <Required show={props.required} />
          </ControlLabel>
          <FormControl
            name={id}
            componentClass={componentClass}
            {...attributes}
            {...controlProps}
          />
          {helpText && <HelpBlock>{helpText}</HelpBlock>}
        </FormGroup>
      );
  }
}

FormField.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
  helpText: PropTypes.object,
};

export default FormField;
