/**
 *
 * Period
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'redux';
import GChart from 'components/GChart';
import {
  GC_GREEN_AXIS,
  GC_RED_AXIS,
  GC_GREEN_FILL,
  GC_RED_LINE,
} from 'components/GChart/constants';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Table } from 'react-bootstrap';
import withSettings from 'containers/SettingsProvider/withSettings';
import withDialog from 'containers/Dialog/withDialog';
import withKvApi from 'containers/KmVretersPage/withKvApi';
import Pagination from 'components/Pagination';
import { PAGESIZE } from 'utils/constants';
import formatTime from 'utils/formatTime';
import distanceString from '../distanceString';
import listByPeriod from './listByPeriod';
import messages from '../messages';
import getGraphData, { getFirst } from './getGraphData';

const Wrapper = styled.div`
  padding-top: 20px;
  table.table {
    @media (max-width: 479px) {
      font-size: smaller;
    }
    && thead > tr > th {
      vertical-align: top;
      .units {
        font-weight: normal;
      }
    }
    th,
    td {
      text-align: right;
    }
    .td-link {
      text-align: center;
    }
  }
`;

const Units = styled.div`
  font-weight: normal;
`;

/* eslint-disable react/prefer-stateless-function */
class Period extends React.PureComponent {
  state = { offset: 0 };

  handlePaging = offset => {
    this.setState({ offset });
  };

  render() {
    const { active, data, units, period, intl } = this.props;
    const { offset } = this.state;
    const { list } = data.value;
    const tableList = listByPeriod(list, period);
    const slicedList = tableList.slice(offset, offset + PAGESIZE);
    const headers = ['year', 'distance', 'speed'].map(key =>
      intl.formatMessage(messages[key]),
    );
    const graphData = getGraphData(tableList, period, units);
    const chartProps = {
      id: `chart-${period}`,
      data: [headers, ...graphData],
      active,
      format: period === 'month' ? 'MMM y' : 'y',
      options: {
        title:
          period === 'month'
            ? intl.formatMessage(messages.monthTitle)
            : undefined,
        hAxis: {
          format: period === 'month' ? 'MMM' : 'y',
          legend: { position: 'top' },
          maxValue: new Date(),
          minValue: getFirst(period),
        },
        vAxes: [
          {
            ...GC_GREEN_AXIS,
            title: units,
          },
          {
            title: `${units}/${intl.formatMessage(messages.hr)}`,
            ...GC_RED_AXIS,
          },
        ],
        series: {
          0: { type: 'bars', color: GC_GREEN_FILL },
          1: { type: 'line', color: GC_RED_LINE, targetAxisIndex: 1 },
        },
        interpolateNulls: true,
        focusTarget: 'category',
      },
      chartType: 'ComboChart',
      width: '100%',
      height: '200px',
    };

    return (
      <Wrapper>
        <GChart {...chartProps} />
        <Table responsive hover>
          <thead>
            <tr>
              <th>
                <FormattedMessage {...messages[period]} />
              </th>
              <th>
                <FormattedMessage {...messages.number} />
              </th>
              <th>
                <FormattedMessage {...messages.distance} />
                <Units>({units})</Units>
              </th>
              <th>
                <FormattedMessage {...messages.avgDistance} />
                <Units>({units})</Units>
              </th>
              <th>
                <FormattedMessage {...messages.pace} />
                <Units>(min/{units})</Units>
              </th>
              <th>
                <FormattedMessage {...messages.speed} />
                <Units>
                  ({units}/<FormattedMessage {...messages.hr} />)
                </Units>
              </th>
            </tr>
          </thead>
          <tbody>
            {slicedList.map(item => (
              <tr key={item.dateStr}>
                <td className="td-date">{item.dateStr}</td>
                <td className="td-number">{item.number}</td>
                <td className="td-distance">
                  {distanceString(item.distance, units)}
                </td>
                <td className="td-distance">
                  {distanceString(item.distance / item.number, units)}
                </td>
                <td className="td-time">
                  {formatTime(item.time / item.distance)}
                </td>
                <td className="td-distance">
                  {distanceString((item.distance * 3600) / item.time, units, 2)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          value={offset}
          length={tableList.length}
          onChange={this.handlePaging}
        />
      </Wrapper>
    );
  }
}

Period.propTypes = {
  active: PropTypes.bool,
  data: PropTypes.object.isRequired,
  units: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  dlgConfirm: PropTypes.func.isRequired,
  apiChange: PropTypes.func.isRequired,
  intl: intlShape,
};

export default compose(
  injectIntl,
  withDialog,
  withKvApi,
  withSettings(['units']),
)(Period);
