/**
 *
 * GroupEdit
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Container from 'components/KmVreters/Container';
import LoginAlert from 'components/UserControl/LoginAlert';
import { request2 } from 'utils/request';
import messages from '../messages';
import EditGroup from './EditGroup';
import CreateGroup from './CreateGroup';

function GroupEdit(props) {
  const { user, hash } = props;
  const history = useHistory();
  const header = hash ? messages.editGroup : messages.createGroup;
  const handleSubmit = options => {
    request2({ ...options, user }).then(result =>
      history.push(`/inschrijven/${result.hash}`),
    );
  };
  return (
    <Container width={720}>
      <Container.Header>
        <FormattedMessage {...header} />
      </Container.Header>
      {user.api ? (
        <React.Fragment>
          <p>
            <FormattedMessage {...messages.createInfo} />
          </p>
          {hash ? (
            <EditGroup hash={hash} onSubmit={handleSubmit} />
          ) : (
            <CreateGroup onSubmit={handleSubmit} />
          )}
        </React.Fragment>
      ) : (
        <LoginAlert />
      )}
    </Container>
  );
}

GroupEdit.propTypes = {
  user: PropTypes.object,
  hash: PropTypes.string,
};

export default GroupEdit;
