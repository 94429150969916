import { format, subMonths, subYears } from 'date-fns';
import { MILES_KM } from 'utils/constants';
import round10 from 'utils/round10';
import createArray from 'utils/createArray';
import { staticLocale } from 'utils/useDateLocale';

export function getFirst(period) {
  if (period === 'year') {
    return undefined;
  }
  return subYears(new Date(), 2);
}

function getColumns(list, period) {
  if (period === 'month') {
    const result = {};
    createArray(24).forEach(m => {
      const dt = subMonths(new Date(), m);
      result[format(dt, 'Y-MM')] = { label: format(dt, 'MMM', staticLocale) };
    });
    return result;
  }
  const firstYear = Number(list[list.length - 1].dateStr);
  const result = {};
  const thisYear = new Date().getFullYear();
  for (let yr = firstYear; yr <= thisYear; yr++) {
    const label = yr.toString();
    result[label] = { label };
  }
  return result;
}

function getFilteredList(list, period) {
  const columns = getColumns(list, period);
  list.every(item => {
    const col = columns[item.dateStr];
    if (!col) {
      return false;
    }
    col.data = item;
    return true;
  });
  return Object.keys(columns)
    .sort()
    .map(key => columns[key]);
}

function getGraphData(list, period, units) {
  const conv = units === 'km' ? 1 : MILES_KM;
  return getFilteredList(list, period).map(item => [
    item.label,
    item.data ? round10(item.data.distance * conv, -1) : 0,
    item.data
      ? round10((item.data.distance * 3600 * conv) / item.data.time, -2)
      : undefined,
  ]);
}

export default getGraphData;
