import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import usePagination from 'components/Pagination/usePagination';
import DataCell from './DataCell';
import getContent from './getContent';
import useSelectContext from './SelectContext/useSelectContext';
import SelectCheckBox from './SelectContext/SelectCheckbox';

function makeTitle(item) {
  return Object.entries(item)
    .map(([key, value]) => `${key}: ${getContent(value)}`)
    .join('\n');
}

function DataTable(props) {
  const { fields, data } = props;
  const { slice, Paginator, paginationText } = usePagination(data.length, 20);
  const selectContext = useSelectContext();

  if (data.length === 0) {
    return <p>No data</p>;
  }

  return (
    <Fragment>
      <Table responsive>
        <caption>{paginationText}</caption>
        <thead>
          <tr>
            {selectContext && <th />}
            {fields.map(field => <th key={field.key}>{field.key}</th>)}
          </tr>
        </thead>
        <tbody>
          {slice(data).map(item => (
            <tr key={item.id} title={makeTitle(item)}>
              {selectContext && (
                <td>
                  <SelectCheckBox item={item} />
                </td>
              )}
              {fields.map(field => (
                <DataCell key={field.key} field={field} item={item} />
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <Paginator />
    </Fragment>
  );
}

DataTable.propTypes = {
  fields: PropTypes.array,
  data: PropTypes.array,
};
export default DataTable;
