/**
 *
 * SubscriptionModal
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Modal, Panel } from 'react-bootstrap';
import * as colors from 'styles/colors';
import messages from './messages';
import text from './supportText';
import Markdown from '../../Markdown';
import Subscribe from './Subscribe';

const Wrapper = styled(Panel)`
  &.panel {
    border-color: ${colors.red500};
  }
`;

function SubscriptionModal(props) {
  const { onHide } = props;
  return (
    <Modal show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage {...messages.header} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Markdown source={text} linkTarget="_blank" />
        <Wrapper className="p-4 mt-5">
          <p>
            <FormattedMessage {...messages.intro} />
          </p>
          <Subscribe />
        </Wrapper>
      </Modal.Body>
    </Modal>
  );
}

SubscriptionModal.propTypes = {
  onHide: PropTypes.func.isRequired,
};

export default SubscriptionModal;
