/**
 * Create path from link and parameters
 */
function makePath(link, params) {
  return link.replace(/:\w+/g, replace(params));
}

function replace(params) {
  return match => {
    const key = match.slice(1);
    const result = params[key];
    if (result === undefined) {
      throw new Error(`No such param: ${key}`);
    }
    return result;
  };
}

export default makePath;
