/*
 * Auth Messages
 *
 * This contains all the text for the Auth component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  email: {
    id: 'app.components.AskEmail.email',
    defaultMessage: 'Email address',
  },
});
