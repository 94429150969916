const escape = str =>
  str.includes(',') ? `"${str.replace(/["\\]/, '\\$&')}"` : str;

function getValue(row, def) {
  const val = def.getValue(row);
  if (val === null || val === undefined) {
    return def.empty || val;
  }
  return typeof val === 'string' ? escape(val) : val;
}

function toCSV(data, definitions) {
  if (!data || data.length === 0) {
    return '';
  }
  const header = definitions.map(o => escape(o.title));
  const result = [['sep=,'], header];
  data.forEach(row => {
    const rowValues = definitions.map(f => getValue(row, f));
    result.push(rowValues);
  });
  return result.map(r => r.join(',')).join('\n');
}

export default toCSV;
