/*
 * Privacy Messages
 *
 * This contains all the text for the Privacy component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.Privacy.header',
    defaultMessage: 'About RunnerMaps',
  },
  about: {
    id: 'app.components.Privacy.about',
    defaultMessage:
      'RunnerMaps is a web site by <a href="https://www.harryonline.net">HarryOnline</a>',
  },
  privacy: {
    id: 'app.components.Privacy.privacy',
    defaultMessage: 'Privacy Policy',
  },
  conditions: {
    id: 'app.components.Privacy.conditions',
    defaultMessage: 'General Conditions',
  },
});
