/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';

import history from 'utils/history';
import settingsProviderReducer from 'containers/SettingsProvider/reducer';
import uiControlReducer from 'containers/UiControl/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    settings: settingsProviderReducer,
    uiControl: uiControlReducer,
    router: connectRouter(history),
    ...injectedReducers,
  });

  return rootReducer;
}
