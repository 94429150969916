/**
 *
 * PrList
 *
 */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import { Nav, NavItem, Table } from 'react-bootstrap';
import MetaTags from 'components/MetaTags';
import usePagination from 'components/Pagination/usePagination';
import formatDate from 'utils/formatDate';
import formatTime from 'utils/formatTime';
import Spinner from 'components/Spinner';
import { useHash, useSearch } from 'utils/urlUtils';
import Container from '../Container';
import messages from './messages';

const baseYear = 2003;
const allYears = new Array(new Date().getFullYear() - baseYear + 1)
  .fill(0)
  .map((_, i) => baseYear + i);

const Wrapper = styled(Table)`
  @media (max-width: 479px) {
    font-size: smaller;
  }
  .td-pos {
    text-align: right;
    width: 5%;
    min-width: 2.3em;
  }
  .td-date {
    text-align: right;
    white-space: nowrap;
    width: 10%;
  }
  .td-distance {
    text-align: right;
    width: 5%;
    padding-right: 1em;
  }
  .td-time {
    text-align: right;
    width: 5%;
    min-width: 4.6em;
  }
`;

/* eslint-disable react/prefer-stateless-function */
function PrList(props) {
  const { data, intl } = props;
  const { club, list } = (data && data.value) || {};
  const hash = useHash();
  const search = useSearch();
  const activeKey = Number(hash.distance || 5);
  const limit = activeKey === 99 ? 10 : 100;

  const title = intl.formatMessage(messages.title, { club: club && club.name });
  const tags = { title };

  function getFiltered() {
    if (!list) {
      return [];
    }
    return activeKey === 99
      ? list.slice(0).sort((a, b) => b.date - a.date)
      : list.filter(item => Math.floor(item.distance) === Number(activeKey));
  }

  const yearsControl = useMemo(
    () => {
      if (!list) {
        return [];
      }
      const yearSet = new Set(allYears);
      list.forEach(item => {
        const year = new Date(item.date * 1000).getFullYear();
        yearSet.add(year);
      });
      const options = Array.from(yearSet)
        .sort((a, b) => b - a)
        .map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ));
      return (
        <select
          value={search.year}
          onChange={e => search.push('year', e.target.value || null)}
          className="form-control w-auto"
        >
          <option value="">Alle</option>
          {options}
        </select>
      );
    },
    [list],
  );

  const filteredList = useMemo(getFiltered, [list, activeKey]);
  const { slice, offset, Paginator, setPage, paginationText } = usePagination(
    filteredList.length,
    limit,
  );

  function handleSelect(selectedKey) {
    setPage(0);
    hash.push('distance', selectedKey);
  }

  return (
    <Container width={720}>
      <MetaTags tags={tags} />
      <div className="d-flex justify-content-between align-items-baseline">
        <Container.Header>{title}</Container.Header>
        {yearsControl}
      </div>
      <div>
        <Nav bsStyle="tabs" activeKey={activeKey} onSelect={handleSelect}>
          <NavItem eventKey={5}>5 km</NavItem>
          <NavItem eventKey={10}>10 km</NavItem>
          <NavItem eventKey={15}>15 km</NavItem>
          <NavItem eventKey={16}>10 EM</NavItem>
          <NavItem eventKey={21}>Halve mar.</NavItem>
          <NavItem eventKey={42}>Marathon</NavItem>
          <NavItem eventKey={99} className="pull-right">
            Recent
          </NavItem>
        </Nav>
        {data.loading !== false ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <div className="mx-3 mt-4">{paginationText}</div>
            <Wrapper className="mt-3">
              <thead>
                <tr>
                  {activeKey !== 99 && <th>#</th>}
                  <th>
                    <FormattedMessage {...messages.name} />
                  </th>
                  <th className="text-center">
                    <FormattedMessage {...messages.distance} />
                  </th>
                  {activeKey === 99 && (
                    <th className="text-center">
                      <FormattedMessage {...messages.time} />
                    </th>
                  )}
                  <th className="text-center">
                    <FormattedMessage {...messages.date} />
                  </th>
                  <th className="d-none d-sm-table-cell">
                    <FormattedMessage {...messages.event} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {slice(filteredList).map((item, index) => (
                  <tr key={`${activeKey}-${item.distance}-${item.user_id}`}>
                    {activeKey !== 99 && (
                      <td className="td-pos">{index + 1 + offset}</td>
                    )}
                    <td className="td-name">
                      <Link to={`/kmvreters/list/${item.user_id}`}>
                        {item.name}
                      </Link>
                    </td>
                    {activeKey === 99 && (
                      <td className="td-distance">{item.distance}</td>
                    )}
                    <td className="td-time">{formatTime(item.time)}</td>
                    <td
                      className="td-date"
                      title={`${item.event}, ${item.city}`}
                    >
                      {formatDate(item.date, 'PP')}
                    </td>
                    <td
                      className="d-none d-sm-table-cell text-muted"
                      title={item.city}
                    >
                      {item.event}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Wrapper>
            <Paginator />
          </React.Fragment>
        )}
      </div>
    </Container>
  );
}

PrList.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  hashAdd: PropTypes.func,
  intl: intlShape.isRequired,
};

export default injectIntl(PrList);
