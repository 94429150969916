/**
 *
 * RadioButtons -- adds className 'checked' to checked items
 *  Can use attribute 'value' instead of 'checked'
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'react-bootstrap';

function RadioButtons(props) {
  const { id, options, onChange, required, value } = props;
  const handleChange = val => () => onChange({ target: { id, val } });
  return options.map(option => (
    <Radio
      key={option.value}
      name={id}
      value={option.value}
      required={required}
      checked={value && value === option.value}
      onChange={handleChange(option.value)}
    >
      {option.label}
    </Radio>
  ));
}

RadioButtons.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  val: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RadioButtons;
