/*
 * RoutePath Messages
 *
 * This contains all the text for the RoutePath component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  start: {
    id: 'app.components.RoutePath.start',
    defaultMessage: 'Start',
  },
  finish: {
    id: 'app.components.RoutePath.finish',
    defaultMessage: 'Finish',
  },
  export: {
    id: 'app.components.RoutePath.export',
    defaultMessage: 'Export',
  },
  gpxTrack: {
    id: 'app.components.RoutePath.gpxTrack',
    defaultMessage: 'GPX Track',
  },
  link: {
    id: 'app.components.RoutePath.link',
    defaultMessage: 'Link to this route',
  },
  notFound: {
    id: 'app.components.RoutePath.notFound',
    defaultMessage: 'Route {id} could not be found',
  },
  title: {
    id: 'app.components.RoutePath.title',
    defaultMessage: 'Route {title} | RunnerMaps',
  },
});
