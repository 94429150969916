/**
 *
 * Required
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

import { injectIntl, intlShape } from 'react-intl';
import messages from './messages';

function Required(props) {
  const { show, intl } = props;
  if (!show) {
    return null;
  }
  return <span title={intl.formatMessage(messages.required)}> *</span>;
}

Required.propTypes = {
  show: PropTypes.bool,
  intl: intlShape,
};

export default injectIntl(Required);
