/**
 *
 * WithRoute
 * goBack/goTo/hashClear
 * HoC to add goBack property to component
 */

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { push, goBack } from 'connected-react-router';
import makePath from 'utils/makePath';
import injectSaga from 'utils/injectSaga';
import { DAEMON } from 'utils/constants';
import saga from './saga';
import { hashAdd, hashClear, hashDelete } from './actions';
import { makeSelectLocation } from './selectors';

export const mapStateToProps = createStructuredSelector({
  location: makeSelectLocation(),
});

export function mapDispatchToProps(dispatch) {
  function onLinkClick(ev) {
    if (ev.ctrlKey || ev.shiftKey) {
      return;
    }
    ev.preventDefault();
    dispatch(push(ev.currentTarget.attributes.href.value));
  }

  return {
    goBack: () => dispatch(goBack()),
    goTo: (link, params) => dispatch(push(makePath(link, params))),
    hashAdd: (key, value) => dispatch(hashAdd(key, value)),
    hashClear: () => dispatch(hashClear()),
    hashDelete: key => dispatch(hashDelete(key)),
    clickTo: (link, params) => () => dispatch(push(makePath(link, params))),
    onMenuSelect: (key, ev) => onLinkClick(ev),
    onLinkClick,
  };
}

const withSaga = injectSaga({ key: 'withroute', saga, mode: DAEMON });
const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withSaga,
  connected,
);

export { default as withLocation } from './withLocation';
