import {
  TRAVELMODE_CYCLING,
  TRAVELMODE_STRAIGHT,
  TRAVELMODE_WALKING,
} from 'utils/directions/constants';

import credentials from '../config/credentials';

export const ACCESS_NONE = 0;
export const ACCESS_SUPER = 1;
export const ACCESS_PREMIUM = 2;
export const ACCESS_PUBLIC = 3;

const mapTypes = [
  {
    id: 'stadia',
    url: 'https://tiles-eu.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}.png',
    attribution:
      '<a href="https://www.stadiamaps.com/">&copy; Stadia Maps</a>&nbsp;<a href="https://openmaptiles.org/">&copy; OpenMapTiles</a>&nbsp;<a href="https://www.openstreetmap.org/about/">&copy; OpenStreetMap contributors</a>',
    access: ACCESS_PUBLIC,
    maxZoom: 20,
  },
  {
    id: 'carto',
    url:
      'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
    attribution:
      'Map tiles by <a href="https://carto.com/attribution">Carto</a>, under CC BY 3.0. Data by <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, under ODbL.',
    access: ACCESS_NONE,
    maxZoom: 21,
  },
  {
    id: 'mapy',
    url: `https://api.mapy.cz/v1/maptiles/basic/256/{z}/{x}/{y}?apikey=${
      credentials.mapy
    }`,
    attribution:
      '<a href="https://api.mapy.cz/copyright">Seznam.cz a.s. and others</a>. Data by <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    access: ACCESS_PREMIUM,
    maxZoom: 20,
  },
  {
    id: 'openstreetmap',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?',
    attribution:
      '<a href="https://www.openstreetmap.org/about/">&copy; OpenStreetMap contributors</a>',
    access: ACCESS_SUPER,
    maxZoom: 21,
  },
  {
    id: 'openCycle',
    name: 'Fietskaart',
    url: `https://{s}.tile.thunderforest.com/cycle/{z}/{x}/{y}.png?${
      credentials.thunderforest
    }`,
    attribution:
      'Maps © <a href="http://www.thunderforest.com">Thunderforest</a>, Data © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>',
    access: ACCESS_PREMIUM,
  },
  {
    id: 'outdoors',
    name: 'Outdoors',
    url: `https://{s}.tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?${
      credentials.thunderforest
    }`,
    attribution:
      'Maps © <a href="http://www.thunderforest.com">Thunderforest</a>, Data © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>',
    access: ACCESS_PREMIUM,
  },
  {
    id: 'landscape',
    name: 'Landschap',
    url: `https://{s}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png?${
      credentials.thunderforest
    }`,
    attribution:
      'Maps © <a href="http://www.thunderforest.com">Thunderforest</a>, Data © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>',
    access: ACCESS_PREMIUM,
  },
  {
    id: 'transport',
    url: `https://{s}.tile.thunderforest.com/transport/{z}/{x}/{y}.png?${
      credentials.thunderforest
    }`,
    attribution:
      'Maps © <a href="http://www.thunderforest.com">Thunderforest</a>, Data © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>',
    access: ACCESS_PREMIUM,
  },
  {
    id: 'stamenTerrain',
    url:
      'https://tiles-eu.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}{r}.png',
    attribution:
      '&copy; <a href="https://stadiamaps.com/" target="_blank">Stadia Maps</a> <a href="https://stamen.com/" target="_blank">&copy; Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/about" target="_blank">OpenStreetMap</a> contributors',
    access: ACCESS_PUBLIC,
    maxZoom: 20,
  },
  {
    id: 'openbusmap',
    url: 'https://tile.memomaps.de/tilegen/{z}/{x}/{y}.png',
    attribution:
      'Map&nbsp;<a href="https://memomaps.de/">memomaps.de</a>&nbsp;<a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, map data&nbsp;<a href="http://openstreetmap.org/">Openstreetmap</a>&nbsp;<a href="http://opendatacommons.org/licenses/odbl/1.0/">ODbL</a>',
    access: ACCESS_PUBLIC,
    maxZoom: 20,
  },
  {
    id: 'worldTopo',
    url: `https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}?${
      credentials.esri
    }`,
    attribution:
      'Sources: Esri, HERE, Garmin, Intermap, increment P Corp., GEBCO, USGS, FAO, NPS, NRCAN, GeoBase, IGN, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), (c) OpenStreetMap contributors, and the GIS User Community',
    access: ACCESS_SUPER,
    maxZoom: 19,
    doc: 'https://services.arcgisonline.com/arcgis/rest/services',
  },
  {
    id: 'worldImagery',
    url: `https://ibasemaps-api.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}?${
      credentials.esri
    }`,
    attribution:
      'Source: Esri, Maxar, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community',
    access: ACCESS_PUBLIC,
    maxZoom: 20,
  },
  {
    id: 'hereHybrid',
    url: `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?style=explore.satellite.day&${
      credentials.here2
    }`,
    attribution: `Map Tiles &amp; Directions &copy; ${new Date().getFullYear()} <a href="http://developer.here.com">HERE</a>`,
    access: ACCESS_PREMIUM,
    maxZoom: 20,
  },
  {
    id: 'stadiaHybrid',
    url:
      'https://tiles-eu.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}.png',
    attribution:
      '<a href="https://www.stadiamaps.com/">&copy; Stadia Maps</a>&nbsp;<a href="https://openmaptiles.org/">&copy; OpenMapTiles</a>&nbsp;<a href="https://www.openstreetmap.org/about/">&copy; OpenStreetMap contributors</a>',
    access: ACCESS_PUBLIC,
    maxZoom: 20,
  },

  {
    id: 'hikingRoutes',
    url: '//tile.waymarkedtrails.org/hiking/{z}/{x}/{y}.png',
    overlay: true,
    attribution:
      '&copy; <a href="http://waymarkedtrails.org">Sarah Hoffmann</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
    access: ACCESS_PUBLIC,
    maxZoom: 18,
  },
  {
    id: 'cyclingRoutes',
    url: '//tile.waymarkedtrails.org/cycling/{z}/{x}/{y}.png',
    overlay: true,
    attribution:
      '&copy; <a href="http://waymarkedtrails.org">Sarah Hoffmann</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
    access: ACCESS_PUBLIC,
    maxZoom: 18,
  },
  {
    id: 'mtbRoutes',
    url: '//tile.waymarkedtrails.org/mtb/{z}/{x}/{y}.png',
    overlay: true,
    attribution:
      '&copy; <a href="http://waymarkedtrails.org">Sarah Hoffmann</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
    access: ACCESS_SUPER,
    maxZoom: 18,
  },
  {
    id: 'railway',
    url: 'https://{s}.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png',
    attribution:
      '<a href="https://www.openstreetmap.org/copyright">© OpenStreetMap contributors</a>, Style: <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA 2.0</a> <a href="http://www.openrailwaymap.org/">OpenRailwayMap</a> and OpenStreetMap',
    minZoom: 2,
    maxZoom: 19,
    overlay: true,
    access: ACCESS_NONE,
  },
];

const mapTypesById = {};
mapTypes.forEach(tp => {
  mapTypesById[tp.id] = tp;
});

export const defaultMap = 'stadia';

export function strava(travelMode, color = 'bluered') {
  const modeOptions = {
    [TRAVELMODE_WALKING]: 'run',
    [TRAVELMODE_CYCLING]: 'ride',
    [TRAVELMODE_STRAIGHT]: 'all',
  };
  const mode = modeOptions[travelMode] || 'running';
  return {
    id: 'strava',
    name: 'Strava HeatMap',
    url: `https://heatmap-external-{s}.strava.com/tiles/${mode}/${color}/{z}/{x}/{y}.png?px=256`,
    attribution: `&copy; ${new Date().getFullYear()} Strava`,
    overlay: true,
    access: ACCESS_PUBLIC,
    maxZoom: 12,
  };
}

function checkAccess(type, options) {
  const { pm, su } = options.access;
  if (su) {
    return type.access >= ACCESS_SUPER;
  }
  if (pm) {
    return type.access >= ACCESS_PREMIUM;
  }
  return type.access >= ACCESS_PUBLIC;
}

export function getOverlays(options) {
  if (!options.travelMode) {
    return [];
  }
  return [...mapTypes, strava(options.travelMode)].filter(
    type => type.overlay && checkAccess(type, options),
  );
}

function getMapTypeString(options) {
  const typeString = 'stadia|stamenTerrain|openbusmap|worldImagery';
  const premiumString = typeString.replace(
    'openbusmap',
    'mapy|openCycle|outdoors|landscape|transport|hereHybrid',
  );
  const superString = `${premiumString}|openstreetmap|worldTopo`;
  const { pm, su } = options.access;
  if (su) {
    return superString;
  }
  return pm ? premiumString : typeString;
}

export function getMapTypes(options) {
  return getMapTypeString(options)
    .split('|')
    .map(id => mapTypesById[id]);
}
