/*
 * Ranking Messages
 *
 * This contains all the text for the Ranking component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.KmVreters.Ranking.title',
    defaultMessage: 'Ranking',
  },
  clubRanking: {
    id: 'app.components.KmVreters.Ranking.clubRanking',
    defaultMessage: 'Ranking of {club}',
  },
  name: {
    id: 'app.components.KmVreters.Ranking.name',
    defaultMessage: 'Name',
  },
  club: {
    id: 'app.components.KmVreters.Ranking.club',
    defaultMessage: 'Club',
  },
  category: {
    id: 'app.components.KmVreters.Ranking.category',
    defaultMessage: 'Cat.',
  },
  number: {
    id: 'app.components.KmVreters.List.number',
    defaultMessage: 'Number',
  },
  distance: {
    id: 'app.components.KmVreters.List.distance',
    defaultMessage: 'Distance',
  },
  noProfile: {
    id: 'app.components.KmVreters.Ranking.noProfile',
    defaultMessage:
      '<a href="#profile">Complete your profile</a> to see your races in this list',
  },
  noRace: {
    id: 'app.components.KmVreters.Ranking.noRace',
    defaultMessage:
      'You have not entered any <a href="#list">races with a link</a> for this year',
  },
  forYear: {
    id: 'app.components.KmVreters.Ranking.forYear',
    defaultMessage:
      'This is the ranking for {year}. View <a href="#ranking">current ranking</a>',
  },
  prevYears: {
    id: 'app.components.KmVreters.Ranking.prevYears',
    defaultMessage: 'View rankings of previous years: ',
  },
  select: {
    id: 'app.components.KmVreters.Ranking.select',
    defaultMessage: 'Select…',
  },
  pbList: {
    id: 'app.components.Kmvreters.PrList.title',
    defaultMessage: 'PBs of members of {club}',
  },
});
