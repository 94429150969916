/**
 *
 * UserGroups
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { lightBlack } from 'styles/colors';
import Spinner from 'components/Spinner';
import { useRequest } from 'utils/request';
import storage from 'utils/storage';
// import { H2 } from 'components/Virtual/styleComponents';
import messages from './messages';

const Wrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  .btn {
    margin-bottom: 1rem;
    span {
      margin-left: 1rem;
      font-size: small;
      color: ${lightBlack};
    }
  }
`;

function UserGroups(props) {
  const { user } = props;
  if (!user.api) {
    return null;
  }
  const [groups] = useRequest({ url: `/res/groups`, user });
  if (groups.loading || user.loading) {
    return <Spinner />;
  }
  storage.setItem('showReservations', groups.length > 0);
  if (groups.length === 0) {
    return null;
  }
  storage.removeItem('resGroups');
  return (
    <Wrapper>
      {groups.map(group => (
        <Link
          key={group.hash}
          to={`/inschrijven/${group.hash}`}
          className="btn btn-default"
        >
          <b>{group.name}</b>
          {[
            group.userId === user.api.userId && messages.owner,
            group.memberId && messages.member,
          ]
            .filter(Boolean)
            .map(msg => <FormattedMessage key={msg.id} {...msg} />)}
        </Link>
      ))}
    </Wrapper>
  );
}

UserGroups.propTypes = {
  user: PropTypes.object,
};

export default UserGroups;
