/*
 * ReservationsPage Messages
 *
 * This contains all the text for the ReservationsPage component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.ReservationsPage.title',
    defaultMessage: 'RunnerMaps Reservations Races',
  },
  description: {
    id: 'app.components.ReservationsPage.description',
    defaultMessage: 'Join a virtual race and run the distance with Strava',
  },
});
