import React from 'react';
import PropTypes from 'prop-types';
import useSelectContext from './useSelectContext';

function SelectCheckBox({ item }) {
  const { items, itemSet, setItems } = useSelectContext();
  const checked = itemSet.has(item.id);
  function handleChange() {
    setItems(checked ? items.filter(i => i.id !== item.id) : [...items, item]);
  }
  return (
    <input
      type="checkbox"
      checked={checked}
      onChange={handleChange}
      disabled={!checked && items.length >= 2}
    />
  );
}

SelectCheckBox.propTypes = {
  item: PropTypes.object.isRequired,
};

export default SelectCheckBox;
