import { MILES_KM } from 'utils/constants';
import round10 from 'utils/round10';
import formatTime from 'utils/formatTime';

function getGraphData(list, units) {
  const conv = units === 'km' ? 1 : MILES_KM;
  return list.map(item => ({
    quartiles: item.number > 3,
    values: {
      distance: `${item.distance} ${units}`,
      best: formatTime(item.times[0]),
      q1: formatTime(item.times[1]),
      q2: formatTime(item.times[2]),
    },
    data: [
      item.distance,
      ...item.speeds.map(speed => round10(speed * conv, -1)),
    ],
  }));
}

export default getGraphData;
