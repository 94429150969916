/**
 *
 * AccountModal
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Alert, Button, Modal } from 'react-bootstrap';
import { green500 } from 'styles/colors';
import { useCurrentUser2 } from 'containers/User';
import ConfirmButton from 'components/ConfirmButton';
import { useRequest, request2 } from 'utils/request';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'components/Spinner';
import messages from './messages';
import LoginAlert from '../LoginAlert';

const AlertWrapper = styled.div`
  border: 1px solid ${green500};
`;

function labelRaces(count) {
  return (
    count && (
      <FormattedMessage key="n" {...messages.nRaces} values={{ count }} />
    )
  );
}

function getProfile(data) {
  const { nRoutes, profile, strava } = data;
  const result = [];
  if (nRoutes) {
    result.push({ key: 'nRoutes', value: nRoutes });
  }
  if (profile) {
    const { name, sex, yob, club, kmvRuns } = profile;
    const value = [name || <FormattedMessage key="p" {...messages.private} />];
    if (sex) {
      value.push([' (', <FormattedMessage key="s" {...messages[sex]} />, ')']);
    }
    if (yob) {
      value.push([', ', yob]);
    }
    if (club) {
      value.push([', ', club]);
    }
    if (kmvRuns) {
      value.push([', ', labelRaces(kmvRuns)]);
    }
    result.push({ key: 'kmvProfile', value });
  }
  if (strava) {
    const { firstname, lastname, sex, vRaces } = strava;
    const value = [`${firstname} ${lastname}`];
    if (sex) {
      value.push([
        ' (',
        <FormattedMessage key="sex" {...messages[sex]} />,
        ')',
      ]);
    }
    if (vRaces) {
      value.push([', ', labelRaces(vRaces)]);
    }
    result.push({ key: 'stravaProfile', value });
  }
  return result;
}

function AccountModal(props) {
  const { onHide, param } = props;
  const [user, update] = useCurrentUser2();
  const [userData] = useRequest({ url: user.api && '/user/data', user });
  const showButton = user.api && !user.pm;
  function handleCancel() {
    request2({
      method: 'DELETE',
      url: `/user/subscribe`,
      user,
    }).then(() => {
      update(['api', 'ar'], 0);
    });
  }
  return (
    <Modal show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage {...messages.header} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {param && (
          <Alert bsStyle="success">
            <FormattedMessage {...messages.paymentOK} />
          </Alert>
        )}
        {user.api ? (
          <dl className="dl-horizontal">
            <dt>
              <FormattedMessage {...messages.email} />
            </dt>
            <dd className="mb-3">{user.email}</dd>
            {user.pm && (
              <React.Fragment>
                <dt>
                  <FormattedMessage {...messages.premiumUntil} />
                </dt>
                <dd className="mb-3">
                  {user.premiumDate}
                  <span className="ml-3 font-italic green500">
                    <FormattedMessage {...messages.thanks} />
                  </span>
                  <FontAwesomeIcon
                    icon={['far', 'thumbs-up']}
                    className="ml-2 mr-3 red500"
                  />
                  {user.api.ar ? (
                    <div className="mt-1 text-muted">
                      <span className="mr-3">
                        <FormattedMessage {...messages.recurring} />
                      </span>
                      <ConfirmButton
                        bsStyle="default"
                        bsSize="xs"
                        onClick={handleCancel}
                        confirmationText={messages.confirmCancel}
                      >
                        <FormattedMessage {...messages.cancelRecurring} />
                      </ConfirmButton>
                    </div>
                  ) : (
                    <Button bsStyle="default" bsSize="xs" href="#subscription">
                      <FormattedMessage {...messages.extend} />
                    </Button>
                  )}
                </dd>
              </React.Fragment>
            )}
            {!userData || userData.loading ? (
              <Spinner />
            ) : (
              getProfile(userData).map(o => (
                <React.Fragment key={o.key}>
                  <dt>
                    <FormattedMessage {...messages[o.key]} />
                  </dt>
                  <dd className="mb-3">{o.value}</dd>
                </React.Fragment>
              ))
            )}
          </dl>
        ) : (
          <LoginAlert />
        )}
        {showButton && (
          <AlertWrapper className="p-4 mx-sm-5 my-3">
            <div className="mb-4 mx-sm-4">
              <FormattedHTMLMessage {...messages.supporterText} />
            </div>
            <div className="text-center my-3">
              <Button bsStyle="success" href="#subscription">
                <FormattedMessage {...messages.supporterAction} />
              </Button>
            </div>
          </AlertWrapper>
        )}
      </Modal.Body>
    </Modal>
  );
}

AccountModal.propTypes = {
  param: PropTypes.string,
  onHide: PropTypes.func.isRequired,
};

export default AccountModal;
