/**
 *
 * SupportLink
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { green500 } from 'styles/colors';

import messages from './messages';

const Wrapper = styled(Button)`
  &.btn {
    text-align: left;
  }
  svg {
    color: ${green500};
  }
`;

function SupportLink(props) {
  const { className } = props;
  return (
    <Wrapper
      bsStyle="link"
      className={className}
      href="https://runnermaps.freshdesk.com/"
    >
      <FontAwesomeIcon icon="question" size="lg" fixedWidth />
      <FormattedMessage {...messages.help} />
    </Wrapper>
  );
}

SupportLink.propTypes = {
  className: PropTypes.string,
};

export default SupportLink;
