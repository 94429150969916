import { createSelector } from 'reselect';
import { initialState } from './reducerBuilder';

/**
 * Direct selector to the rest state domain
 */

const selectRestDomain = entity => state => state.get(entity, initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Rest
 */

const makeSelectRest = entity => () =>
  createSelector(selectRestDomain(entity), substate => substate.toJS());

export default makeSelectRest;
export { selectRestDomain };
