import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import { push } from 'connected-react-router';
import { addAlert } from 'containers/AlertService/actions';
import { editClear } from 'containers/EditService/actions';
import { PATH_ROUTE } from 'containers/App/constants';
import makePath from 'utils/makePath';
import { API_URL } from 'config/constants';

import {
  GET_ENTITY,
  LIST_ENTITY,
  CHANGE_ENTITY,
  CHANGE_ENTITY_SUCCESS,
} from './constants';
import makeSelectUser from '../User/selectors';

function* authHeader() {
  const user = yield select(makeSelectUser());
  const headers = {};
  if (user && user.api) {
    headers.Authorization = user.api.hashKey;
  }
  return headers;
}

export function* makeRequest(action) {
  const { type, url, options } = action;
  const headers = yield authHeader();
  const fullUrl = `${API_URL}/${url}`;
  yield put({ ...action, type: `${type}_REQUEST` });
  const requestOptions = {
    method: options.method,
    ...options.params,
    body: options.body && JSON.stringify(options.body),
    headers,
  };
  try {
    const result = yield request(fullUrl, requestOptions);
    yield put({ ...action, type: `${type}_SUCCESS`, payload: result });
  } catch (error) {
    yield put({ ...action, type: `${type}_ERROR`, error });
  }
}

export function* redirect(action) {
  const { options, payload } = action;
  if (options.entity === 'routes' && payload) {
    yield put(editClear());
    const link = makePath(PATH_ROUTE, { routeId: payload.id });
    yield put(push(link));
  }
}

export function* handleResponse(prop, style, response) {
  const responseProp =
    (response && response.status && prop[response.status]) || prop;
  if (typeof responseProp === 'function') {
    responseProp();
    return;
  }
  if (typeof responseProp === 'string' || responseProp.id) {
    yield put(addAlert({ title: responseProp, style }));
  }
}

export function* handleSuccess(action) {
  const { options } = action;
  if (options && options.onSuccess) {
    yield handleResponse(options.onSuccess, 'success');
  }
}

export function* handleError(action) {
  const { options, error } = action;
  if (options && options.onError) {
    yield handleResponse(options.onError, 'danger', error.response);
  }
}

// Individual exports for testing
export default function* defaultSaga() {
  yield takeEvery(GET_ENTITY, makeRequest);
  yield takeLatest(LIST_ENTITY, makeRequest);
  yield takeEvery(CHANGE_ENTITY, makeRequest);
  yield takeEvery(CHANGE_ENTITY_SUCCESS, redirect);
  yield takeEvery(match(/_ERROR$/), handleError);
  yield takeEvery(match(/_SUCCESS$/), handleSuccess);
}

function match(re) {
  return action => re.test(action.type);
}
