import React from 'react';
import { Alert } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';
import useCurrentUser from 'containers/User/useCurrentUser';
import Spinner from 'components/Spinner';
import messages from './messages';

function LoginAlert() {
  const user = useCurrentUser();
  if (user.loading) {
    return <Spinner />;
  }
  return (
    <Alert variant="info">
      <FormattedHTMLMessage {...messages.login} />
    </Alert>
  );
}

export default LoginAlert;
