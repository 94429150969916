// From: https://redux.js.org/docs/advanced/Middleware.html
import memorySizeOf from 'utils/memorySizeOf';

const logger = store => next => action => {
  const result = next(action);
  if (process && process.env && process.env.NODE_ENV === 'development') {
    const state = store.getState().toJS();
    // eslint-disable-next-line no-console
    console.log(action.type.replace(/.*\//, 'action '), {
      action,
      state,
      size: memorySizeOf(state),
    });
  }
  return result;
};

export default logger;
