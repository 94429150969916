import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import messages from './messages';

const TAB_KEYS = ['allRaces', 'byDistance', 'byMonth', 'byYear'];

export const tabAttribs = TAB_KEYS.reduce(
  (acc, key) => ({
    ...acc,
    [key]: {
      eventKey: key,
      title: (
        <React.Fragment>
          <FormattedMessage {...messages[key]} />
          {key !== 'allRaces' && <FontAwesomeIcon icon="chart-bar" />}
        </React.Fragment>
      ),
    },
  }),
  {},
);

export function selectTab(tab) {
  if (!tab) {
    return TAB_KEYS[0];
  }
  const result = TAB_KEYS.find(key => tab === key.toLowerCase());
  return result || TAB_KEYS[0];
}
