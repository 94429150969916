/**
 *
 * CsvLink
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { format } from 'date-fns';
import formatTime from 'utils/formatTime';
import toCSV from 'utils/toCSV';
import messages from '../messages';

const getDefinition = intl => [
  { title: intl.formatMessage(messages.ranking), getValue: o => o.pos },
  {
    title: intl.formatMessage(messages.name),
    getValue: o => `${o.firstname} ${o.lastname}`,
  },
  {
    title: intl.formatMessage(messages.raceTime),
    getValue: o => formatTime(o.race_time, 1, true),
  },
  {
    title: intl.formatMessage(messages.startDate),
    getValue: o => format(new Date(o.start_date), 'yyyy-MM-dd HH:mm:ss'),
  },
  {
    title: `${intl.formatMessage(messages.distance)} (km)`,
    getValue: o => (o.distance / 1000).toFixed(2),
  },
  {
    title: intl.formatMessage(messages.elapsedTime),
    getValue: o => formatTime(o.elapsed_time, 0, true),
  },
];

function CsvLink(props) {
  const { data, filename, children, intl } = props;
  const csv = toCSV(data.filter(o => o.activity_id), getDefinition(intl));
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  return (
    <div className="">
      <a
        className="btn btn-link btn-sm pt-0 ml-3 mb-3"
        href={url}
        download={filename}
      >
        {children}
      </a>
    </div>
  );
}

CsvLink.propTypes = {
  data: PropTypes.array.isRequired,
  filename: PropTypes.string.isRequired,
  intl: intlShape,
  children: PropTypes.node,
};

CsvLink.defaultProps = {
  children: 'CSV,',
};

export default injectIntl(CsvLink);
