import { useState } from 'react';
import useStorage from 'utils/storage/useStorage';

function useSettings() {
  const [refDist, setRefDist] = useStorage('refDist', 10000);
  const [period, setPeriod] = useStorage('refPeriod', 'all');
  const [xType, setXType] = useState('number');

  return {
    refDist,
    setRefDist,
    period,
    setPeriod,
    xType,
    setXType,
  };
}

export default useSettings;
