/**
 *
 * Choice
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Radio } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IntlString from 'components/IntlString';
import { green500 } from 'styles/colors';

const Wrapper = styled.div`
  display: flex;
`;

const Label = styled.div`
  font-weight: bold;
  flex-grow: 1;
`;

const Items = styled.div`
  text-align: right;
  .fa-check {
    color: ${green500};
    margin-right: 5px;
  }
  .radio {
    margin-top: 0;
  }
  input {
    opacity: 0;
    position: absolute;
  }
  div.radio:not(.active) span {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  div.radio.active span {
    font-weight: bold;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
`;

class Choice extends React.PureComponent {
  handleChange = value => {
    const { onChange } = this.props;
    return () => onChange(value);
  };

  render() {
    const { id, label, items, value } = this.props;
    return (
      <Wrapper>
        <Label>
          <IntlString message={label} />
        </Label>
        <Items>
          {items.map(item => (
            <Radio
              key={[id, item.value]}
              name={id}
              className={item.value === value ? 'active' : ''}
              checked={item.value === value}
              onChange={this.handleChange(item.value)}
            >
              {item.value === value && <FontAwesomeIcon icon="check" />}
              <IntlString message={item.label} />
            </Radio>
          ))}
        </Items>
      </Wrapper>
    );
  }
}

Choice.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  value: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Choice;
