import ReactGA from 'react-ga4';
import parseSearch from 'utils/parseSearch';

const pending = {};

const delay = 10000;

const Track = {
  ...ReactGA,
  collect,
};

function sendEvent(key) {
  return () => {
    const item = pending[key];
    const options = {
      ...item.options,
      value: item.value,
    };
    Track.event(options);
    delete pending[key];
  };
}

function collect(options) {
  const { category, action, label, nonInteraction } = options;
  const key = [category, action, label, nonInteraction].join('|');
  if (pending[key]) {
    pending[key].value++;
    return;
  }
  pending[key] = { options, value: 1 };
  window.setTimeout(sendEvent(key), delay);
}

function gaLog(func) {
  return args => {
    console.log('GA', func, args); // eslint-disable-line no-console
    ReactGA[func](args);
  };
}

const { track } = parseSearch();
if (track) {
  track.split(',').forEach(func => {
    Track[func] = gaLog(func);
  });
}

export default Track;
