/**
 * get statistical description of list of number
 * @param  {array} list of numbers
 * @return {object} containing min, max, median, q1, q3
 */

function getQuartiles(list) {
  const sortedList = list.slice(0).sort((a, b) => Math.sign(a - b));
  switch (list.length) {
    case 1:
      return [list[0], list[0], list[0], list[0]];
    case 2:
      return [sortedList[0], sortedList[0], sortedList[1], sortedList[1]];
    default:
  }
  const p1 = (list.length + 1) / 4;
  const p3 = 3 * p1;
  return [
    sortedList[0],
    getInterPolate(p1, sortedList),
    getInterPolate(p3, sortedList),
    sortedList[list.length - 1],
  ];
}

function getInterPolate(p, list) {
  const t = Math.trunc(p);
  const f = p - t;
  let result = list[t - 1];
  if (f !== 0) {
    result += (list[t] - list[t - 1]) * f;
  }
  return result;
}

export default getQuartiles;
