/*
 *
 * AlertService reducer
 *
 */

import { fromJS } from 'immutable';
import { ALERT_ADD, ALERT_DISMISS } from './constants';

export const initialState = fromJS([]);

function alertServiceReducer(state = initialState, action) {
  switch (action.type) {
    case ALERT_ADD:
      return state
        .filter(alert => alert.title !== action.options.title)
        .push(action.options);
    case ALERT_DISMISS:
      return state.filter(alert => alert.id !== action.id);
    default:
      return state;
  }
}

export default alertServiceReducer;
