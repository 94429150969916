/**
 *
 * KmVreters
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';

import { FormattedMessage } from 'react-intl';
import messages from './messages';

function KmVreters() {
  return (
    <div>
      <FormattedMessage {...messages.title} />
    </div>
  );
}

KmVreters.propTypes = {};

export default KmVreters;
export { default as Edit } from './Edit';
export { default as Import } from './Import';
export { default as List } from './List';
export { default as Profile } from './Profile';
export { default as Ranking } from './Ranking';
export { default as PrList } from './PrList';
export { default as Overview } from './Overview';
