/**
 *
 * Checkbox -- adds className 'checked' to checked items
 *  Can use attribute 'value' instead of 'checked'
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import pick from 'lodash/pick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { grey500, green500 } from 'styles/colors';

const Wrapper = styled.button`
  &.checkbox {
    display: flex;
    & > svg {
      margin-right: 6px;
      &.fa-square {
        color: ${grey500};
      }
      &.fa-check-square {
        color: ${green500};
      }
    }
    opacity: ${p => (p.disabled ? 0.5 : 1)};
    padding: 0;
  }
`;

class Checkbox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  getValue() {
    const { checked, value } = this.props;
    return checked !== undefined ? checked : value;
  }

  handleChange() {
    const { value, checked, onChange, disabled, id } = this.props;
    if (disabled) {
      return;
    }
    if (checked === undefined) {
      onChange({ target: { type: 'checkbox', id, value: !value } });
    } else {
      // Pretend to be a checkbox
      onChange({ target: { type: 'checkbox', id, value, checked: !checked } });
    }
  }

  render() {
    const { children, className, bsSize } = this.props;
    const value = this.getValue();
    const icon = value ? 'check-square' : ['far', 'square'];
    const classes = className.split(' ');
    if (value) {
      classes.push('checked');
    }
    classes.push('checkbox');
    const wrapperProps = pick(this.props, ['center', 'color', 'disabled']);
    const size = bsSize === 'large' ? '24px' : '18px';
    return (
      <Wrapper
        {...wrapperProps}
        type="button"
        role="checkbox"
        aria-checked={value}
        size={size}
        className={classes.join(' ')}
        onClick={this.handleChange}
      >
        <FontAwesomeIcon size="lg" icon={icon} />
        {children}
      </Wrapper>
    );
  }
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  bsSize: PropTypes.string,
  value: PropTypes.any,
  children: PropTypes.node,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  className: '',
};

export default Checkbox;
