import React from 'react';
import { PropTypes } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from '../../containers/User';
import UserInfo from './UserInfo';
import Wrapper from './Wrapper';
import UserList from './UserList';

function Manage(props) {
  const { api, su } = useCurrentUser();
  const { replace } = useHistory();
  if (!api) {
    return null;
  }
  if (!su) {
    replace('/home');
    return null;
  }
  const { userId } = props.match.params;
  return (
    <Wrapper>
      {userId ? <UserInfo userId={Number(userId)} /> : <UserList />}
    </Wrapper>
  );
}

Manage.propTypes = {
  match: PropTypes.object.isRequired,
};
export default Manage;
