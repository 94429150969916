/**
 *
 * PoweredByStrava
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import poweredByStrava from 'images/api_logo_pwrdBy_strava_horiz_gray.png';

const Wrapper = styled.div`
  text-align: center;
  img {
    transform: scale(${p => p.scale});
  }
`;

function PoweredByStrava(props) {
  const { scale } = props;
  return (
    <Wrapper scale={scale}>
      <div className="text-center">
        <img src={poweredByStrava} alt="Powered by Strava" />
      </div>
    </Wrapper>
  );
}

PoweredByStrava.propTypes = {
  scale: PropTypes.number,
};

PoweredByStrava.defaultProps = {
  scale: 0.5,
};

export default PoweredByStrava;
