export const text1 = {
  nl: `
RunnerMaps bestaat 12,5 jaar! Dat gaan we vieren met een jubileumwedstrijd op **23 augustus**. Virtueel, zo moet dat nu even.

Een mooie gelegenheid om jezelf weer eens te testen op je favoriete afstand:

`,
  en: `
RunnerMaps exists for 12.5 years! We're going to celebrate this with a celebration race on **August 23**. Virtual, that's how it has to be these days.

A nice occasion to challenge yourself again on your favourite distance:
`,
};

export const text2 = {
  nl: `
## Prijzen

- Iedere 10e finisher wint een jaar RunnerMaps Premium: geen advertenties en toegang tot meer kaarten.
- De nummers 1, 2 en 3 winnen resp. 3, 2 en 1 jaar lang RunnerMaps Premium.

## Premium?

Binnenkort wordt het voor iedereen mogelijk om RunnerMaps zonder advertenties te gebruiken (dus meer ruimte op je scherm) en toegang te krijgen tot meer ([Thunderforest](https://www.thunderforest.com/maps/)) kaarten. Met een jaarlijkse bijdrage ondersteun je dan ook de verdere ontwikkeling van RunnerMaps.

Natuurlijk blijft de gratis versie zoals deze nu is ook bestaan.
`,
  en: `
## Prizes

- Every 10th finisher wins a year RunnerMaps Premium: no advertisements and access to more map styles.
- The numbers 1, 2, and 3 win 3, 2, and 1 year RunnerMaps Premium.

## Premium?
Soon it will be possible for everyone use RunnerMaps without advertisements, so more space on your screen, and access to more ([Thunderforest](https://www.thunderforest.com/maps/)) map styles. For an annual contribution you will support the further development of RunnerMaps.

Of course, the free version as it is now, will continue to exists.
`,
};
