/* global L */
function leafletIcon(options) {
  const defaultOptions = {
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  };
  const combined = { ...defaultOptions, ...options };
  const size = combined.iconSize;
  const anchors = {
    iconAnchor: [size[0] / 2, size[1]],
    popupAnchor: [0, -size[1]],
  };
  return L.icon({ ...anchors, ...options });
}

export default leafletIcon;
