/**
 *
 * ListRaces
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { green500 } from 'styles/colors';
import usePagination from 'components/Pagination/usePagination';
import formatTime from 'utils/formatTime';
import { PAGESIZE } from 'utils/constants';
import compareDate from 'utils/compareDate';
import DateString from '../DateString';

const Wrapper = styled.div`
  .pagination-text {
    margin-left: 10px;
  }
  .td-num {
    width: 4em;
    text-align: right;
    white-space: nowrap;
  }
  .td-date {
    width: 6em;
    text-align: right;
  }
  svg[data-icon='check'] {
    color: ${green500};
  }
  tr.row-past {
    opacity: 0.65;
  }
  td .btn {
    padding-top: 0;
    padding-bottom: 0;
  }
  @media (min-width: 480px) {
    .td-num {
      width: 5em;
    }
    .td-date {
      width: 7em;
    }
  }
  @media (min-width: 768px) {
    .td-num {
      width: 8em;
    }
    .td-date {
      width: 10em;
    }
  }
`;

function ListRaces(props) {
  const { races, caption, type, pageSize, onClickPrivate } = props;
  const { slice, Paginator, paginationText } = usePagination(
    races.length,
    pageSize,
  );
  if (!races || races.loading || races.length === 0) {
    return null;
  }
  const lastColumn = race => {
    switch (type) {
      case 'upcoming':
        return race.athlete_id && <FontAwesomeIcon size="lg" icon="check" />;
      case 'ran':
        return race.race_time && <b>{formatTime(race.race_time)}</b>;
      case 'created':
        return race.number || '-';
      case 'all':
        return race.registered ? `${race.finished} / ${race.registered}` : '-';
      default:
        return null;
    }
  };
  const muteRace = race => type === 'created' && compareDate(race.date) < 0;
  return (
    <Wrapper>
      <Table condensed hover>
        {caption && (
          <caption>
            <FormattedMessage {...caption} />
            {paginationText}
          </caption>
        )}
        <tbody>
          {slice(races).map(race => (
            <tr key={race.hash} className={muteRace(race) ? 'row-past' : ''}>
              <td>
                <Link to={`/virtual/${race.hash}`}>
                  <b>{race.title}</b>
                </Link>
                {Boolean(race.own_private) && (
                  <Button bsStyle="link" onClick={onClickPrivate}>
                    <FontAwesomeIcon icon="info" className="red500" />
                  </Button>
                )}
              </td>
              <td className="td-num">{`${race.distance / 1000} km`}</td>
              <td className="td-date">
                <DateString
                  date={race.date}
                  endDate={race.end_date}
                  format="d MMM"
                />
              </td>
              <td className="td-num">{lastColumn(race)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Paginator />
    </Wrapper>
  );
}

ListRaces.propTypes = {
  caption: PropTypes.object,
  races: PropTypes.array,
  type: PropTypes.string,
  pageSize: PropTypes.number,
  onClickPrivate: PropTypes.func,
};

ListRaces.defaultProps = {
  pageSize: PAGESIZE,
};

export default ListRaces;
