/**
 *
 * Firebase authentication
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import Auth from 'components/Auth';
import AskEmail from 'components/AskEmail';
import { DAEMON } from 'utils/constants';
import storage from 'utils/storage';
import firebase from './firebase';
import { userLoading, userChange, userConfirmSignUp } from './actions';
import makeSelectUser from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import withRoute from '../withRoute';

class User extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { askEmail: this.isSignIn() && !storage.getItem('email') };
    this.gotEmail = this.gotEmail.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  isSignIn() {
    return firebase.auth().isSignInWithEmailLink(window.location.href);
  }

  componentDidMount() {
    const { loading, stateChange, confirmSignUp, user } = this.props;
    if (user.auth) {
      return;
    }
    loading();
    firebase.auth().onAuthStateChanged(usr => {
      stateChange(usr);
    });
    const email = storage.getItem('email');
    if (this.isSignIn() && email) {
      confirmSignUp(email);
    }
  }

  gotEmail(email) {
    const { confirmSignUp } = this.props;
    confirmSignUp(email);
    this.setState({ askEmail: false });
  }

  handleCancel() {
    this.setState({ askEmail: false });
  }

  render() {
    const { children } = this.props;
    const { askEmail } = this.state;
    return (
      <React.Fragment>
        {askEmail && (
          <AskEmail
            onSubmit={this.gotEmail}
            onCancel={this.handleCancel}
            intro={<FormattedMessage {...messages.emailIntro} />}
            submitLabel={<FormattedMessage {...messages.emailConfirm} />}
          />
        )}
        {window.location.hash === '#login' && <Auth />}
        {children}
      </React.Fragment>
    );
  }
}

User.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.object.isRequired,
  loading: PropTypes.func.isRequired,
  stateChange: PropTypes.func.isRequired,
  confirmSignUp: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

function mapDispatchToProps(dispatch) {
  return {
    loading: () => dispatch(userLoading(true)),
    stateChange: user => dispatch(userChange(user)),
    confirmSignUp: email => dispatch(userConfirmSignUp(email)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'user', reducer });
const withSaga = injectSaga({ key: 'user', saga, mode: DAEMON });

export default compose(
  withRoute,
  withReducer,
  withSaga,
  withConnect,
)(User);

export { default as useCurrentUser2 } from './useCurrentUser2';
export { default as useCurrentUser } from './useCurrentUser';
export { default as useStrava } from './useStrava';
