/*
 *
 * Rest reducer
 *
 */

import { fromJS } from 'immutable';
import { idList, idObject } from 'utils/arrays';
import {
  GET_ENTITY_REQUEST,
  GET_ENTITY_ERROR,
  GET_ENTITY_SUCCESS,
  LIST_ENTITY_REQUEST,
  LIST_ENTITY_ERROR,
  LIST_ENTITY_SUCCESS,
  RESET_ENTITY,
} from './constants';

export const initialState = fromJS({});

function reducerBuilder(entity) {
  return function reducer(state = initialState, action) {
    const { type, options, payload, error } = action;
    if (!type.startsWith('app/Rest/') || options.entity !== entity) {
      return state;
    }
    switch (action.type) {
      case GET_ENTITY_REQUEST:
        return state.merge({ current: { loading: true } });
      case GET_ENTITY_SUCCESS:
        return state.merge({
          current: { ...payload, loading: false },
        });
      case GET_ENTITY_ERROR:
        console.error('fail', action, error); // eslint-disable-line no-console
        return state.merge({ current: { ...options, error, loading: false } });
      case LIST_ENTITY_REQUEST:
        return state.set('loading', true);
      case LIST_ENTITY_SUCCESS:
        return state.filter((v, k) => k === 'current').merge({
          ...idObject(payload),
          list: idList(payload),
          loading: false,
        });
      case LIST_ENTITY_ERROR:
        return state.set('loading', false);
      case RESET_ENTITY:
        return initialState;
      default:
        return state;
    }
  };
}

export default reducerBuilder;
