/**
 *
 * PrimaryAction
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Button, ButtonToolbar } from 'react-bootstrap';
import Flex from 'components/Flex';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const PrimaryBar = styled(ButtonToolbar)`
  &.btn-toolbar {
    display: flex;
    & > .btn + .btn {
      margin-left: 15px;
    }
  }
`;

function PrimaryAction(props) {
  const { submitText, valid, submitted, onSubmit, onCancel, children } = props;
  const finalSubmitText = submitText.id ? submitText : messages[submitText];
  const history = useHistory();
  function handleCancel() {
    if (typeof onCancel === 'string') {
      history.push(onCancel);
    } else {
      onCancel();
    }
  }
  return (
    <Flex center spaced>
      <div>{children}</div>
      <PrimaryBar>
        {onCancel && (
          <Button disabled={submitted} onClick={handleCancel}>
            <FormattedMessage {...messages.cancel} />
          </Button>
        )}
        <Button
          bsStyle="success"
          disabled={!valid || submitted}
          type="submit"
          onClick={onSubmit}
        >
          <FormattedMessage {...finalSubmitText} />
        </Button>
      </PrimaryBar>
    </Flex>
  );
}

PrimaryAction.propTypes = {
  onSubmit: PropTypes.func, // usually handled by form submit
  onCancel: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  submitText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  submitted: PropTypes.bool,
  valid: PropTypes.bool,
  children: PropTypes.node,
};

PrimaryAction.defaultProps = {
  submitText: 'save',
  valid: true,
};

export default PrimaryAction;
