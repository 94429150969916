import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import cx from '../../utils/cx';
import getContent from './getContent';

function makeLink(url, content) {
  if (url.startsWith('http')) {
    return <a href={url}>{content}</a>;
  }
  return <Link to={url}>{content}</Link>;
}

function DataCell(props) {
  const { item, field, className } = props;
  const { key, getLink, format, style } = field;

  const value = item[key];

  if (value === undefined || value === null) {
    return <td> </td>;
  }

  const alignment = typeof value === 'number' ? 'text-right' : 'text-left';

  const content = getContent(value, format);

  return (
    <td className={className || cx(alignment, field.className)} style={style}>
      {getLink ? makeLink(getLink(content, item), content) : content}
    </td>
  );
}

DataCell.propTypes = {
  item: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default DataCell;
