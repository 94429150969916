/*
* Period
* Date or period picker
*/

import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
// import { Alert, Button } from 'react-bootstrap';
import produce from 'immer';
import { addDays, differenceInCalendarDays, parseISO } from 'date-fns';
import Checkbox from 'components/Checkbox';

import { FormattedMessage } from 'react-intl';
import controlValue from 'components/FormField/controlValue';
import { dateFormat } from 'utils/datetime';
import messages from '../messages';

function shiftDate(e0, s0, s1) {
  const diff = differenceInCalendarDays(parseISO(s1), parseISO(s0));
  return dateFormat(addDays(parseISO(e0), diff));
}

// Note: compare always returns false if second op is undefined
function latest(d1, d2) {
  return d1 > d2 ? d1 : d2 || d1;
}

function Period(props) {
  const { value, onChange, expandOnly } = props;
  const { date, endDate } = value;

  function init() {
    return {
      startMax: expandOnly ? date : undefined,
      endMin: expandOnly ? endDate || date : undefined,
    };
  }

  const reducer = produce((draft, action) => {
    const { key, val } = action;
    const result = { date, endDate };
    switch (key) {
      case 'date':
        result.date = val;
        if (endDate) {
          result.endDate = latest(
            shiftDate(endDate, date, val),
            expandOnly && draft.endMin,
          );
        }
        break;
      case 'endDate':
        result.endDate = val;
        draft.multiDay = Boolean(val) && val !== draft.date;
        break;
      case 'multiDay':
        result.endDate = val ? dateFormat(addDays(parseISO(date), 1)) : date;
        break;
      default:
    }
    draft[key] = val;
    onChange(result);
  });

  const [state, dispatch] = useReducer(reducer, props, init);
  const { startMax, endMin } = state;
  const startMin = dateFormat(new Date());
  const multiDay = endDate && endDate !== date;
  const setState = key => val => {
    dispatch({
      key,
      val: controlValue(val),
      onChange,
      date,
      endDate,
    });
  };
  return (
    <div className="d-flex align-items-center">
      <input
        type="date"
        className="form-control input-short mr-3"
        value={date || ''}
        onChange={setState('date')}
        min={startMin}
        max={startMax}
      />
      {multiDay ? (
        <React.Fragment>
          <FormattedMessage {...messages.until} />
          <input
            type="date"
            className="form-control input-short ml-3"
            value={endDate || ''}
            onChange={setState('endDate')}
            min={latest(endMin, date)}
          />
        </React.Fragment>
      ) : (
        <Checkbox
          checked={multiDay}
          onChange={setState('multiDay')}
          className="my-2"
          disabled={!date}
        >
          <FormattedMessage {...messages.multiDay} />
        </Checkbox>
      )}
    </div>
  );
}

Period.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  expandOnly: PropTypes.bool,
};

export default Period;
