import formatTime from 'utils/formatTime';
import distanceString from '../distanceString';

function filterItem(filterText, units) {
  return item => {
    if (!item || filterText === '') {
      return true;
    }
    return filterText.split(/\s+/).every(filterByWord(item, units));
  };
}

function filterByWord(item, units) {
  return word => {
    if (/^(19|20)\d\d/.test(word)) {
      return item.dateStr.startsWith(word);
    }
    if (/^\d+:/.test(word)) {
      // Test for times
      const timeStr = formatTime(item.time);
      return timeStr.startsWith(word);
    }
    const numValue = Number(word.replace(',', '.'));
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(numValue)) {
      const re = new RegExp(word, 'i');
      return re.test(item.event) || re.test(item.city) || re.test(item.notes);
    }
    const distance = distanceString(item.distance, units);

    if (/[.,]/.test(word)) {
      return distance === numValue.toFixed(1);
    }
    return Math.floor(Number(distance)) === numValue;
  };
}

export default filterItem;
