/**
 * Return largest index where list[index] <= value
 * @param {array} list
 * @param {number} value
 */
function binSearch(list, value) {
  if (list[0] > value) {
    return undefined;
  }
  let i = 0;
  let d = list.length;
  while (d > 1) {
    d = Math.ceil(d / 2);
    if (list[i + d] <= value) {
      i += d;
    }
  }
  return i;
}

export default binSearch;
