import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

function ActivityLegend(props) {
  const { payload } = props;
  return (
    <div className="text-center my-2">
      {payload.map(
        entry =>
          messages[entry.value] && (
            <span
              key={entry.dataKey}
              style={{ color: entry.color }}
              className="mr-4"
            >
              {'— '}
              <FormattedMessage {...messages[entry.value]} />
            </span>
          ),
      )}
    </div>
  );
}

ActivityLegend.propTypes = {
  payload: PropTypes.array,
};

export default ActivityLegend;
