/**
 * ResultColumns
 */

import styled from 'styled-components';

const ResultColumns = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .panel {
      width: 100%;
    }
    .td-name {
      max-width: 95px;
      .btn {
        text-align: left;
      }
    }
    .pagination-text, .pager {
      margin: 10px 5px;
    }
    @media screen and (min-width: 640px) {
      flex-wrap: nowrap;
      .panel {
        width: calc(50% - 10px);
      }
    }
    .panel-heading {
      padding: 5px;
      font-size: larger;
      font-weight: bold;
    }
  }
`;

export default ResultColumns;
