/**
 *
 * TitleLink
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Wrapper = styled(Link)`
  padding: 0 10px;
  margin-left: -10px;
`;

function TitleLink(props) {
  const { to } = props;
  return (
    <Wrapper to={to}>
      <FontAwesomeIcon icon="arrow-left" size="lg" />
    </Wrapper>
  );
}

TitleLink.propTypes = {
  to: PropTypes.string,
};

export default TitleLink;
