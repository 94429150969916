import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
} from 'react-bootstrap';
import messages from './messages';

class AskEmail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { email: '' };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(ev) {
    this.setState({ email: ev.target.value });
  }

  handleSubmit(ev) {
    ev.preventDefault();
    const { onSubmit } = this.props;
    const { email } = this.state;
    onSubmit(email);
  }

  render() {
    const { onCancel, intro, submitLabel } = this.props;
    const { email } = this.state;
    return (
      <Modal show bsSize="sm" onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage {...messages.email} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{intro}</p>
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="email">
              <ControlLabel className="sr-only">
                <FormattedMessage {...messages.email} />
              </ControlLabel>
              <FormControl
                type="email"
                value={email}
                onChange={this.handleChange}
                required
                autoFocus
              />
            </FormGroup>
            <Button type="submit" bsStyle="success">
              {submitLabel}
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

AskEmail.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  intro: PropTypes.node.isRequired,
  submitLabel: PropTypes.node,
};

AskEmail.defaultProps = {
  submitLabel: 'OK',
};

export default AskEmail;
