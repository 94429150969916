/**
 *
 * Session
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import { Button, Table, Panel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Switch from 'components/Switch';
import { staticLocale } from 'utils/useDateLocale';
import * as colors from 'styles/colors';
import iconColors from 'styles/iconColors';
import messages from '../messages';
import InfoTrigger, { closePopovers } from './Popover';

const Wrapper = styled(Panel)`
  ${iconColors};
  .btn.btn-link {
    padding: 5px;
    margin: -5px;
    opacity: 0.65;
  }
  & table > tbody > tr > td {
    padding: 8px;
  }
  td a {
    cursor: help;
  }
  .td-num {
    text-align: right;
    min-width: 35px;
    color: ${colors.green500};
  }
  tr.waiting-header {
    background-color: ${colors.grey100};
    font-weight: bold;
    color: ${colors.lightBlack};
    td {
      padding-left: 10px;
    }
  }
  tr.waiting {
    color: ${colors.lightBlack};
    .td-num {
      color: ${colors.red500};
    }
  }
  tr.is-self {
    font-weight: bold;
  }
  th {
    white-space: nowrap;
    min-width: 2em;
  }
  .panel-heading,
  .panel-footer {
    padding: 10px;
  }
`;

function Session(props) {
  const { session, userId, onJoin, isMember } = props;
  const { date, joined, maxSize, draft, done, skip, location } = session;
  const dt = new Date(date);
  const canJoin = isMember && dt > new Date();
  const dateStr = `${format(dt, 'PPPPp', staticLocale)} ${location}`;

  if (!userId) {
    return <p>{dateStr}</p>;
  }
  const values = { count: maxSize - joined.length };
  const hasJoined = joined.some(o => o.userId === userId);
  const hasFooter = !done && !skip && joined.length < maxSize;
  function getIcon(i) {
    if (i >= maxSize) {
      return 'times';
    }
    return draft ? 'bars' : 'check';
  }
  function getClass(u, i) {
    const classes = [];
    if (u.userId === userId) {
      classes.push('is-self');
    }
    if (i >= maxSize) {
      classes.push('waiting');
    }
    return classes.join(' ');
  }
  function getStyle() {
    if (done) {
      return 'default';
    }
    return hasJoined ? 'success' : 'warning';
  }
  const header = skip ? (
    <FormattedMessage
      {...messages.noSession}
      values={{ date: dateStr.replace(/ \S+$/, '') }}
    />
  ) : (
    dateStr
  );
  return (
    <Wrapper bsStyle={getStyle()}>
      <Panel.Heading>
        {done || skip ? (
          header
        ) : (
          <Switch
            id={`switch-${session.id}`}
            size="sm"
            value={hasJoined}
            onChange={onJoin}
            disabled={!canJoin}
          >
            {dateStr}
          </Switch>
        )}
      </Panel.Heading>
      {!skip && joined.length === 0 ? (
        <Panel.Body>
          <FormattedMessage {...messages.noJoins} />
        </Panel.Body>
      ) : (
        <Table hover>
          <tbody>
            {joined
              .map(u => ({ ...u, id: `${session.id}-${u.userId}` }))
              .map((u, i) => (
                <React.Fragment key={u.userId}>
                  {i === maxSize && (
                    <tr className="waiting-header">
                      <td colSpan="4">
                        <FormattedMessage {...messages.waitingList} />
                      </td>
                    </tr>
                  )}
                  <tr key={u.id} className={getClass(u, i)}>
                    <td className="td-num">{i + 1}</td>
                    <td>
                      <a href="#legend">
                        <FontAwesomeIcon icon={getIcon(i)} fixedWidth />
                      </a>
                    </td>
                    <td width="90%">{u.name}</td>
                    <td>
                      <InfoTrigger user={u}>
                        <Button
                          bsStyle="link"
                          data-id={u.id}
                          onClick={closePopovers}
                        >
                          <FontAwesomeIcon icon="info-circle" />
                        </Button>
                      </InfoTrigger>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        </Table>
      )}
      {hasFooter && (
        <Panel.Footer>
          <FormattedMessage {...messages.spotsLeft} values={values} />
        </Panel.Footer>
      )}
    </Wrapper>
  );
}

Session.propTypes = {
  session: PropTypes.object,
  userId: PropTypes.number,
  onJoin: PropTypes.func,
  isMember: PropTypes.bool,
  working: PropTypes.bool,
};

export default Session;
