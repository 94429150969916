import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import formatTime from 'utils/formatTime';

export function ChartToolTip(props) {
  const { active, payload, label } = props;
  return useMemo(
    () => {
      if (!active || !payload || payload.length === 0) {
        return null;
      }
      const { title, raceDate, distance, race_time } = payload[0].payload;
      return (
        <div className="bg-white p-2 border rounded">
          <div className="font-weight-bold blue500">{title}</div>
          <div>{new Date(raceDate).toLocaleDateString()}</div>
          <div>{`${distance / 1000} km in ${formatTime(race_time, 0)}`}</div>
        </div>
      );
    },
    [label],
  );
}

ChartToolTip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.number,
};

export default ChartToolTip;
