import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSettings } from './selectors';
import { changeSetting } from './actions';

function builder(settings) {
  const props = settings.reduce(
    (acc, setting) => ({
      ...acc,
      [setting]: makeSelectSettings(setting)(),
    }),
    {},
  );
  const mapStateToProps = createStructuredSelector(props);

  function mapDispatchToProps(dispatch) {
    return {
      changeSetting: (setting, value) =>
        dispatch(changeSetting(setting, value)),
    };
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  );
}

export default builder;
