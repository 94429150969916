/*
 *
 * Clubs reducer
 *
 */

// import { fromJS } from 'immutable';
import reducerBuilder from 'containers/Rest/reducerBuilder';

const restReducer = reducerBuilder('clubs');

function clubsReducer(state, action) {
  const { type, options } = action;
  if (!options || options.entity !== 'clubs') {
    return restReducer(state, action);
  }
  switch (type) {
    default:
      return restReducer(state, action);
  }
}

export default clubsReducer;
