/**
 *
 * IntlString
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

function IntlString(props) {
  const { message } = props;
  return message.id ? <FormattedMessage {...message} /> : message;
}

IntlString.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default IntlString;
