/*
 *
 * SettingsProvider actions
 *
 */

import { CHANGE_SETTING } from './constants';

export function changeSetting(setting, value) {
  return {
    type: CHANGE_SETTING,
    setting,
    value,
  };
}
