/**
 *
 * SessionsView
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { find } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDialog from 'containers/Dialog/useDialog';
import ExplanationModal from 'components/ExplanationModal';
import Container from 'components/KmVreters/Container';
import LoginAlert from 'components/UserControl/LoginAlert';
import ShareLink from 'components/ShareLink';
import Spinner from 'components/Spinner';
import { H2 } from 'components/Virtual/styleComponents';
import { green500 } from 'styles/colors';
import { useRequest, request2 } from 'utils/request';
import storage from 'utils/storage';
import Session from './Session';
import MemberControl from './MemberControl';
import Legend from './Legend';
import AutoRegister from './AutoRegister';
import messages from '../messages';
import explanationText from './explanationText';

const HeaderButtons = styled.div`
  float: right;
  margin-top: -6px;
  .fa-pen,
  .fa-users {
    color: ${green500};
    opacity: 0.65;
  }
`;

const confirmGroupButtons = [
  { label: messages.no, value: false, style: 'default' },
  { label: messages.yesGroup, value: true, style: 'danger' },
];

const confirmSessionButtons = [
  { label: messages.no, value: false, style: 'default' },
  { label: messages.yesSession, value: true, style: 'danger' },
];

function doNothing() {}

function SessionsView(props) {
  const { dlgConfirm, dlgAlert } = useDialog();
  const { user, hash } = props;
  const userId = user.api && user.api.userId;
  const [group, setGroup] = useRequest({ url: `/res/groups/${hash}` });
  if (group.loading || user.loading) {
    return <Spinner />;
  }
  if (group.error) {
    return (
      <Container.Alert bsStyle="danger" width={640}>
        {group.error.message}
      </Container.Alert>
    );
  }
  storage.setItem('showReservations', true);
  const { name, members, sessions, schedule } = group;
  const upcoming = sessions.filter(s => !s.done);
  const past = sessions.filter(s => s.done);
  const member = user.api && find(members, { userId: user.api.userId });
  const canEdit = userId === group.userId;

  function confirmCancel(status, message, buttons) {
    if (status) {
      return Promise.resolve();
    }
    return dlgConfirm({ message, buttons });
  }

  function handleSession(sid, status) {
    return confirmCancel(status, messages.confirmSession, confirmSessionButtons)
      .then(() => {
        const options = {
          method: 'POST',
          url: `/res/groups/${hash}/sessions/${sid}`,
          body: {
            status,
          },
          user,
          withPromise: true,
        };
        return request2(options)
          .then(setGroup)
          .catch(err => {
            dlgAlert({ message: err.message, bsStyle: 'danger' });
          });
      })
      .catch(doNothing);
  }
  function handleMember(status, memberName) {
    return confirmCancel(status, messages.confirmGroup, confirmGroupButtons)
      .then(() => {
        const options = {
          method: 'POST',
          url: `/res/groups/${hash}`,
          body: {
            status,
            name: memberName,
          },
          user,
          withPromise: true,
        };
        return request2(options).then(setGroup);
      })
      .catch(doNothing);
  }
  return (
    <Container width={640}>
      <Container.Header>
        <HeaderButtons>
          {canEdit && (
            <React.Fragment>
              <Link
                to={`/inschrijven/members/${hash}`}
                className="btn btn-link"
              >
                <FontAwesomeIcon icon="users" />
              </Link>
              <Link to={`/inschrijven/edit/${hash}`} className="btn btn-link">
                <FontAwesomeIcon icon="pen" />
              </Link>
            </React.Fragment>
          )}
          <ShareLink bsStyle={canEdit ? 'default' : 'link'} bsSize="sm" />
        </HeaderButtons>
        {name}
      </Container.Header>
      {userId ? (
        <MemberControl user={user} member={member} onJoin={handleMember} />
      ) : (
        <LoginAlert />
      )}
      <section>
        <ExplanationModal storageKey="showReservExpl" text={explanationText} />
        <H2>
          <FormattedMessage {...messages.trainingSessions} />
        </H2>
        <AutoRegister member={member} schedule={schedule} {...props} />
        {upcoming.length === 0 && (
          <Alert bsStyle="info">
            <FormattedMessage {...messages.noSessions} />
          </Alert>
        )}
        {upcoming.map(session => (
          <Session
            key={session.id}
            session={session}
            userId={userId}
            isMember={Boolean(member)}
            onJoin={val => handleSession(session.id, val)}
          />
        ))}
      </section>
      <Legend />
      {past.length > 0 && (
        <section id="history">
          <H2>
            <FormattedMessage {...messages.latestSession} />
          </H2>
          {past.map(session => (
            <Session key={session.id} session={session} userId={userId} />
          ))}
        </section>
      )}
    </Container>
  );
}

SessionsView.propTypes = {
  user: PropTypes.object,
  hash: PropTypes.string,
};

export default SessionsView;
