/**
 *
 * withClubs
 * HOC to add Clubs tuple
 *
 */

// import React from 'react';
import injectReducer from 'utils/injectReducer';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import makeSelectRest from 'containers/Rest/selectors';
import mapDispatchToProps from 'containers/Rest/mapDispatchToProps';
import reducer from './reducer';

const key = 'clubs';
const withReducer = injectReducer({ key, reducer });

const mapStateToProps = createStructuredSelector({
  [key]: makeSelectRest(key)(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps(key),
);

export default compose(
  withReducer,
  withConnect,
);
