/*
 * KmVreters Messages
 *
 * This contains all the text for the KmVreters component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.KmVreters.title',
    defaultMessage: 'RunnerMaps Kilometervreters',
  },
});
