/**
 *
 * ChartResults
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useMeasure } from 'react-use';
import { useHistory } from 'react-router-dom';
import {
  CartesianGrid,
  Line,
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { subDays, subMonths } from 'date-fns';
import { last } from 'lodash';
import { red100, red500 } from 'styles/colors';
import linearRegression from 'utils/linearRegression';
import formatTime from 'utils/formatTime';
import ChartToolTip from './ChartToolTip';
import useSettings from './useSettings';
import ChartControls from './ChartControls';
import getParams from './getParams';
import ProgressAlert from './ProgressAlert';

const MSEC_WEEK = 7 * 24 * 60 * 60 * 1000;

function ChartResults(props) {
  const { races } = props;
  const settings = useSettings();
  const { refDist, period, xType } = settings;
  const [ref, { width }] = useMeasure();
  const { push } = useHistory();
  const start =
    period === 'all'
      ? subDays(new Date(last(races).race_date), 1)
      : subMonths(new Date(), period);
  const data = races
    .map(r => ({
      ...r,
      raceDate: new Date(r.race_date).getTime(),
      refTime: (refDist / r.distance) ** 1.08 * r.race_time,
    }))
    .filter(r => r.raceDate > start)
    .reverse();

  const { m, b } = linearRegression(data, 'raceDate', 'refTime', 2);
  const withTrend = data.map(d => ({ ...d, trend: m * d.raceDate + b }));

  const progress = m * MSEC_WEEK; // sec/week

  const margin = { top: 5, right: 30, left: 20, bottom: 5 };
  const { domain, ticks } = xType === 'category' ? {} : getParams(start);

  function onClick({ activePayload }) {
    if (activePayload && activePayload.length > 0) {
      const { hash } = activePayload[0].payload;
      push(`/virtual/${hash}`);
    }
  }

  return (
    <div ref={ref}>
      <LineChart
        width={width}
        height={300}
        data={withTrend}
        margin={margin}
        onClick={onClick}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="raceDate"
          type={xType}
          tickFormatter={t => new Date(t).toLocaleDateString()}
          ticks={ticks}
          domain={domain}
        />
        <YAxis
          domain={['auto', 'auto']}
          tickFormatter={t => formatTime(t, 0)}
        />
        <Tooltip content={<ChartToolTip />} animationDuration={500} />
        <Line
          dot={false}
          dataKey="trend"
          stroke={red100}
          strokeWidth={5}
          strokeDasharray="5 5"
          animationDuration={500}
        />
        <Line dataKey="refTime" stroke={red500} animationDuration={500} />
      </LineChart>
      <ChartControls settings={settings} />
      {data.length > 1 && <ProgressAlert progress={progress} />}
    </div>
  );
}

ChartResults.propTypes = {
  races: PropTypes.array,
};

export default ChartResults;
