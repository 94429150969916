/*
 * KmVretersPage Messages
 *
 * This contains all the text for the KmVretersPage component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.KmVretersPage.header',
    defaultMessage: 'This is KmVretersPage container !',
  },
  login: {
    id: 'app.containers.KmVretersPage.login',
    defaultMessage: 'You must <a href="#login">sign in</a> first',
  },
});
