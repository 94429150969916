/**
 *
 * VirtualEdit
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
// import { Alert, Button } from 'react-bootstrap';
import { pick } from 'lodash';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Alert } from 'react-bootstrap';
import Container from 'components/KmVreters/Container';
import useForm from 'components/FormField/useForm';
import { DistanceInput } from 'components/Distance';
import Spinner from 'components/Spinner';
import PrimaryAction from 'components/PrimaryAction';
import LoginAlert from 'components/UserControl/LoginAlert';
import { request2 } from 'utils/request';
import messages from '../messages';
import Period from './Period';

const initialState = {
  title: '',
  description: '',
  image: '',
  period: {},
  distance: 10,
  fiexd: false,
};

const copyFields = [
  'title',
  'description',
  'image',
  'distance',
  'fixed',
  'is_public',
];

function getUrl(hash) {
  const url = '/virtual/races';
  return hash ? `${url}/${hash}` : url;
}

function VirtualEdit(props) {
  const { user, hash, page } = props;
  const url = getUrl(hash);
  const [state, setState, renderField] = useForm(initialState, messages);
  const [hasEntries, setHasEntries] = useState(false);
  const [loading, setLoading] = useState(true);
  const {
    title,
    description,
    image,
    period,
    distance,
    fixed,
    is_public,
  } = state;

  const history = useHistory();
  useEffect(
    () => {
      if (!hash) {
        setLoading(false);
        return;
      }
      request2({ url, user })
        .then(data => {
          const copying = hash && page === 'create';
          const fields = copying ? copyFields : [...copyFields, 'hash'];
          setState({
            ...pick(data, fields),
            distance: data.distance / 1000,
            period: { date: data.date, endDate: data.end_date },
          });
          setHasEntries(!copying && data.entries.length > 0);
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error('request', err);
        })
        .finally(() => setLoading(false));
    },
    [user, hash, page],
  );

  const handleSubmit = ev => {
    ev.preventDefault();
    const { date, endDate: end_date } = period;
    const method = hash && page === 'edit' ? 'PUT' : 'POST';
    const requestUrl = method === 'POST' ? '/virtual/races' : url;
    const body = {
      title,
      description,
      image,
      date,
      end_date,
      distance: Math.round(distance * 1000),
      fixed,
      is_public,
    };
    request2({ method, url: requestUrl, user, body })
      .then(response => {
        history.push(`/virtual/${response.hash}`);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error('request', err);
      });
  };

  const header = messages.create;
  const isValid = !hash || page === 'create' || hash === state.hash;
  if (loading) {
    return <Spinner />;
  }
  return (
    <Container width={640}>
      <Container.Header>
        <FormattedMessage {...header} />
      </Container.Header>
      {user.api ? (
        <React.Fragment>
          <Alert bsStyle="info">
            <FormattedHTMLMessage {...messages.organise} />
          </Alert>
          <form onSubmit={handleSubmit}>
            {renderField({
              id: 'title',
              type: 'text',
              required: true,
            })}
            {renderField({
              id: 'description',
              componentClass: 'textarea',
              helpText: <FormattedMessage {...messages.descriptionHelp} />,
              row: 3,
            })}
            {renderField({
              id: 'image',
              type: 'url',
              helpText: <FormattedMessage {...messages.imageHelp} />,
              placeholder: 'http://',
            })}
            {renderField({
              id: 'period',
              componentClass: Period,
              expandOnly: hasEntries,
              required: true,
              helpText: hasEntries ? (
                <FormattedMessage {...messages.hasEntriesDate} />
              ) : (
                undefined
              ),
            })}
            {renderField({
              id: 'distance',
              componentClass: DistanceInput,
              helpText: hasEntries ? (
                <FormattedMessage {...messages.hasEntriesDistance} />
              ) : (
                undefined
              ),
              readOnly: hasEntries,
              required: true,
            })}
            {renderField({
              id: 'fixed',
              componentClass: 'checkbox',
              helpText: <FormattedMessage {...messages.fixedHelp} />,
              required: true,
            })}
            {user.su &&
              renderField({
                id: 'is_public',
                componentClass: 'checkbox',
              })}
            <PrimaryAction onCancel="/virtual" valid={isValid} />
          </form>
        </React.Fragment>
      ) : (
        <LoginAlert />
      )}
    </Container>
  );
}

VirtualEdit.propTypes = {
  user: PropTypes.object,
  page: PropTypes.string,
  hash: PropTypes.string,
  goTo: PropTypes.func,
};

export default VirtualEdit;
