/* eslint-disable no-param-reassign */
import produce from 'immer';
import getTempId from './getTempId';
import createSession from './createSession';

const groupReducer = produce((draft, action) => {
  const { type, value, id, prop } = action;
  switch (type) {
    case 'name':
      draft.name = value;
      break;
    case 'delete':
      delete draft.schedule[id];
      break;
    case 'addSession':
      draft.schedule[getTempId()] = createSession();
      break;
    case 'schedule':
      draft.schedule[id][prop] = value;
      break;
    default:
      throw new Error('Unknown type');
  }
});

export default groupReducer;
