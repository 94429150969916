/**
 *
 * ExplanationModal
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Modal } from 'react-bootstrap';

import { FormattedMessage } from 'react-intl';
import Markdown from 'components/Markdown';
import useStorage from 'utils/storage/useStorage';
import messages from './messages';

const SideButton = styled(Button)`
  &.pull-right {
    margin-top: -6px;
    margin-right: -6px;
  }
`;

const ModalBody = styled(Modal.Body)`
  max-height: calc(100vh - 180px);
  overflow-y: auto;
`;

function ExplanationModal(props) {
  const { text, storageKey, initialShow, className } = props;
  const [show, setShow] = useStorage(storageKey, initialShow);
  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);
  return (
    <React.Fragment>
      <SideButton bsStyle="link" onClick={handleShow} className={className}>
        <FormattedMessage {...messages.explanation} />
      </SideButton>
      <Modal show={show} onHide={handleHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage {...messages.header} />
          </Modal.Title>
        </Modal.Header>
        <ModalBody>
          <Markdown source={text} linkTarget="_blank" />
        </ModalBody>
        <Modal.Footer>
          <Button bsStyle="success" onClick={handleHide}>
            <FormattedMessage {...messages.understood} />
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

ExplanationModal.propTypes = {
  storageKey: PropTypes.string.isRequired,
  initialShow: PropTypes.bool,
  text: PropTypes.object.isRequired,
  className: PropTypes.string,
};

ExplanationModal.defaultProps = {
  initialShow: true,
  className: 'pull-right',
};

export default ExplanationModal;
