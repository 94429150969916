import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { red500 } from 'styles/colors';

const Sup = styled(Button)`
  height: 18px;
  .fa-thumbs-up {
    color: ${red500};
    opacity: 0.75;
    transform: translateY(-4px);
  }
`;

function Supporter() {
  return (
    <Sup href="#subscription" bsStyle="link" className="pl-1 pr-2 py-0">
      <FontAwesomeIcon
        icon={['far', 'thumbs-up']}
        className="ml-1"
        size="xs"
        title="supporter"
      />
    </Sup>
  );
}

export default Supporter;
