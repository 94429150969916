import pick from 'lodash/pick';
import memoize from 'memoize-one';
import { sortBy } from 'lodash';
import getQuartiles from 'utils/getQuartiles';
import distanceString from '../distanceString';

function listByDistances(list, unit) {
  const grouped = list.reduce(addRaceToDistances(unit), {});
  const byFrequency = sortBy(Object.values(grouped), ['number', 'lastDate'])
    .reverse()
    .map((item, rank) => ({
      ...item,
      rank,
      avgTime: item.totalTime / item.number,
      title: `${item.event}, ${item.city}`,
      times: getQuartiles(item.times),
      speeds: getQuartiles(item.speeds),
    }));
  return sortBy(byFrequency, item => Number(item.distance));
}

function addRaceToDistances(unit) {
  return (acc, race) => {
    const key = distanceString(Number(race.distance), unit);
    const current =
      acc[key] === undefined
        ? newDistance(key, race)
        : addToDistance(acc[key], race);
    return {
      ...acc,
      [key]: current,
    };
  };
}

function newDistance(distance, race) {
  const current = {
    distance,
    number: 1,
    totalTime: race.time,
    bestTime: race.time,
    lastDate: race.dateStr,
    times: [race.time],
    speeds: [getSpeed(race)],
  };
  return withBest(current, race);
}

function addToDistance(current, race) {
  const cumulative = {
    ...current,
    number: current.number + 1,
    totalTime: current.totalTime + race.time,
    times: [...current.times, race.time],
    speeds: [...current.speeds, getSpeed(race)],
  };
  return race.time < current.bestTime ? withBest(cumulative, race) : cumulative;
}

function withBest(current, race) {
  return {
    ...current,
    bestTime: race.time,
    ...pick(race, ['date', 'link', 'event', 'city']),
  };
}

function getSpeed(race) {
  return (Number(race.distance) * 3600) / race.time;
}

export default memoize(listByDistances);
