import { css } from 'styled-components';
import { green500, blue500, red500, faintBlack } from './colors';

const iconColors = css`
  .fa-check {
    color: ${green500};
  }
  .fa-bars {
    color: ${blue500};
    opacity: 0.65;
  }
  .fa-times {
    color: ${red500};
  }
  .fa-minus {
    color: ${faintBlack};
  }
`;

export default iconColors;
