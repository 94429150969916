/* eslint-disable no-bitwise */
/**
 *
 * Attended
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function makeIcons(score) {
  const symbol = score & 0x80 ? 'check' : 'minus';
  const icon = (
    <FontAwesomeIcon key={`att-${score}`} icon={symbol} fixedWidth />
  );
  const rem = score & 0x7f;
  return rem ? [icon, ...makeIcons(rem << 1)] : [icon];
}

function Attended(props) {
  const { score } = props;
  // if (score === 0) {
  //   return <FontAwesomeIcon icon="minus" fixedWidth />;
  // }
  return <span>{makeIcons(score)}</span>;
}

Attended.propTypes = {
  score: PropTypes.number,
};

export default Attended;
