/*
 *
 * Rest constants
 *
 */

export const GET_ENTITY = 'app/Rest/GET_ENTITY';
export const GET_ENTITY_REQUEST = 'app/Rest/GET_ENTITY_REQUEST';
export const GET_ENTITY_SUCCESS = 'app/Rest/GET_ENTITY_SUCCESS';
export const GET_ENTITY_ERROR = 'app/Rest/GET_ENTITY_ERROR';

export const LIST_ENTITY = 'app/Rest/LIST_ENTITY';
export const LIST_ENTITY_REQUEST = 'app/Rest/LIST_ENTITY_REQUEST';
export const LIST_ENTITY_SUCCESS = 'app/Rest/LIST_ENTITY_SUCCESS';
export const LIST_ENTITY_ERROR = 'app/Rest/LIST_ENTITY_ERROR';

export const CHANGE_ENTITY = 'app/Rest/CHANGE_ENTITY';
export const CHANGE_ENTITY_REQUEST = 'app/Rest/CHANGE_ENTITY_REQUEST';
export const CHANGE_ENTITY_SUCCESS = 'app/Rest/CHANGE_ENTITY_SUCCESS';
export const CHANGE_ENTITY_ERROR = 'app/Rest/CHANGE_ENTITY_ERROR';

export const RESET_ENTITY = 'app/Rest/RESET_ENTITY';
