import { createGlobalStyle } from 'styled-components';
import 'styles/bootstrap/bootstrap-utilities.css';
import 'styles/bootstrap/css/bootstrap.css';
import 'leaflet/dist/leaflet.css';
import { red200, red500, green500, blue500 } from './colors';

const GlobalStyles = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    display: flex;
    cursor: inherit;
  }
  
  body.full-screen > #app > :not(main) {
    display: none;
  }

  #app {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (orientation: landscape) {
      flex-direction: row;
    }
    @media (orientation: portrait) {
      .overflow-container {
        overflow-y: hidden;
        & > div {
          overflow-y: auto;
        }
      }
    }



  // workaround for Safari bug: https://github.com/Leaflet/Leaflet/issues/8068
  .leaflet-control-container .leaflet-top, .leaflet-control-container .leaflet-bottom {
    transform: translate3d(0px, 0px, 0px);
  }

  .leaflet-container {
    flex-grow: 1;
  }

  .modal-content .modal-header {
    border-bottom: 2px solid ${red200};
  }
  .modal-content .modal-footer {
    border-top: 2px solid ${red200};
  }
  .modal-content > ul.pager {
    margin: 20px 15px;
  }
  
  .btn {
    -webkit-appearance: none !important;
  }

  .btn.inline {
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .nowrap {
    white-space: nowrap;
  }
  
  .fa-trash-alt {
    color: ${red500};
  }

  .fa-facebook {
    color: #3b5998; 
  }

  .fa-twitter {
    color: #55acee;
  }

  img.pull-right.margin {
    margin-left: 10px;
    margin-bottom: 10px;
  }
  
  .input-short.input-short {
    width: 11em;
  }

  svg.fa-fw + span, span + svg.fa-fw {
    margin-left: 0.5rem;
  }

  @media (max-height: 799px) {
    .hidden-low {
      display: none !important;
    }
  }

  @media screen and (orientation: portrait) {
    .hidden-portrait {
      display: none !important;
    }
  }

  @media screen and (orientation: landscape) {
    .visible-portrait {
      display: none !important;
    }
  }

  .alert > ul, .alert > span > ul {
    padding-left: 20px;
  }
    
  .red500 {
    color: ${red500} !important;
  }

  .green500 {
    color: ${green500} !important;
  }

  .blue500 {
    color: ${blue500} !important;
  }
`;

export default GlobalStyles;
