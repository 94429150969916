/*
 * Import Messages
 *
 * This contains all the text for the Import component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.KmVreters.Import.title',
    defaultMessage: 'Import from uitslagen.nl',
  },
  name: {
    id: 'app.components.KmVreters.Import.name',
    defaultMessage: 'Name',
  },
  helpCheck: {
    id: 'app.components.KmVreters.Import.helpCheck',
    defaultMessage: 'Check the races you want to import',
  },
  helpComplete: {
    id: 'app.components.KmVreters.Import.helpComplete',
    defaultMessage: 'Complete missing distances, if any',
  },
  helpSubmit: {
    id: 'app.components.KmVreters.List.helpSubmit',
    defaultMessage: 'Click "Import"',
  },
  search: {
    id: 'app.components.KmVreters.List.search',
    defaultMessage: 'Search',
  },
  race: {
    id: 'app.components.KmVreters.List.race',
    defaultMessage: 'Race',
  },
  date: {
    id: 'app.components.KmVreters.List.date',
    defaultMessage: 'Date',
  },
  distance: {
    id: 'app.components.KmVreters.List.distance',
    defaultMessage: 'Distance',
  },
  time: {
    id: 'app.components.KmVreters.List.time',
    defaultMessage: 'Time',
  },
  link: {
    id: 'app.components.KmVreters.List.link',
    defaultMessage: 'Link',
  },
  more: {
    id: 'app.components.KmVreters.Import.more',
    defaultMessage: 'More',
  },
  morePages: {
    id: 'app.components.KmVreters.Import.morePages',
    defaultMessage: '{count} more page(s)',
  },
  import: {
    id: 'app.components.KmVreters.Import.import',
    defaultMessage: 'Import',
  },
  check: {
    id: 'app.components.KmVreters.Import.check',
    defaultMessage: 'Check',
  },
  checkAll: {
    id: 'app.components.KmVreters.Import.checkAll',
    defaultMessage: 'Check All',
  },
});
