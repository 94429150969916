import { useCallback, useMemo, useState } from 'react';
import { useEvent } from 'react-use';

function useShowPosition(data) {
  const [index, setIndex] = useState(-1);
  const [start, setStart] = useState(0);
  const time = useMemo(
    () => {
      if (!data || !data[start] || !data[index]) {
        return null;
      }
      return data[index].time - data[start].time;
    },
    [start, index, data],
  );

  const onKeyDown = useCallback(ev => {
    if (!data) {
      return;
    }
    const { code, shiftKey, ctrlKey } = ev;
    const mul = (shiftKey ? 10 : 1) * (ctrlKey ? 100 : 1);
    function changeIndex(dir) {
      setIndex(oldIndex => {
        let newIndex = oldIndex + dir * mul;
        if (newIndex < 0) {
          newIndex = -1;
        }
        if (newIndex >= data.length) {
          newIndex = data.length - 1;
        }
        return newIndex;
      });
    }
    switch (code) {
      case 'Comma':
        changeIndex(-1);
        break;
      case 'Period':
        changeIndex(1);
        break;
      case 'KeyS':
        setStart(index);
        break;
      default:
    }
  });
  useEvent('keydown', onKeyDown);
  return { time, index };
}

export default useShowPosition;
