/*
 * Jubileum Messages
 *
 * This contains all the text for the Jubileum component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.Jubileum.title',
    defaultMessage: 'RunnerMaps 12.5 Years!',
  },
  description: {
    id: 'app.components.Jubileum.description',
    defaultMessage:
      '12.5 Years of RunnerMaps celebrated on Aug 23 with races from 1.5K to full marathon!',
  },
  subtitle: {
    id: 'app.components.Jubileum.subtitle',
    defaultMessage: '12.5 Years!',
  },
});
