/*
 * HomeLink Messages
 *
 * This contains all the text for the HomeLink component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.HomeLink.title',
    defaultMessage: 'RunnerMaps',
  },
  subtitle: {
    id: 'app.components.HomeLink.subtitle',
    defaultMessage: 'Running on the Map',
  },
  jubileum: {
    id: 'app.components.Jubileum.subtitle',
    defaultMessage: '12,5 Years!',
  },
});
