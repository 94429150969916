/**
 *
 * RaceResults
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useRequest } from 'utils/request';
import ListRaces from './ListRaces';
import messages from './messages';
import ChartResults from './ChartResults';
import { H2 } from './styleComponents';

function RaceResults(props) {
  const { user } = props;
  const [races] = useRequest({ url: '/virtual/results', user });
  if (!races || races.loading || races.length === 0) {
    return null;
  }
  return (
    <React.Fragment>
      <H2>
        <FormattedMessage {...messages.results} />
      </H2>
      <ChartResults races={races} />
      <ListRaces caption={messages.results} races={races} type="ran" />
    </React.Fragment>
  );
}

RaceResults.propTypes = {
  user: PropTypes.object,
};
export default RaceResults;
