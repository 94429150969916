/**
 *
 * HashModals
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import Settings from 'components/Settings';
import Privacy from 'components/Privacy';
import AccountModal from 'components/UserControl/AccountModal';
import SubscriptionModal from 'components/UserControl/SubscriptionModal';

function HashModals() {
  const { pathname, search, hash, state } = useLocation();
  const { push } = useHistory();
  function handleHide() {
    push(state || `${pathname}${search}`);
  }
  const match = hash.match(/#(\w+)(=(\w+))?/);
  if (!match) {
    return null;
  }
  switch (match[1]) {
    case 'account':
      return <AccountModal param={match[3]} onHide={handleHide} />;
    case 'subscription':
      return <SubscriptionModal param={match[3]} onHide={handleHide} />;
    case 'privacy':
    case 'conditions':
      return <Privacy onHide={handleHide} />;
    case 'settings':
      return <Settings onHide={handleHide} />;
    default:
      return null;
  }
}

export default HashModals;
