/**
 *
 * Participants
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import usePagination from 'components/Pagination/usePagination';
import { useCurrentUser } from 'containers/User';
import { multiDay } from 'utils/datetime';
import formatTime from 'utils/formatTime';
import messages from '../messages';
import ActivityModal from './ActivityModal';
import Ranking from './Ranking';
import makeName from './makeName';
import DateString from '../../DateString';
import Supporter from './Supporter';

const Wrapper = styled.div`
  .table {
    margin-bottom: 10px;
  }
  .pager {
    margin: 0;
    margin-bottom: 30px;
  }
  .pg-tr {
    float: right;
    font-weight: normal;
  }
`;

export const ResultTable = styled(Table)`
  tr.current-athlete, tr.current-athlete .btn.btn-link {
    font-weight: bold;
  }
  th {
    white-space: normal;
  }
  .td-name {
    padding: 0;
    .btn.btn-link {
      padding 5px;
    }
  }
  .td-num {
    text-align: right;
    width: 12%;
    white-space: nowrap;
  }
  .td-pos {
    text-align: right;
    white-space: nowrap;
    width: 5%;
  }
  .td-cat {
    text-align: center;
    width: 10%;
  }
  .td-modal {
    padding: 0;
    width: 5%;
  }
`;

function Participants(props) {
  const { entries, showSex } = props;
  const history = useHistory();
  const { hash } = useLocation();
  const show =
    hash.slice(1) && entries.find(e => e.athlete_id === Number(hash.slice(1)));
  function setShow(activity) {
    history.push(`#${activity && activity.athlete_id}`);
  }
  // const [show, setShow] = useState(null);
  const handleHide = () => setShow('');
  const { slice, Paginator, offset, paginationText } = usePagination(
    entries.length,
  );
  const user = useCurrentUser();
  const { strava = {} } = user;
  if (entries.length === 0) {
    return <FormattedMessage {...messages.noEntries} />;
  }
  const isMultiDay = multiDay(entries[0]);
  const isSelf = entry => entry.athlete_id === strava.athlete_id;
  const withPagination = entries.length > 15;
  const list = withPagination ? slice(entries) : entries;
  const ownResult = entries.findIndex(isSelf);
  if (ownResult !== -1) {
    if (ownResult < offset) {
      list.unshift(entries[ownResult]);
    }
    if (ownResult > offset + list.length) {
      list.push(entries[ownResult]);
    }
  }
  const today = format(new Date(), 'yyyy-MM-dd');
  function formatStart(act) {
    if (!isMultiDay || act.start_date.startsWith(today)) {
      return <DateString date={act.start_date} format="H:mm" />;
    }
    return <DateString date={act.start_date_local} format="d MMM" />;
  }
  return (
    <Wrapper>
      {withPagination &&
        showSex && <div className="pagination-text">{paginationText}</div>}
      <ResultTable condensed hover responsive>
        <thead>
          <tr>
            <th className="td-pos">
              <FormattedMessage {...messages.ranking} />
            </th>
            <th>
              <FormattedMessage {...messages.name} />
              {withPagination &&
                !showSex && <span className="pg-tr">{paginationText}</span>}
            </th>
            {showSex && (
              <th className="td-cat">
                <FormattedMessage {...messages.sex} />
              </th>
            )}
            <th className="td-num">
              <FormattedMessage {...messages.time} />
            </th>
            <th className="td-num">
              <FormattedMessage {...messages.startDate} />
            </th>
          </tr>
        </thead>
        <tbody>
          {list.map(e => (
            <tr
              key={e.athlete_id}
              className={isSelf(e) ? 'current-athlete' : ''}
            >
              <td className="td-pos">
                <Ranking pos={e.pos} />
              </td>
              <td className="td-name">
                <Button
                  bsStyle="link"
                  className="pr-1 text-left"
                  onClick={() => setShow(e)}
                >
                  <span className="text-wrap">{makeName(e)}</span>
                </Button>
                {Boolean(e.premium) && <Supporter />}
              </td>
              {showSex && (
                <td className="td-cat">
                  {(e.sex === 'M' || e.sex === 'F') && (
                    <FormattedMessage {...messages[e.sex]} />
                  )}
                </td>
              )}
              {e.race_time ? (
                <React.Fragment>
                  <td className="td-num">{formatTime(e.race_time)}</td>
                  <td className="td-num">{formatStart(e)}</td>
                </React.Fragment>
              ) : (
                <td colSpan="2" className="text-muted text-center">
                  <span className="small text-nowrap">
                    <FormattedMessage {...messages.didNotFinish} />
                  </span>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </ResultTable>
      {withPagination && <Paginator />}
      {show && <ActivityModal activity={show} onHide={handleHide} />}
    </Wrapper>
  );
}

Participants.propTypes = {
  isOwn: PropTypes.bool,
  showSex: PropTypes.bool,
  entries: PropTypes.array,
  setRace: PropTypes.func,
};

export default Participants;
