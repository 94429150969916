/**
 *
 * IframeCover
 */

import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import { EMBEDDED, IFRAME } from 'containers/App/constants';

const A = styled.a`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1200;
`;

function IframeCover() {
  if (EMBEDDED || !IFRAME) {
    return null;
  }
  return <A href={window.location.href} target="_top" />;
}

export default IframeCover;
