/**
 *
 * MyPopover
 *
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import iconColors from 'styles/iconColors';
import { staticLocale } from 'utils/useDateLocale';
import messages from '../messages';
import Attended from '../GroupMembers/Attended';

const DL = styled.dl`
  margin-bottom: 0;
  dd + dt {
    margin-top: 0.5rem;
  }
  ${iconColors};
`;

const triggerList = {};

export function getPopover(user) {
  const { created, score } = user;
  return (
    <Popover id={user.id} onClick={closePopovers}>
      <DL>
        <dt>
          <FormattedMessage {...messages.registered} />
        </dt>
        <dd>{format(parseISO(created), 'iiii H:mm', staticLocale)}</dd>
        <dt>
          <FormattedMessage {...messages.attended} />
        </dt>
        <dd>
          <Attended score={score} />
        </dd>
      </DL>
    </Popover>
  );
}

function InfoTrigger(props) {
  const { user } = props;
  const trigger = useRef(null);
  triggerList[user.id] = trigger;
  const handleClick = ev => {
    ev.stopPropagation();
  };
  return (
    <OverlayTrigger
      ref={trigger}
      {...props}
      overlay={getPopover(user)}
      onClick={handleClick}
    />
  );
}

InfoTrigger.propTypes = {
  trigger: PropTypes.string,
  placement: PropTypes.string,
  user: PropTypes.object.isRequired,
};

InfoTrigger.defaultProps = {
  trigger: 'click',
  placement: 'left',
};

export function closePopovers(ev) {
  const id = ev.currentTarget.dataset && ev.currentTarget.dataset.id;
  Object.keys(triggerList).forEach(key => {
    const trigger = triggerList[key].current;
    if (key !== id && trigger && trigger.state && trigger.state.show) {
      trigger.handleHide();
    }
  });
}

export default InfoTrigger;
