/*
 *
 * SettingsProvider reducer
 *
 */

import { fromJS } from 'immutable';
import storage from 'utils/storage';

import { CHANGE_SETTING, INITIAL_LOCALE, CHOICES } from './constants';

export const initialState = fromJS({
  locale: INITIAL_LOCALE,
  units: storage.getItem('units') || CHOICES.units[0],
  autocenter: storage.getItem('autocenter') || false,
});

function SettingsProviderReducer(state = initialState, action) {
  const { type, setting, value } = action;
  switch (type) {
    case CHANGE_SETTING:
      return state.set(setting, value);
    default:
      return state;
  }
}

export default SettingsProviderReducer;
