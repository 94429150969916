export const byId = item => item.id;

export function idList(arr) {
  return arr.map(byId);
}

export function idObject(arr) {
  return arr.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});
}

export function listFromId(obj, prop) {
  return obj[prop].map(id => obj[id]);
}

export function getIndexed(obj, listProp, index) {
  const key = obj[listProp][index];
  return obj[key];
}
