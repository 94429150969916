/**
 *
 * UserError
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

function UserError(props) {
  const { user } = props;
  if (!user.error) {
    return null;
  }
  const message = messages[user.error] || messages.error;
  return (
    <Alert bsStyle="danger">
      <FormattedMessage {...message} />
    </Alert>
  );
}

UserError.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserError;
