/**
 *
 * GroupMembers
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { produce } from 'immer';
import Container from 'components/KmVreters/Container';
import LoginAlert from 'components/UserControl/LoginAlert';
import Spinner from 'components/Spinner';
import TitleLink from 'components/Virtual/TitleLink';
import { useRequest, request2 } from 'utils/request';
import Members from './Members';
import messages from '../messages';

function GroupMembers(props) {
  const { user, hash } = props;
  const [group, setGroup] = useRequest({ url: `/res/groups/${hash}` });
  if (group.loading || user.loading) {
    return <Spinner />;
  }
  const { name, members, schedule } = group;
  function handleChange(memberId, status) {
    const options = {
      method: 'POST',
      url: `/res/groups/${hash}/editor`,
      body: {
        member_id: memberId,
        status,
      },
      user,
    };
    request2(options).then(() => {
      const newMembers = group.members.map(
        mem => (mem.id !== memberId ? mem : { ...mem, editor: status }),
      );
      setGroup(
        produce(group, draft => {
          draft.members = newMembers;
        }),
      );
    });
  }
  return (
    <Container width={640}>
      <Container.Header>
        <TitleLink to={`/inschrijven/${hash}`} />
        <FormattedMessage {...messages.members} values={{ name }} />
      </Container.Header>
      {user.api ? (
        <Members
          members={members}
          schedule={schedule}
          onChange={handleChange}
        />
      ) : (
        <LoginAlert />
      )}
    </Container>
  );
}

GroupMembers.propTypes = {
  user: PropTypes.object,
  hash: PropTypes.string,
};

export default GroupMembers;
