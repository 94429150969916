import { useMemo } from 'react';
import { useSelector } from 'react-redux';

function useCreateSelector(inputSelectors, resultFunc) {
  const inputArray = Array.isArray(inputSelectors)
    ? inputSelectors
    : [inputSelectors];
  const inputValues = inputArray.map(selector => useSelector(selector));
  return useMemo(() => resultFunc(...inputValues), inputValues);
}

export default useCreateSelector;
