function periodString(dateString, period) {
  switch (period) {
    case 'month':
      return dateString.slice(0, 7);
    case 'year':
      return dateString.slice(0, 4);
    default:
      throw new Error(`Invalid period ${period}`);
  }
}

export default periodString;
