import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import useCurrentUser from 'containers/User/useCurrentUser';
import Flex from 'components/Flex';
import stravaButton from 'images/btn_strava_connectwith_orange.png';
import Spinner from '../../Spinner/index';
import { request2 } from '../../../utils/request';

const Wrapper = styled(Flex)`
  img {
    margin-left: 1rem;
  }
  @media (min-width: 480px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

function StravaConnect(props) {
  const user = useCurrentUser();
  const { pathname } = useLocation();
  const [link, setLink] = useState();
  useEffect(
    () => {
      const options = {
        url: `/strava/connect/${user.api.hashKey}?noredirect=true`,
        user,
      };
      request2(options).then(res => {
        setLink(res.link);
      });
    },
    [pathname],
  );
  if (!link) {
    return <Spinner />;
  }

  const { message } = props;
  return (
    <div className="alert alert-info">
      <Wrapper center column>
        <FormattedMessage {...message} />
        <a href={link}>
          <img src={stravaButton} alt="Connect with Strava" />
        </a>
      </Wrapper>
    </div>
  );
}

StravaConnect.propTypes = {
  message: PropTypes.object,
};

export default StravaConnect;
