import memoize from 'memoize-one';
import periodString from '../periodString';

function listByPeriod(list, period) {
  const grouped = list.reduce(addRaceToPeriod(period), {});
  return Object.keys(grouped)
    .sort()
    .reverse()
    .map(key => grouped[key]);
}

function addRaceToPeriod(period) {
  return (acc, race) => {
    const key = periodString(race.dateStr, period);
    const current =
      acc[key] === undefined
        ? newPeriod(key, race)
        : addToPeriod(acc[key], race);
    return {
      ...acc,
      [key]: current,
    };
  };
}

function newPeriod(dateStr, race) {
  return {
    dateStr,
    number: 1,
    time: race.time,
    distance: Number(race.distance),
  };
}

function addToPeriod(current, race) {
  return {
    ...current,
    number: current.number + 1,
    time: current.time + race.time,
    distance: current.distance + Number(race.distance),
  };
}

const memoized = memoize(listByPeriod);

export default memoized;
