/*
 * Profile Messages
 *
 * This contains all the text for the Profile component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.KmVreters.Profile.title',
    defaultMessage: 'My Profile',
  },
  intro: {
    id: 'app.components.KmVreters.Profile.intro',
    defaultMessage:
      'If you complete this profile, your information will be listed in the <a href="ranking">Ranking</a>.',
  },
  name: {
    id: 'app.components.KmVreters.Profile.name',
    defaultMessage: 'Your Name',
  },
  sex: {
    id: 'app.components.KmVreters.Profile.sex',
    defaultMessage: 'Sex',
  },
  male: {
    id: 'app.components.KmVreters.Profile.male',
    defaultMessage: 'Male',
  },
  female: {
    id: 'app.components.KmVreters.Profile.female',
    defaultMessage: 'Female',
  },
  yob: {
    id: 'app.components.KmVreters.Profile.yob',
    defaultMessage: 'Year of Birth',
  },
  importCommand: {
    id: 'app.components.KmVreters.Profile.importCommand',
    defaultMessage: 'Import now',
  },
  club: {
    id: 'app.components.KmVreters.Profile.club',
    defaultMessage: 'Club/Running Group',
  },
  noClub: {
    id: 'app.components.KmVreters.Profile.noClub',
    defaultMessage: 'Not member of a club',
  },
  clubMissing: {
    id: 'app.components.KmVreters.Profile.clubMissing',
    defaultMessage:
      'Is your club not in the list? Contact us on the <a href="https://runnermaps.freshdesk.com/">Support Site</a>',
  },
});
