/**
 *
 * Distances
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Table } from 'react-bootstrap';
import withSettings from 'containers/SettingsProvider/withSettings';
import withDialog from 'containers/Dialog/withDialog';
import withKvApi from 'containers/KmVretersPage/withKvApi';
import GChart from 'components/GChart';
import { GC_GREEN_LINE, GC_GREEN_AXIS } from 'components/GChart/constants';
import Switch from 'components/Switch';
import Pagination from 'components/Pagination';
import { PAGESIZE } from 'utils/constants';
import formatDate from 'utils/formatDate';
import formatTime from 'utils/formatTime';
import toggleState from 'utils/toggleState';
import listByDistances from './listByDistances';
import messages from '../messages';
import getGraphData from './getGraphData';
import showLink from '../Races/showLink';

const SwitchWrapper = styled(Switch)`
  margin: 15px;
`;

const Wrapper = styled(Table)`
  @media (max-width: 479px) {
    font-size: smaller;
  }
  th,
  td {
    text-align: right;
  }
  .td-link {
    text-align: center;
  }
`;

/* eslint-disable react/prefer-stateless-function */
class Distances extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { selectedOnly: true, offset: 0 };
    this.toggleSelected = toggleState('selectedOnly').bind(this);
  }

  handlePaging = offset => {
    this.setState({ offset });
  };

  render() {
    const { active, data, units, intl } = this.props;
    const { selectedOnly, offset } = this.state;
    const { list } = data.value;
    const tableList = listByDistances(list, units);
    const filteredList = tableList.filter(item => item.rank < PAGESIZE);
    const slicedList = selectedOnly
      ? filteredList
      : tableList.slice(offset, offset + PAGESIZE);

    const headers = [
      'distance',
      'speed',
      'speed',
      'speed',
      'speed',
      { type: 'string', role: 'tooltip' },
    ];
    const graphData = getGraphData(slicedList, units).map(item => [
      ...item.data,
      intl.formatMessage(
        item.quartiles ? messages.fullTooltip : messages.shortTooltip,
        item.values,
      ),
    ]);
    const chartProps = {
      id: `chart-distances`,
      data: [headers, ...graphData],
      active,
      options: {
        legend: { position: 'none' },
        title: intl.formatMessage(messages.distanceTitle),
        series: [{ color: GC_GREEN_LINE }],
        candlestick: {
          risingColor: {
            strokeWidth: 0,
            fill: GC_GREEN_LINE,
          }, // green
        },
        vAxes: [
          {
            title: `${units}/${intl.formatMessage(messages.hr)}`,
            ...GC_GREEN_AXIS,
          },
        ],
      },
      chartType: 'CandlestickChart',
      width: '100%',
      height: '200px',
    };

    return (
      <React.Fragment>
        {tableList.length > PAGESIZE && (
          <SwitchWrapper
            id="selectedOnly"
            value={selectedOnly}
            onChange={this.toggleSelected}
            size="small"
            inline
          >
            <FormattedMessage {...messages.selectedOnly} />
          </SwitchWrapper>
        )}
        <GChart {...chartProps} />
        <Wrapper responsive hover>
          <thead>
            <tr>
              <th>
                <FormattedMessage {...messages.distance} />
              </th>
              <th>
                <FormattedMessage {...messages.number} />
              </th>
              <th>
                <FormattedMessage {...messages.avgTime} />
              </th>
              <th>
                <FormattedMessage {...messages.bestTime} />
              </th>
              <th>
                <FormattedMessage {...messages.date} />
              </th>
              <th className="td-link">
                <FormattedMessage {...messages.link} />
              </th>
            </tr>
          </thead>
          <tbody>
            {slicedList.map(item => (
              <tr key={item.distance}>
                <td className="td-distance">{`${item.distance} ${units}`}</td>
                <td className="td-number">{item.number}</td>
                <td className="td-time">{formatTime(item.avgTime)}</td>
                <td className="td-time">{formatTime(item.times[0])}</td>
                <td className="td-date" title={item.title}>
                  {formatDate(item.date, 'PP')}
                </td>
                <td className="td-link">{showLink(item.link)}</td>
              </tr>
            ))}
          </tbody>
        </Wrapper>
        {!selectedOnly && (
          <Pagination
            value={offset}
            length={tableList.length}
            onChange={this.handlePaging}
          />
        )}
      </React.Fragment>
    );
  }
}

Distances.propTypes = {
  active: PropTypes.bool,
  data: PropTypes.object.isRequired,
  units: PropTypes.string.isRequired,
  dlgConfirm: PropTypes.func.isRequired,
  apiChange: PropTypes.func.isRequired,
  intl: intlShape,
};

export default compose(
  injectIntl,
  withDialog,
  withKvApi,
  withSettings(['units']),
)(Distances);
