/**
 *
 * CreateRaces
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { useRequest } from 'utils/request';
import { Alert } from 'react-bootstrap';
import ListRaces from './ListRaces';
import messages from './messages';

function CreateRaces(props) {
  const { user } = props;
  const [races] = useRequest({ url: '/virtual/races', user });
  if (!races || races.loading) {
    return null;
  }
  if (races.error) {
    return (
      <Alert bsStyle="danger">
        {races.error.messages || races.error.toString()}
      </Alert>
    );
  }
  const future = [];
  const past = [];
  const today = new Date().toISOString().slice(0, 10);
  races.forEach(r => (r.date < today ? past.unshift(r) : future.push(r)));
  return (
    <ListRaces
      caption={messages.racesCreated}
      races={[...future, ...past]}
      type="created"
    />
  );
}

CreateRaces.propTypes = {
  user: PropTypes.object,
};
export default CreateRaces;
