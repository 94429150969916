import { useCurrentUser } from 'containers/User';
import { API2_URL } from 'config/constants';
import request from 'utils/request';

function useAuthRequest() {
  const user = useCurrentUser();
  return (url, options = {}) => {
    const fullUrl = `${API2_URL}/${url}`;
    const { headers, body, ...rest } = options;
    const allOptions = {
      ...rest,
      headers: {
        ...headers,
        Authorization: user.api.hashKey,
      },
    };
    if (body) {
      allOptions.body = JSON.stringify(body);
      allOptions.headers['Content-Type'] = 'application/json';
    }
    return request(fullUrl, allOptions);
  };
}

export default useAuthRequest;
