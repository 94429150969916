/**
 *
 * Auth
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'redux';
import { Alert, Modal } from 'react-bootstrap';
import withRoute from 'containers/withRoute';
import withUser from 'containers/User/withUser';
import { FormattedMessage } from 'react-intl';
import FirebaseAuth from './FirebaseAuth';
import EmailForm from './EmailForm';
import ApiStatus from './ApiStatus';
import UserError from './UserError';
import messages from './messages';

const Wrapper = styled(Modal)`
  .modal-dialog {
    max-width: 380px;
  }
`;

const STAGE_OPTIONS = 1;
const STAGE_EMAIL = 2;
const STAGE_SENT = 3;

class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = { stage: STAGE_OPTIONS, email: '' };
    this.handleClose = this.handleClose.bind(this);
    this.useEmail = this.useEmail.bind(this);
    this.setEmail = this.setEmail.bind(this);
  }

  handleClose() {
    const { user, signOut, hashClear } = this.props;
    if (user.error) {
      signOut();
    }
    hashClear();
  }

  useEmail() {
    this.setState({ stage: STAGE_EMAIL });
  }

  setEmail(email) {
    const { signInWithEmail } = this.props;
    signInWithEmail(email);
    this.setState({ stage: STAGE_SENT, email });
  }

  render() {
    const { user } = this.props;
    const { stage, email } = this.state;
    return (
      <Wrapper show onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage {...messages.login} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <FormattedMessage {...messages.intro} />
          </p>
          {stage === STAGE_OPTIONS && <FirebaseAuth onEmail={this.useEmail} />}
          {stage === STAGE_EMAIL && <EmailForm onSubmit={this.setEmail} />}
          {stage === STAGE_SENT && (
            <Alert>
              <FormattedMessage {...messages.submitted} values={{ email }} />
            </Alert>
          )}
          <UserError user={user} />
          <ApiStatus user={user} />
        </Modal.Body>
      </Wrapper>
    );
  }
}

Auth.propTypes = {
  user: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired,
  hashClear: PropTypes.func.isRequired,
  signInWithEmail: PropTypes.func.isRequired,
};

export default compose(
  withUser,
  withRoute,
)(Auth);
