function formatTime(signedSecs, dec, forceHours) {
  const secs = Math.abs(signedSecs);
  const sign = signedSecs < 0 ? '-' : '';
  const dateStr = new Date(1000 * secs).toUTCString();
  const timeStr = dateStr.split(' ').reverse()[1];
  let result = forceHours ? timeStr : timeStr.replace(/^[0:]*(\d+:\d+)/, '$1');
  if (dec) {
    result += secs
      .toFixed(3)
      .replace(/^\d+/, '')
      .slice(0, dec - 3);
  }
  return `${sign}${result}`;
}

export default formatTime;
