import Track from './Track';

function trackUiToggle(state, action) {
  const { prop } = action;
  const newState = { ...state, [prop]: !state[prop] };
  switch (action.prop) {
    case 'onlyOwnRoutes':
    case 'showRoutes':
    case 'distanceFilter':
      if (newState.showRoutes) {
        Track.event({
          category: 'Map',
          action: 'Show routes',
          label: `${newState.onlyOwnRoutes ? 'own' : 'all'}${
            newState.distanceFilter ? '+filter' : ''
          }`,
        });
      }
      break;
    case 'showLocations':
      if (newState.showLocations) {
        Track.event({
          category: 'Map',
          action: 'Show locations',
        });
      }
      break;
    default:
    // No tracking
  }
}

export default trackUiToggle;
