/**
 *
 * VirtualPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useCurrentUser, useStrava } from 'containers/User';
import { Wrapper } from 'containers/KmVretersPage';
import MetaTags from 'components/MetaTags';
import Spinner from 'components/Spinner';
import Footer from 'components/Footer';
import Virtual from 'components/Virtual';
import VirtualEdit from 'components/Virtual/VirtualEdit';
import VirtualView from 'components/Virtual/VirtualView';
import VirtualAdmin from 'components/Virtual/VirtualAdmin';
import Jubileum from 'components/Virtual/Jubileum';
import PoweredByStrava from 'components/Virtual/PoweredByStrava';
import messages from './messages';

function VirtualPage(props) {
  const { match } = props;
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  if (searchParams.has('strava')) {
    window.stravaUser = Number(searchParams.get('strava'));
  }
  const { path, params } = match;
  const { page, hash } = params;
  const user = useCurrentUser(true);
  useStrava();
  function getComponent() {
    if (path === '/jubileum') {
      return Jubileum;
    }
    switch (page) {
      case 'edit':
      case 'create':
        return VirtualEdit;
      case 'admin':
        return VirtualAdmin;
      default:
        return hash ? VirtualView : Virtual;
    }
  }
  const Component = getComponent();
  const tags = { title: messages.title, description: messages.description };
  return (
    <Wrapper>
      <MetaTags tags={tags} />
      {user.loading ? (
        <Spinner />
      ) : (
        <Component user={user} hash={hash} page={page} />
      )}
      <PoweredByStrava scale={0.5} />
      <Footer />
    </Wrapper>
  );
}

VirtualPage.propTypes = {
  match: PropTypes.object,
};

export default VirtualPage;
