/*
 *
 * EditService constants
 *
 */

export const EDIT_ADD = 'app/EditService/EDIT_ADD';
export const EDIT_CLEAR = 'app/EditService/EDIT_CLEAR';
export const EDIT_CREATE = 'app/EditService/EDIT_CREATE';
export const EDIT_DELETE = 'app/EditService/EDIT_DELETE';
export const EDIT_DRAG = 'app/EditService/EDIT_DRAG';
export const EDIT_EDIT = 'app/EditService/EDIT_EDIT';
export const EDIT_IMPORT = 'app/EditService/EDIT_IMPORT';
export const EDIT_INSERT = 'app/EditService/EDIT_INSERT';
export const EDIT_MOVE = 'app/EditService/EDIT_MOVE';
export const EDIT_REPLACE = 'app/EditService/EDIT_REPLACE';
export const EDIT_ROUTING = 'app/EditService/EDIT_ROUTING';
export const EDIT_UNDO = 'app/EditService/EDIT_UNDO';
export const EDIT_SAVE = 'app/EditService/EDIT_SAVE';
export const EDIT_GEOCODE = 'app/EditService/EDIT_GEOCODE';

export const STATUS_NONE = 0;
export const STATUS_START = 1;
export const STATUS_EDITING = 2;
