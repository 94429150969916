/* eslint-disable react/no-array-index-key */
/**
 * ActivityChart
 */

import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMeasure } from 'react-use';
import { Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  XAxis,
  YAxis,
} from 'recharts';
import { blue500, yellow500, red500, blue100, red100 } from 'styles/colors';
import formatTime from 'utils/formatTime';
import messages from '../messages';
import ActivityLegend from './ActivityLegend';
import processData from './processData';
import EffInfo from './EffInfo';

function formatPercentage(value) {
  return `${value < 0 ? '' : '+'}${value.toFixed(1)}%`;
}

function ActivityChart(props) {
  const { data, times, distance } = props;
  const { start, end, duration } = times;

  const [ref, { width }] = useMeasure();
  const margin = { top: 5, right: 0, left: 0, bottom: 5 };
  const chartData = useMemo(() => processData(data, start, end), [
    data,
    start,
    end,
  ]);

  const lines = [
    {
      key: 'trendSpeed',
      yAxisId: 'speed',
      color: blue100,
      strokeWidth: 5,
      strokeDasharray: '5 5',
    },
    {
      key: 'trendHeartrate',
      yAxisId: 'heartrate',
      color: red100,
      strokeWidth: 5,
      strokeDasharray: '5 5',
    },
    {
      key: 'speed',
      yAxisId: 'speed',
      color: blue500,
      label: 'km/h',
      text: messages.speed,
    },
    {
      key: 'heartrate',
      yAxisId: 'heartrate',
      color: red500,
      label: 'bpm',
      text: messages.heartrate,
    },
  ];

  return (
    <Fragment>
      <div ref={ref} className="mb-4">
        <LineChart
          width={width}
          height={300}
          data={chartData.data}
          margin={margin}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="time"
            type="time"
            tickFormatter={t => formatTime(t, 0)}
            minTickGap={60}
          />
          <Legend content={ActivityLegend} verticalAlign="top" />
          {lines.map(
            ({ key, color, label, yAxisId }) =>
              label ? (
                <Fragment key={key}>
                  <YAxis
                    key={key}
                    yAxisId={key}
                    domain={['auto', 'auto']}
                    orientation={key.includes('speed') ? 'left' : 'right'}
                    label={{ value: label, angle: -90 }}
                  />
                  <Line
                    dataKey={key}
                    dot={false}
                    stroke={color}
                    animationDuration={500}
                    yAxisId={key}
                  />
                </Fragment>
              ) : (
                <Line
                  key={key}
                  dataKey={key}
                  dot={false}
                  stroke={color}
                  strokeWidth={5}
                  strokeDasharray="5 5"
                  animationDuration={500}
                  yAxisId={yAxisId}
                />
              ),
          )}
          <ReferenceArea
            x1={start}
            x2={end}
            yAxisId="speed"
            fill={yellow500}
            fillOpacity={0.15}
          />
        </LineChart>
      </div>
      <div>
        <Table responsive className="w-auto">
          <thead>
            <tr>
              <th>
                <FormattedMessage {...messages.metric} />
              </th>
              <th className="text-center" colSpan="2">
                <FormattedMessage {...messages.average} />
              </th>
              <th className="text-center">
                <FormattedMessage {...messages.split} />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <FormattedMessage {...messages.pace} />
              </td>
              <td className="text-right pr-0">
                {formatTime((1000 * duration) / distance, 0)}
              </td>
              <td className="pl-1">km/h</td>
              <td className="text-right">
                {formatPercentage(chartData.paceDecay)}
              </td>
            </tr>
            <tr>
              <td>
                <FormattedMessage {...messages.heartrate} />
              </td>
              <td className="text-right pr-0">
                {chartData.averageHeartrate.toFixed(0)}
              </td>
              <td className="pl-1">bpm</td>
              <td className="text-right">
                {formatPercentage(-chartData.hrDecay)}
              </td>
            </tr>
            <tr>
              <td>
                <FormattedMessage {...messages.efficiencyFactor} />
                <EffInfo />
              </td>
              <td className="text-right pr-0">
                {chartData.efficiencyFactor.toFixed(2)}
              </td>
              <td className="pl-1"> </td>
              <td className="text-right">
                {formatPercentage(chartData.efDecay)}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
}

ActivityChart.propTypes = {
  data: PropTypes.array.isRequired,
  times: PropTypes.object.isRequired,
  distance: PropTypes.number.isRequired,
};

export default ActivityChart;
