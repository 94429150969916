/*
 * VirtualPage Messages
 *
 * This contains all the text for the VirtualPage component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.VirtualPage.title',
    defaultMessage: 'RunnerMaps Test Runs',
  },
  description: {
    id: 'app.components.VirtualPage.description',
    defaultMessage: 'Join a test run and run the distance with Strava',
  },
});
