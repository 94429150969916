/**
 *
 * AllRaces
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { useRequest } from 'utils/request';
import ListRaces from '../ListRaces';
import messages from '../messages';

function AllRaces(props) {
  const { user } = props;
  const [races] = useRequest({ url: '/virtual/admin/races', user });
  if (!races || races.loading) {
    return null;
  }
  const future = [];
  const past = [];
  const today = new Date().toISOString().slice(0, 10);
  races.forEach(
    r => ((r.end_date || r.date) >= today ? future.push(r) : past.unshift(r)),
  );
  return (
    <React.Fragment>
      <ListRaces
        caption={messages.upcomingRaces}
        races={future}
        type="all"
        pageSize={999}
      />
      <ListRaces caption={messages.pastRaces} races={past} type="all" />
    </React.Fragment>
  );
}

AllRaces.propTypes = {
  user: PropTypes.object,
};
export default AllRaces;
