/**
 * https://gist.github.com/zensh/4975495
 */

const ignoreObjects = [];

function MemorySize() {
  this.objects = [...ignoreObjects];
}

MemorySize.prototype.objectSize = function objectSize(obj) {
  if (this.objects.includes(obj)) {
    return 0;
  }
  this.objects.push(obj);
  const objClass = Object.prototype.toString(obj).slice(8, -1);
  switch (objClass) {
    case 'Array':
    case 'Object':
      return Object.keys(obj).reduce(
        (sum, key) => sum + this.sizeOf(key) + this.sizeOf(obj[key]),
        0,
      );
    case 'Date':
      return 8; // size of Date?
    default:
      console.log('object', objClass);
      if (obj.byteLength) {
        return obj.byteLength;
      }
      return obj.toString().length * 2;
  }
};

MemorySize.prototype.sizeOf = function sizeOf(obj) {
  if (obj === null || obj === undefined) {
    return 0;
  }
  switch (typeof obj) {
    case 'number':
      return 8;
    case 'string':
      return obj.length * 2;
    case 'boolean':
      return 4;
    case 'object':
      return this.objectSize(obj);
    default:
      if (obj.byteLength !== undefined) {
        return obj.byteLength;
      }
      return 8; // size of Date?
  }
};

MemorySize.prototype.propSize = function propSize(obj) {
  return Object.keys(obj).reduce(
    (acc, key) => ({ ...acc, [key]: this.sizeOf(obj[key]) }),
    {},
  );
};

function memorySizeOf(obj, sub) {
  const memSize = new MemorySize();
  return sub ? memSize.propSize(obj) : memSize.sizeOf(obj);
}

export function ignoreMemory(obj) {
  if (!ignoreObjects.includes(obj)) {
    ignoreObjects.push(obj);
  }
}

export default memorySizeOf;
