function addScript(options) {
  return new Promise(resolve => {
    // Create scriptelement with options
    const defaultOptions = {
      async: true,
      type: 'text/javascript',
    };
    const { wait, reload, ...attributes } = { ...defaultOptions, ...options };
    const scriptElement = document.createElement('script');
    Object.keys(attributes).forEach(key => {
      scriptElement[key] = attributes[key];
    });
    const head = document.getElementsByTagName('head')[0];
    // Check for existing and possible need to reload
    if (reload) {
      if (attributes.id) {
        const current = document.getElementById(attributes.id);
        if (current) {
          head.removeChild(current);
        }
      }
      if (wait && window[wait]) {
        delete window[wait];
      }
    } else if (attributes.id) {
      const current = document.getElementById(attributes.id);
      if (current) {
        resolve(wait && window[wait]);
        return;
      }
    }
    // Load script
    head.appendChild(scriptElement);
    if (!wait) {
      resolve();
    }
    const timer = setInterval(() => {
      if (window[wait]) {
        clearInterval(timer);
        resolve(window[wait]);
      }
    }, 100);
  });
}

export default addScript;
