/**
 *
 * ChartControls
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { green500 } from 'styles/colors';
import messages from '../messages';

const PERIODS = {
  3: 'period_3',
  12: 'period_12',
  36: 'period_36',
  all: 'period_all',
};

const DISTANCES = {
  3000: '3 km',
  5000: '5 km',
  10000: '10 km',
};

const isProduction = true; // window.location.hostname === 'runnermaps.nl';

function ChartControls(props) {
  const { settings } = props;
  const { refDist, setRefDist, period, setPeriod, xType, setXType } = settings;

  return (
    <div className="d-flex justify-content-around flex-wrap">
      <FormGroup className="d-flex align-items-center mr-2">
        <ControlLabel htmlFor="period" className="text-right mr-2">
          <FormattedMessage {...messages.showPeriod} />
        </ControlLabel>
        <select
          id="period"
          value={period}
          onChange={e => setPeriod(e.target.value)}
          className="form-control w-auto"
        >
          {Object.keys(PERIODS).map(p => (
            <FormattedMessage key={p} {...messages[PERIODS[p]]}>
              {txt => <option value={p}>{txt}</option>}
            </FormattedMessage>
          ))}
        </select>
      </FormGroup>
      <FormGroup className="d-flex align-items-center mr-2">
        <ControlLabel htmlFor="refDist" className="text-right mr-2">
          <FormattedMessage {...messages.refDist} />
        </ControlLabel>
        <select
          id="refDist"
          value={refDist}
          onChange={e => setRefDist(e.target.value)}
          className="form-control w-auto"
        >
          {Object.keys(DISTANCES).map(d => (
            <option key={d} value={d}>
              {DISTANCES[d]}
            </option>
          ))}
        </select>
      </FormGroup>
      {!isProduction && (
        <FormGroup className="d-flex align-items-center">
          <ControlLabel htmlFor="timeScale" className="text-right mr-2">
            <FormattedMessage {...messages.timeScale} />
          </ControlLabel>
          <Switch
            id="timeScale"
            onChange={v => setXType(v ? 'number' : 'category')}
            checked={xType === 'number'}
            onColor={green500}
          />
        </FormGroup>
      )}
    </div>
  );
}

ChartControls.propTypes = {
  settings: PropTypes.object,
};

export default ChartControls;
