import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the settings state domain
 */
const selectSettings = state => state.get('settings', initialState);

const selectLocale = state => state.get('settings', initialState).get('locale');

/**
 * Select the settings
 */

const makeSelectLocale = () =>
  createSelector(selectSettings, settingsState => settingsState.get('locale'));

const makeSelectSettings = setting => () =>
  createSelector(selectSettings, settingsState => settingsState.get(setting));

export { selectLocale, selectSettings, makeSelectSettings, makeSelectLocale };
