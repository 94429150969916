/*
 * Reservations Messages
 *
 * This contains all the text for the Reservations component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.Reservations.header',
    defaultMessage: 'Smart Reservations List',
  },
  owner: {
    id: 'app.components.Reservations.owner',
    defaultMessage: 'Owner',
  },
  member: {
    id: 'app.components.Reservations.member',
    defaultMessage: 'Member',
  },
  intro1: {
    id: 'app.components.Reservations.intro1',
    defaultMessage:
      'On the smart reservations list, all group members can register themselves for each training session. If there are sufficient spots, everyone can come. If not, those that have not attended the previous time, or the time before that, or before that, will be first.',
  },
  intro2: {
    id: 'app.components.Reservations.intro2',
    defaultMessage:
      'This way everyone can join as often as anyone else, and the coach does not have to make a schedule. Also, it is flexible, so if you have to cancel, your turn is not lost, but next time you will have higher priority.',
  },
  intro3: {
    id: 'app.components.Reservations.intro3',
    defaultMessage:
      'Register at least 24 hours in advance. At that moment the order will become final, so you know in advance whether you can join or not. You can still register after that, if there are still spots left, otherwise you will be on the waiting list.',
  },
  forCoaches: {
    id: 'app.components.Reservations.forCoaches',
    defaultMessage: 'For Coaches',
  },
  createGroup: {
    id: 'app.components.Reservations.createGroup',
    defaultMessage: 'Create a Group',
  },
  coachText: {
    id: 'app.components.Reservations.coachText',
    defaultMessage: ' to get started.',
  },
  forAthletes: {
    id: 'app.components.Reservations.forAthletes',
    defaultMessage: 'For Athletes',
  },
  athleteText: {
    id: 'app.components.Reservations.athleteText',
    defaultMessage:
      'Tell your coach to use this reservations list. He/She will share the link for your group.',
  },
  editGroup: {
    id: 'app.components.Reservations.editGroup',
    defaultMessage: 'Update a Group',
  },
  createInfo: {
    id: 'app.components.Reservations.createInfo',
    defaultMessage:
      'Give a name for the group and add weekly training sessions.',
  },
  groupName: {
    id: 'app.components.Reservation.groupName',
    defaultMessage: 'Name for this group',
  },
  weekday: {
    id: 'app.components.Reservations.weekday',
    defaultMessage: 'Day of the week',
  },
  startTime: {
    id: 'app.components.Reservations.startTime',
    defaultMessage: 'Start time',
  },
  maxSize: {
    id: 'app.components.Reservations.maxSize',
    defaultMessage: 'Max. number of participants',
  },
  location: {
    id: 'app.components.Reservations.location',
    defaultMessage: 'Location',
  },
  weekdays: {
    id: 'app.components.Reservations.weekdays',
    defaultMessage: 'Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday',
  },
  addSession: {
    id: 'app.components.Reservations.addSession',
    defaultMessage: 'Add Training Session',
  },
  trainingSessions: {
    id: 'app.components.Reservations.trainingSessions',
    defaultMessage: 'Training Sessions',
  },
  latestSession: {
    id: 'app.components.Reservations.latestSession',
    defaultMessage: 'Latest Session',
  },
  noSessions: {
    id: 'app.components.Reservations.noSessions',
    defaultMessage: 'No training session planned.',
  },
  members: {
    id: 'app.components.Reservations.members',
    defaultMessage: 'Members of {name}',
  },
  noMembers: {
    id: 'app.components.Reservations.noMembers',
    defaultMessage: 'There are no group members yet.',
  },
  name: {
    id: 'app.containers.Virtual.name',
    defaultMessage: 'Name',
  },
  registered: {
    id: 'app.components.Reservations.registered',
    defaultMessage: 'Registered',
  },
  attended: {
    id: 'app.components.Reservations.attended',
    defaultMessage: 'Sessions Attended',
  },
  time: {
    id: 'app.components.Reservations.time',
    defaultMessage: 'Time',
  },
  status: {
    id: 'app.components.Reservations.status',
    defaultMessage: 'Status',
  },
  waitingList: {
    id: 'app.components.Reservations.waitingList',
    defaultMessage: 'Waiting List',
  },
  spotsLeft: {
    id: 'app.components.Reservations.spotsLeft',
    defaultMessage: 'Still {count, plural, one {# spot} other {# spots}} left',
  },
  check: {
    id: 'app.components.Reservations.check',
    defaultMessage:
      "Confirmed: you can join. If you can't join any more, please cancel so someone from the waiting list can take your spot.",
  },
  bars: {
    id: 'app.components.Reservations.bars',
    defaultMessage:
      'Registered: you are registered on this preliminary list, but someone with a higher priority can still join up to 24 hours before the start of the session.',
  },
  times: {
    id: 'app.components.Reservations.times',
    defaultMessage:
      'Waitlisted: check the list later on, in case someone has cancelled.',
  },
  groupMember: {
    id: 'app.components.Reservations.groupMember',
    defaultMessage: 'Group Member',
  },
  noMember: {
    id: 'app.components.Reservations.noMember',
    defaultMessage: 'Not a Member',
  },
  noJoins: {
    id: 'app.components.Reservations.noJoins',
    defaultMessage: 'No one has joined the session yet',
  },
  joinGroup: {
    id: 'app.components.Reservations.joinGroup',
    defaultMessage: 'Join Group',
  },
  save: {
    id: 'app.components.KmVreters.Edit.save',
    defaultMessage: 'Save',
  },
  confirmSession: {
    id: 'app.components.Reservations.confirmSession',
    defaultMessage: 'Cancel your registration for this session?',
  },
  confirmGroup: {
    id: 'app.components.Reservations.confirmGroup',
    defaultMessage:
      'Leave this group? This will also cancel any registrations for upcoming sessions.',
  },
  no: {
    id: 'app.containers.Dialog.no',
    defaultMessage: 'No',
  },
  yesGroup: {
    id: 'app.components.Reservations.yesGroup',
    defaultMessage: 'Yes, Leave',
  },
  yesSession: {
    id: 'app.components.Reservations.yesSession',
    defaultMessage: 'Yes, Cancel',
  },
  noSession: {
    id: 'app.components.Reservations.noSession',
    defaultMessage: '{date} - No training session',
  },
  coach: {
    id: 'app.components.Reservations.coach',
    defaultMessage: 'Coach',
  },
  coachInfo: {
    id: 'app.components.Reservations.coachInfo',
    defaultMessage:
      'A coach can see the members list and edit the group settings.',
  },
  autoRegNot: {
    id: 'app.components.Reservations.autoRegNot',
    defaultMessage: 'You have not set a fixed day yet',
  },
  autoRegSet: {
    id: 'app.components.Reservations.autoRegSet',
    defaultMessage: 'Fixed day: <b>{day}</b>.',
  },
  autoRegCancel: {
    id: 'app.components.Reservations.autoRegCancel',
    defaultMessage: "Please cancel here if you can't make it.",
  },
  autoRegister: {
    id: 'app.components.Reservations.autoRegister',
    defaultMessage: 'Always join session on',
  },
  autoRegisterHelp: {
    id: 'app.components.Reservations.autoRegisterHelp',
    defaultMessage:
      'You will be added to any new session on these days/locations.',
  },
  none: {
    id: 'app.components.Reservations.none',
    defaultMessage: 'None',
  },
  modalTitle: {
    id: 'app.components.ExplanationModal.header',
    defaultMessage: 'How It Works',
  },
  explanation: {
    id: 'app.components.ExplanationModal.explanation',
    defaultMessage: 'Explanation',
  },
  understood: {
    id: 'app.components.ExplanationModal.understood',
    defaultMessage: 'OK, clear!',
  },
});
