import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useFetch from './useFetch';

function useList() {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const fetch = useFetch();
  const { search } = useLocation();
  const email = new URLSearchParams(search).get('email') || '';

  function handleSearch() {
    setLoading(true);
    fetch('manage/users', { email })
      .then(setList)
      .catch(console.error) // eslint-disable-line no-console
      .finally(() => setLoading(false));
  }

  useEffect(
    () => {
      if (email) {
        handleSearch();
      } else {
        setList([]);
      }
    },
    [email],
  );

  return {
    loading,
    list,
    email,
  };
}

export default useList;
