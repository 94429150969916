/**
 *
 * withUser HOC component to add user info and actions
 *
 */

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  userProfile,
  userSignOut,
  userSendSignUp,
  userSimulate,
} from './actions';
import makeSelectUser from './selectors';

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

function mapDispatchToProps(dispatch) {
  return {
    updateProfile: data => dispatch(userProfile(data)),
    simulate: email => dispatch(userSimulate(email)),
    signOut: user => dispatch(userSignOut(user)),
    signInWithEmail: email => dispatch(userSendSignUp(email)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default withConnect;
