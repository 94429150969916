/*
 *
 * SettingsProvider constants
 *
 */

import storage from 'utils/storage';
import browserLanguage from 'utils/browserLanguage';

export const CHANGE_SETTING = 'app/LanguageToggle/CHANGE_SETTING';

export const DEFAULT_LOCALE = 'nl';
export const INITIAL_LOCALE =
  storage.getItem('locale') || browserLanguage(['en', 'nl']) || DEFAULT_LOCALE;

export const CHOICES = {
  locale: ['nl', 'en'],
  units: ['km', 'mi'],
};
