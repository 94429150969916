/*
 * MapPage Messages
 *
 * This contains all the text for the MapPage component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.containers.MapPage.title',
    defaultMessage: 'RunnerMaps: Running on the Map',
  },
  description: {
    id: 'app.containers.MapPage.description',
    defaultMessage:
      'Easily create and share running, walking, and cycling routes on the map',
  },
});
