import { takeEvery, takeLatest, select, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { hashClear } from 'containers/withRoute/actions';
import { API_URL } from 'config/constants';
import request from 'utils/request';
import storage from 'utils/storage';
import { encrypt } from 'utils/cryptr';
import sleep from 'utils/sleep';
import firebase from './firebase';
import {
  USER_SIGNOUT,
  USER_CHANGE,
  USER_SIMULATE,
  USER_LOADING,
  USER_API,
  USER_SEND_SIGNUP,
  USER_CONFIRM_SIGNUP,
} from './constants';
import { userLoading, userApi, userError } from './actions';
import makeSelectUser from './selectors';
import { sendSignUp, confirmSignUp } from './handleSignUp';

// Individual exports for testing

function* waitLoading(action) {
  if (action.value === true) {
    yield sleep(5000);
    yield put(userLoading(false));
  }
}

function* doneLoading() {
  yield put(userLoading(false));
}

function* saveInfo() {
  const user = yield select(makeSelectUser());
  const { email, su, photoURL, api } = user;
  storage.setItem(
    'userInfo',
    encrypt(JSON.stringify({ email, su, photoURL, pmDate: api && api.pm })),
  );
}

function* signOut() {
  try {
    storage.removeItem('userInfo');
    yield firebase.auth().signOut();
  } catch (error) {
    yield put(userError(error));
  }
}

function* userChanged(action) {
  if (action.user) {
    const { email } = action.user.providerData[0];
    if (!email) {
      yield put(userError('noEmail'));
      return;
    }
    const code = btoa(email);
    const result = yield request(`${API_URL}/user/${code}`);
    yield put(userApi(result));
    if (result.isLogged && window.location.hash === '#login') {
      yield put(hashClear());
    }
  }
}

function* userSimulate(action) {
  const { email } = action;
  if (!email) {
    yield put(userError('noEmail'));
    return;
  }
  const code = btoa(email);
  const result = yield request(`${API_URL}/user/${code}`);
  yield put(userApi(result));
  if (result.isLogged && window.location.hash === '#login') {
    yield put(hashClear());
  }
}

function* userSendSignUp(action) {
  const { type, email } = action;
  try {
    const result = yield sendSignUp(email);
    storage.setItem('email', email);
    yield put({ ...action, type: `${type}_SUCCESS`, payload: result });
  } catch (error) {
    yield put({ ...action, type: `${type}_ERROR`, error });
  }
}

function* userConfirmSignUp(action) {
  const { type, email } = action;
  try {
    const result = yield confirmSignUp(email);
    storage.removeItem('email');
    yield put(push(window.location.pathname));
    yield put({ ...action, type: `${type}_SUCCESS`, payload: result });
  } catch (error) {
    yield put({ ...action, type: `${type}_ERROR`, error });
  }
}

export default function* defaultSaga() {
  yield takeEvery(USER_SIGNOUT, signOut);
  yield takeEvery(USER_CHANGE, userChanged);
  yield takeEvery(USER_SIMULATE, userSimulate);
  yield takeEvery(USER_LOADING, waitLoading);
  yield takeEvery(USER_API, doneLoading);
  yield takeEvery(USER_API, saveInfo);
  yield takeLatest(USER_SEND_SIGNUP, userSendSignUp);
  yield takeLatest(USER_CONFIRM_SIGNUP, userConfirmSignUp);
}
