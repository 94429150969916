/*
 * Account Messages
 *
 * This contains all the text for the AccountModal component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.AccountModal.header',
    defaultMessage: 'My Account',
  },
  email: {
    id: 'app.components.AskEmail.email',
    defaultMessage: 'Email Address',
  },
  premiumUntil: {
    id: 'app.components.AccountModal.premiumUntil',
    defaultMessage: 'Subscription Until',
  },
  thanks: {
    id: 'app.components.AccountModal.thanks',
    defaultMessage: 'Thanks for your support!',
  },
  recurring: {
    id: 'app.components.AccountModal.recurring',
    defaultMessage: 'Your subscription will automatically be extended.',
  },
  cancelRecurring: {
    id: 'app.components.AccountModal.cancelRecurring',
    defaultMessage: 'Cancel',
  },
  confirmCancel: {
    id: 'app.components.AccountModal.confirmCancel',
    defaultMessage: 'Cancel the automatic extension of your subscription?',
  },
  extend: {
    id: 'app.components.AccountModal.extend',
    defaultMessage: 'Extend',
  },
  kmvProfile: {
    id: 'app.components.AccountModal.kmvProfile',
    defaultMessage: 'Mile Eaters Profile',
  },
  stravaProfile: {
    id: 'app.components.AccountModal.stravaProfile',
    defaultMessage: 'Strava Profile',
  },
  M: {
    id: 'app.containers.Virtual.M',
    defaultMessage: 'M',
  },
  F: {
    id: 'app.containers.Virtual.F',
    defaultMessage: 'F',
  },
  V: {
    id: 'app.containers.Virtual.F',
    defaultMessage: 'F',
  },
  private: {
    id: 'app.components.AccountModal.private',
    defaultMessage: '(private)',
  },
  nRoutes: {
    id: 'app.components.AccountModal.nRoutes',
    defaultMessage: 'Aantal routes',
  },
  nRaces: {
    id: 'app.components.AccountModal.nRaces',
    defaultMessage: '{count} races',
  },
  supporterText: {
    id: 'app.components.AccountModal.supporterText',
    defaultMessage:
      'Support the further development of RunnerMaps and get a subscription for a better experience',
  },
  supporterAction: {
    id: 'app.components.AccountModal.supporterAction',
    defaultMessage: 'More about the RunnerMaps Subscription',
  },
  paymentOK: {
    id: 'app.components.AccountModal.paymentOK',
    defaultMessage:
      'The payment was successful. A confirmation message has been sent by email.',
  },
});
