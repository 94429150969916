/**
 *
 * AlertService
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { DAEMON } from 'utils/constants';
import reducer from './reducer';
import saga from './saga';
import { addAlert } from './actions';

class AlertService extends React.PureComponent {
  // componentDidCatch(error, info) {
  //   const { addError } = this.props;
  //   addError({ title: error.message });
  //   console.error('AlertService', { error, info }); // eslint-disable-line no-console
  // }

  render() {
    const { children } = this.props;
    return children;
  }
}

AlertService.propTypes = {
  // addError: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    addError: options => dispatch(addAlert({ ...options, style: 'danger' })),
  };
}

const withReducer = injectReducer({ key: 'alertservice', reducer });
const withSaga = injectSaga({ key: 'alertservice', saga, mode: DAEMON });

export default compose(
  withReducer,
  withSaga,
  connect(
    null,
    mapDispatchToProps,
  ),
)(AlertService);
