import { Track } from 'utils/analytics';

function trackBlocked(label) {
  // Based on https://www.labnol.org/internet/adblock-with-google-analytics/28819/
  // Get the first AdSense ad unit on the page
  window.setTimeout(() => {
    const ad = document.querySelector('ins.adsbygoogle');
    const blocked = ad && ad.innerHTML.replace(/\s/g, '').length === 0;
    Track.event({
      category: 'Adblock',
      action: blocked ? 'Yes' : 'No',
      label,
      nonInteraction: true,
    });
  }, 2000);
}

export default trackBlocked;
