/**
 *
 * Distance
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import withSettings from 'containers/SettingsProvider/withSettings';
import { MILES_KM } from 'utils/constants';

function Distance(props) {
  const { distance, units, decimals } = props;
  const dist = getDistance(distance, units, decimals);
  return (
    <span title={dist[2].toFixed(4)}>
      <span className="distance-digits">{dist[0]}</span> {dist[1]}
    </span>
  );
}

Distance.propTypes = {
  distance: PropTypes.number.isRequired,
  decimals: PropTypes.number,
  units: PropTypes.string.isRequired,
};

Distance.defaultProps = {
  decimals: 1,
};

export default withSettings(['units'])(Distance);

export function getDistance(distance, units, decimals = 1) {
  const dist = units === 'km' ? distance : distance * MILES_KM;
  return [dist.toFixed(decimals), units, dist];
}

export function getDistanceString(distance, units, decimals) {
  return getDistance(distance, units, decimals).join(' ');
}

export { default as DistanceInput } from './Input';
