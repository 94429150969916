/**
 *
 * ReservationsPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
import { useCurrentUser } from 'containers/User';
import { Wrapper } from 'containers/KmVretersPage';
import MetaTags from 'components/MetaTags';
import Spinner from 'components/Spinner';
import Footer from 'components/Footer';
import Reservations, {
  GroupEdit,
  SessionsView,
  GroupMembers,
} from 'components/Reservations';
import messages from './messages';

function ReservationsPage(props) {
  const { match } = props;
  const { page, hash } = match.params;
  const user = useCurrentUser();
  function getComponent() {
    switch (page) {
      case 'edit':
      case 'create':
        return GroupEdit;
      case 'members':
        return GroupMembers;
      default:
        return hash ? SessionsView : Reservations;
    }
  }
  const Component = getComponent();
  const tags = { title: messages.title, description: messages.description };
  return (
    <Wrapper>
      <MetaTags tags={tags} />
      {user.loading ? (
        <Spinner />
      ) : (
        <Component user={user} hash={hash} page={page} />
      )}
      <Footer />
    </Wrapper>
  );
}

ReservationsPage.propTypes = {
  match: PropTypes.object,
};

export default ReservationsPage;
