/*
 * List Messages
 *
 * This contains all the text for the List component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'app.components.KmVreters.List.title',
    defaultMessage: 'List of Races',
  },
  intro: {
    id: 'app.components.KmVreters.List.intro',
    defaultMessage: 'Below the races that {name} ran.',
  },
  introSelf: {
    id: 'app.components.KmVreters.List.introSelf',
    defaultMessage: 'Below the races that you ran.',
  },
  summary: {
    id: 'app.components.KmVreters.List.summary',
    defaultMessage:
      'The total distance is <b>{total}</b> {units}, of which <b>{current}</b> in {year}.',
  },
  summaryNone: {
    id: 'app.components.KmVreters.List.summaryNone',
    defaultMessage: 'You have not registered any races yet.',
  },
  allRaces: {
    id: 'app.components.KmVreters.List.allRaces',
    defaultMessage: 'All Races',
  },
  race: {
    id: 'app.components.KmVreters.List.race',
    defaultMessage: 'Race',
  },
  date: {
    id: 'app.components.KmVreters.List.date',
    defaultMessage: 'Date',
  },
  distance: {
    id: 'app.components.KmVreters.List.distance',
    defaultMessage: 'Distance',
  },
  avgDistance: {
    id: 'app.components.KmVreters.List.avgDistance',
    defaultMessage: 'Avg. Distance',
  },
  time: {
    id: 'app.components.KmVreters.List.time',
    defaultMessage: 'Time',
  },
  link: {
    id: 'app.components.KmVreters.List.link',
    defaultMessage: 'Link',
  },
  notes: {
    id: 'app.components.KmVreters.Edit.notes',
    defaultMessage: 'Notes',
  },
  linkText: {
    id: 'app.components.KmVreters.Edit.link',
    defaultMessage: 'Link to the Results',
  },
  edit: {
    id: 'app.components.KmVreters.List.edit',
    defaultMessage: 'Edit Race',
  },
  delete: {
    id: 'app.components.KmVreters.List.delete',
    defaultMessage: 'Delete Race',
  },
  byDistance: {
    id: 'app.components.KmVreters.List.byDistance',
    defaultMessage: 'By Distance',
  },
  byMonth: {
    id: 'app.components.KmVreters.List.byMonth',
    defaultMessage: 'By Month',
  },
  month: {
    id: 'app.components.KmVreters.List.month',
    defaultMessage: 'Month',
  },
  byYear: {
    id: 'app.components.KmVreters.List.byYear',
    defaultMessage: 'By Year',
  },
  year: {
    id: 'app.components.KmVreters.List.year',
    defaultMessage: 'Year',
  },
  countRaces: {
    id: 'app.components.KmVreters.List.countRaces',
    defaultMessage: 'Number of races: <b>{total}</b>',
  },
  countFilteredRaces: {
    id: 'app.components.KmVreters.List.countFilteredRaces',
    defaultMessage: 'Number of races: <b>{count}</b> of <b>{total}</b>',
  },
  search: {
    id: 'app.components.KmVreters.List.search',
    defaultMessage: 'Search',
  },
  addRace: {
    id: 'app.components.KmVreters.List.addRace',
    defaultMessage: 'Add Race',
  },
  confirmDelete: {
    id: 'app.components.KmVreters.List.confirmDelete',
    defaultMessage: 'Delete {name}?',
  },
  selectedOnly: {
    id: 'app.components.KmVreters.List.selectedOnly',
    defaultMessage: 'Show only most common distances',
  },
  number: {
    id: 'app.components.KmVreters.List.number',
    defaultMessage: 'Number',
  },
  avgTime: {
    id: 'app.components.KmVreters.List.avgTime',
    defaultMessage: 'Avg. Time',
  },
  bestTime: {
    id: 'app.components.KmVreters.List.bestTime',
    defaultMessage: 'Best Time',
  },
  pace: {
    id: 'app.components.KmVreters.List.pace',
    defaultMessage: 'Pace',
  },
  speed: {
    id: 'app.components.KmVreters.List.speed',
    defaultMessage: 'Speed',
  },
  hr: {
    id: 'app.components.KmVreters.List.hr',
    defaultMessage: 'h',
  },
  monthTitle: {
    id: 'app.components.KmVreters.List.monthTitle',
    defaultMessage: 'Races in the past 24 months',
  },
  distanceTitle: {
    id: 'app.components.KmVreters.List.distanceTitle',
    defaultMessage:
      'Distribution of pace by distance. The vertical line runs from lowest to highest speed, in half the races the speed was within the wider area.',
  },
  fullTooltip: {
    id: 'app.components.KmVreters.List.fullTooltip',
    defaultMessage:
      'Distance {distance}\nBest time {best}\nHalf between {q1} and {q2}',
  },
  shortTooltip: {
    id: 'app.components.KmVreters.List.shortTooltip',
    defaultMessage: 'Distance {distance}\nBest time {best}',
  },
  startUitslagen: {
    id: 'app.components.KmVreters.List.startUitslagen',
    defaultMessage:
      'A quick way to start is <a href="#import">importing your results from <b>uitslagen.nl</b></a>',
  },
  startButton: {
    id: 'app.components.KmVreters.List.startButton',
    defaultMessage: 'Add more races using the button to the right.',
  },
});
