/**
 *
 * Races
 *
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ENDASH } from 'utils/characters';
import messages from '../messages';

function showLink(link) {
  if (!link) {
    return ENDASH;
  }
  return (
    <a href={link}>
      <span className="sr-only">
        <FormattedMessage {...messages.linkText} />
      </span>
      <FontAwesomeIcon icon="external-link-alt" />
    </a>
  );
}

export default showLink;
