/**
 *
 * UpcomingRaces
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'react-bootstrap';
import { useRequest } from 'utils/request';
import ListRaces from './ListRaces';
import messages from './messages';

function UpcomingRaces(props) {
  const { user } = props;
  const [show, setShow] = useState(false);
  const [races] = useRequest({ url: '/virtual/upcoming', user });
  if (!races || races.loading || races.length === 0) {
    return null;
  }
  return (
    <React.Fragment>
      <ListRaces
        caption={messages.upcomingRaces}
        races={races}
        type="upcoming"
        onClickPrivate={() => setShow(true)}
      />
      {show && (
        <Modal show bsSize="small" onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage {...messages.ownRoute} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormattedMessage {...messages.ownPrivate} />
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
}

UpcomingRaces.propTypes = {
  user: PropTypes.object,
};
export default UpcomingRaces;
