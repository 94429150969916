/*
 * ConfirmButton Messages
 *
 * This contains all the text for the ConfirmButton component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmationText: {
    id: 'app.components.ConfirmButton.confirmationText',
    defaultMessage: 'Are you sure?',
  },
});
