/*
 * Footer Messages
 *
 * This contains all the text for the Footer component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  privacy: {
    id: 'app.components.Footer.privacy',
    defaultMessage: 'Privacy<span class="hidden-xs"> policy</span>',
  },
  support: {
    id: 'app.components.Footer.support',
    defaultMessage: 'Support',
  },
  help: {
    id: 'app.components.Footer.help',
    defaultMessage: 'Help',
  },
  settings: {
    id: 'app.components.Footer.settings',
    defaultMessage: 'Settings',
  },
  premium: {
    id: 'app.components.Footer.premium',
    defaultMessage: 'No advertisement (more space) and better maps',
  },
});
