import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import useLocale from 'containers/SettingsProvider/useLocale';
import { lightBlack } from 'styles/colors';

const StyledMarkdown = styled(ReactMarkdown)`
  h2 {
    font-weight: bold;
    color: ${lightBlack};
  }
  ul,
  ol {
    padding-left: 20px;
  }
`;

function Markdown(props) {
  const { source } = props;
  const locale = useLocale();
  const newSource =
    typeof source === 'string'
      ? source
      : source[locale] || source.en || source.nl;
  return <StyledMarkdown {...props} source={newSource} />;
}

Markdown.propTypes = {
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Markdown;
