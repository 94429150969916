/*
 *
 * User actions
 *
 */

import {
  USER_CHANGE,
  USER_LOADING,
  USER_SIGNOUT,
  USER_ERROR,
  USER_API,
  USER_SIMULATE,
  USER_STRAVA,
  USER_PROFILE,
  USER_SEND_SIGNUP,
  USER_CONFIRM_SIGNUP,
} from './constants';

export function userSignOut() {
  return {
    type: USER_SIGNOUT,
  };
}

export function userError(error) {
  return {
    type: USER_ERROR,
    error,
  };
}

export function userChange(user) {
  return {
    type: USER_CHANGE,
    user,
  };
}

export function userSimulate(email) {
  return {
    type: USER_SIMULATE,
    email,
  };
}

export function userLoading(value) {
  return {
    type: USER_LOADING,
    value,
  };
}

export function userApi(data) {
  return {
    type: USER_API,
    data,
  };
}

export function userStrava(data) {
  return {
    type: USER_STRAVA,
    data,
  };
}

export function userProfile(data) {
  return {
    type: USER_PROFILE,
    data,
  };
}

export function userSendSignUp(email) {
  return {
    type: USER_SEND_SIGNUP,
    email,
  };
}

export function userConfirmSignUp(email) {
  return {
    type: USER_CONFIRM_SIGNUP,
    email,
  };
}
