/**
 *
 * SettingsLink
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { red500 } from 'styles/colors';

import messages from './messages';

const Wrapper = styled(Button)`
  &.btn {
    text-align: left;
  }
  svg {
    color: ${red500} !important;
  }
  display: flex;
`;

function PremiumLink(props) {
  const { className } = props;
  const history = useHistory();
  return (
    <Wrapper
      bsStyle="link"
      className={className}
      onClick={() => history.push('#subscription')}
    >
      <FontAwesomeIcon icon={['far', 'thumbs-up']} size="lg" fixedWidth />
      <div className="small">
        <div className="font-weight-bold">RunnerMaps Premium</div>
        <div className="small text-wrap">
          <FormattedMessage {...messages.premium} />
        </div>
      </div>
    </Wrapper>
  );
}

PremiumLink.propTypes = {
  className: PropTypes.string,
};

export default PremiumLink;
