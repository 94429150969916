import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the kmVretersPage state domain
 */

const selectKmVretersPageDomain = state =>
  state.get('kmVretersPage', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by KmVretersPage
 */

const makeSelectKmVretersPage = () =>
  createSelector(selectKmVretersPageDomain, substate => substate.toJS());

export default makeSelectKmVretersPage;
export { selectKmVretersPageDomain };
