/**
 *
 * Privacy
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { Modal, Nav, NavItem } from 'react-bootstrap';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { red500 } from 'styles/colors';
import privacy from './privacy';
import conditions from './conditions';
import messages from './messages';
import Markdown from '../Markdown';

const Wrapper = styled(Modal)`
  .modal-header {
    border-color: ${red500};
  }
  .modal-body {
    padding-right: 0;
    font-size: 1.2rem;
    h2 {
      font-size: 1.4em;
    }
  }
  .modal-footer {
    text-align: left;
    font-size: 1.2rem;
  }
`;

const FixHeight = styled.div`
  max-height: calc(100vh - 200px);
  overflow-y: auto;
`;

function Privacy(props) {
  const { onHide } = props;
  const { hash } = useLocation();
  const activeKey = hash.startsWith('#conditions') ? 'conditions' : 'privacy';
  return (
    <Wrapper show bsSize="large" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage {...messages.header} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <Nav bsStyle="tabs" activeKey={activeKey} id="privacy-modal">
          <NavItem eventKey="privacy" href="#privacy">
            <FormattedMessage {...messages.privacy} />
          </NavItem>
          <NavItem eventKey="conditions" href="#conditions">
            <FormattedMessage {...messages.conditions} />
          </NavItem>
        </Nav>
        <FixHeight>
          {activeKey === 'privacy' && (
            <Markdown source={privacy} linkTarget="_blank" />
          )}
          {activeKey === 'conditions' && (
            <Markdown source={conditions} linkTarget="_blank" />
          )}
        </FixHeight>
      </Modal.Body>
      <Modal.Footer className="d-sm-flex justify-content-between py-3 text-left">
        <div className="flex-grow-1">
          <FormattedHTMLMessage {...messages.about} />
        </div>
        <div className="text-sm-right">
          Contact:{' '}
          <a href="mailto:harry+support@runnermaps.nl">harry@runnermaps.nl</a>
        </div>
      </Modal.Footer>
    </Wrapper>
  );
}

Privacy.propTypes = {
  onHide: PropTypes.func,
};

export default Privacy;
