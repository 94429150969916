import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { request2 } from 'utils/request';
import useCurrentUser from './useCurrentUser';
import { userStrava } from './actions';
import { STRAVA_LOADING, STRAVA_CONNECTED, STRAVA_NONE } from './constants';

function useStrava() {
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const { api, strava } = user;
  const loggedIn = api && api.hashKey;
  useEffect(
    () => {
      if (loggedIn && (!strava || strava.status !== STRAVA_LOADING)) {
        if (!user.api.stravaId) {
          dispatch(userStrava({ status: STRAVA_NONE }));
          return;
        }
        dispatch(userStrava({ status: STRAVA_LOADING }));
        request2({ url: `/strava/athlete`, user }).then(data => {
          const newData = data
            ? { ...data, status: STRAVA_CONNECTED }
            : { status: STRAVA_NONE };
          dispatch(userStrava(newData));
        });
      }
    },
    [loggedIn],
  );
}

useStrava.propTypes = {
  user: PropTypes.object,
  onSuccess: PropTypes.func,
};

export default useStrava;
