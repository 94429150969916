/**
 *
 * DateString
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import useLocale from 'containers/SettingsProvider/useLocale';
import { period } from 'utils/datetime';
import { subYears } from 'date-fns';

function DateString(props) {
  const { date, endDate, className, format } = props;
  const yearString =
    new Date(date) < subYears(new Date(), 1) ? ` ${date.slice(0, 4)}` : '';
  const locale = useLocale();
  return (
    <span className={className}>
      {period(date, endDate, format, locale)}
      {yearString}
    </span>
  );
}

DateString.propTypes = {
  date: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  format: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default DateString;
