/* eslint-disable no-irregular-whitespace */
const privacy = {
  nl: `
## Privacybeleid

Wij respecteren de privacy van alle gebruikers van onze site en dragen er zorg voor dat de persoonlijke informatie die je ons verschaft vertrouwelijk wordt behandeld. Hiertoe wordt niet meer informatie opgeslagen dan nodig is om de gebruiker te identificeren, gewenste instellingen te onthouden, eventueel routes te kunnen delen en de klassementen te kunnen opmaken voor de Kilometervretercompetitie. Op deze site verzamelde gegevens worden nooit gedeeld met derden, met uitzondering van:

*   Openbare routes zijn zichtbaar voor alle bezoekers van de website, maar een bezoeker kan niet zien wie de route gemaakt heeft. Als een route niet als openbaar is gemarkeerd, is deze alleen zichtbaar als het juiste webadres wordt ingevoerd.
*   Als je deelneemt aan de Kilometervretercompetitie, zijn je profiel en je geregistreerde wedstrijden zichtbaar voor andere bezoekers. Als je geen profiel invult, zijn ook je wedstrijden niet zichtbaar.
*   Als je deelneemt aan een Virtuele Wedstrijd zijn je naam en de wedstrijdtijd zichtbaar op de site, alsmede een link naar je Strava profiel of activiteit. Op Strava kun je instellen of die gegevens al dan niet zichtbaar zijn voor anderen dan je volgers. Virtuele wedstrijden zijn vervangen door testlopen, hierin wordt geen informatie met anderen gedeeld

Het gebruik van de website wordt vastgelegd in logfiles, waarin tijdstip, uw IP-adres en de bezochte pagina’s staan. Dit logbestand kan worden gebruikt om problemen te detecteren en te verhelpen. Daarnaast wordt Google Analytics gebruikt voor analyses van het gebruik van de website, zie de tekst hieronder.

Uw gegevens kunnen worden verwijderd als u dat wilt. U kunt hiervoor [contact op nemen via email](mailto:harry+support@runnermaps.nl).

## Cookies

RunnerMaps maakt gebruik van “cookies” en vergelijkbare technieken om de functionaliteit van de website te verbeteren. Hiermee wordt informatie opgeslagen: om de kaartinstellingen en eventueel je aanmelding te onthouden.

Je kunt zelf bepalen hoe er met cookies omgegaan moet worden. Je kunt je browser zo instellen dat die het gebruik van cookies toestaat, niet toestaat of gedeeltelijk toestaat. In dit laatste geval kunt je instellen welke websites cookies mogen plaatsen. Alle overige websites wordt het plaatsen van cookies dan verboden. Deze mogelijkheid wordt door de meestgebruikte moderne browsers geboden.

Cookies kunnen altijd van een computer worden verwijderd, ook weer via de browser.

## Google Analytics

Deze website maakt gebruik van Google Analytics, een webanalyse-service die wordt aangeboden door Google Inc. (“Google”). Google Analytics maakt gebruik van “cookies” (tekstbestandjes die op Uw computer worden geplaatst) om de website te helpen analyseren hoe gebruikers de site gebruiken. De door het cookie gegenereerde informatie over Uw gebruik van de website (met inbegrip van Uw IP-adres) wordt overgebracht naar en door Google opgeslagen op servers in de Verenigde Staten. Google gebruikt deze informatie om bij te houden hoe u de website gebruikt, rapporten over de website-activiteit op te stellen voor website-exploitanten en andere diensten aan te bieden met betrekking tot website-activiteit en internetgebruik. Google mag deze informatie aan derden verschaffen indien Google hiertoe wettelijk wordt verplicht, of voor zover deze derden de informatie namens Google verwerken. Google zal Uw IP-adres niet combineren met andere gegevens waarover Google beschikt. U kunt het gebruik van cookies weigeren door in Uw browser de daarvoor geëigende instellingen te kiezen. Wij wijzen u er echter op dat u in dat geval wellicht niet alle mogelijkheden van deze website kunt benutten. Door gebruik te maken van deze website geeft u toestemming voor het verwerken van de informatie door Google op de wijze en voor de doeleinden zoals hiervoor omschreven

## Advertenties

Externe leveranciers (inclusief Google) maken gebruik van cookies om advertenties weer te geven op basis van eerdere bezoeken van een gebruiker aan deze website. Met de DoubleClick-cookie kunnen Google en zijn partners advertenties weergeven op basis van uw bezoek aan deze site en/of andere sites op internet. U kunt zich afmelden voor het gebruik van de DoubleClick-cookie voor op interesses gebaseerd adverteren door het [beheer van advertentievoorkeuren](http://www.google.com/ads/preferences/) te bezoeken.

Externe leveranciers en advertentienetwerken geven ook advertenties weer op deze site. Je kunt je afmelden voor cookies van externe leveranciers voor op interesses gebaseerd adverteren door [aboutads.info](http://www.aboutads.info/) te bezoeken.
  `,
  en: `
## Privacy Policy
 
We respect the privacy of all users of this site and take care that personal information that you provide us with will be dealt with confidentially. No more information will be stored than necessary for the purpose of identifying the user, remembering the user’s preferences, optionally sharing your routes and preparing the rankings for the Mile Eaters Competition and the Test Runs. Information collected on this site will not be shared with third parties, except for the following:

* Public routes are visible to all visitors of the site, but a visitor cannot see who created the route. If a route is marked as not public, it is only visible if the correct web address is entered.
* If you participate in the Mile Eaters Competition, your profile and registered races are visible to other visitors. If you do not fill in a profile, your races will also not be visible.
* If you participate in a Virtual Race, your name and race times are visible on the site, and also a link to your Strava profile or activity. On Strava, you can control whether these data are visible to others than your followers. Virtual Races have now been replaced with Test Runs, in which no data is accessible to others.

The usage of this web site is stored in log files, in which the time, your IP-address and the visited pages are recorded. This log file can be used to detect and fix problems. In addition, Google Analytics is used for analyses of the use of the website, see the text below.

Your data can be removed on your request. To do so, please [contact us via email](mailto:harry+support@runnermaps.nl).

## Cookies

This web site uses cookies and similar techniques to optimize the functionality of the site. With these techniques, information can be stored on your computer, for example to remember your latest map settings or registration.

You can control how cookies are dealt with. You can adjust you browser to allow the use of cookies, deny, or partially allow it. You can set which web sites may use cookies, and disallow all other web sites. These options are available in most modern browsers.

Cookies can always be removed from the computer, also via the browser.

## Google Analytics

This website uses Google Analytics, a web analytics service provided by Google, Inc. (“Google”). Google Analytics uses “cookies”, which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States . Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.

## Advertisements

Third party vendors, including Google, use cookies to serve ads based on a user’s prior visits to your website. Google’s use of the DART cookie enables it and its partners to serve ads to your users based on their visit to your sites and/or other sites on the Internet. Users may opt out of the use of the DART cookie by visiting the [advertising opt-out page](http://www.google.com/privacy_ads.html).
  `,
};

export default privacy;
