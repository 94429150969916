/**
 *
 * KmMarker
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
import L from 'leaflet';
import { Marker } from 'react-leaflet';
import { yellowA200, black } from 'styles/colors';

export const KmMarkerStyles = createGlobalStyle`
  .km-marker {
    border: 1px solid ${black};
    color: ${black};
    background-color: ${yellowA200};
    width: auto !important;
    padding: 1px 1px;
    text-align: center;
    min-width: 12px;
    font-size: 10px;
    line-height: 1;
  }
`;

function KmMarker(props) {
  const { pos, km } = props;
  const icon = L.divIcon({
    className: 'km-marker',
    html: km,
  });
  return <Marker position={pos} icon={icon} interactive={false} />;
}

KmMarker.propTypes = {
  pos: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  km: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default KmMarker;
