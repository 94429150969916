/**
 *
 * Members
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Alert, Button, Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { green500, red100 } from 'styles/colors';
import iconColors from 'styles/iconColors';
import messages from '../messages';
import Attended from './Attended';
import getDOW from '../getDOW';

const Wrapper = styled(Table)`
  .tdc {
    text-align: center;
  }
  .btn {
    margin-top: -6px;
    margin-bottom: -6px;
  }
  ${iconColors};
  .fa-user {
    color: ${red100};
  }
  .fa-user-check {
    color: ${green500};
  }
  svg + svg {
    margin-left: 0.5rem;
  }
  span + span {
    margin-left: 1rem;
  }
  .td-flex {
    display: flex;
    justify-content: space-around;
  }
`;

function Members(props) {
  const { members, schedule, onChange } = props;
  if (members.length === 0) {
    return (
      <Alert bsStyle="info">
        <FormattedMessage {...messages.noMembers} />
      </Alert>
    );
  }
  return (
    <React.Fragment>
      <Wrapper responsive hover>
        <thead>
          <tr>
            <th>
              <FormattedMessage {...messages.name} />
            </th>
            <th className="tdc">
              {schedule.map(s => (
                <span key={s.weekday}>{getDOW(s.weekday).slice(0, 2)}</span>
              ))}
            </th>
            <th className="tdc">
              <FormattedMessage {...messages.coach} />
            </th>
            <th width="40%">
              <FormattedMessage {...messages.attended} />
            </th>
          </tr>
        </thead>
        <tbody>
          {members
            .map(member => ({
              ...member,
              sched: member.schedule
                ? member.schedule.split(',').map(Number)
                : [],
            }))
            .map(member => (
              <tr key={member.id}>
                <td>{member.name}</td>
                <td className="tdc">
                  {schedule.map(s => (
                    <FontAwesomeIcon
                      key={s.id}
                      icon={member.sched.includes(s.id) ? 'check' : 'minus'}
                      fixedWidth
                    />
                  ))}
                </td>
                <td className="tdc">
                  <Button
                    bsStyle="link"
                    onClick={() => onChange(member.id, !member.editor)}
                  >
                    <FontAwesomeIcon
                      icon={member.editor ? 'user-check' : 'user'}
                      fixedWidth
                    />
                  </Button>
                </td>
                <td>
                  <Attended score={member.score} />
                </td>
              </tr>
            ))}
          <tr />
        </tbody>
      </Wrapper>
      <p className="help-block">
        <FormattedMessage {...messages.coachInfo} />
      </p>
    </React.Fragment>
  );
}

Members.propTypes = {
  members: PropTypes.array,
  schedule: PropTypes.array,
  onChange: PropTypes.func,
};

export default Members;
