import { useEffect, useState } from 'react';
import useFetch from './useFetch';

function useInfo(userId) {
  const [userInfo, setUserInfo] = useState(null);
  const fetch = useFetch();
  useEffect(
    () => {
      fetch(`/manage/users/${userId}`).then(data => {
        setUserInfo(data);
      });
    },
    [userId],
  );
  return userInfo;
}

export default useInfo;
