/**
 *
 * ActivityStats
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { nl, en } from 'date-fns/locale';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { parseISO } from 'date-fns';
import useLocale from 'containers/SettingsProvider/useLocale';
import formatTime from 'utils/formatTime';
import { formatNoDot, multiDay } from 'utils/datetime';
import { ENDASH } from 'utils/characters';
import messages from '../messages';

const Dl = styled.dl`
  min-width: 30rem;
  dt {
    float: left;
    width: 15rem;
    white-space: nowrap;
    text-align: right;
    padding-right: 1rem;
    height: 3rem;
    clear: left;
  }
  dd {
    display: block;
    height: 3rem;
  }
`;

function ActivityStats(props) {
  const { activity } = props;
  const {
    distance,
    start_date,
    moving_time,
    elapsed_time,
    race_distance,
    race_time,
    source,
    d_start,
    t_start,
  } = activity;
  const lang = useLocale();
  const locale = lang === 'nl' ? nl : en;

  function formatDist(d) {
    return (d / 1000).toFixed(2);
  }
  const formatTpl = multiDay(activity) ? 'd MMM H:mm' : 'H:mm';
  return (
    <div className="my-4 d-flex flex-column flex-md-row justify-content-between align-items-top">
      <Dl className="mr-4">
        <dt>
          <FormattedMessage {...messages.startDate} />
        </dt>
        <dd>{formatNoDot(parseISO(start_date), formatTpl, { locale })}</dd>
        <dt>
          <FormattedMessage {...messages.distance} />
        </dt>
        <dd>{`${formatDist(distance)} km`}</dd>
        <dt>
          <FormattedMessage {...messages.elapsedTime} />
        </dt>
        <dd>{formatTime(elapsed_time)}</dd>
        <dt>
          <FormattedMessage {...messages.movingTime} />
        </dt>
        <dd>{formatTime(moving_time)}</dd>
      </Dl>
      <Dl>
        <dt>
          <FormattedMessage {...messages.testDistanceTime} />
        </dt>
        <dd>{formatTime(race_time, 1)}</dd>
        <dt>
          <FormattedMessage {...messages.pace} />
        </dt>
        <dd>{`${formatTime((1000 * race_time) / race_distance)} min/km`}</dd>
        {source === 'streams' && (
          <React.Fragment>
            <dt>
              <FormattedMessage {...messages.fastestPart} />
            </dt>
            <dd>
              {`${formatDist(d_start)} ${ENDASH} ${formatDist(
                d_start + race_distance,
              )} km`}
              <br />
              {`${formatTime(t_start)} ${ENDASH} ${formatTime(
                t_start + race_time,
              )}`}
            </dd>
          </React.Fragment>
        )}
      </Dl>
    </div>
  );
}

ActivityStats.propTypes = {
  activity: PropTypes.object,
};

export default ActivityStats;
