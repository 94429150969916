/**
 *
 * DatePickerWithSuggestions
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { API_URL } from 'config/constants';
import request from 'utils/request';

function DatePickerWithSuggestions(props) {
  const { value, onSuggestions } = props;

  function fetchForDate() {
    if (!onSuggestions) {
      return;
    }
    if (!value) {
      onSuggestions([]);
      return;
    }
    const url = `${API_URL}/kmvreters/date/${value}`;
    request(url)
      .then(onSuggestions)
      .catch(() => onSuggestions([]));
  }

  useEffect(fetchForDate, [value]);
  return <input {...omit(props, ['onSuggestions'])} type="date" />;
}

DatePickerWithSuggestions.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSuggestions: PropTypes.func,
};

export default DatePickerWithSuggestions;
