/**
 *
 * LayersControl
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { compose } from 'redux';
import { LayersControl, TileLayer, withLeaflet } from 'react-leaflet';
import { injectIntl, intlShape } from 'react-intl';
import withUser from 'containers/User/withUser';
import { getMapTypes, getOverlays } from 'utils/mapTypes';
import messages from './messages';

const { BaseLayer, Overlay } = LayersControl;

function MyLayersControl(props) {
  const { travelMode, current, intl, user } = props;
  const typeOptions = {
    travelMode,
    access: user,
  };
  const layers = getMapTypes(typeOptions);
  const currentLayer = layers.find(type => type.id === current) || layers[0];
  const overlays = getOverlays(typeOptions);
  return (
    <LayersControl position="topright">
      {layers.map(type => (
        <BaseLayer
          checked={type.id === currentLayer.id}
          key={type.id}
          name={intl.formatMessage(messages[type.id])}
        >
          <TileLayer {...type} />
        </BaseLayer>
      ))}
      {overlays.map(type => (
        <Overlay key={type.id} name={intl.formatMessage(messages[type.id])}>
          <TileLayer {...type} />
        </Overlay>
      ))}
    </LayersControl>
  );
}

MyLayersControl.propTypes = {
  user: PropTypes.object,
  travelMode: PropTypes.string,
  current: PropTypes.string,
  intl: intlShape,
};

export default compose(
  withLeaflet,
  withUser,
  injectIntl,
)(MyLayersControl);
