/**
 *
 * Dialog
 * based on https://stackoverflow.com/questions/35623656/how-can-i-display-a-modal-dialog-in-redux-that-performs-asynchronous-actions/35641680#35641680
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Button, Modal } from 'react-bootstrap';
import * as colors from 'styles/colors';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import { DAEMON } from 'utils/constants';
import IntlString from 'components/IntlString';
import makeSelectDialog from './selectors';
import { hideDialog } from './actions';
import reducer from './reducer';
import saga from './saga';
import { DIALOG_ALERT, DIALOG_CONFIRM, DIALOG_PROMPT } from './constants';
import messages from './messages';

const Wrapper = styled(Modal)`
  &.modal {
    .modal-dialog {
      margin-top: 40px;
    }
    .modal-header {
      border-bottom: none;
      h4.modal-title {
        font-size: 1.6rem;
        line-height: 1.65;
      }
    }
    .modal-footer {
      display: flex;
      justify-content: flex-end;
      border-top: none;
      .btn {
        min-width: 5em;
      }
      .btn + .btn {
        margin-left: 15px;
      }
    }
    .form-control {
      margin-top: 15px;
    }
  }
  &.modal.dialog-danger .modal-content {
    color: ${colors.red700};
  }
`;

export const defaultButtons = [
  { label: messages.cancel, value: false, style: 'default' },
  { label: messages.ok, value: true, style: 'primary' },
];

export const yesNoButtons = [
  { label: messages.no, value: false, style: 'danger' },
  { label: messages.yes, value: true, style: 'success' },
];

export class Dialog extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  state = { value: '' };

  handleChange = ev => {
    this.setState({ value: ev.target.value });
  };

  handleHide = result => () => {
    const { onHide, dialog } = this.props;
    return onHide(result, dialog.promise);
  };

  getButtons() {
    const { dialog } = this.props;
    if (dialog.modalProps.buttons) {
      return dialog.modalProps.buttons;
    }
    return dialog.modalProps.yesNo ? yesNoButtons : defaultButtons;
  }

  renderFooter() {
    const { dialog } = this.props;
    const { value } = this.state;
    const buttons = this.getButtons();
    switch (dialog.modalType) {
      case DIALOG_ALERT:
        return (
          <Button type="button" bsStyle="primary" onClick={this.handleHide()}>
            OK
          </Button>
        );
      case DIALOG_CONFIRM:
        return buttons.map(button => (
          <Button
            key={button.value}
            type="button"
            bsStyle={button.style}
            onClick={this.handleHide(button.value)}
          >
            <IntlString message={button.label} />
          </Button>
        ));
      case DIALOG_PROMPT:
        return defaultButtons.map(button => (
          <Button
            key={button.value}
            type="button"
            bsStyle={button.style}
            onClick={this.handleHide(button.value && value)}
          >
            <IntlString message={button.label} />
          </Button>
        ));
      default:
        return null;
    }
  }
  /* eslint-disable jsx-a11y/no-autofocus */
  render() {
    const { onHide, dialog } = this.props;
    const { value } = this.state;
    if (!dialog.modalType) {
      return null;
    }
    const { title, message, rows } = dialog.modalProps;
    const inputType = rows > 1 ? 'textarea' : undefined;
    const backdrop = dialog.modalType === DIALOG_ALERT || 'static';
    const isError =
      dialog.modalType === DIALOG_ALERT &&
      dialog.modalProps.bsStyle === 'danger';
    const className = isError ? 'dialog-danger' : '';
    return (
      <Wrapper
        show
        bsSize="small"
        backdrop={backdrop}
        className={className}
        onHide={isError ? this.handleHide() : onHide}
      >
        {title && (
          <Modal.Header closeButton={isError}>
            <Modal.Title>
              <IntlString message={title} />
            </Modal.Title>
          </Modal.Header>
        )}
        {(message || dialog.modalType === DIALOG_PROMPT) && (
          <Modal.Body>
            {message && (
              <div>
                <IntlString message={message} />
              </div>
            )}
            {dialog.modalType === DIALOG_PROMPT && (
              <input
                className="form-control"
                autoFocus
                type={inputType}
                rows={rows}
                value={value}
                onChange={this.handleChange}
              />
            )}
          </Modal.Body>
        )}
        {!isError && <Modal.Footer>{this.renderFooter()}</Modal.Footer>}
      </Wrapper>
    );
  }
}

Dialog.propTypes = {
  dialog: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  dialog: makeSelectDialog(),
});

function mapDispatchToProps(dispatch) {
  return {
    onHide: (result, promise) => dispatch(hideDialog(result, promise)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'dialog', reducer });
const withSaga = injectSaga({ key: 'dialog', saga, mode: DAEMON });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(Dialog);
