import { format, parseISO } from 'date-fns';
import { nl, en } from 'date-fns/locale';
import { ENDASH } from './characters';

export const dateFormat = dt => format(dt, 'yyyy-MM-dd');
export const timeFormat = dt => format(dt, 'H:mm');

export function formatNoDot(...rest) {
  return format(...rest).replace(/\b([a-z]{3})\./, '$1');
}

export function multiDay(ev) {
  const { date, end_date } = ev;
  return end_date && end_date !== date;
}

export function fromServer(ts) {
  const dt = new Date(ts);
  return [dateFormat(dt), timeFormat(dt)];
}

export function toServer(date, time) {
  const dt = new Date(`${date} ${time}`);
  return dt.toISOString();
}

export function period(from, to, formatStr, lang) {
  const locale = lang === 'nl' ? nl : en;
  const fromDate = parseISO(from);
  if (!to || from === to) {
    return formatNoDot(fromDate, formatStr, { locale });
  }
  const toDate = parseISO(to);
  const toStr = formatNoDot(toDate, formatStr, { locale });
  if (toDate.getFullYear() !== fromDate.getFullYear()) {
    return `${formatNoDot(fromDate, formatStr, { locale })} ${ENDASH} ${toStr}`;
  }
  let shorter = formatStr.replace(/\W+y+$/, '');
  if (toDate.getMonth() !== fromDate.getMonth()) {
    return `${formatNoDot(fromDate, shorter, { locale })} ${ENDASH} ${toStr}`;
  }
  shorter = shorter.replace(/\W+M+$/, '');
  return `${formatNoDot(fromDate, shorter, { locale })}${ENDASH}${toStr}`;
}
