/*
 * UserControl Messages
 *
 * This contains all the text for the UserControl component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  signIn: {
    id: 'app.components.UserControl.signIn',
    defaultMessage: 'Sign In',
  },
  signOut: {
    id: 'app.components.UserControl.signOut',
    defaultMessage: 'Sign Out',
  },
  account: {
    id: 'app.components.UserControl.account',
    defaultMessage: 'Account',
  },
  login: {
    id: 'app.containers.KmVretersPage.login',
    defaultMessage: 'You must <a href="#login">sign in</a> first',
  },
});
