// import { take, call, put, select } from 'redux-saga/effects';
import { put, takeEvery } from 'redux-saga/effects';
import sleep from 'utils/sleep';

import { ALERT_ADD } from './constants';
import { dismissAlert } from './actions';

export function* setTimer(action) {
  if (action.options.autoDismiss) {
    yield sleep(5000);
    yield put(dismissAlert(action.options.id));
  }
}

// Individual exports for testing
export default function* defaultSaga() {
  yield takeEvery(ALERT_ADD, setTimer);
}
