/**
 *
 * GroupForm
 *
 */

import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import PrimaryAction from 'components/PrimaryAction';
// import authHeader from 'utils/authHeader';
import { H2 } from 'components/Virtual/styleComponents';
import { green200 } from 'styles/colors';
import messages from '../messages';
import groupReducer from './groupReducer';

const IconButton = styled(Button)`
  svg + span {
    margin-left: 0.5em;
    @media (max-width: 479px) {
      display: none;
    }
  }
`;

const Fieldset = styled.div`
  border: 1px solid ${green200};
  border-radius: 4px;
  padding: 10px 15px 0;
  margin-bottom: 15px;
  .btn {
    margin-bottom: 15px;
    &[disabled] {
      display: none;
    }
  }
  input[type='text'] {
    max-width: 12em;
  }
  @media (min-width: 468px) {
    display: flex;
    justify-content: stretch;
    align-items: flex-end;
    .form-group {
      flex-grow: 1;
      margin-right: 10px;
    }
    &[disabled] {
      display: inline-block;
      visibility: hidden;
    }
  }
  select,
  input[type='time'],
  input[type='number'] {
    max-width: 8em;
  }
`;

function GroupForm(props) {
  const { group, onSubmit } = props;
  const [state, dispatch] = useReducer(groupReducer, group);
  const { name, schedule } = state;
  function handleSubmit(ev) {
    ev.preventDefault();
    onSubmit(state);
  }
  const canDelete = Object.keys(schedule).length > 1;
  const cancelLink = group.hash ? `/inschrijven/${group.hash}` : '/inschrijven';
  return (
    <form onSubmit={handleSubmit}>
      <FormGroup controlId="name">
        <ControlLabel>
          <FormattedMessage {...messages.groupName} />
        </ControlLabel>
        <FormControl
          type="text"
          value={name}
          onChange={ev => dispatch({ type: 'name', value: ev.target.value })}
          required
        />
      </FormGroup>
      <H2>
        <FormattedMessage {...messages.trainingSessions} />
      </H2>
      {Object.keys(schedule).map(id => (
        <Fieldset key={id}>
          <FormGroup controlId={`weekday-${id}`}>
            <ControlLabel>
              <FormattedMessage {...messages.weekday} />
            </ControlLabel>
            <FormControl
              componentClass="select"
              value={schedule[id].weekday}
              onChange={ev =>
                dispatch({
                  type: 'schedule',
                  id,
                  prop: 'weekday',
                  value: Number(ev.target.value),
                })
              }
            >
              <FormattedMessage {...messages.weekdays}>
                {text => {
                  const days = text.split('|');
                  return days.map((day, val) => (
                    <option key={day} value={val}>
                      {day}
                    </option>
                  ));
                }}
              </FormattedMessage>
            </FormControl>
          </FormGroup>
          <FormGroup controlId={`time-${id}`}>
            <ControlLabel>
              <FormattedMessage {...messages.startTime} />
            </ControlLabel>
            <FormControl
              type="time"
              value={schedule[id].startTime}
              onChange={ev =>
                dispatch({
                  type: 'schedule',
                  id,
                  prop: 'startTime',
                  value: ev.target.value,
                })
              }
              step={300}
              required
            />
          </FormGroup>
          <FormGroup controlId={`size-${id}`}>
            <ControlLabel>
              <FormattedMessage {...messages.maxSize} />
            </ControlLabel>
            <FormControl
              type="number"
              value={schedule[id].maxSize}
              min={1}
              max={255}
              onChange={ev =>
                dispatch({
                  type: 'schedule',
                  id,
                  prop: 'maxSize',
                  value: ev.target.value,
                })
              }
              required
            />
          </FormGroup>
          <FormGroup controlId={`location-${id}`}>
            <ControlLabel>
              <FormattedMessage {...messages.location} />
            </ControlLabel>
            <FormControl
              type="text"
              value={schedule[id].location}
              onChange={ev =>
                dispatch({
                  type: 'schedule',
                  id,
                  prop: 'location',
                  value: ev.target.value,
                })
              }
              required
            />
          </FormGroup>
          <Button
            bsStyle="danger"
            disabled={!canDelete}
            onClick={() =>
              dispatch({
                type: 'delete',
                id,
              })
            }
          >
            <FontAwesomeIcon size="lg" icon="times" />
          </Button>
        </Fieldset>
      ))}
      <PrimaryAction onCancel={cancelLink}>
        <IconButton
          bsStyle="primary"
          onClick={() =>
            dispatch({
              type: 'addSession',
            })
          }
        >
          <FontAwesomeIcon size="lg" icon="plus" />
          <FormattedMessage {...messages.addSession} />
        </IconButton>
      </PrimaryAction>
    </form>
  );
}

GroupForm.propTypes = {
  group: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default GroupForm;
