/*
 * User Messages
 *
 * This contains all the text for the User component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  emailIntro: {
    id: 'app.containers.User.emailIntro',
    defaultMessage:
      'Please confirm your email address to complete the signin process',
  },
  emailConfirm: {
    id: 'app.containers.User.emailConfirm',
    defaultMessage: 'Confirm',
  },
});
