import { format } from 'date-fns';
import { staticLocale } from 'utils/useDateLocale';

function formatDate(secs, frmt = 'PPp') {
  if (!secs) {
    return '';
  }
  const date = new Date(secs * 1000);
  return format(date, frmt, staticLocale);
}

export default formatDate;
