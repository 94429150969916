import 'whatwg-fetch';
import { useEffect } from 'react';
import { API2_URL } from 'config/constants';
import sleep from './sleep';
import useSafeState from './useSafeState';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  const contentType = response.headers.get('Content-Type');
  return /json/.test(contentType) ? response.json() : response.text();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * fetch and retry on fail
 * @param {string} uri
 * @param {object} options
 */
async function retryFetch(uri, options) {
  const maxAttempts = 5;
  let error;
  // eslint-disable-next-line no-plusplus
  for (let attempt = 0; attempt < maxAttempts; attempt++) {
    try {
      // eslint-disable-next-line no-await-in-loop
      return await fetch(uri, options);
    } catch (err) {
      error = err;
      // eslint-disable-next-line no-await-in-loop
      await sleep(1000);
    }
  }
  error.request = options; // eslint-disable-line no-param-reassign
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */

export default function request(url, options, trackFunction) {
  const t0 = Date.now();
  return retryFetch(url, options)
    .then(track(trackFunction, t0))
    .then(checkStatus)
    .then(parseJSON);
}

export function track(trackFunction, t0) {
  return response => {
    if (trackFunction) {
      trackFunction(response, Date.now() - t0);
    }
    return response;
  };
}

export function request2(options, trackFunction) {
  const { url, user, body, headers, ...rest } = options;
  if (!url) {
    return Promise.resolve();
  }
  const newUrl = url.startsWith('/') ? `${API2_URL}${url}` : url;
  const newHeaders = headers || {};
  newHeaders['Content-Type'] = 'application/json';
  newHeaders.referrer = window.location.href;
  if (user && user.api) {
    newHeaders.Authorization = user.api.hashKey;
  }
  if (window.stravaUser) {
    newHeaders['X-Strava-User'] = window.stravaUser;
  }
  const newOptions = {
    ...rest,
    body: body && JSON.stringify(body),
    headers: newHeaders,
  };
  return request(newUrl, newOptions, trackFunction);
}

export function useRequest(options, trackFunction) {
  const [result, setResult] = useSafeState({ loading: true });
  const test = JSON.stringify(makeCmp(options));
  useEffect(
    () => {
      request2(options, trackFunction)
        .then(setResult)
        .catch(error => setResult({ loading: false, error }));
    },
    [test],
  );
  return [result, setResult];
}

function makeCmp(options) {
  const { user } = options;
  if (!user) {
    return options;
  }
  return {
    ...options,
    user: user.api && user.api.hashKey,
  };
}
