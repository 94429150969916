/**
 *
 * Overview
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';

import { FormattedMessage } from 'react-intl';
import messages from './messages';

/* eslint-disable react/prefer-stateless-function */
class Overview extends React.PureComponent {
  render() {
    return (
      <div>
        <FormattedMessage {...messages.title} />
      </div>
    );
  }
}

Overview.propTypes = {};

export default Overview;
