/**
 *
 * Debug
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'redux';
import { API_URL } from 'config/constants';
import {
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
  Table,
} from 'react-bootstrap';
import request from 'utils/request';
import withUser from 'containers/User/withUser';
import withRoute from 'containers/withRoute';
import formatDate from 'utils/formatDate';
import L from 'leaflet';
import Spinner from '../Spinner';
import MetaTags from '../MetaTags';

const Wrapper = styled.div`
  padding: 10px;
  @media (orientation: portrait) and (min-width: 768px) {
    width: 748px;
    margin: 0 auto;
  }
  @media (orientation: landscape) and (min-width: 878px) {
    width: 748px;
    margin: 0 auto;
  }
  th {
    text-align: center;
  }
`;

const UsersTable = styled(Table)`
  td:not(:nth-child(2)) {
    text-align: right;
  }
`;

const RoutesTable = styled(Table)`
  td:last-child {
    white-space: nowrap;
    text-align: right;
  }

  td:first-child,
  td:nth-child(4) {
    text-align: right;
  }
  td:nth-child(5) {
    text-align: center;
  }
`;

const Form = styled.form`
  display: flex;
  .form-group {
    margin-bottom: 0;
    margin-right: 15px;
  }
`;

class Debug extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      loading: false,
      users: [],
      selected: undefined,
      routes: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  request(url, params) {
    const { user } = this.props;
    const headers = {};
    if (!user || !user.api) {
      return null;
    }
    headers.Authorization = user.api.hashKey;
    const fullUrl = `${API_URL}/${url}${L.Util.getParamString(params)}`;
    return request(fullUrl, { headers });
  }

  handleChange(ev) {
    this.setState({ email: ev.target.value });
  }

  setSelected(selected) {
    return () => {
      this.setState(prev => {
        if (prev.selected === selected) {
          return {
            selected: undefined,
            routes: [],
          };
        }
        this.request(`debug/users/${selected.user_id}`)
          .then(routes => {
            this.setState({
              loading: false,
              routes,
            });
          })
          .catch(error => {
            this.setState({
              loading: false,
            });
            console.error(error); // eslint-disable-line no-console
          });
        return {
          selected,
          loading: true,
          routes: [],
        };
      });
    };
  }

  handleSearch(ev) {
    ev.preventDefault();
    const { email } = this.state;
    this.setState({ loading: true });
    this.request('/debug/users', { email: email || '%' })
      .then(users => {
        this.setState({
          loading: false,
          users,
          selected: undefined,
          routes: [],
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
        });
        console.error(error); // eslint-disable-line no-console
      });
  }

  renderForm() {
    const { email, loading } = this.state;
    return (
      <Form onSubmit={this.handleSearch}>
        <FormGroup controlId="email">
          <ControlLabel className="sr-only">Email</ControlLabel>
          <FormControl
            type="text"
            placeholder="Email"
            value={email}
            onChange={this.handleChange}
            autoFocus
          />
        </FormGroup>
        <Button type="submit" disabled={loading} bsStyle="primary">
          Search
        </Button>
      </Form>
    );
  }

  renderUsers() {
    const { users, selected, loading } = this.state;
    if (loading && !selected) {
      return <Spinner />;
    }
    if (users.length === 0) {
      return <p>No users</p>;
    }
    const fields = ['user_id', 'email', 'created', 'lastLogin', 'nRoutes'];
    const filtered = selected
      ? users.filter(user => user.user_id === selected.user_id)
      : users;
    return (
      <UsersTable responsive>
        <thead>
          <tr>{fields.map(field => <th key={field}>{field}</th>)}</tr>
        </thead>
        <tbody>
          {filtered.map(user => (
            <tr
              key={user.user_id}
              role="button"
              tabIndex={0}
              onClick={this.setSelected(user)}
            >
              {fields.map(field => <td key={field}>{user[field]}</td>)}
            </tr>
          ))}
        </tbody>
      </UsersTable>
    );
  }

  renderValue(value, field) {
    switch (field) {
      case 'id':
        return <a href={`/route/${value}`}>{value}</a>;
      case 'distance':
        return value.toFixed(2);
      case 'modified':
        return formatDate(value);
      default:
        return value;
    }
  }

  renderRoutes() {
    const { simulate } = this.props;
    const { routes, loading, selected } = this.state;
    if (!selected) {
      return null;
    }
    if (loading) {
      return <Spinner />;
    }
    if (routes.length === 0) {
      return <p>No routes</p>;
    }
    const fields = ['id', 'title', 'city', 'distance', 'public', 'modified'];
    return (
      <React.Fragment>
        <div className="text-right">
          <Button bsStyle="default" onClick={() => simulate(selected.email)}>
            Use as {selected.email}
          </Button>
        </div>
        <RoutesTable responsive>
          <thead>
            <tr>{fields.map(field => <th key={field}>{field}</th>)}</tr>
          </thead>
          <tbody>
            {routes.map(route => (
              <tr key={route.id}>
                {fields.map(field => (
                  <td key={field}>{this.renderValue(route[field], field)}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </RoutesTable>
      </React.Fragment>
    );
  }

  render() {
    const { user, goTo } = this.props;
    if (!user || !user.api) {
      return null;
    }
    if (!user.api.su) {
      goTo('/home');
    }
    const tags = {
      title: 'RunnerMaps Debug',
      description: 'Check user and routes',
    };
    return (
      <Wrapper>
        <MetaTags tags={tags} />
        {this.renderForm()}
        {this.renderUsers()}
        {this.renderRoutes()}
      </Wrapper>
    );
  }
}

Debug.propTypes = {
  user: PropTypes.object,
  simulate: PropTypes.func,
  goTo: PropTypes.func.isRequired,
};

export default compose(
  withRoute,
  withUser,
)(Debug);
