/**
 *
 * EditGroup
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { useRequest } from 'utils/request';
import Spinner from 'components/Spinner';
import GroupForm from './GroupForm';
import getTempId from './getTempId';

function EditGroup(props) {
  const { hash, onSubmit } = props;
  const [group] = useRequest({ url: `/res/groups/${hash}` });
  const { loading, name } = group;
  if (loading) {
    return <Spinner />;
  }
  const schedule = {};
  group.schedule.forEach(session => {
    schedule[getTempId()] = session;
  });
  const withSchedule = { name, schedule, hash };
  const handleSubmit = data => {
    const body = {
      name: data.name,
      schedule: Object.values(data.schedule),
    };
    onSubmit({
      method: 'PUT',
      url: `/res/groups/${hash}`,
      body,
    });
  };
  return <GroupForm group={withSchedule} onSubmit={handleSubmit} />;
}

EditGroup.propTypes = {
  hash: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default EditGroup;
