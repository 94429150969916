/**
 *
 * Start FirebaseUi
 *
 */

import { INITIAL_LOCALE } from 'containers/SettingsProvider/constants';
import 'firebaseui/dist/firebaseui.css';
import addScript from 'utils/addScript';
import firebase from './firebase';
import uiConfig from './uiConfig';

window.firebase = firebase;
const FB_SOURCE = `https://www.gstatic.com/firebasejs/ui/4.3.0/firebase-ui-auth__${INITIAL_LOCALE}.js`;

async function loadUi() {
  if (window.ui) {
    return;
  }
  await addScript({ wait: 'firebaseui', id: 'fb_ui', src: FB_SOURCE });
  window.ui = new window.firebaseui.auth.AuthUI(firebase.auth());
}

function fbStart(id) {
  return new Promise((resolve, reject) => {
    loadUi().then(() => {
      const config = {
        ...uiConfig,
        callbacks: {
          signInSuccessWithAuthResult: () => false,
          uiShown: resolve,
          signInFailure: reject,
        },
        credentialHelper: window.firebaseui.auth.CredentialHelper.NONE,
      };
      if (!window.ui.isPendingRedirect()) {
        window.ui.start(id, config);
      }
    });
  });
}

export default fbStart;
