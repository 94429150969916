// import { select } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga/effects';
import { DIALOG_HIDE } from './constants';

export function* resolvePromise(action) {
  if (action.promise) {
    if (action.promise.rejectOnFalse && action.result === false) {
      action.promise.reject(action.result);
    } else {
      action.promise.resolve(action.result);
    }
  }
}

export default function* defaultSaga() {
  yield takeEvery(DIALOG_HIDE, resolvePromise);
}
